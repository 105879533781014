import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalBasic } from '../../../actions/modalActions';
import { eliminarAsesorProducto } from '../../../actions/trabajadoresActions';
import FormularioAsignacion from './FormularioAsignacion';

const ProductoAsesores = ({producto}) => {
    
    const dispatch = useDispatch()
    const {productoAsesores} = useSelector(state=>state.trabajadores)
    const handleAgregarAsesor = (p) =>{
        dispatch(updateStateModalBasic(<FormularioAsignacion producto={p} />, 'Asignar Asesor' ))
    }
    const handleEliminarAsesor = (asesor)=>
    {
        dispatch(eliminarAsesorProducto(asesor,productoAsesores,producto))
    }
    return (
        <tr>
            <td>
                {producto.nombre_proyecto}    
                <div className='d-flex align-items-center ml-2'><span className='btn btn-info' style={{cursor:'pointer'}} title="Agregar asesor" onClick={()=>handleAgregarAsesor(producto)}><i className='fas fa-plus'></i></span></div>
            </td>  
            <td> 
                    <ul className='mt-4'>
                        {
                            producto.asesores.length ? producto.asesores.map((asesor,j)=>(
                                <li key={j} className="d-flex justify-content-between align-items-center"><p>{asesor.nombre_usuario} {asesor.apellido_usuario}</p> <span className='btn btn-danger btn-xs' style={{cursor:'pointer'}} title="Eliminar asesor" onClick={()=>handleEliminarAsesor(asesor)}><i className='fas fa-trash' ></i></span></li>
                            )) : 'No hay asesores asignados'
                        }
                    </ul>
            </td> 
        </tr>
    );
};

export default ProductoAsesores;