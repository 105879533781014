import React from 'react';

const Header = ({mes}) => {
    return (
        <div>
            <div id="header">
                <h3>
                <span>✧･ﾟ: *✧･ﾟ:* &nbsp;</span> 
                    Top Ventas {mes}
                <span>&nbsp;✧･ﾟ: *✧･ﾟ:*</span> 
                </h3>
            </div>
        </div>
    );
};

export default Header;