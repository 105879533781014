import {gruposAcademicosTypes} from '../actions/types'

const initialState = {
    grupos: [],
    estudiantes:[],
    loading:false,
}

export default function(state = initialState, action){
    switch (action.type) {
        case gruposAcademicosTypes.LISTAR_GRUPOS_ACA_EXITO:
            return{
                ...state,
                grupos: action.payload,
                loading:false,
            }
        case gruposAcademicosTypes.LISTAR_ESTUDIANTES_PRODUCTO_EXITO:
            return{
                ...state,
                estudiantes: action.payload,
                loading:false,
            }
        case gruposAcademicosTypes.LISTAR_ESTUDIANTES_PRODUCTO:
            return{
                ...state,
                loading:true,
        }
        case gruposAcademicosTypes.LISTAR_GRUPOS_ACA:
            return{
                ...state,
                loading:true,
        }
        default:
            return state
    }
} 