import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { importarLeads } from '../../../actions/leadsActions';
import useSelectProyecto from "../../../hooks/useSelectProyectos";

const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
}

const FormularioLead = () => {
    const [proyecto, SelectProyecto] = useSelectProyecto(null);
    const [mailChimp, setMailChimp] = useState(false);
    const [asignarAuto, setAsignarAuto] = useState(false);
    const [archivo, setArchivo] = useState(null);
    const { usuario } = useSelector(state => state.usuario);
    const dispatch = useDispatch();

    const subirArchivo = e => {
        setArchivo(e.target.files[0]);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(importarLeads(archivo, proyecto.id_proyecto, asignarAuto, mailChimp, usuario.id_usuario, usuario.id_tp_nivel_usuario));
    }

    return (
        <form id="formNuevo2" style={{ padding: '15px' }} onSubmit={handleSubmit}>
            <div className="form-row align-items-center mb-3">
                <div className="col-md-3">
                    <label htmlFor="titulopop" className="mb-0">Producto:</label>
                </div>
                <div className="col-md-7">
                    <SelectProyecto />
                </div>
                <div className="col-md-2"></div>
            </div>
            <div className="form-row align-items-center mb-3">
                <div className="col-md-3">
                    <label htmlFor="enlace" className="mb-0">Asignar Asesor Automaticamente:</label>
                </div>
                <div className="col-md-7">
                    <input type="checkbox" value={asignarAuto} onChange={() => setAsignarAuto(!asignarAuto)} />
                </div>
                <div className="col-md-2"></div>
            </div>
            <div className="form-row align-items-center mb-3">
                <div className="col-md-3">
                    <label htmlFor="enlace" className="mb-0">Seleccionar Archivo CSV:</label>
                </div>
                <div className="col-md-7">
                    <input type="file" className="form-control" onChange={subirArchivo} />
                </div>
                <div className="col-md-2"></div>
            </div>
            <button type="submit" className="btn" style={style.boton}>
                <i className="fa fa-upload"></i> Importar Datos
            </button>
        </form>
    );
}

export default FormularioLead;
