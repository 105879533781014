import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faFile, faListAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { useSelector, useDispatch } from "react-redux";
import FormularioEmitidos from "./FormularioEmitidos";
import { Badge } from "react-bootstrap";
import { eliminarCertificado } from "../../../actions/basesCertActions";
 
const CertificadosEmitidos = () => {

  //State
  const {emitidos, loading} = useSelector(state=>state.bases)
  const {usuario} = useSelector(state=>state.usuario)
  const dispatch=useDispatch()
  const handleEliminar = (id,producto) =>{
    dispatch(eliminarCertificado(id,usuario.id_usuario,producto))
  }
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Diplomas emitidos" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Diplomas emitidos" },
              ]}
            />
          <div className="row mt-2">
            <div className="col-md-6">
              <FormularioEmitidos />
            </div>
          </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions">
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Listado de Diplomas
                  </h5>
        
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(handleEliminar,usuario)}
                    tabla={emitidos}
                    keyCol="id_certificado"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CertificadosEmitidos;

/**====================== TABLA =========== */

const colums = (handleEliminar,usuario) => [
  {
    text: 'ID',
    key: 'id_certificado',
    className: 'id_registro',
    width: 50
  }
  ,
  {
    text: "Fecha Emisión",
    key: "fecha_emision",
    align: "center",
    sortable: true,
    className: "periodo text-center",
  }
  ,
  {
    text: "Discente",
    key: "nombre_usuario",
    align: "center",
    sortable: true,
    className: "periodo",
    cell: (record) => <p>{record.nombre_usuario} {record.apellido_usuario}</p>
  }
  ,
  {
    text: "Nota",
    key: "nota",
    align: "center",
    sortable: true,
    className: "periodo",
  }
  ,
  {
    text: "Título",
    key: "titulo_certificado",
    align: "center",
    sortable: true,
    className: "titulo",
    cell: (record) => 
    <div className="text-center" dangerouslySetInnerHTML={{__html:record.titulo_certificado}}>
    </div>
  
  }
  ,
  {
    text:"N° Evento",
    key: "n_evento",
    align: "center",
    className: "n_evento text-center",
    sortable: true
  }
  ,
  {
    text:"N° Libro",
    key: "n_libro",
    align: "center",
    className: "n_libro text-center",
    sortable: true
    
  }
  ,
  {
    text:"Certificado",
    key: "archivo",
    align: "center",
    className: "archivo",
    sortable: true,
    cell: (record) => 
      <div className="d-flex justify-content-center">
      { record.estado!=="1" ? <a className="btn btn-info btn-xs" href={`${process.env.REACT_APP_API_URL}/files_inedi/certificados_emitidos/${record.archivo}`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>:<a className="btn btn-info btn-xs" href={`${process.env.REACT_APP_API_URL}/files_inedi/certificados_firmados/${record.archivo}`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>}
      </div>
    
  }
  ,
  {
    text:"Estado",
    key: "estado",
    align: "center",
    className: "estado",
    sortable: true,
    cell: (record) => 
      <div className="d-flex justify-content-center">
      { record.aprobado!=="1" ? <Badge variant={'danger'} >Por aprobar</Badge> : record.estado!=="1" ? <Badge variant={'danger'} >Por firmar</Badge> :<Badge variant={'success'} >Firmado</Badge> }
      </div>
    
  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <div className="d-flex justify-content-between">
    {usuario.id_tp_nivel_usuario==="1" ? <>    <a className="btn btn-info btn-xs" href={`watana:?url_logo=${process.env.REACT_APP_API_URL}/files_inedi/icon_inedi2.png&url_descarga=${process.env.REACT_APP_API_URL}/files_inedi/certificados_emitidos/${record.archivo}&url_subida=${process.env.REACT_APP_API_URL}/admin/subirFirmados/${record.archivo}&firma_x=130&firma_y=240&firma_largo=260&firma_alto=70&firma_pagina=1&firma_texto=Firmado%20por:%3CFIRMANTE%3E%0ADirector%20INEDI%20Posgrados%0A%3CORGANIZACION%3E%0AFecha%3A%20%3CFECHA%3E%0A`} target="_blank" download><FontAwesomeIcon icon={faEdit}/></a></>:null}
    <button className="btn btn-danger btn-xs" onClick={()=>handleEliminar(record.id_certificado,record.producto)}><FontAwesomeIcon icon={faTrash}/></button> 
  </div>
  }
  
  
];
