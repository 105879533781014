import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { filtrarTratos, listarTratos } from '../../../actions/leadsActions';
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import useSelectFases from '../../../hooks/useSelectFases';

const FormularioFiltroAsesorTratos = () => {
const dispatch = useDispatch();
const {usuario} = useSelector(state=>state.usuario)
const [proyecto, SelectProyecto] = useSelectProyecto(null)
const [fechaCierre, setFechaCierre] = useState(null)
const [fechaRegistro, setFechaRegistro] = useState(null)
const [fase, SelectFase] = useSelectFases(null)
const traerDatos = () =>{
  if(!proyecto && !fechaCierre && !fechaRegistro && !fase)
  {
    dispatch(listarTratos(usuario))
  }
  else{
  const datosFiltro = {
      fechaCierre: fechaCierre ? moment(fechaCierre).format('YYYY-MM-DD') : null,
      proyecto: proyecto ? proyecto.id_proyecto : null,
      fechaRegistro: fechaRegistro ? moment(fechaRegistro).format('YYYY-MM-DD') : null,
      fase: fase ? fase : null,
    }

    //console.log(datosFiltro)

    dispatch(filtrarTratos(datosFiltro,usuario))
  }
}



 return (
 
    <div className="box">
      <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro:
                  </h5>
                
                </div>
      <div className="row p-2">
      
        <div className="col-7 mb-2">
          <label htmlFor="">Producto:</label>
          <SelectProyecto/>
        </div>
        <div className="col-4">
          <label htmlFor="">Fecha de Cierre:</label>
          <DatePicker
                selected={fechaCierre}
                onChange={(date) =>setFechaCierre(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                autoComplete="off"
                name="fechaCierre"
                className="form-control"
              />
        </div>
        <div className="col-4">
          <label htmlFor="">Fecha Registro:</label>
          <DatePicker
                selected={fechaRegistro}
                onChange={(date) =>setFechaRegistro(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                autoComplete="off"
                name="fechaRegistro"
                className="form-control"
              />
        </div>
        <div className="col-4 mb-2">
          <label htmlFor="">Fase:</label>
          <SelectFase />
        </div>
        <div className="col-12 pt-2">
        <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
         </div>
      </div>
    </div>
  
  
    );
};

export default FormularioFiltroAsesorTratos;
