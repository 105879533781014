import React from "react";
import MaterialTableDemo from "../../../plugins/MaterialTableDemo";
import useGetCapacitacionControl from "../../../../../hooks/useGetCapacitacionControl";
import { useParams } from 'react-router-dom';
import * as moment from 'moment'; 
import './TablaControlCapacitaciones.scss';

const TablaControlCapacitaciones = ({ colums, columnVisibility }) => {
  const { idCapacitacion } = useParams();
  const [{ datos }] = useGetCapacitacionControl();

  // Configuración para la paginación
  const registrosPorPagina = 10; // Número de registros por página
  const [paginaActual, setPaginaActual] = React.useState(1); // Estado para la página actual

  const tabla_sesiones = datos[idCapacitacion] ? datos[idCapacitacion].tabla_sesiones : {};

  // Filtrar columnas basadas en la visibilidad
  const filteredColumns = colums.filter(col => !columnVisibility[col.key]);

  // Agregar un índice global basado en la página actual
  const tablaDatos = Object.values(tabla_sesiones).map((record, index) => ({
    ...record,
    itemIndex: (paginaActual - 1) * registrosPorPagina + index + 1, // Calcula el índice global
  }));

  return (
    <div className="p-0 mt-4 bg-white tabla-control-capacitaciones">
      <div className="continer-fluid border-bottom-0 border">
        <h5 className="p-3 d-flex align-items-center mb-0" style={{ color: "#434343" }}>
          <i className="fas fa-list pr-2"></i>Area de detalles
        </h5>
      </div>
      <div className="tabla border">
        <MaterialTableDemo
          colums={filteredColumns}
          tabla={tablaDatos} // Utiliza el nuevo array con `itemIndex` calculado
          className='table-responsive'  
          exportExcel={true}
          addConfig={{
            show_filter: true,
            key_column: 'id_sesion',
            filename: `control-capacitacion_${moment(new Date()).format('DD/MM/YYYY')}`,
          }}
          // Si tienes control sobre la paginación, puedes agregar un callback para cambiar la página actual
          onChangePage={(page) => setPaginaActual(page)}
        />
      </div>
    </div>
  );
};

export default TablaControlCapacitaciones;
