import React, { useEffect } from "react";
import Layout from "../../../../app/Layout";
import Breadcrumb from "../../General/Breadcrumb";
import HeaderContent from "../../General/HeaderContent";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TablaPension from "../../../components/Finanzas/TablaPension";
import TablaCuotas from "../../../components/Finanzas/TablaCuotas";
import FormularioCuotas from "../../../components/Finanzas/FormularioCuotas";
import ModalCustomWidthwithRedux from "../../../../containers/ModalCustomWidthwithRedux";
import SpinnerLoading from "../../../components/Spinners/SpinnerLoading";
import RegistroFacturaOrdinaria from "../../../../containers/FinanzasContainers/RegistroFacturaOrdinaria";
import "./ExaminarCuota.scss";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  cambiarEstadoPensionFraccion,
  obtenerCuotasFraccionadas,
  registrarDetallesPagos,
} from "../../../../actions/pagosActions";
import {
  updateStateModalBasic,
  updateStateModalCustom,
} from "../../../../actions/modalActions";
import FormularioAnularPago from "../../../components/Finanzas/FormularioAnularPago";
import ModalBasicwithRedux from "../../../../containers/ModalBasicwithRedux";
import Swal from "sweetalert2";
import Descuentos from "../../../components/Finanzas/Descuentos";
import CuotasVariables from "../../../components/Finanzas/CargosVariables";
import ResumenPension from "../../../components/Finanzas/ResumenPension";
import AccionPagarPension from "../../../components/Finanzas/AccionPagarPension";
import RevisarPago from "../../../components/Finanzas/RegistroFactura/RevisarPago";

const ExaminarCuota = () => {
  const dispatch = useDispatch();
  const { loading, error, todo } = useSelector(
    (state) => state.pagos.datosFinanzasDiscente.cuotasPensiones
  );
  const params = new URLSearchParams(window.location.search);
  const idPension = params.get("pension");
  const tipo = params.get("q");
  const valor = params.get("value");
  const matricula = params.get("matricula");

  const handleFraccionarPension = () => {
    dispatch(cambiarEstadoPensionFraccion(idPension, todo.id_tp_cuota));
  };

  const handleModalRevisarPago = () => {
    // dispatch(registrarDetallesPagos("cuota", todo.id_dt_matricula));
    dispatch(
      updateStateModalCustom(
        <RevisarPago idPensionFraccion={todo.id_dt_matricula} /> ,
        "Revisión de pago reportado",
        "modal-wl"
      )
    );
  }
  const handleModalPagar = () => {
    if (todo.id_tp_cuota === "2") {
      Swal.fire({
        icon: "error",
        title: "Esta cuota ha sido fraccionada",
        text: "Deberá desfraccionar esta cuota para poder pagarla.",
      });
      return;
    }
    if (todo.nombre_tp_estado_pago === "PAGADO" && todo.id_tp_cuota !== "2") {
      //Modal de anular el pago
      dispatch(
        updateStateModalBasic(
          <FormularioAnularPago id={todo.id_dt_matricula} tipo="cuota" />,
          "Anular pago"
        )
      );
      return;
    }
    if (todo.nombre_tp_estado_pago !== "PAGADO") {
      dispatch(registrarDetallesPagos("cuota", todo.id_dt_matricula));
      dispatch(
        updateStateModalCustom(
          <RegistroFacturaOrdinaria />,
          "Registro de pago",
          "modal-wl"
        )
      );
    }
  };

  const handleNuevaCuota = () => {
    dispatch(updateStateModalBasic(<FormularioCuotas />, "Agregar cuota de fracción"));
  };

  const handleObtenerCuotasFraccionadas = () => {
    dispatch(obtenerCuotasFraccionadas(tipo, valor, matricula, idPension));
  }

  useEffect(() => {
    handleObtenerCuotasFraccionadas();
  }, []);

  return (
    <Layout>
      <div className="ExaminarCuota">
        <div className="ExaminarCuota__head">
          <HeaderContent title="Examinar" />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/pagos", nombre: "Registro de pagos" },
            ]}
          />
        </div>
        <div className="ExaminarCuota__body">
          <SpinnerLoading loading={loading} error={error}>
            <ModalCustomWidthwithRedux />
            <ModalBasicwithRedux />
            <div className="pt-3">
              <div className="InformacionPension">
                <h4 className='title-head'>Tabla pensión</h4>
                <TablaPension />
              </div>
              
                <div className="CuotasContainer mt-5">
                  <Row>
                    <Col md={5}>
                      <Descuentos
                        estadoCuota={todo.nombre_tp_estado_pago}
                      />
                      <CuotasVariables 
                        estadoCuota={todo.nombre_tp_estado_pago}
                      />
                    </Col>
                    <Col md={7} style={{paddingLeft: '100px'}}>
                      <ResumenPension />
                      {todo.id_tp_cuota === "2" ? (
                        <>
                          <div className="CuotasContainer__head d-flex justify-content-between align-items-center">
                            <h4>Tabla de pago fraccionado</h4>
                            <span
                              variant="light"
                              className="btn-icon"
                              onClick={handleNuevaCuota}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                              Agregar
                            </span>
                            </div>
                          <div className="mt-3" >
                            <TablaCuotas />
                          </div>
                        </>
                      ) : (
                        <AccionPagarPension
                          handleModalPagar={handleModalPagar}
                          handleFraccionarPension={handleFraccionarPension}
                          handleModalRevisarPago = {handleModalRevisarPago}
                          estadoCuota={todo.nombre_tp_estado_pago}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
            </div>
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
};

export default ExaminarCuota;
