import { Accordion, Card, Table } from 'react-bootstrap';
import React from 'react';
import PieChart from './PieChart'
import "./indicadores.css"
const Indicador = ({datos}) => {
    console.log(datos.grafico.data)
    const formatTiempoTotal = (seconds) =>{
        let hours = Math.floor(seconds / (60 * 60));
        let divisor_for_minutes = seconds % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let secs = Math.ceil(divisor_for_seconds);
    
        return `${hours<10?'0'+hours:hours}:${minutes<10?'0'+minutes:minutes}:${secs<10?'0'+secs:secs}`
      }
  //let porcentaje = datos.porcentaje;
    return (
        <div className="col-12 col-md-6 mb-3 text-center">
        <Card>
            <Card.Header> 
            <div className="row text-center p-1" >
                <div className="col-12 col-md-3">
                 <img src={datos.img_usuario ?  process.env.REACT_APP_API_URL+'/'+datos.img_usuario : datos.gender==='F' ? 'https://sistema.inedi.edu.pe/images/user_2.png' : 'https://sistema.inedi.edu.pe/images/user_3.png' } style={{borderRadius:'100%', maxWidth:'80px'}} alt='' />
                </div>
                <div className="col-12 col-md-9" style={{marginTop:'20px', textAlign:'left'}}>
                   <h3>{datos.nombre_usuario}</h3> 
                </div>
            </div>   
            </Card.Header>
            <Card.Body>
            <div className="row text-center">
            <div className="col-md-8" style={{display:'block', marginBottom:'15px',minHeight:'150px'}}>
               <div className='d-flex justify-content-between align-items-end h-100'>
                    <div>
                        <div style={{minWidth:'50px',height:'40px', backgroundColor:'#ffc3d2', padding:'5px',fontWeight:'600',fontSize:'11px'}}>
                            <p> {datos.grafico.data[0].cantidad} </p>
                            <span>({parseFloat(datos.grafico.data[0].porcentaje).toFixed(2)} %) </span>
                        </div>
                        <p>{datos.grafico.data[0].label}</p>
                    </div>
                    <div>
                        <div style={{minWidth:'50px',height:'60px', backgroundColor:'#76dbf9', padding:'5px',fontWeight:'600',fontSize:'11px'}}>
                            <p> {datos.grafico.data[2].cantidad} </p>
                            <span>({parseFloat(datos.grafico.data[2].porcentaje).toFixed(2)} %) </span>
                        </div>
                        <p>{datos.grafico.data[2].label}</p>
                    </div>
                    <div>
                        <div style={{minWidth:'50px',height:'80px', backgroundColor:'#ffecb9', padding:'5px',fontWeight:'600',fontSize:'11px'}}>
                            <p> {datos.grafico.data[1].cantidad} </p>
                            <span>({parseFloat(datos.grafico.data[1].porcentaje).toFixed(2)} %) </span>
                        </div>
                        <p>{datos.grafico.data[1].label}</p>
                    </div>
                    <div>
                        <div style={{minWidth:'50px',height:'100px', backgroundColor:'#78e7e8', padding:'5px',fontWeight:'600',fontSize:'11px'}}>
                            <p> {datos.grafico.data[3].cantidad} </p>
                            <span>({parseFloat(datos.grafico.data[3].porcentaje).toFixed(2)} %) </span>
                        </div>
                        <p>{datos.grafico.data[3].label}</p>
                    </div>
                    <div>
                        <div style={{minWidth:'50px',height:'120px', backgroundColor:'#e8c4ff', padding:'5px',fontWeight:'600',fontSize:'11px'}}>
                            <p>{datos.grafico.data[4].cantidad}</p>
                            <span>({parseFloat(datos.grafico.data[4].porcentaje).toFixed(2)} %) </span>
                        </div>
                        <p>{datos.grafico.data[4].label}</p>
                    </div>
                    <div>
                        <div style={{minWidth:'50px',height:'140px', backgroundColor:'#7fcaf8', padding:'5px',fontWeight:'600',fontSize:'11px'}}>
                            <p>{datos.grafico.data[5].cantidad}</p>
                            <span>({parseFloat(datos.grafico.data[5].porcentaje).toFixed(2)} %) </span>
                        </div>
                        <p>{datos.grafico.data[5].label}</p>
                    </div>
               </div>
            </div>
            <div className='col-md-4 p-3'>
            <div className='d-flex flex-column justify-content-between align-items-start h-100'>
                    <div className='d-flex'>
                        <div className='mr-2' style={{width:'15px',height:'15px', backgroundColor:'#ffc3d2', padding:'5px'}}></div>
                        <p>{datos.grafico.data[0].leyenda}</p>
                    </div>
                    <div className='d-flex'>
                    <div className='mr-2' style={{width:'15px',height:'15px', backgroundColor:'#76dbf9', padding:'5px'}}></div>
                        <p>{datos.grafico.data[2].leyenda}</p>
                    </div>
                    <div className='d-flex'>
                    <div className='mr-2' style={{width:'15px',height:'15px', backgroundColor:'#ffecb9', padding:'5px'}}></div>
                        <p>{datos.grafico.data[1].leyenda}</p>
                    </div>
                    <div className='d-flex'>
                    <div className='mr-2' style={{width:'15px',height:'15px', backgroundColor:'#78e7e8', padding:'5px'}}></div>
                        <p>{datos.grafico.data[3].leyenda}</p>
                    </div>
                    <div className='d-flex'>
                        <div className='mr-2' style={{width:'15px',height:'15px', backgroundColor:'#e8c4ff', padding:'5px'}}></div>
                        <p>{datos.grafico.data[4].leyenda}</p>
                    </div>
                    <div className='d-flex'>
                        <div className='mr-2' style={{width:'15px',height:'15px', backgroundColor:'#7fcaf8', padding:'5px'}}></div>
                        <p>{datos.grafico.data[5].leyenda}</p>
                    </div>
               </div>
            </div>
            </div>
           <div className="row text-center p-2" style={{marginTop:'15px'}}>
            <div className="col-12 mb-2">
            <Table striped bordered hover>
            <thead>
                <tr>
                <th>Leads</th>
                <th>Reprogramados</th>
                <th>Perdidos</th>
                <th>Tratos</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>{datos.base_leads}</td>
                <td>{datos.reprogramados}</td>
                <td>{datos.perdidos}</td>
                <td>{datos.tratos}</td>
                </tr>
            </tbody>
            </Table>
            </div>
            <div className="col-12 mb-2">
            <Table striped bordered hover>
            <thead>
                <tr>
                <th>N° Llamadas</th>
                <th>Tiempo Total</th>
                <th>Tiempo Conversado</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>{datos.llamadas.cantidad_llamadas}</td>
                <td>{formatTiempoTotal(datos.llamadas.tiempo_total)}</td>
                <td>{formatTiempoTotal(datos.llamadas.total_conversado)}</td>
                </tr>
            </tbody>
            </Table>
            </div>
           <div className="col-12 mt-2">
           <Accordion>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0" style={{textAlign:'left', cursor:'pointer'}}>
                   <p><b>Abrir detalle de productos</b></p> 
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body> 
                    <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Producto</th>
                        <th>Leads</th>
                        <th>Llamadas</th>
                        <th>Por llamar</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        datos.productos ? datos.productos.map((producto,i)=>(
                                <tr key={i}>
                                <td>{producto.nombre_proyecto}</td>
                                <td>{producto.leads}</td>
                                <td>{producto.llamados}</td>
                                <td>{producto.por_llamar}</td>
                                </tr>
                        )) : ''
                    }
                    
                    </tbody>
                    </Table>
                </Card.Body>
                </Accordion.Collapse>
            </Card>
           </Accordion>
          
           </div>
            </div>
            </Card.Body>
        </Card>
        </div>
    );
};

export default Indicador;