import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import {actualizarAnuncioCampaign} from '../../../actions/campaignsActions';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const EditarAnuncioC = ({registro}) => {

const [nombre_anuncio, setNombreAnuncio]=useState(registro.nombre_anuncio)
const [objetivo, setObjetivo]=useState(registro.objetivo)
const [observaciones, setObservaciones]=useState(registro.observaciones?registro.observaciones:'')
const [pagina, setPagina]=useState(registro.pagina?registro.pagina:'')
const [inversion, setInversion]=useState(registro.inversion)
const [publico, setPublico]=useState(registro.publico)
const [estado, setEstado]=useState(registro.estado)
const [leads, setLeads]=useState(registro.cantidad_leads?registro.cantidad_leads:'')
const [fechaInicio, setFechaInicio]=useState(registro.fecha_inicio)
const [fechaFin, setFechaFin]=useState(registro.fecha_fin?registro.fecha_fin:'')
  const dispatch = useDispatch();
  const {anuncios} = useSelector(state=>state.campaigns)
  const [fuente, setFuente]=useState('')

  const handleChanceLeads = (e) =>{
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value !== '' && re.test(e.target.value)) {
       setLeads(e.target.value)
    }
    else
      { setLeads('')}
  }


  const editarCampaign = async (e) =>{
    e.preventDefault();
    
    const datosCamp={
      id_anuncio: registro.id_anuncio,
      nombre_anuncio: nombre_anuncio!==''?nombre_anuncio:null,
      inversion: inversion!==''?inversion:null,
      estado: estado!==''?estado:null,
      publico: publico !=='' ? publico : null,
      objetivo: objetivo !=='' ? objetivo : null,
      fecha_inicio: fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null,
      fecha_fin: fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null,
      cantidad_leads: leads!=='' ? leads : null,
      observaciones : observaciones!=='' ? observaciones : null,
      pagina : pagina!=='' ? pagina : null,
      fuente: fuente!=='' ? fuente :null,
    }

   await dispatch(actualizarAnuncioCampaign(datosCamp,anuncios))
   console.log(datosCamp)
  }
 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={(e) => editarCampaign(e)}>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Nombre Anuncio:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="nombre_recurso" value={nombre_anuncio} onChange={(e)=>setNombreAnuncio(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Inversión:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="inversion" value={inversion} onChange={(e)=>setInversion(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Leads Recabados:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="leads" value={leads} onChange={handleChanceLeads} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Objetivo:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="objetivo" value={objetivo} onChange={(e)=>setObjetivo(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Público:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="publico" value={publico} onChange={(e)=>setPublico(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Fecha inicio:
                      </label>
                    </div>
                    <div className="col-md-7">
                    <input type="date" name="fechaInicio" value={fechaInicio} className="form-control" onChange={(e)=>setFechaInicio(e.target.value)}/>
                  
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Fecha Fin:
                      </label>
                    </div>
                    <div className="col-md-7">
                    <input type="date" name="fechaFin" value={fechaFin} className="form-control" onChange={(e)=>setFechaFin(e.target.value)}/>
                  
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Observaciones:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <textarea className="form-control" name="objetivo" value={observaciones} onChange={(e)=>setObservaciones(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Página:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="pagina" value={pagina} onChange={(e)=>setPagina(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                       Estado:
                      </label>
                    </div>
                    <div className="col-md-7">
                    <select name="estado" className="form-control" value={estado} onChange={(e)=>setEstado(e.target.value)} >
                      <option value="">Seleccione una opción</option>
                      <option value="1">Activo</option>
                      <option value="2">Inactivo</option>
                    </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                       Fuente de anuncio:
                      </label>
                    </div>
                    <div className="col-md-7">
                    <select name="fuente" className="form-control" value={fuente} onChange={(e)=>setFuente(e.target.value)} >
                      <option value="">Seleccione una opción</option>
                      <option value="1">Facebook ADS Form</option>
                      <option value="2">Facebook ADS Google Form</option>
                      <option value="3">Google ADS</option>
                      <option value="4">Webinar</option>
                      <option value="5">Landing Page</option>
                      <option value="6">LinkedIn</option>
                      <option value="7">Cartera</option>
                      <option value="8">LinkedIn ADS</option>
                      <option value="9">WhatsApp</option>
                      <option value="10">TikTok ADS</option>
                      <option value="11">Aliados</option>
                      <option value="12">Webinar automático</option>
                      <option value="13">Registro en Plataforma</option>
                      <option value="14">Registro de Marketing</option>
                    </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default EditarAnuncioC;
