import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { updateStateModalBasic } from "../../../actions/modalActions";

import "./Trabajadores.scss";

const Trabajadores = () => {
	return (
		<Layout>
			<div className="Trabajadores">
				<div className="Trabajadores__container">
					<div className="Trabajadores__head">
						<HeaderContent title="Enlaces" icon="group" />
						<Breadcrumb
							link={[
								{
									url: "/inicio",
									nombre: "Inicio",
									icon: "fas fa-home",
								},
								{ url: "", nombre: "Enlaces" },
							]}
						/>
					</div>
					<div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
						<div className="box mt-5">
							<div className="box-header with-border">
								<h5 style={{ color: "#434343" }}>
									<FontAwesomeIcon icon={faListAlt} /> Listas
									de Enlaces
								</h5>
							</div>
							<div className="box-primary">
								<div className="row">
									<div className="col-md-4">
										<div
											style={{
												padding: "20px",
												fontSize: "15px",
												fontWeight: "bold",
											}}
										>
											Ingresos:
										</div>
										<div style={{ padding: "10px" }}>
											<ul>
												<li>
													<a
														href="https://www.tlcbcp.com/#/"
														target="_blank"
													>
														{" "}
														BCP - Telecrédito{" "}
													</a>
												</li>
												<li>
													<a
														href="https://bcpzonasegurabeta.viabcp.com/#/iniciar-sesion"
														target="_blank"
													>
														{" "}
														BCP - Web
													</a>
												</li>
												<li>
													<a
														href="https://zonasegura1.bn.com.pe/BNWeb/Inicio"
														target="_blank"
													>
														{" "}
														Banco de la Nación{" "}
													</a>
												</li>
												<li>
													<a
														href="https://panel.culqi.com/#/login"
														target="_blank"
													>
														{" "}
														Culqui - Compañia
														incubadora{" "}
													</a>
												</li>
											</ul>
										</div>
									</div>
									<div className="col-md-4">
										<div
											style={{
												padding: "20px",
												fontSize: "15px",
												fontWeight: "bold",
											}}
										>
											Gastos:
										</div>
										<div style={{ padding: "10px" }}>
											<ul>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/1Ns-Xc55377Pe717vq-m8Wz3ZCavmBdyiUdBbrTAfX7o/edit#gid=0"
														target="_blank"
													>
														{" "}
														Operaciones{" "}
													</a>
												</li>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/1-PV2S4WOLtZgqwgsnGhZLuYFSMLYqTP22o_mm5ChDus/edit#gid=372648979"
														target="_blank"x
													>
														{" "}
														Pública{" "}
													</a>
												</li>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/1ze3KygJ3s0WP1Gu0ggfkIabYptrpAuIUpKzUwbSGJ0Y/edit#gid=372648979"
														target="_blank"
													>
														{" "}
														Experiencia{" "}
													</a>
												</li>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/1Ugsa06jqKEzZ4rcp55rMXl9EmKiWOX_sO6gvfTGA1kI/edit#gid=372648979"
														target="_blank"
													>
														{" "}
														Comercial{" "}
													</a>
												</li>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/1QKmw_3qr5hozuZflivsC9WRpksyJ0Ui2/edit#gid=616549478"
														target="_blank"
													>
														{" "}
														Costos Fijos{" "}
													</a>
												</li>
											</ul>
										</div>
									</div>
									<div className="col-md-4">
										<div
											style={{
												padding: "20px",
												fontSize: "15px",
												fontWeight: "bold",
											}}
										>
											Sunat:
										</div>
										<div style={{ padding: "10px" }}>
											<ul>
												<li>
													<a
														href="https://api-seguridad.sunat.gob.pe/v1/clientessol/4f3b88b3-d9d6-402a-b85d-6a0bc857746a/oauth2/loginMenuSol?originalUrl=https://e-menu.sunat.gob.pe/cl-ti-itmenu/AutenticaMenuInternet.htm&state=rO0ABXNyABFqYXZhLnV0aWwuSGFzaE1hcAUH2sHDFmDRAwACRgAKbG9hZEZhY3RvckkACXRocmVzaG9sZHhwP0AAAAAAAAx3CAAAABAAAAADdAAEZXhlY3B0AAZwYXJhbXN0AEsqJiomL2NsLXRpLWl0bWVudS9NZW51SW50ZXJuZXQuaHRtJmI2NGQyNmE4YjVhZjA5MTkyM2IyM2I2NDA3YTFjMWRiNDFlNzMzYTZ0AANleGVweA=="
														target="_blank"
													>
														{" "}
														Buzón SOL{" "}
													</a>
												</li>
												<li>
													<a
														href="https://api-seguridad.sunat.gob.pe/v1/clientessol/59d39217-c025-4de5-b342-393b0f4630ab/oauth2/loginMenuSol?originalUrl=https://e-menu.sunat.gob.pe/cl-ti-itmenu2/AutenticaMenuInternetPlataforma.htm&state=rO0ABXQA701GcmNEbDZPZ28xODJOWWQ4aTNPT2krWUcrM0pTODAzTEJHTmtLRE1IT2pBQ2l2eW84em5lWjByM3RGY1BLT0tyQjEvdTBRaHNNUW8KWDJRQ0h3WmZJQWZyV0JBaGtTT0hWajVMZEg0Mm5ZdHlrQlFVaDFwMzF1eVl1V2tLS3ozUnVoZ1ovZisrQkZndGdSVzg1TXdRTmRhbAp1ek5OaXdFbG80TkNSK0E2NjZHeG0zNkNaM0NZL0RXa1FZOGNJOWZsYjB5ZXc3MVNaTUpxWURmNGF3dVlDK3pMUHdveHI2cnNIaWc1CkI3SkxDSnc9"
														target="_blank"
													>
														{" "}
														Declaraciones y Pagos{" "}
													</a>
												</li>
											</ul>
										</div>
									</div>
									<div className="col-md-4">
										<div
											style={{
												padding: "20px",
												fontSize: "15px",
												fontWeight: "bold",
											}}
										>
											Registro:
										</div>
										<div style={{ padding: "10px" }}>
											<ul>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/1Q13sN69pj_aMIk1f6aJ_4_tm6UUVUXr8/edit#gid=23684186"
														target="_blank"
													>
														{" "}
														Bancos
													</a>
												</li>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/1jXJd_2jdUjUdGMZREuyxLeXNxHGBXhHR/edit#gid=2106207750"
														target="_blank"
													>
														{" "}
														Compañia incubadora{" "}
													</a>
												</li>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/14DUQheXtoOW6N_oCYbQcHi49ZISHWhUj/edit#gid=1245271918"
														target="_blank"
													>
														{" "}
														Facturas{" "}
													</a>
												</li>
												<li>
													<a
														href="https://administrador.inngresa.com/home"
														target="_blank"
													>
														{" "}
														Inngresa - Asistencia
														del team{" "}
													</a>
												</li>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/1yf9v3eEeKNxYnvJrqAbHcB-KumnT-9m-/edit?rtpof=true#gid=1188562106"
														target="_blank"
													>
														{" "}
														Credenciales{" "}
													</a>
												</li>
											</ul>
										</div>
									</div>
									<div className="col-md-4">
										<div
											style={{
												padding: "20px",
												fontSize: "15px",
												fontWeight: "bold",
											}}
										>
											EE.FF:
										</div>
										<div style={{ padding: "10px" }}>
											<ul>
												<li>
													<a
														href="https://app.qbo.intuit.com/app/expenses"
														target="_blank"
													>
														QuickBooks
													</a>
												</li>
											</ul>
										</div>
									</div>
									<div className="col-md-4">
										<div
											style={{
												padding: "20px",
												fontSize: "15px",
												fontWeight: "bold",
											}}
										>
											Planillas:
										</div>
										<div style={{ padding: "10px" }}>
											<ul>
												<li>
													<a
														href="https://docs.google.com/spreadsheets/d/1QKmw_3qr5hozuZflivsC9WRpksyJ0Ui2/edit?rtpof=true"
														target="_blank"
													>
														Planillas
													</a>
												</li>
											</ul>
										</div>
									</div>
									<div className="col-md-4">
										<div
											style={{
												padding: "20px",
												fontSize: "15px",
												fontWeight: "bold",
											}}
										>
											Otros:
										</div>
										<div style={{ padding: "10px" }}>
											<ul>
												<li>
													<a
														href="https://drive.google.com/drive/folders/1xatKaQdpz2fBmmTG1u1qx7D6EyEyNUei?usp=sharing"
														target="_blank"
													>
														Tutoriales
													</a>
												</li>
												<li>
													<a
														href="https://drive.google.com/drive/folders/1l71UyVW0Khby6ksvxWC4f0UtPHBvOxiB?usp=sharing"
														target="_blank"
													>
														E-books
													</a>
												</li>
												<li>
													<a
														href="https://drive.google.com/drive/folders/1CQKTO1fKtIKTc31L1GF3QiYCvS3WXCGI?usp=sharing"
														target="_blank"
													>
														Formatos
													</a>
												</li>
												<li>
													<a
														href="https://drive.google.com/drive/folders/16pFcMHZEWC9BL-sxUr7l9WkI7Vk-G8v2?usp=sharing"
														target="_blank"
													>
														Creación de contenido
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Trabajadores;

/**====================== TABLA =========== */

const colums = (handleModalNuevo) => [
	{
		text: "ID",
		key: "id_contrato",
		className: "id_contrato",
		width: 50,
	},
	{
		text: "Fecha Registro",
		key: "fecha_registro",
		align: "center",
		className: "fecha_registro",
		sortable: true,
	},
	{
		text: "Proyecto",
		key: "nombre_proyecto",
		align: "center",
		sortable: true,
		className: "nombre_proyecto",
	},
	{
		text: "Asesor",
		key: "nombre_usuario",
		align: "center",
		sortable: true,
		className: "nombre_usuario",
	},
	{
		text: "DNI",
		key: "dni",
		align: "center",
		sortable: true,
		className: "dni",
		width: 50,
	},
	{
		text: "Nombres",
		key: "nombres",
		align: "center",
		className: "nombres",
		sortable: true,
	},
	{
		text: "Apellidos",
		key: "apellidos",
		align: "center",
		className: "apellidos",
		sortable: true,
	},
	{
		text: "Celular",
		key: "celular",
		align: "center",
		className: "celular",
		sortable: true,
	},
	{
		text: "Correo",
		key: "correo",
		align: "center",
		className: "correo",
		sortable: true,
	},
	{
		text: "Inversión",
		key: "inversion",
		align: "center",
		className: "inversion",
		sortable: true,
	},
	{
		text: "N° Operación",
		key: "n_operacion",
		align: "center",
		className: "n_operacion",
		sortable: true,
	},
	{
		text: "Comprobante",
		key: "tipo_comprobante",
		align: "center",
		className: "tipo_comprobante",
		sortable: true,
		cell: (record) => (
			<>
				{" "}
				{record.tipo_comprobante !== "0"
					? record.tipo_comprobante === "1"
						? "Boleta"
						: "Factura"
					: "No determina"}{" "}
			</>
		),
	},
	{
		text: "Ruc",
		key: "ruc",
		align: "center",
		className: "ruc",
		sortable: true,
	},
	{
		text: "Voucher",
		key: "voucher",
		align: "center",
		className: "voucher",
		sortable: false,
		cell: (record) => (
			<>
				<img
					style={{ maxWidth: "150px" }}
					src={
						process.env.REACT_APP_API_URL +
						"/vouchers/" +
						record.voucher
					}
					alt="imagen"
					onClick={() =>
						handleModalNuevo(
							process.env.REACT_APP_API_URL +
								"/vouchers/" +
								record.voucher
						)
					}
				/>
			</>
		),
	},
];
