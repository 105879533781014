import React, { useEffect, useState } from "react";
import Layout from "../../../../app/Layout";
import HeaderContent from "../../General/HeaderContent";
import Breadcrumb from "../../General/Breadcrumb";
import SpinnerLoading from "../../../components/Spinners/SpinnerLoading";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { avisoError } from "../../../components/AvisosMensajes/MensajesSwalf";
import ListarReporte from "./ListarReporte";
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { reporteDiario } from "../../../../actions/leadsActions";
import { Link } from "react-router-dom";
 
const ReporteDiario = () => {
  const dispatch = useDispatch();
  const[loading, setLoading] = useState(false)
  const[listaAsesores,setListaAsesores] = useState(null)
  const[fechaFiltro,setFechaFiltro] = useState(null)
  const {usuario} = useSelector(state=>state.usuario)

  const fetchData = async () =>{
    setLoading(true);
    
    const fechaParseada = fechaFiltro ? moment(fechaFiltro).format('YYYY-MM-DD') : null

    const respuesta = await (dispatch(reporteDiario(fechaParseada,usuario)))
  //  console.log(respuesta)
    setListaAsesores(respuesta)
    setLoading(false);
  }
  const traerDatos = () => {
    fetchData();
  }

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Reporte diario" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Leads" },
                { url: "", nombre: "Reportes" },
                { url: "", nombre: "Reporte Diario" },
              ]}
            />
          </div>
          <div className="d-flex justify-content-end my-2"><Link className="btn btn-primary" to={'/reporte-llamadas'}>Reporte de llamadas</Link></div>
        
          <div className="box p-3">
            <div className="row">
            
              <div className="col-4">
                <label htmlFor="">Fecha de consulta</label>
                <DatePicker
                selected={fechaFiltro}
                onChange={(date) =>setFechaFiltro(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                
                name="fecha_filtro"
               // startDate={formik.values.fechaInicio}
                className="form-control"
              
               // endDate={formik.values.fechaFin}
              />
              </div>
              <div className="col-4 pt-4">
                <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>      
              </div>
            </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
          <SpinnerLoading loading={loading}>
                <ListarReporte asesores={listaAsesores}/>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReporteDiario;