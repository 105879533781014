import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch } from 'react-redux';
import useSelectProyecto from "../../../hooks/useSelectProyectos";
import { insertarGrupo } from '../../../actions/gruposAcademicosActions';

const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const FormularioGrupoAcademico = () => {

const [proyecto, SelectProyecto] = useSelectProyecto(null)
const [descripcionGrupo, setDescripcion]=useState("")
const [nombreGrupo,setNombreGrupo]=useState("")
  const dispatch = useDispatch();

  const handleChangeDesc = (e)=>{
    setDescripcion(e.target.value)
  }
  const handleChangeNam = (e)=>{
    setNombreGrupo(e.target.value)
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    const datosCamp={
      producto: proyecto?proyecto.id_proyecto:null,
      descripcion: descripcionGrupo ? descripcionGrupo : "",
      nombre: nombreGrupo ? nombreGrupo:"",
    }
    const retornarListaActualizada = async () =>{
     await (dispatch(insertarGrupo(datosCamp)))
    }
  
    
  // console.log(datosPop)
  retornarListaActualizada()
  }

 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Producto:
                      </label>
                    </div>
                    <div className="col-md-7">
                        <SelectProyecto/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Nombre del grupo
                      </label>
                    </div>
                    <div className="col-md-7">

                      <input name="nombreGrupo" value={nombreGrupo} className='form-control'  onChange={handleChangeNam}/>

                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Descripción del grupo
                      </label>
                    </div>
                    <div className="col-md-7">

                      <textarea className='form-control'  onChange={handleChangeDesc}>{descripcionGrupo}</textarea>

                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default FormularioGrupoAcademico;
