import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from "moment";
import React from 'react';

const SesionItem = ({datos}) => {

    const a = moment(datos.fecha_sesion).format('YYYY-MM-DD')
    const b = moment().format('YYYY-MM-DD')
    const disabled = new Date(a)-new Date(b) !==0 ? 'disabled': ''
 
    return (
        <div className="row d-flex p-2 m-1" style={{borderBottom:'1px solid #F5F5F5'}}>
            <div className="col-3 d-flex align-items-center" style={{fontSize:'12px', padding:'5px'}}>
              <FontAwesomeIcon icon={faCalendarAlt} />  
              <span style={{marginLeft:'8px'}}> {datos.fecha_sesion} </span>
            </div>
            <div className="col-6 d-flex flex-column align-items-start p-1">
                <h6>Clase en Vivo</h6>
                <p>{datos.nombre_sesion}</p>
            </div>
            <div className="col-3 d-flex flex-column align-items-start p-1">
              <a className={`btn btn-primary ${disabled}`} href={datos.ruta_archivo} target="_blank" rel="noopener noreferrer">Ir a la Clase</a>
            </div>
        </div>
    );
};

export default SesionItem;