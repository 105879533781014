import React from 'react';

//Redux
import { useDispatch } from 'react-redux';
import useSelectProyecto from "../../../hooks/useSelectProyectos";
import { listarGruposAcademicos, listarGruposAcademicosIdProyecto } from '../../../actions/gruposAcademicosActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const FormularioFiltroGrupos = () => {

const [proyecto, SelectProyecto] = useSelectProyecto(null)

  const dispatch = useDispatch();
  const traerDatos = ()=>{
   if(proyecto)
   {
     dispatch(listarGruposAcademicosIdProyecto(proyecto.id_proyecto))
   }
   else{
     dispatch(listarGruposAcademicos())
   }
  }
 return (
  <div className="box">
     <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                  </h5>
                
                </div>
      <div className="row p-2">
 
        <div className="col-9 mb-2">
          <label htmlFor="">Producto:</label>
          <SelectProyecto/>
        </div>
        
        <div className="col-12 pt-4">
        <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
       </div>
      </div>
    </div>
  
  
    );
};

export default FormularioFiltroGrupos;
