import {leadstypes} from '../actions/types'

const initialState = {
    leads: [],
    contactos:[],
    n_llamadas:0,
    fases:[],
    tratos:[],
    loading:false,
    asesores:[],
    fuentes:[],
    estados:[],
    filtrado:[],
    respuesta_importacion:null,
    observaciones:[],
    detalleLead:{
        llamadas:[]
    },
}

export default function(state = initialState, action){
    switch (action.type) {
        case leadstypes.LISTAR_LEADS_EXITO:
            return{
                ...state,
                leads: action.payload,
                loading:false,
                filtrado:[]
            }
        case leadstypes.LISTAR_LEADS:
                return{
                    ...state,
                    loading:true,
                }
        case leadstypes.LISTAR_ASESORES:
                return{
                    ...state,
                    asesores: action.payload,
                }
        case leadstypes.LISTAR_FUENTES:
            return{
                ...state,
                fuentes: action.payload,
            }
        case leadstypes.LISTAR_ESTADOS_LEAD:
            return{
                ...state,
                estados: action.payload,
            }
        case leadstypes.LISTAR_FASES:
            return{
                ...state,
                fases: action.payload,
            }
            case leadstypes.LISTAR_TRATOS:
                return {
                  ...state,
                  tratos: action.payload,
                  loading: false,
                };
        case leadstypes.LISTAR_OBSERVACIONES_LEAD:
            return{
                ...state,
                observaciones: action.payload,
            }
        case leadstypes.FILTRAR_LEADS:
                return{
                    ...state,
                    leads: action.payload,
                    filtrado:action.payload,
                    loading:false
                }
        case leadstypes.FILTRAR_CONTACTOS:
            return{
                ...state,
                contactos: action.payload,
                filtrado:action.payload,
                loading:false
            }
        case leadstypes.IMPORTAR_LEADS_EXITO:
            return{
                ...state,
                respuesta_importacion: action.payload,
            }
        case leadstypes.NUEVA_LLAMADA:
            return{
                ...state,
                n_llamadas:state.n_llamadas+1
            }
        case leadstypes.DETALLE_LEAD_EXITO:
            return{
                ...state,
                detalleLead: action.payload,
                loading:false
            }
        case leadstypes.TRAER_DETALLE_LEAD:
            return{
                ...state,
                detalleLead:null,
                loading: true,
            }
        default:
            return state
    }
} 