import { 
  OBTENER_CAPACITACIONES_CONTROL,
  OBTENER_CAPACITACIONES_CONTROL_EXITO,
  GUARDAR_CAPACITACION_ACTIVO,
  OBTENER_INFO_CONTROL,
  OBTENER_INFO_CONTROL_EXITO,
  AGREGAR_DOCENTE_CAPACITACION,
  AGREGAR_DOCENTE_CAPACITACION_EXITO,
  ELIMINAR_DOCENTE_CAPACITACION,
  ELIMINAR_DOCENTE_CAPACITACION_EXITO,
  SESION_ACTIVA,
  GUARDAR_PONENTE_TENTATIVO,
  GUARDAR_PONENTE_TENTATIVO_EXITO,
  GUARDAR_PONENTE_TENTATIVO_CONFIRMADO,
  GUARDAR_PONENTE_TENTATIVO_CONFIRMADO_EXITO,
  NUEVA_IMAGEN,
  NUEVA_IMAGEN_EXITO,
  NUEVA_IMAGEN_ERROR,
  ELIMINAR_IMAGEN_CONFIRMACION,
  ELIMINAR_IMAGEN_CONFIRMACION_EXITO,
  ELIMINAR_IMAGEN_CONFIRMACION_ERROR,
  NUEVA_EVIDENCIA_EXITO,
  NUEVA_EVIDENCIA_ERROR,
  ELIMINAR_EVIDENCIA_CONFIRMACION,
  ELIMINAR_EVIDENCIA_CONFIRMACION_EXITO,
  ELIMINAR_EVIDENCIA_CONFIRMACION_ERROR,
  CHANGE_VALUES,
  GUARDAR_DATOS_FORM,
  GUARDAR_DATOS_FORM_ERROR,
  DOCENTE_ELIMINADO_TENTATIVO_CONFIRMADO_EXITO,
  CAMBIAR_ESTADO_DOCENTE_EXITO,
  CAMBIAR_ESTADO_DOCENTE,
  CAMBIAR_ESTADO_DOCENTE_ERROR,
  ACTUALIZAR_ESTADO_INDICADOR_CONTROL,
  ACTUALIZAR_ESTADO_AJUSTE_CONTROL,
  GUARDAR_DESEMPENO_DOCENTE,
  GUARDAR_DESEMPENO_DOCENTE_EXITO,
  GUARDAR_DESEMPENO_DOCENTE_ERROR,
  OBTENER_DESEMPENO_DOCENTE,
  OBTENER_DESEMPENO_DOCENTE_EXITO,
  OBTENER_DESEMPENO_DOCENTE_ERROR,
  OBTENER_DETALLES_SESION_EXITO,
  OBTENER_DETALLES_SESION_ERROR,
  AGREGAR_LINK_DRIVE_EXITO,
  AGREGAR_LINK_DRIVE_ERROR
  
 } from './types';
import authAxios from '../config/authAxios';
import { avisoError, avisoCorrecto, avisoCargando } from '../view/components/AvisosMensajes/MensajesSwalf';

export const obtenerDetallesSesion = (idSesion) => async (dispatch) => {
  try {
    const respuesta = await authAxios.get(`/admin/obtenerDetallesSesion/${idSesion}`);
    dispatch({
      type: OBTENER_DETALLES_SESION_EXITO,
      payload: respuesta.data
    });
  } catch (error) {
    dispatch({
      type: OBTENER_DETALLES_SESION_ERROR,
      payload: error.response?.data?.error || 'Error al obtener los detalles de la sesión'
    });
  }
};


export const obtenerReporteNaranja = (datos) => async dispatch => {
  try {
  //  console.log(datos)
    let respuesta = await authAxios.post(`/admin/reporteAcademica/naranja`,datos)
  //  console.log(respuesta.data)
    if (!respuesta.data.error) 
    {
      return {mensaje: true, informacion: respuesta.data}
    }
    else
      {   
        return {mensaje: false, informacion: null}
      }
    
  } catch (error) {
  //  console.log(error)
    return {mensaje: false, textMensaje: error}
  }
}

export const agregarLinkDrive = ({ idSesion, idDocente, linkDrive }) => async (dispatch) => {
  try {
    const respuesta = await authAxios.post('/admin/insertarLinkDriveSesion/', {
      id_sesion: idSesion,
      id_docente: idDocente,
      link_drive: linkDrive
    });

    dispatch({
      type: AGREGAR_LINK_DRIVE_EXITO,
      payload: respuesta.data
    });

    alert('Link agregado con éxito');
  } catch (error) {
    dispatch({
      type: AGREGAR_LINK_DRIVE_ERROR,
      payload: error.response?.data?.error || 'Error al agregar el link del drive'
    });
  }
};


export const obtenerTodosCapacitacionesControl = () => {
  return async (dispatch) => {
    dispatch({
      type: OBTENER_CAPACITACIONES_CONTROL
    })

    try {
      const capacitaciones = await authAxios.get(`/admin/rptecc__mostrarProyectosReporteCapacitaciones`)

      dispatch({
        type: OBTENER_CAPACITACIONES_CONTROL_EXITO,
        payload: capacitaciones.data
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const guardarCapacitacionActivo = capacitacion => {
  return dispatch => {
    dispatch({
      type: GUARDAR_CAPACITACION_ACTIVO,
      payload: capacitacion
    })
  }
}

export const obtenerInfoControl = idCapacitacion => {
  return async (dispatch, getState) => {

    const { control_capacitacion: { area_trabajo } } = getState()
    const capacitaciones = getState().control_capacitacion.capacitaciones.todos

    const copia_area = {...area_trabajo.datos}

    dispatch({
      type: OBTENER_INFO_CONTROL
    })

    try {
      const detalles = await authAxios.get(`/admin/rptecc__mostrarReporteCapacitaciones__IdProyecto/${idCapacitacion}`)
      const nuevoDetallesSesiones = agregarIdIndex(detalles.data.tabla_sesiones)
      detalles.data.tabla_sesiones = nuevoDetallesSesiones

      copia_area[idCapacitacion] = detalles.data
      if (!Object.keys(capacitaciones).length) {
        await dispatch( obtenerTodosCapacitacionesControl() )
      }

      dispatch({
        type: OBTENER_INFO_CONTROL_EXITO,
        payload: copia_area
      })
    } catch (error) {
      console.log(error)
    }
  }
}

const agregarIdIndex = arreglo => {
  const nuevoArreglo = [...arreglo].map((item, index) => (
    {
      ...item,
      id: index+1
    }
  ))
  return nuevoArreglo;
}

export const agregarDocenteCapacitacion = (id_proyecto, id_docente) => {
  return async (dispatch, getState) => {

    const datos = {
      id_proyecto,
      id_docente
    }

    try {
      const { docentes_filtro } = getState().control_capacitacion.area_trabajo.datos[id_proyecto];
      //Buscar si ya esta en la tabla
      const copiaTablaDocentes = [...docentes_filtro]
      const existeDocente = copiaTablaDocentes.find(item => item.id_docente === id_docente);

      if (existeDocente) {
        avisoError('Docente ya se encuentra asignado a esta capacitación')
        return
      }

      dispatch({
        type: AGREGAR_DOCENTE_CAPACITACION
      })
      //consultar la api
      const respuesta = await authAxios.post('/admin/rptecc_agregarDocenteFiltro', datos)
      //Insertar docente en la tabla docentes_filtro
      dispatch({
        type: AGREGAR_DOCENTE_CAPACITACION_EXITO,
        payload: {
          id_proyecto,
          id_docente,
          docenteNuevo: respuesta.data
        }
      })

    } catch (error) {
      console.log(error)
    }
  }
}

export const eliminarRelacionDocente = (idFiltroDocente, id_proyecto) => {
  return async (dispatch) => {
    dispatch({
      type: ELIMINAR_DOCENTE_CAPACITACION
    })
    try {
      await authAxios.delete(`/admin/rptec__eliminarDocenteFiltro__IdFiltroDocente/${idFiltroDocente}`)

      avisoCorrecto('Docente eliminado correctamente')

      dispatch({
        type: ELIMINAR_DOCENTE_CAPACITACION_EXITO,
        payload: {
          id_proyecto,
          idFiltroDocente
        }
      })

    } catch (error) {
     // console.log(error)
      avisoError(error.response.data.msg)
    }
  }
} 

export const cambiarEstadoDocente = (idFiltroDocente, idEstado, idProyecto) => {
  return async dispatch => {

    dispatch({
      type: CAMBIAR_ESTADO_DOCENTE
    })

    try {
      const respuesta = await authAxios.put(`/admin/rptecc_editarDocenteFiltro/${idFiltroDocente}/${idEstado}`)

      dispatch({
        type: CAMBIAR_ESTADO_DOCENTE_EXITO,
        payload: {
          nuevaFila: respuesta.data,
          idProyecto,
          idFiltroDocente
        }
      })
    } catch (error) {
      //console.log(error)
      dispatch({
        type: CAMBIAR_ESTADO_DOCENTE_ERROR,
        payload: error.response.msg
      })
    }
  }
}

export const guardarDesempenoDocente = (idFiltroDocente, puntualidad, claridad) => {
  return async dispatch => {
    dispatch({
      type: GUARDAR_DESEMPENO_DOCENTE
    });

    try {
      const respuesta = await authAxios.post(`/admin/guardarDesempenoDocente/`, {
        id_filtro_docente: idFiltroDocente,
        puntualidad,
        claridad
      });

      dispatch({
        type: GUARDAR_DESEMPENO_DOCENTE_EXITO,
        payload: {
          idFiltroDocente,
          puntualidad,
          claridad
        }
      });

      avisoCorrecto('Desempeño guardado correctamente');
    } catch (error) {
      dispatch({
        type: GUARDAR_DESEMPENO_DOCENTE_ERROR,
        payload: error.response?.data?.error || 'Hubo un problema al guardar el desempeño'
      });

      avisoError('Hubo un problema al guardar el desempeño');
    }
  };
};


export const obtenerDesempenoDocente = (idFiltroDocente) => {
  return async dispatch => {
    try {
      const respuesta = await authAxios.get(`/admin/obtenerDesempenoDocente/${idFiltroDocente}`);

      dispatch({
        type: OBTENER_DESEMPENO_DOCENTE_EXITO,
        payload: {
          idFiltroDocente,
          desempeno: respuesta.data,
        }
      });
    } catch (error) {
      dispatch({
        type: OBTENER_DESEMPENO_DOCENTE_ERROR,
        payload: error.response?.data?.error || 'Error al obtener el desempeño del docente'
      });
    }
  };
};






export const cambiarEstadoPubli = (idFiltroDocente, idEstado, idProyecto) => {
  return async dispatch => {

    dispatch({
      type: CAMBIAR_ESTADO_DOCENTE
    })

    try {
      const respuesta = await authAxios.put(`/admin/rptecc_editarDocentePubli/${idFiltroDocente}/${idEstado}`)

      dispatch({
        type: CAMBIAR_ESTADO_DOCENTE_EXITO,
        payload: {
          nuevaFila: respuesta.data,
          idProyecto,
          idFiltroDocente
        }
      })
    } catch (error) {
      //console.log(error)
      dispatch({
        type: CAMBIAR_ESTADO_DOCENTE_ERROR,
        payload: error.response.msg
      })
    }
  }
}

export const guardarSesionCapacitacionActivo = (id_sesion, idProyecto) => {
  return (dispatch, getState) => {

    const copiaSesionActiva = {...getState().control_capacitacion.area_trabajo.activo} || null

    if (copiaSesionActiva) {
      if (copiaSesionActiva.id_sesion === id_sesion) return;
    }
    
    const sesiones = [...getState().control_capacitacion.area_trabajo.datos[idProyecto].tabla_sesiones]
    const sesionActiva = sesiones.filter(sesion => sesion.id_sesion === id_sesion)
    dispatch({
      type: SESION_ACTIVA,
      payload: sesionActiva[0]
    })
  }
}

export const guardarPonenteTentativo = (idSesion, idDocenteFiltro, idReporte, idProyecto) => {
  return async (dispatch, getState) => {

    const { docentes_tentativos: tentativos } = getState().control_capacitacion.area_trabajo.activo

    const existeDocente = tentativos.find(tentativo => tentativo.id_filtro_docente === idDocenteFiltro )

    if (existeDocente) {
      avisoError('Docente ya ingresado en la lista de tentativos')
      return;
    }

    const datos = {
      id_sesion: idSesion,
      id_filtro_docente: idDocenteFiltro,
      id_reporte_control_capacitacion: idReporte
    }
  // console.log(datos)
    dispatch({
      type: GUARDAR_PONENTE_TENTATIVO
    })

    try {
      const respuesta = await authAxios.post('/admin/rptecc_RegistrarDocenteTentativo', datos)
      //console.log(respuesta)
      dispatch({
        type: GUARDAR_PONENTE_TENTATIVO_EXITO,
        payload: {
          idSesion,
          idProyecto,
          nuevaSesion: respuesta.data
        }
      }) 
    } catch (error) {
      //console.log(error) 
     // avisoError(error.response.data.msg)
    }
  }
}

export const guardarTentativoConfirmado = (idFiltroSesion, idProyecto) => {
  return async (dispatch, getState) => {

    const { docente_confirmado } = getState().control_capacitacion.area_trabajo.activo      
    if (docente_confirmado.length) {
      avisoError('Ya hay un docente en esta sesión')
      return;
    }

    dispatch({
      type: GUARDAR_PONENTE_TENTATIVO_CONFIRMADO
    })

    try {
      const respuesta = await authAxios.put(`/admin/rptecc_editarEstadoDisonible/${idFiltroSesion}`)
      dispatch({
        type: GUARDAR_PONENTE_TENTATIVO_CONFIRMADO_EXITO,
        payload: {
          nuevaSesion: respuesta.data,
          idSesion: respuesta.data.id_sesion,
          idProyecto
        }
      })
      if(respuesta.data.docente_confirmado.length)
      dispatch( cambiarEstadoDocente( respuesta.data.docente_confirmado[0].id_filtro_docente, 3, respuesta.data.id_proyecto) )
    } catch (error) {
      console.log(error)
    }
  }
}

export const eliminarDocenteTentativoConfirmado = (id_filtro_sesion) => {
  return async (dispatch, getState) => {
    try {
      const { id_proyecto: idProyecto, id_sesion: idSesion } = getState().control_capacitacion.area_trabajo.activo 

      const respuesta = await authAxios.delete(`/admin/rptecc_eliminarDocenteTentativo/${id_filtro_sesion}`)

      dispatch({
        type: DOCENTE_ELIMINADO_TENTATIVO_CONFIRMADO_EXITO,
        payload: {
          nuevaSesion: respuesta.data,
          idSesion,
          idProyecto
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const agregarNuevaImagen = (datos, idProyecto) => {
  return async (dispatch) => {

    dispatch({
      type: NUEVA_IMAGEN
    })

    avisoCargando('Subiendo imagen')

    try {
      const respuesta = await authAxios.post(`/admin/rptecc_subirArchivoConfirmacion`, datos)

      dispatch({
        type: NUEVA_IMAGEN_EXITO,
        payload: {
          nuevaSesion: respuesta.data,
          idSesion: respuesta.data.id_sesion,
          idProyecto
        }
      })

      avisoCorrecto('Imagen subida')

    } catch (error) {
     // console.log(error)
      dispatch({
        type: NUEVA_IMAGEN_ERROR
      })
      avisoError('No hay docentes asignados.')
    }
  }
}

export const agregarNuevaEvidencia = (datos, idProyecto) => {
  return async (dispatch) => {

    dispatch({
      type: NUEVA_IMAGEN
    })

    avisoCargando('Subiendo evidencia')

    try {
      const respuesta = await authAxios.post(`/admin/rptecc_subirArchivoEvidencia`, datos)

      dispatch({
        type: NUEVA_EVIDENCIA_EXITO,
        payload: {
          nuevaSesion: respuesta.data,
          idSesion: respuesta.data.id_sesion,
          idProyecto
        }
      })

      avisoCorrecto('Evidencia subida')

    } catch (error) {
      //console.log(error)
      dispatch({
        type: NUEVA_EVIDENCIA_ERROR
      })
      avisoError('No hay docentes asignados.')
    }
  }
}

export const eliminarImagen = (idImagen, idProyecto) => {
  return async dispatch => {
    dispatch({
      type: ELIMINAR_IMAGEN_CONFIRMACION
    })
    avisoCargando('Eliminando...')
    try {
      const respuesta = await authAxios.delete(`/admin/rptecc_eliminarArchivoConfirmacion/${idImagen}`)
      dispatch({
        type: ELIMINAR_IMAGEN_CONFIRMACION_EXITO,
        payload: {
          nuevaSesion: respuesta.data,
          idSesion: respuesta.data.id_sesion,
          idProyecto
        }
      })
      avisoCorrecto('Imagen eliminada correctamente')
    } catch (error) {
     // console.log(error)
      dispatch({
        type: ELIMINAR_IMAGEN_CONFIRMACION_ERROR
      })
      avisoError(error.response.data.msg)
    }
  }
}

export const eliminarEvidencia = (idImagen, idProyecto) => {
  return async dispatch => {
    dispatch({
      type: ELIMINAR_EVIDENCIA_CONFIRMACION
    })
    avisoCargando('Eliminando...')
    try {
      const respuesta = await authAxios.delete(`/admin/rptecc_eliminarArchivoEvidencia/${idImagen}`)
      dispatch({
        type: ELIMINAR_EVIDENCIA_CONFIRMACION_EXITO,
        payload: {
          nuevaSesion: respuesta.data,
          idSesion: respuesta.data.id_sesion,
          idProyecto
        }
      })
      avisoCorrecto('Evidencia eliminada correctamente')
    } catch (error) {
     // console.log(error)
      dispatch({
        type: ELIMINAR_EVIDENCIA_CONFIRMACION_ERROR
      })
      avisoError(error.response.data.msg)
    }
  }
}

export const actualizarForm = (key, value) => {
  return dispatch => {
    dispatch ({
      type: CHANGE_VALUES,
      payload: {
        key,
        value
      }
    })
  }
}

export const actualizarEstadoIndicador = (idProyecto, valor, idEstadoSesion) => {
  return async (dispacth, getState) => {
    try {
      const idSesion = getState().control_capacitacion.area_trabajo.activo.id_sesion;
      dispacth({
        type: ACTUALIZAR_ESTADO_INDICADOR_CONTROL,
        payload: {
          idProyecto,
          idEstadoSesion,
          idSesion,
          valor: valor === 'Completo' ? 'Incompleto' : 'Completo'
        }
      })

      await authAxios.put(`/admin/rptec__actualizarEstadoProcesoSesion__IdDt/${idEstadoSesion}`);

    } catch (error) {
      console.log(error);
    }
  }
}
export const actualizarEstadoAjuste = (idProyecto, valor, idEstadoSesion) => {
  return async (dispacth, getState) => {
    try {
      const idSesion = getState().control_capacitacion.area_trabajo.activo.id_sesion;
      dispacth({
        type: ACTUALIZAR_ESTADO_AJUSTE_CONTROL,
        payload: {
          idProyecto,
          idEstadoSesion,
          idSesion,
          valor: valor === 'Completo' ? 'Incompleto' : 'Completo'
        }
      })

      await authAxios.put(`/admin/rptec__actualizarEstadoCalidadSesion__IdDt/${idEstadoSesion}`);

    } catch (error) {
      console.log(error);
    }
  }
}
export const guardarDatosForm = (idProyecto) => {
  return async (dispatch, getState) => {

    const sesionActivo = {...getState().control_capacitacion.area_trabajo.activo}

    const datos = {
      calificacion: sesionActivo.calificacion,
      estado_intranet: sesionActivo.estado_intranet
    }

    try {

      const respuesta = await authAxios.put(`/admin/rptecc_editarAccionesIntranet/${sesionActivo.id_sesion}`, datos)

      dispatch({
        type: GUARDAR_DATOS_FORM,
        payload: {
          nuevaSesion: respuesta.data,
          idSesion: sesionActivo.id_sesion,
          idProyecto
        }
      })
      avisoCorrecto('Guardado correctamente')
    } catch (error) {
      //console.log(error)
      dispatch({
        type: GUARDAR_DATOS_FORM_ERROR
      })
      avisoError(error.response.data.msg)
    }
  }
}
