import {campaigntypes} from '../actions/types'

const initialState = {
    campaigns: [],
    tipos_recursos:[],
    recursos:[],
    anuncios:[],
    webinars:[],
    loading:false,
    totales:{},
    resumen_webinar:{},
    configuracion_campaign:{ mejor_asistentes:'',mejor_inscritos:'',peor_asistentes:'',peor_inscritos:'',id_cmap:''}
}

export default function(state = initialState, action){
    switch (action.type) {
        case campaigntypes.LISTAR_CAMPAIGNS_EXITO:
            return{
                ...state,
                campaigns: action.payload,
                loading:false,
            }
        case campaigntypes.TOTALES:
            return{
                ...state,
                totales: action.payload,
                loading:false,
            }
        case campaigntypes.RESUMEN_WEBINAR:
            return{
                ...state,
                resumen_webinar: action.payload,
                loading:false,
            }
        case campaigntypes.ACTUALIZAR_COMENTARIO:
            return{
                ...state,
                resumen_webinar: {
                    ...state.resumen_webinar,
                    [action.payload.campo]:action.payload.comentario
                },
                loading:false,
            }
        case campaigntypes.LISTAR_WEBINARS_EXITO:
            return{
                ...state,
                webinars: action.payload,
                resumen_webinar:{},
                loading:false,
            }
        case campaigntypes.CONFIGURACION_CAMPAIGN:
            return{
                ...state,
                configuracion_campaign: action.payload,
            }
        case campaigntypes.LISTAR_TIPOS_RECURSO_EXITO:
                return{
                    ...state,
                    tipos_recursos: action.payload,
            }
        case campaigntypes.LISTAR_CAMPAIGNS:
                return{
                    ...state,
                    loading:true,
            }
        case campaigntypes.LISTAR_WEBINARS:
            return{
                ...state,
                loading:true,
        }
        case campaigntypes.LISTAR_ANUNCIOS:
            return{
                ...state,
                loading:true,
            }
        case campaigntypes.LISTAR_ANUNCIOS_EXITO:
            return{
                ...state,
                anuncios: action.payload,
                totales:{},
                loading:false,
            }
        case campaigntypes.LISTAR_RECURSOS:
            return{
                ...state,
                loading:true,
            }
        case campaigntypes.LISTAR_RECURSOS_EXITO:
            return{
                ...state,
                recursos: action.payload,
                loading:false,
            }
        default:
            return state
    }
} 