import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { filtrarLeads } from "../../../actions/leadsActions";
import useSelectAsesores from "../../../hooks/useSelectAsesores";
import useSelectProyecto from "../../../hooks/useSelectProyectos";
import useSelectEstadosLeads from "../../../hooks/useSelectEstadosLeads";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import { Button } from "react-bootstrap";
import { avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
import useSelectFuenteMulti from "../../../hooks/useSelectFuenteMulti";
import useSelectMedioContacto from "../../../hooks/useSelectMedioContacto";

const style = {
    boton: {
        background: "#28314A",
        color: "#fff",
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: "#fff",
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa",
    },
};

const FormularioFiltro = ({ contacto = false }) => {
    const dispatch = useDispatch();
    const { usuario } = useSelector((state) => state.usuario);
    const { filtrado } = useSelector((state) => state.leads);
    const [filters, setFilters] = useState({});
    const [asesor, SelectAsesor, setAsesor] = useSelectAsesores(null);
    const [proyecto, SelectProyecto, setProyecto] = useSelectProyecto(null);
    const [estado, SelectEstado, setEstado] = useSelectEstadosLeads(null);
    const [fechaFiltro, setFechaFiltro] = useState(null);
    const [fechaFiltroHasta, setFechaFiltroHasta] = useState(null);
    const [fuente, SelectFuente, setFuente] = useSelectFuenteMulti(null);
    const [medio, SelectMedio, setMedio] = useSelectMedioContacto(null);
    const [checkOmitir, setCheckOmitir] = useState(false);

    const handleFiltroChange = (key, value) => {
        const newFilter = { ...filters, [key]: value };
        setFilters(newFilter);
        localStorage.setItem('filters', JSON.stringify(newFilter));
    };

	useEffect(() => {
		const savedFilters = localStorage.getItem('filters');
		if (savedFilters) {
			const parsedFilters = JSON.parse(savedFilters);
			setFilters(parsedFilters);
			setAsesor(parsedFilters.asesor);
			setProyecto(parsedFilters.proyecto);
			setEstado(parsedFilters.estado);
			setFechaFiltro(parsedFilters.fechaFiltro ? new Date(parsedFilters.fechaFiltro) : null);
			setFechaFiltroHasta(parsedFilters.fechaFiltroHasta ? new Date(parsedFilters.fechaFiltroHasta) : null);
			setFuente(parsedFilters.fuente);
			setMedio(parsedFilters.medio);
			setCheckOmitir(parsedFilters.checkOmitir);
		}
	}, []);
    const handleUrlDescargaDialFire = () => {
        const estados_str = estado
            ? estado
                .map((est, i) => {
                    return est.id_estado;
                })
                .join(",")
            : "";
        return `${process.env.REACT_APP_API_URL}/admin/csv/export/DialFire/${
            asesor ? asesor.id_usuario : null
        }/${proyecto ? proyecto.id_proyecto : null}/${
            fechaFiltro ? moment(fechaFiltro).format("YYYY-MM-DD") : null
        }/${
            fechaFiltroHasta
                ? moment(fechaFiltroHasta).format("YYYY-MM-DD")
                : null
        }/${estados_str}/${checkOmitir ? "1" : "0"}`;
    };

    const traerDatos = () => {
        if (fechaFiltro !== null && fechaFiltroHasta !== null) {
            const datosFiltro = {
                asesor: asesor ? asesor : null,
                fechaFiltroDesde: fechaFiltro
                    ? moment(fechaFiltro).format("YYYY-MM-DD")
                    : null,
                fechaFiltroHasta: fechaFiltroHasta
                    ? moment(fechaFiltroHasta).format("YYYY-MM-DD")
                    : null,
                proyecto: proyecto ? proyecto : null,
                estado: estado ? estado : null,
                fuente: fuente !== "" ? fuente : null,
                medio_contacto: medio ? medio.id : null,
                checkOmitir: checkOmitir,
            };

            dispatch(filtrarLeads(datosFiltro, usuario, contacto));
        } else {
            avisoError("Debe seleccionar un rango de fecha por lo menos.");
        }
    };

    return (
        <div className="box">
            <div className="box-header with-border">
                <h5 style={{ color: "#434343" }}>
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                </h5>
            </div>
            <div className="row p-2">
                <div className="col-md-6 mb-2">
                    <label htmlFor="">Asesor:</label>
                    <SelectAsesor
                        value={asesor}
                        onChange={(value) => {
                            setAsesor(value);
                            handleFiltroChange('asesor', value);
                        }}
                    />
                </div>
                <div className="col-md-6 mb-2">
                    <label htmlFor="">Fuente:</label>
                    <SelectFuente
                        value={fuente}
                        onChange={(value) => {
                            setFuente(value);
                            handleFiltroChange('fuente', value);
                        }}
                    />
                </div>
                <div className="col-md-12 mb-2">
                    <label htmlFor="">Medio de contacto:</label>
                    <SelectMedio
                        value={medio}
                        onChange={(value) => {
                            setMedio(value);
                            handleFiltroChange('medio', value);
                        }}
                    />
                </div>
                <div className="col-md-12 mb-2">
                    <label htmlFor="">Producto:</label>
                    <SelectProyecto
                        value={proyecto}
                        onChange={(value) => {
                            setProyecto(value);
                            handleFiltroChange('proyecto', value);
                        }}
                    />
                </div>

                <div className="col-4">
                    <label htmlFor="">Desde:</label>
                    <DatePicker
                        selected={fechaFiltro}
                        onChange={(date) => {
                            setFechaFiltro(date);
                            handleFiltroChange('fechaFiltro', date);
                        }}
                        selectsStart
                        dateFormat="dd/MM/yyyy"
                        autoComplete="off"
                        name="fechaFiltro"
                        startDate={fechaFiltro}
                        className="form-control"
                        endDate={fechaFiltroHasta}
                    />
                </div>
                <div className="col-4">
                    <label htmlFor="">Hasta:</label>
                    <DatePicker
                        selected={fechaFiltroHasta}
                        onChange={(date) => {
                            setFechaFiltroHasta(date);
                            handleFiltroChange('fechaFiltroHasta', date);
                        }}
                        selectsStart
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        name="fechaFiltroHasta"
                        startDate={fechaFiltro}
                        endDate={fechaFiltroHasta}
                        minDate={fechaFiltro}
                        autoComplete="off"
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="">Estado:</label>
                    <SelectEstado
                        value={estado}
                        onChange={(value) => {
                            setEstado(value);
                            handleFiltroChange('estado', value);
                        }}
                    />
                </div>
                <div className="col-6 mb-2">
                    <div className="form-group form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="check-omitir"
                            checked={checkOmitir}
                            onChange={() => {
                                setCheckOmitir(!checkOmitir);
                                handleFiltroChange('checkOmitir', !checkOmitir);
                            }}
                        />
                        <label className="form-check-label" htmlFor="check-omitir">
                            Omitir exportados anteriormente
                        </label>
                    </div>
                </div>
                <div className="col-12 pt-4">
                    <button className="btn btn-primary" onClick={traerDatos}>
                        Consultar
                    </button>
                    {
                        !contacto &&
                        filtrado.length > 0 &&
                        asesor && proyecto && fechaFiltro && fechaFiltroHasta &&
                        estado ? (
                            <Button
                                href={handleUrlDescargaDialFire()}
                                variant="primary"
                            >
                                {" "}
                                CSV DialFire{" "}
                            </Button>
                        ) : (
                            ""
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default FormularioFiltro;
