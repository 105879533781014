import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { actualizarLlamada } from '../../../actions/leadsActions';
import authAxios from '../../../config/authAxios';

const Reminder = ({reminder}) => {

    const [mostrado,setMostrado]=useState(false)
    const [timerMinutes,setTimerMinutes] = useState(0);
    const dispatch = useDispatch()
    let interval = useRef();

    const empezarContador = (reminder) => {
       // console.log(reminder);
        let finalTime=null;

        finalTime = new Date(reminder.fecha_hora).getTime();
       
        interval = setInterval(() =>{

        const now = new Date().getTime();
        const currentTime =  finalTime - now;
        let hours = Math.floor((currentTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let mins = Math.floor((currentTime%(1000*60*60))/(1000*60));
        //console.log(reminder.asunto,finalTime,now,hours,mins)   
        if(mins<=parseInt(reminder.recordatorio) && hours<=0)
        {
            setTimerMinutes(mins);
            clearInterval(interval);
            if(!mostrado)
            {
                setMostrado(true)
                dispatch(actualizarLlamada(reminder))
                //console.log('Tienes una llamada programada para '+reminder.fecha_hora)
               // console.log('Terminó')
                const options = {
                    body: `Debes ponerte en contacto con ${reminder.nombres} ${reminder.apellidos}\nFecha y Hora: ${reminder.fecha_hora}`,
                    icon: "https://sistema.inedi.edu.pe/images/orange_icon.png?auto=compress&cs=tinysrgb&dpr=1&w=250",
                    dir: "ltr"
                  };
                const NotifMe= new Notification("Llamada Programada",options)

                Swal.fire({
                    title: "Llamada programada",
                    html: `Debes ponerte en contacto con <strong>${reminder.nombres} ${reminder.apellidos}</strong><br/>Fecha y Hora: <strong> ${reminder.fecha_hora}</strong><br/><br/>Ir a registro:<a href='detalleRegistro/${reminder.id_lead}' >AQUI</a>`,
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Finalizar!",
                    showLoaderOnConfirm: true,
                    preConfirm: async function() {
                        try {
            
                           // let correo = await authAxios.post(`/admin/finalizarRecordatorio`, reminder)
                           // if (!correo.data.error) {
                               return true
                           // }
          
                            } catch (error) {
                               Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: error.response.data.msg
                                })
                            }
                  },
                    allowOutsideClick: () => !Swal.isLoading()
                  })
                  .then(result => {
                    //mensaje que se cambio la contraseña satisfactoriamente
                    // console.log(result)
                    if (result.value === true) {
                     
                    }
                  });
           
            }

         /*   dispatch({
                type:examenesTypes.TIEMPO_EXAMEN_TERMINADO
            })*/
        }


        },1000) ;

   
    }

    useEffect(()=>{
        if(!mostrado)
        empezarContador(reminder);
        return () =>{
            clearInterval(interval);
        };
    },[mostrado])

    return (
        <div style={{display:'none'}}>
            
        </div>
    );
};

export default Reminder;