import React from 'react';
import DescargaGoogleContacts from './DescargaGoogleContacts';

const ArchivosCSVGoogle = ({link_gen}) => {

    const mostrarEnlaces = () => {
        if(link_gen.length > 0) {
          return link_gen.map((link,i) => (
            <DescargaGoogleContacts 
                key={i}
                datos={link}
                index={i}
            />
          ))
        } else {
          return <p>No hay datos</p>
        }
      }
    

    return (
        mostrarEnlaces()
    );
};

export default ArchivosCSVGoogle;