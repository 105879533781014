
import React from 'react';
import { Table } from 'react-bootstrap';
//Redux
const ResultadosFiltro = ({resultados}) => {
 // console.log(resultados)
 return (
 
      <div className="row p-2">
       { /*<div className="col-12 mb-2">
         <h4>Campaña: {resultados?.campaign} </h4>   
        </div>*/}
        <div className="col-md-6">
            <div className="embudo">
              <div className="embudo_1">
                <h3>S/. {resultados.meta.monto}</h3>
                <p style={{fontSize:'15px'}}>{resultados.meta.n_pensiones} ({resultados.meta.porcentaje})</p>
                <p style={{fontSize:'15px'}}>Total</p>
              </div>
              <div className="embudo_2">
                <h3>S/. {resultados.activos.monto}</h3>
                <p style={{fontSize:'15px'}}>{resultados.activos.n_pensiones} ({resultados.activos.porcentaje})</p>
                <p style={{fontSize:'15px'}}>Activos</p>
              </div>
              <div className="embudo_3">
                <h3 style={{marginTop:'-60px'}}>S/. {resultados.producto.monto}</h3>
                <p style={{fontSize:'15px'}}>{resultados.producto.n_pensiones} ({resultados.producto.porcentaje})</p>
                <p style={{fontSize:'15px'}}>Al día</p>
              </div>
            </div>
           <div>

              <Table className='mb-4'>
                    <thead>
                      <tr>
                        <th>
                          Estados
                        </th>
                        <th>
                          N° pensiones
                        </th>
                        <th>
                          Monto
                        </th>
                        <th>
                         (%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{textAlign:'center'}}>Desertados</td>
                            <td style={{textAlign:'center'}}>{resultados.perdida.n_pensiones}</td>
                            <td style={{textAlign:'center'}}>{parseFloat(resultados.perdida.monto).toFixed(2)}</td>
                            <td style={{textAlign:'center'}}>{resultados.perdida.porcentaje}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'center'}}>Deudores</td>
                            <td style={{textAlign:'center'}}>{resultados.brecha.n_pensiones}</td>
                            <td style={{textAlign:'center'}}>{parseFloat(resultados.brecha.monto).toFixed(2)}</td>
                            <td style={{textAlign:'center'}}>{resultados.brecha.porcentaje}</td>
                        </tr>
                    </tbody>
                    
                </Table>
           </div> 
        </div>    
        <div className="col-md-6">
        </div>     
      </div>
    
  
  
    );
};

export default ResultadosFiltro;
