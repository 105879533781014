import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch } from 'react-redux';
import useSelectCampaigns from "../../../hooks/useSelectCampaigns";
import useSelectTiposRecurso from "../../../hooks/useSelectTiposRecurso";
import {insertarRecurso} from '../../../actions/campaignsActions';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const FormularioRecurso = () => {

const [campaign, SelectCampaigns] = useSelectCampaigns(null)
const [tipos_recursos, SelectTipoRecursos] = useSelectTiposRecurso(null)
const [nombre_recurso, setNombreRecurso]=useState('')
const [url_recurso, setUrlRecurso]=useState('')
const [estado, setEstado]=useState('')

  const dispatch = useDispatch();

  const handleSubmit = (e) =>{
    e.preventDefault();
    const datosCamp={
      campaign: campaign?campaign:'',
      tipo_recurso: tipos_recursos?tipos_recursos.id_recurso:'',
      nombre_recurso: nombre_recurso!==''?nombre_recurso:'',
      url_recurso: url_recurso!==''?url_recurso:null,
      estado: estado!==''?estado:''
    }
  
  const retornarListaActualizada = async () =>{
     await (dispatch(insertarRecurso(datosCamp)))
    }
  
    
  // console.log(datosPop)
    if(campaign && tipos_recursos && nombre_recurso!=='' && estado!=='' )
    { 
      retornarListaActualizada()
    }
    else
    {
      avisoError('Faltan campos por rellenar...')
    }
  }

 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Campaña:
                      </label>
                    </div>
                    <div className="col-md-7">
                        <SelectCampaigns/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Tipo Recurso:
                      </label>
                    </div>
                    <div className="col-md-7">
                        <SelectTipoRecursos/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Nombre Recurso:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="nombre_recurso" value={nombre_recurso} onChange={(e)=>setNombreRecurso(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        URL:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="url_recurso" value={url_recurso} onChange={(e)=>setUrlRecurso(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                       Estado
                      </label>
                    </div>
                    <div className="col-md-7">
                    <select name="estado" className="form-control" value={estado} onChange={(e)=>setEstado(e.target.value)} >
                      <option value="">Seleccione una opción</option>
                      <option value="1">Idea</option>
                      <option value="2">Completado</option>
                    </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default FormularioRecurso;
