import React, { useEffect, useState } from "react";
import Layout from "../../../../app/Layout";
import HeaderContent from "../../General/HeaderContent";
import Breadcrumb from "../../General/Breadcrumb";
import SpinnerLoading from "../../../components/Spinners/SpinnerLoading";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { avisoError } from "../../../components/AvisosMensajes/MensajesSwalf";
import ListarReporte from "./ListarReporte";
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { reporteLeadsGeneral } from "../../../../actions/leadsActions";
import { Table } from "react-bootstrap";
import { faList, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSelectProyecto from "../../../../hooks/useSelectProyectos";
import ArchivosCSVGoogle from "./ArchivosCSVGoogle";
 
const ReporteLeadsGeneral = () => {
  const dispatch = useDispatch();
  const[loading, setLoading] = useState(false)
  const[reporteG,setReporteG] = useState(null)
  const[fechaInicio,setFechaInicio] = useState(null)
  const[fechaFin,setFechaFin] = useState(null)
  const {usuario} = useSelector(state=>state.usuario)
  const [proyecto, SelectProyecto] = useSelectProyecto(null)
  const fetchData = async () =>{
    setLoading(true);

    const rango = {
      proyecto: proyecto ? proyecto.id_proyecto:null,
      fechaInicio: fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null,
      fechaFin : fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null
    }

    const respuesta = await (dispatch(reporteLeadsGeneral(rango,usuario)))
   // console.log(respuesta)
    setReporteG(respuesta)
    setLoading(false);
  }
  const traerDatos = () => {
    if(fechaInicio && fechaFin && proyecto)
    {  fetchData();}
    else
    {
      avisoError('Debe indicar todos los campos ...')
    }
  }

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Reporte Cantidad de Leads" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "/leads", nombre: "Leads" },
                { url: "/reporte-cantidad-leads", nombre: "Reportes" },
                { url: "/reporte-cantidad-leads", nombre: "Reporte Cantidad de Leads" },
              ]}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
                <div className="box">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                  </h5>
                
                </div>
                  <div className="row p-3">
                    <div className="col-12 mb-2">
                        <label htmlFor="">Producto:</label>
                        <SelectProyecto/>
                    </div>
                    <div className="col-4">
                      <label htmlFor="">Desde:</label>
                      <DatePicker
                      selected={fechaInicio}
                      onChange={(date) =>setFechaInicio(date)}
                      selectsStart
                      dateFormat="dd/MM/yyyy"
                      name="fechaInicio_"
                    // startDate={formik.values.fechaInicio}
                      className="form-control"
                    
                    // endDate={formik.values.fechaFin}
                    />
                    </div>
                    <div className="col-4">
                      <label htmlFor="">Hasta:</label>
                      <DatePicker
                      selected={fechaFin}
                      onChange={(date) =>setFechaFin(date)}
                      dateFormat="dd/MM/yyyy"
                      name="fechaFin_"
                    // startDate={formik.values.fechaInicio}
                      className="form-control"
                    // endDate={formik.values.fechaFin}
                      startDate={fechaInicio}
                      endDate={fechaFin}
                      minDate={fechaInicio}
                    />
                    </div>
                    <div className="col-4 pt-4">
                      <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>   
                 
                    </div>
                  </div>
                </div>
              </div>
          { reporteG ?   <div className="col-md-6">
                <div className="box">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faList} /> Resultados:
                  </h5>
                
                </div>
                  <div className="row p-3">
                    <div className="col-6 mb-3">
                      <p style={{fontSize:'21px'}}>Cantidad de leads (Orgánico): { reporteG.cantidad_total}</p>
                    </div>
                    <div className="col-6 mb-3">
                      <p style={{fontSize:'21px'}}>Cantidad de leads (Cartera): { reporteG.cantidad_total_cartera}</p>
                    </div>
                    <div className="col-4">
                     { reporteG.data.length>0 && proyecto ?  <a className="btn btn-primary" href={`https://apisistema.gestoraperu.pe/admin/excel/reporte/leads/${proyecto.id_proyecto}/${fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null}/${fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null}`}> Descargar Leads</a>: ''}
                    </div>
                    <div className="col-8">
                    <label>Archivos CSV GoogleContacs:</label><br/>
                      <ArchivosCSVGoogle link_gen={reporteG.link_gen} />
                    </div>
                  </div>
                </div>
              </div> : ''}
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
          <SpinnerLoading loading={loading}>

         { reporteG ?  <div className="box p-3">  

                <div className="row mb-2">
                <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                <h6> Promedio diario de Leads: </h6>
                <h3>{reporteG.promedio_diario ? parseFloat(reporteG.promedio_diario).toFixed(2):0}</h3>
                </div>
                <div className="col-md-8">
                  <h6> Promedio de leads x dia de semana: </h6>
                  <Table responsive className="mb-3" >
                    <thead>
                        <tr>
                            {reporteG.dias_labels.map((dia,i)=>(
                              <th key={i}>{dia}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                      { reporteG.dias_labels.map((dia_l,j)=>(

                          <td className="text-center" key={j}>{reporteG.cantidad_dias ? reporteG.cantidad_dias[j] ? parseFloat(reporteG.data_dias[0][dia_l]/reporteG.cantidad_dias[j]).toFixed(2) : 0 : 0 }</td>

                          )) }
                      </tr>
                    </tbody>
                  </Table>
                  </div>
                </div>
                  
                      
                      <h6>Detalle x Fecha:</h6>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Fecha</th>
                            <th>Día</th>
                            { reporteG ? reporteG.labels.map((label, index) => (
                              <th key={index}>{label}</th>
                            )) : ''}
                          </tr>
                        </thead>
                        <tbody>
                        { reporteG ? reporteG.data.map((registro, index) => (
                            <tr key={index}>
                                <td> {registro.fecha_registro_externo} </td>
                                <td> {registro.dia} </td>
                                { reporteG.campos.map((campo,i)=>(

                                    <td className="text-center" key={i}>{ reporteG.data[index][campo] }</td>

                                )) }
                            </tr>
                            )) : ''}
                        </tbody>
                      </Table> </div> : ''
          }
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReporteLeadsGeneral;