import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFile, faListAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import ModalBasicwithRedux from "../../../containers/ModalLargewhithRedux";
import FormularioEmision from "./FormularioEmision";
import { useSelector } from "react-redux";
 
const EmitirCertificados = () => {

  //State
  const {aptos, loading} = useSelector(state=>state.bases)

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Emisión de Diplomas" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Emisión de Diplomas" },
              ]}
            />
          <div className="row mt-2">
            <div className="col-md-6">
              <FormularioEmision />
            </div>
          </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Listado de Discentes Aptos
                  </h5>
                
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums()}
                    tabla={aptos}
                    keyCol="id_matricula"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmitirCertificados;

/**====================== TABLA =========== */

const colums = () => [
  {
    text: 'ID',
    key: 'id_matricula',
    className: 'id_registro',
    width: 50
  }
  ,
  
  {
    text: "DNI",
    key: "dni",
    align: "center",
    sortable: true,
    className: "dni",
  }
  ,
  
  {
    text: "Discente",
    key: "nombre_discente",
    align: "center",
    sortable: true,
    className: "nombre_discente",
  },
  {
    text: "Periodo",
    key: "periodo",
    align: "center",
    sortable: true,
    className: "periodo text-center",
  }
  ,
  {
    text: "Título",
    key: "titulo",
    align: "center",
    sortable: true,
    className: "titulo",
    cell: (record) => 
    <div className="text-center" dangerouslySetInnerHTML={{__html:record.titulo}}>
    </div>
  
  }
  ,
  {
    text: "Nota",
    key: "nota_final",
    align: "center",
    sortable: true,
    className: "nota text-center",
  }
  ,
  {
    text: "Orden / Mérito",
    key: "orden_merito",
    align: "center",
    sortable: true,
    className: "orden_merito text-center",
  }
  ,
  {
    text:"N° Evento",
    key: "n_evento",
    align: "center",
    className: "n_evento text-center",
    sortable: true
  }
  ,
  {
    text:"N° Libro",
    key: "n_libro",
    align: "center",
    className: "n_libro text-center",
    sortable: true
    
  }
  ,
  {
    text:"Contenido",
    key: "tabla_contenido",
    align: "center",
    className: "tabla_contenido",
    sortable: true,
    cell: (record) => 
      <div className="d-flex justify-content-center">
        <a className="btn btn-info btn-xs" href={`${process.env.REACT_APP_API_URL}/src/Controllers/Proyecto/Certificado/assets/img/${record.tabla_contenido}`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>
      </div>
    
  }
  
];
