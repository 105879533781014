import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { agregarLinkDrive } from '../../actions/controlCapacitacionesActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const AgregarLinkDriveButton = ({ idSesion, idDocente }) => {
  const [show, setShow] = useState(false);
  const [linkDrive, setLinkDrive] = useState('');
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (linkDrive.trim() === '') {
      alert('El link no puede estar vacío');
      return;
    }
    dispatch(agregarLinkDrive({ idSesion, idDocente, linkDrive }));
    setLinkDrive('');
    handleClose(); // Cierra el modal después de guardar
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        <FontAwesomeIcon icon={faPlus} />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Link de Drive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Link de Drive</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresar Link"
                value={linkDrive}
                onChange={(e) => setLinkDrive(e.target.value)}
              />
            </Form.Group>
            <Button variant="success" type="submit" className="mt-3">
              Guardar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AgregarLinkDriveButton;
