import React, { useEffect, useState } from "react";
import MaterialTableDemo from "../../components/plugins/MaterialTableDemo";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { Button } from "react-bootstrap";
import { avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
import { useHistory } from "react-router-dom";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
import RegistroDocenteContainer from "../../../containers/DocentesContainers/RegistroDocenteContainer";
import Layout from "../../../app/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faEnvelope, faEye } from '@fortawesome/free-solid-svg-icons';
import './profesor.scss'
import FormularioDatosCorreo from './FormularioDatosCorreo'
//REDUX
import { useDispatch, useSelector } from "react-redux";
import { listarProfesores, eliminarProfesor } from "../../../actions/profesoresActions";
import { updateStateModalBasic } from "../../../actions/modalActions";
import { DoubleArrowRounded } from "@material-ui/icons";


const Profesores = () => {
  
  const history = useHistory();
  const dispatch = useDispatch();
  const profesores = useSelector(state => state.profesores.profesores)
  const usuario = useSelector(state => state.usuario.usuario)

  const redirigirEditar = idDocente => {
    history.push(`profesores/editar/${idDocente}`);
  };
  //ELIMINAR
  const eliminarSeleccion = idUsuario => {
    if (usuario.id_tp_nivel_usuario === '5') {
      avisoError('Acceso no permitido')
      return
    }
    dispatch( eliminarProfesor(idUsuario) )
  }

  const[profesoresOrdenados,setProfesoresOrdenados]=useState(null)

  const ordenarProfesores = lista =>{

    const copiaProfesores = [...lista]
    const listaOrdenada = copiaProfesores.sort((a,b)=> { return b.calificacion_promedio - a.calificacion_promedio})
    setProfesoresOrdenados(listaOrdenada)

  }

  const handleShowModal = () => {
    dispatch( updateStateModalBasic(<RegistroDocenteContainer /> , 'Nuevo docente') )
  }

  const handleEnvioCorreo = (docente)=>{
    if(usuario.id_tp_nivel_usuario==="4" || usuario.id_tp_nivel_usuario==="1" )
    {dispatch(updateStateModalBasic(<FormularioDatosCorreo docente={docente} />,'Nuevo Correo' ))}
    else{
      avisoError("El usuario no cuenta con permisos")
    }
  }

  useEffect(() => {
    if (!profesores.length) {
      dispatch( listarProfesores() ) 
    }
    else
    {
      ordenarProfesores(profesores)
    }
  }, [profesores]);


  return (
    <Layout>
      <HeaderContent title={"Docentes"} subItem={usuario.id_tp_nivel_usuario==='5'? '¡Entrena a tu equipo docente y campeona!': null}/>
      <Breadcrumb
        link={[
          {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
          {url:'/profesores', nombre: 'Docentes'}
        ]}
      />
      <div className="container-fluid px-0 content-actions my-3">
        <div className="row">
          <div className="col-md-12 text-right">
            <Button
              variant='primary'
              className='btn-icon'
              onClick={() => handleShowModal()}
            >
              <FontAwesomeIcon icon={faPlus} />
              Nuevo docente
            </Button>
          </div>
        </div>
      </div>
      <div className="box">
        <div className="box-header with-border">
          <h5 style={{ color: "#434343" }} >
            <i className="fas fa-list pr-2"></i>Tabla de docentes
          </h5>
        </div>
        <div className="box-body">
          <MaterialTableDemo
            colums={colums(redirigirEditar, eliminarSeleccion,handleEnvioCorreo)}
            tabla={profesoresOrdenados ? profesoresOrdenados : []}
          />
        </div>
      </div>
      <ModalBasicwithRedux />
    </Layout>
  );
};

export default Profesores;

//----------------------DATATABLE/---------------
const colums = (redirigirEditar, eliminarSeleccion, handleEnvioCorreo) => [
  {
    key: 'index',
    text: '#',
    align: 'center',
    className: 'text-center',
    cell: (_, index) => index+1
  },
  {
    text: "Nombre",
    key: "nombre_usuario",
    align: "center",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Apellido",
    key: "apellido_usuario",
    align: "center",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "DNI",
    key: "dni",
    align: "center",
    sortable: true,
    className: "text-center"
  },
  {
    text: 'Teléfono',
    key: 'celular',
    align: 'center',
    className: 'text-center',
    cell: record => (
      <>
        <a href={`tel:${record.celular}`}>{record.celular}</a>
      </>
    )
  },
  {
    text: 'Correo',
    key: 'email',
    align: 'center',
    cell: record => (
      <>
        <a href={`mailto:${record.email}`}>{record.email}</a>
      </>
    )
  },
  {
    text: 'Área',
    key: 'area',
    align: 'center',
    className: 'area',
    width: 200
  },
  
  {
    text: 'Calificación Prom.',
    key: 'calificacion_promedio',
    align: 'center',
    sortable:true,
    className: 'calificacion_promedio',
    width: 200,
    cell: record => (
      <>
        {record.calificacion_promedio ? parseFloat(record.calificacion_promedio)+ ' %' : ''}
      </>
    )
  },
  {
    text: "Usuario",
    key: "usuario",
    align: "center",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Acciones",
    key: "button",
    align: "center",
    sortable: true,
    className: "text-center",
    cell: record => {
      return (
        <>
           <Button
            variant='info'
            size='xs'
            type="button"
            onClick={() => handleEnvioCorreo(record)}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </Button>
          <Button
            variant='danger'
            size='xs'
            type="button"
            onClick={() => eliminarSeleccion(record.id_usuario)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            variant='warning'
            size='xs'
            type="button"
            onClick={() => redirigirEditar(record.id_docente)}
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
        </>
      )
    }
  }
  

];