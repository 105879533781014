import React, { useState } from 'react';
import Layout from '../../../app/Layout';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import DataTableComponent from "../../components/plugins/DataTableComponent";
import PieChart from './PieChart';
import { faClock,faHashtag,faListAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import FormularioFiltroLlamadas from './FormularioFiltroLlamadas';
import FormularioFiltroLlamadasAsesor from './FormularioFiltroLlamadasAsesor';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
const ReporteLlamadas = () => {

   
    const [loading, setLoading] = useState(false)
    const [datosTabla,setDatosTabla] = useState({data:[],grafico:null})
    const {usuario} = useSelector(state=>state.usuario)


    const formatTiempo = (seconds) =>{
        let divisor_for_minutes = seconds % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let secs = Math.ceil(divisor_for_seconds);
    
        return `${minutes<10?'0'+minutes:minutes}:${secs<10?'0'+secs:secs}`
      }
      
      const formatTiempoTotal = (seconds) =>{
        let hours = Math.floor(seconds / (60 * 60));
        let divisor_for_minutes = seconds % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let secs = Math.ceil(divisor_for_seconds);
    
        return `${hours<10?'0'+hours:hours}:${minutes<10?'0'+minutes:minutes}:${secs<10?'0'+secs:secs}`
      }
    return (
        <Layout>
            <div className="ControlReportes">
                <div className="ControlReportes__header">
                <HeaderContent title='Reporte de Llamadas' />
                <Breadcrumb
                    link={[
                    { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                    { url: "", nombre: "Reportes" },
                    { url: "", nombre: "Llamadas" },
                    ]}
                />
                </div>
                <div className="ControlReportes__body">
                    <div className='row'>
                        <div className='col-md-6'>
                          {  usuario.id_tp_nivel_usuario!=='11'  ? <FormularioFiltroLlamadas setDatosTabla={setDatosTabla} setLoading={setLoading} usuario={usuario} />
                          : <FormularioFiltroLlamadasAsesor setDatosTabla={setDatosTabla} setLoading={setLoading} usuario={usuario} /> }
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-8'>
                            <SpinnerLoading loading={loading}>
                                <div className="card">
                               
                                    <div className="card-body">
                                    <h5
                                        style={{ color: "#434343" }}
                                        >
                                        <FontAwesomeIcon icon={faListAlt} /> Lista de Llamadas
                                    </h5>
                                    <DataTableComponent
                                        colums={colums(formatTiempo,usuario)}
                                        tabla={datosTabla.data}
                                        keyField="id_lead"
                                    />
                                    </div>
                                </div>
                            </SpinnerLoading>
                        </div> 
                        { datosTabla.grafico ? <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                <PieChart info={datosTabla.grafico}/> 
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-4 d-flex justify-content-center align-items-center'>
                                                <FontAwesomeIcon icon={faClock} size='3x' color={'#27314A'} />
                                            </div>
                                            <div className='col-md-8 text-center'>
                                                <h3>{formatTiempoTotal(datosTabla.tiempo_total)}</h3>
                                                <p>Tiempo total</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-4 d-flex justify-content-center align-items-center'>
                                                <FontAwesomeIcon icon={faClock} size='3x' color={'#27314A'} />
                                            </div>
                                            <div className='col-md-8 text-center'>
                                                <h3>{formatTiempoTotal(datosTabla.tiempo_conversado)}</h3>
                                                <p>Tiempo total conversado</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-4 d-flex justify-content-center align-items-center'>
                                                <FontAwesomeIcon icon={faHashtag} size='3x' color={'#27314A'} />
                                            </div>
                                            <div className='col-md-8 text-center'>
                                                <h3>{datosTabla.data.length}</h3>
                                                <p>N° de registros</p>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div> : <div className='col-md-4'><div className='card'><div className='card-body'>Sin datos por mostrar</div></div></div>} 
                    </div> 
                </div>

            </div>
        </Layout>
    );
};

export default ReporteLlamadas;

const colums = (formatTiempo,usuario) => [
  {
    name: "Estado",
    selector: "completado",
    align: "center",
    className: "completado",
    sortable: true,
    compact:true,
    wrap:true,
    cell:(record)=> <Badge variant={record.completado==='0'?'warning': record.completado==='1' ? 'success' : 'danger'}>{record.completado!=='1'?'Pendiente':'Completo'}</Badge>
  }
  ,
  {
    name:"Accion",
    selector:"destino",
    align:"center",
    className:"destino",
    wrap:true,
    cell:(record)=> <Link
    className="btn btn-warning btn-xs"
    to={`detalleRegistro/${record.destino}`}
    >
    <FontAwesomeIcon icon={faEdit} />
    </Link>
    }
    ,
    {
      name: "Fecha Llamada",
      selector: "fecha_hora",
      align: "center",
      className: "fecha_hora",
      sortable: true,
      wrap:true
    }
    ,
    {
      name: "Nombres",
      selector: "nombres",
      align: "center",
      sortable: true,
      className: "nombres",
      compact:true,
      wrap:true,
      width:"100px"
    },
    {
      name: "Apellidos",
      selector: "apellidos",
      align: "center",
      sortable: true,
      className: "apellidos",
      compact:true,
      wrap:true,
      width:"100px"
    }
    ,
    {
      name: "Estado",
      selector: "nombre_estado",
      align: "center",
      className: "estado",
      sortable: true,
      width:"100px",
      wrap:true
    }

    ,
    {
      name: "Anotaciones",
      selector: "anotaciones",
      align: "center",
      className: "notas",
      sortable: false,
      wrap:true,
    }
    ,
    {
      name: "Asunto",
      selector: "asunto",
      align: "center",
      className: "asunto",
      sortable: true
    }
    ,
    {
      name: "Propósito",
      selector: "nombre_proposito",
      align: "center",
      className: "nombre_proposito",
      sortable: true
    }
 
    ,
    {
      name: "Duración de llamada",
      selector: "tiempo_llamada",
      align: "center",
      className: "proyecto",
      sortable: true,
      width:"80px",
      cell:(record) => <p>{formatTiempo(record.tiempo_llamada)}</p>
    }
      
    ,
    {
      name: "Tiempo conversado",
      selector: "tiempo_conversado",
      align: "center",
      className: "proyecto",
      sortable: true,
      width:"80px",
      cell:(record) => <p>{formatTiempo(record.tiempo_conversado)}</p>
    }
      
    ,
    {
      name: "Asesor",
      selector: "nombre_usuario",
      align: "center",
      className: "nombre_asesor",
      sortable: true,
      width:"100px",
      wrap:true,
    }
    ,

  ];