import React from 'react';

const VistaPrevia = ({registro}) => {
    return (
        <div>
            <div dangerouslySetInnerHTML={{__html:registro.formato_html}}>
            </div>
        </div>
    );
};

export default VistaPrevia;