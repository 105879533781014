import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
//Redux
import { useDispatch } from 'react-redux';
import { crearLlamada } from '../../../actions/leadsActions';
import ReactDatePicker from 'react-datepicker';
import * as moment from 'moment'


const FormularioLlamada = ({registro,registros,contacto=false,detalle=false}) => {

  const [fllamada, setFllamada] = useState(null)
  const dispatch = useDispatch();
  const initialState = {
    id_lead:registro.id_lead,
    tipo_llamada:1,
    fecha_hora:null,
    asunto:'',
    proposito_llamada:'',
    recordatorio:'',
    id_usuario: registro.asesor?registro.asesor:registro.responsable,
  }
  const [datosLead,setdatosLead]=useState(initialState)

  const {asunto,proposito_llamada,recordatorio,tipo_llamada} = datosLead

  const handleChange = (evt) => {
    const value = evt.target.value;
    setdatosLead({
      ...datosLead,
      [evt.target.name]: value
    });
  }

  const convertirLead = async (e) =>{
  e.preventDefault();
  await dispatch(crearLlamada(datosLead,registros,contacto,detalle))
  }


  useEffect(()=>{
    setdatosLead({
      ...datosLead,
      fecha_hora: fllamada ? moment(fllamada).format('YYYY-MM-DD H:mm:ss') : null,
    })
  },[fllamada])

  return (
    <div style={{padding:'15px'}}>
      <form onSubmit={(e) => convertirLead(e)}>
        <div className="row">
          <div className="col-6">
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Lead:</label>
            <p>{registro.nombres} {registro.apellidos}</p>
          </Box>
          </div>
          <div className="col-12">
          <h4>Datos de llamada</h4>
          <br/>
       {/*   <Box component="fieldset" mb={3} borderColor="transparent">
          <label htmlFor="">Asunto:</label>
            <input type="text" name="asunto" value={asunto} className='form-control' onChange={handleChange} required />
          </Box>*/}
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Propósito:</label>
            <select name="proposito_llamada" className='form-control' value={proposito_llamada} onChange={handleChange} required >
              <option value="">Escoge una opción...</option>
              <option value="2"> Venta </option>
              <option value="3"> Seguimiento de Venta</option>
              <option value="1"> Posventa</option>
            </select>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
          <label htmlFor="">Fecha y hora:</label>
          <ReactDatePicker
                selected={fllamada}
                onChange={(date) =>setFllamada(date)}
                dateFormat="dd/MM/yyyy H:mm"
                autoComplete="off"
                name="fllamada"
                className="form-control"
                timeInputLabel="Time:"
                showTimeInput
                required
              />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Tipo llamada:</label>
            <select name="tipo_llamada" className='form-control' value={tipo_llamada} onChange={handleChange} required >
              <option value="">Escoge una opción...</option>
              <option value="0"> Entrante</option>
              <option value="1"> Saliente</option>
            </select>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Recordatorio:</label>
            <select name="recordatorio" className='form-control' value={recordatorio} onChange={handleChange} required >
              <option value="">Escoge una opción...</option>
              <option value="5"> 5 min. antes</option>
              <option value="10"> 10 min. antes</option>
              <option value="15"> 15 min. antes</option>
              <option value="20"> 20 min. antes</option>
              <option value="25"> 25 min. antes</option>
            </select>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent" >
          <button type="submit" className="btn btn-primary btn-submit mr-2" >
           Guardar información
          </button>
          </Box>
          </div>
        </div>
      </form>
  </div>
   );
}
 
export default FormularioLlamada;