import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";

//Reduxt
import {useDispatch} from "react-redux";
import { cargarReporteAnual } from "../../../actions/userActions";
import LineMontoVentasMultiAxis from "../inicio/ChartComponents/LineMontoVentasMultiAxis";
import TablaReporte from "./TablaReporte";
const ReporteAnualAsesores = () => {
  //State
  const dispatch = useDispatch();
  const [datosComercial,setDatosComercial]=useState(null)
  const [loading,setLoading] = useState(false)
  const [periodo,setPeriodo] = useState(new Date().getFullYear())
  const fetchData = async () =>{
    setLoading(true)
    const datos_comercial = await(dispatch(cargarReporteAnual(periodo)))

    if(datos_comercial)
    {
      setDatosComercial(datos_comercial)
      setLoading(false)
    }
  }


  useEffect(() => {
    fetchData()
  }, [periodo]);

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Reporte mensual" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Reporte mensual" },
              ]}
            />
          <div className="row">

              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                      <div className="col-md-6">
                          <label>Periodo</label>
                          <select className="form-control" name="periodo" value={periodo} onChange={(e)=>setPeriodo(e.target.value)}>
                            <option value="">Seleccione un periodo</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                          </select>
                      </div>
                  </div>
                </div>
              </div>

          </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
            <SpinnerLoading loading={loading}>
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="card">
                      <div className="card-body">
                      { datosComercial ? <LineMontoVentasMultiAxis datos={datosComercial} /> : 'Sin datos...'}
                      </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="card">
                      <div className="card-body">
                      { datosComercial ? <TablaReporte datos={datosComercial} /> : 'Sin datos...'}
                      </div>
                </div>
              </div>
            </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReporteAnualAsesores;
