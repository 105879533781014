import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'
import Comentarios from './Comentarios';
import { useDispatch, useSelector } from 'react-redux';
import { traerComentariosVideos } from '../../../actions/cursosActions';
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import { avisoError } from "../../components/AvisosMensajes/MensajesSwalf";

//Redux

const ComentariosVideos = () => {

  const dispatch=useDispatch()
  const usuario = useSelector(state=> state.usuario.usuario);
  const comentarios = useSelector(state=> state.comentarios.comentarios);
  const [datosComentarios, setDatosComentarios] = useState(null)
  const [seleccionProyecto, SelectProyecto] = useSelectProyecto(null);


  const datosFetch = {
    id_docente_consulta: usuario.id_empleado,
    proyecto:null
  }

  const traerComentariosVideosConsulta = async () =>{
    if(seleccionProyecto)
    { 
     await(dispatch(traerComentariosVideos({id_docente_consulta:0,proyecto:seleccionProyecto.id_proyecto})))
    }
    else
    {
      avisoError('Debe seleccionar un producto primero')
    }
  }

    useEffect(()=>{
    const fetchData = async () =>{
        if(usuario.id_tp_nivel_usuario==="2")
        {
          const rpta = await(dispatch(traerComentariosVideos(datosFetch)))
          if(rpta)
          {   
             // setLoading(false)
            // setDatosComentarios(rpta)
          }
        }else
        {
          await(dispatch(traerComentariosVideos({id_docente_consulta:0,proyecto:seleccionProyecto ? seleccionProyecto.id_proyecto : null})))
        }
    }

    fetchData()
    },[])

  
    useEffect(()=>{
      if(comentarios)
      {
        setDatosComentarios(comentarios)
      }
      },[comentarios])
  

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='Comentarios' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Comentarios" }
            ]}
          />

        </div>
        <div className="ControlReportes__body">
          <div className="box box-primary">
            <div className="box-body">
           
             <Comentarios comentarios={comentarios} /> 
            
            </div>
          </div>    
        </div>
      </div>
    </Layout>
  );
}
 
export default ComentariosVideos;