import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import './ContenidoPlan.scss';
import ConceptoItemResumen from './ConceptoItemResumen';
import * as moment from 'moment';

const ContenidoPlan = ({ plan = {}, setConceptosFinales, conceptosFinales, handleEditarConcepto,fecha_inicio_p }) => {

  const { dt_plan: conceptos = [], id_tp_modalidad: idModalidad } = plan;
  
  const num_romanos =["I","II","III","IV","V","VI","VII","VIII"];

  const mostarItems = () => {
    if(!Object.keys(plan).length) return <Alert variant='info'>
      Selecciona un plan para mostrar contenido
    </Alert>

    if(!conceptos.length) {
      return <Alert variant='danger'>
        No hay contendio en este plan
      </Alert>
    } else {      
      return [...conceptosFinales].map((concepto, index) => (
        <ConceptoItemResumen
          key={index}
          handleEditarConcepto={handleEditarConcepto}
          idModalidad={idModalidad}
          concepto={concepto}
        />
      ))
    }
  }

  const actualizarFechas = () => {
    console.log(conceptos)
    let copiaConceptos = [...conceptos];
    let mes_adicional = {
      monto: 0,
      nombre_concepto: `MENSUALIDAD ${num_romanos[conceptos.length]}` ,
      fecha_limite:null,
      id_tp_concepto: 2,
      orden: conceptos.length+1
    }
    if(idModalidad !==2) { //Si es Mensualidad

      let fechaActual = moment();
      let dia = 0;
      let fecha_inicio = moment(fecha_inicio_p);
      let fechaStandar = moment(fechaActual).add(dia,'day');
      //console.log(fechaActual,fecha_inicio)
      
      copiaConceptos.forEach((concepto,i) => {
    /*   console.log('mismo mes',fechaActual.format("M")===fecha_inicio.format("M"))
       console.log('mismi año',fechaActual.format("YYYY")===fecha_inicio.format("YYYY"))*/
        if(idModalidad===1 && fechaActual.format("YYYY")===fecha_inicio.format("YYYY"))
        {
          //console.log('mismo mes',fechaActual.format("M")===fecha_inicio.format("M"))
         // console.log('mismi año',fechaActual.format("YYYY")===fecha_inicio.format("YYYY"))
          if(i===1)
          { 
            mes_adicional.monto=0;
            let fecha_second = new Date(`${fechaActual.format("YYYY")}-${fechaActual.format("MM")}-30 00:00:00`)
            let daysDiff = moment(fecha_second).diff(moment(fechaActual),'days')
            console.log(concepto.monto)
            let fraccion = parseFloat(parseInt(concepto.monto)/30).toFixed(0) ;
            let monto_f = parseFloat(fraccion*daysDiff).toFixed(2);
            mes_adicional.monto= concepto.monto-monto_f
            concepto.fecha_limite = moment(fecha_second).format('YYYY-MM-DD');
           // concepto.monto=concepto.monto - mes_adicional.monto;
            concepto.monto_aux=monto_f;
          }
          else
          {    
            concepto.fecha_limite = moment(fechaStandar).format('YYYY-MM-DD');
            fechaStandar = moment(fechaStandar).add(1, 'month');
            if(fechaStandar.format("M") !== 2) {
              fechaStandar = `${fechaStandar.format('YYYY')}-${fechaStandar.format('MM')}-30 00:00:00`;
            } 
          }
          

        }
        else
        {    
          concepto.fecha_limite = moment(fechaStandar).format('YYYY-MM-DD');
          fechaStandar = moment(fechaStandar).add(1, 'month');
          if(fechaStandar.format("M") !== 2) {
            fechaStandar = `${fechaStandar.format('YYYY')}-${fechaStandar.format('MM')}-30 00:00:00`;
          } 
        }
       
      });

      if(mes_adicional.monto)
      {
        //console.log(mes_adicional)
        mes_adicional.fecha_limite = moment(new Date(fechaStandar)).format('YYYY-MM-DD');
        copiaConceptos = [...copiaConceptos, mes_adicional]
      }

    }
    setConceptosFinales(copiaConceptos);
  }

  useEffect(() => {
    actualizarFechas()
  }, [plan])

  return ( 
    <div className="ContenidoPlan mt-3">
      {mostarItems()}
    </div>
  );
}
 
export default ContenidoPlan;