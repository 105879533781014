import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { enviarCorreoLeadProducto, enviarCorreoLeadProducto_v2, registrarClick, traerPlantillasProyecto } from '../../../actions/leadsActions';


const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const FormularioDatosCorreo = ({registro}) => {

const [plantilla, setPlantilla]=useState('')
const [plantillas,setPlantillas]=useState(null)
const {usuario} = useSelector(state=>state.usuario)
const dispatch = useDispatch();


const handleSubmit = (e) => {
  e.preventDefault();


  const datos = {
    lead: registro,
    id_plantilla: plantilla,
    usuario:usuario,

  };

  // Opcional: Mostrar por consola los datos enviados
  console.log("Datos enviados:", datos);

  handleRegistrarClick(registro, 3);
  dispatch(enviarCorreoLeadProducto_v2(datos));
};


const handleRegistrarClick = (registro,tipo)=>{
  const datos = {
    autor:usuario.id_usuario,
    id_lead: registro.id_lead,
    tipo:tipo,
  }

  dispatch(registrarClick(datos));
}

useEffect(()=>{
 const fetchData = async ()=>{
   const respuesta = await dispatch(traerPlantillasProyecto(registro.producto))
   if(respuesta)
   {
     setPlantillas(respuesta)
   }
 }

 fetchData();

},[])

 return (
 
  <form id="formNuevo2" style={{padding:'15px'}} onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                        <label htmlFor="titulopop" className="mb-0">
                          Nombre Destinatario:
                        </label>
                      </div>
                      <div className="col-md-7">
                          {registro.nombres} {registro.apellidos}
                      </div>
                      <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                        <label htmlFor="titulopop" className="mb-0">
                          Correo Destinatario:
                        </label>
                      </div>
                      <div className="col-md-7">
                          {registro.correo}
                      </div>
                      <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Plantilla:
                      </label>
                    </div>
                    <div className="col-md-7">
               
                        <select name="plantilla" value={plantilla} className='form-control' onChange={(e)=> setPlantilla(e.target.value)}  required >
                                <option value="">Seleccione una plantilla ...</option>
                                {
                                    plantillas?.map((plant,i)=>(
                                        <option key={i} value={plant.id_plantilla}>{plant.nombre_plantilla}</option>
                                    ))
                                }
                        </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-upload"></i> Enviar Correo
                  </button>

                  <div className="form-row align-items-center mb-3">
                      <div dangerouslySetInnerHTML={{__html:plantillas?.map((pl,i)=>{
                        if(pl.id_plantilla===plantilla)
                        {
                          return pl.formato_html
                        }
                      })}}>
                      </div>  
                  </div>         
                  <div className="form-group">
                   
                  </div>
                 
                </form>
  
    );
};

export default FormularioDatosCorreo;
const initialValues = {
    TITULO:'',
    MENSAJE:'',
    ENLACE:''
  }