import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
//Redux
import { useDispatch, useSelector } from 'react-redux';

import { convertirEnContacto, listarFases } from '../../../actions/leadsActions';
import useSelectProyecto from '../../../hooks/useSelectProyectos';

const FormularioConversion = ({registro}) => {


  const usuario = useSelector(state=> state.usuario.usuario);
  const {fases,leads} = useSelector(state=> state.leads);
  const [proyecto, SelectProyecto] = useSelectProyecto(null)
  const dispatch = useDispatch();
  const initialState = {
    lead:registro.id_lead,
    nombres:registro.nombres,
    apellidos:registro.apellidos,
    id_usuario: usuario ? usuario.id_usuario : null,
    id_tp_nivel_usuario: usuario ? usuario.id_tp_nivel_usuario : null,
    importe:'',
    fase:'',
    fecha_cierre:'',
    producto:null,
  }
  const [datosLead,setdatosLead]=useState(initialState)

  const {nombres,apellidos,importe,fase,fecha_cierre} = datosLead

  const handleChange = (evt) => {
    const value = evt.target.value;
    setdatosLead({
      ...datosLead,
      [evt.target.name]: value
    });
  }

  const convertirLead = async (e) =>{
    e.preventDefault();
  await dispatch(convertirEnContacto(datosLead,leads))
  console.log(datosLead)
  }


  useEffect(()=>{
    setdatosLead({
      ...datosLead,
      producto:proyecto?proyecto.id_proyecto:null
    })
  },[proyecto])
  useEffect(()=>{
    dispatch(listarFases())
  },[])
  return (
    <div style={{padding:'15px'}}>
      <form onSubmit={(e) => convertirLead(e)}>
        <div className="row">
          <div className="col-6">
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Nombres</label>
            <p>{nombres}</p>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Apellidos</label>
            <p>{apellidos}</p>
          </Box>
          </div>
          <div className="col-12">
          <h4>Datos del trato</h4>
          <br/>
          <Box component="fieldset" mb={3} borderColor="transparent">
          <label htmlFor="">Producto:</label>
            <SelectProyecto />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
          <label htmlFor="">Fecha cierre:</label>
          <input type="date" name="fecha_cierre" value={fecha_cierre} className='form-control' onChange={handleChange} required />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Importe:</label>
            <input name="importe" value={importe} className='form-control' placeholder='0.00' pattern='[0-9]+([.])?([0-9]+)?' onChange={handleChange} required />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Fase:</label>
            <select name="fase" className='form-control' value={fase} onChange={handleChange} required >
            <option value="">Escoge una opción...</option>
              { fases.length>0 ? fases.map((fase,i)=>{
               return <option key={i} value={fase.id_fase}>{fase.nombre_mostrar}</option>
              }):null}
            </select>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent" >
          <button type="submit" className="btn btn-primary btn-submit mr-2" >
           Guardar Información
          </button>
          </Box>
          </div>
        </div>
      </form>
  </div>
   );
}
 
export default FormularioConversion;