import React, {useState, useEffect} from 'react';
//


//Redux
import { useDispatch, useSelector } from 'react-redux';
import { importarLlamadasDialFire } from '../../../actions/leadsActions';
import useSelectProyecto from "../../../hooks/useSelectProyectos";
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const FormularioLeadsDialFire = () => {

const [archivo, setArchivo] = useState(null);
const {usuario} = useSelector(state=>state.usuario)
const dispatch = useDispatch();

const subirArchivo = e=>{
  setArchivo(e)
}

const handleSubmit = (e) =>
{
    e.preventDefault();
    dispatch(importarLlamadasDialFire(archivo))

}

 

 return (
 
  <form id="formNuevo2" style={{padding:'15px'}} onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Seleccionar Archivo CSV:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e)=> subirArchivo(e.target.files[0])}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  
                  <div className="form-group">
                   
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-upload"></i> Importar Datos
                  </button>
                </form>
  
    );
};

export default FormularioLeadsDialFire;
const initialValues = {
    TITULO:'',
    MENSAJE:'',
    ENLACE:''
  }