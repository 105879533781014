import React, { useState } from 'react';
import * as moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactDatePicker from 'react-datepicker';
import { filtrarTratosR } from '../../../actions/leadsActions';
import { useDispatch } from 'react-redux';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import { Button } from 'react-bootstrap';

const FormularioFiltroVentasAsesor = ({setDatosTabla,setLoading,usuario,datosTabla}) => {
    const[fechaInicio,setFechaInicio] = useState(null)
    const[fechaFin,setFechaFin] = useState(null)
 
    const dispatch = useDispatch()


    const handleUrlExcelDescarga = () => {
        return `${process.env.REACT_APP_API_URL}/admin/excel/reporte/ventas/${fechaInicio?moment(fechaInicio).format('YYYY-MM-DD'):null}/${fechaFin?moment(fechaFin).format('YYYY-MM-DD'):null}/${usuario?usuario.id_usuario:null}`;
      }



    const traerDatos = async () =>{
        if(fechaInicio && fechaFin)
        {  
            const datosFiltro = {
                asesor:usuario,
                fechaFiltroDesde: fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null,
                fechaFiltroHasta: fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null,
                nivel:usuario.id_tp_nivel_usuario,
                ventas:true,
            }
            setLoading(true)
            const rpta = await dispatch(filtrarTratosR(datosFiltro))

            if(rpta)
            {
                setDatosTabla(rpta)
                setLoading(false)
            }
          //  console.log(datosFiltro)
        }
        else
        {
            avisoError("Debes seleccionar un rango de fecha como mínimo")
        }
    }

    return (
        <div className="box">
                                <div className="box-header with-border">
                                    <h5
                                        style={{ color: "#434343" }}
                                        >
                                        <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                                    </h5>   
                                </div>
                                <div className="row p-3">
                                    <div className="col-4">
                                        <label htmlFor="">Desde:</label>
                                        <ReactDatePicker
                                        selected={fechaInicio}
                                        onChange={(date) =>setFechaInicio(date)}
                                        selectsStart
                                        dateFormat="dd/MM/yyyy"
                                        name="fechaInicio"
                                        className="form-control"
                                        autoComplete='off'
                                    />
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="">Hasta:</label>
                                        <ReactDatePicker
                                        selected={fechaFin}
                                        onChange={(date) =>setFechaFin(date)}
                                        dateFormat="dd/MM/yyyy"
                                        name="fechaFin"
                                        className="form-control"
                                        startDate={fechaInicio}
                                        endDate={fechaFin}
                                        minDate={fechaInicio}
                                        autoComplete='off'
                                    />
                                    </div>
                                    <div className="col-4 pt-4">
                                        <button className="btn btn-primary" onClick={()=>traerDatos()} >Consultar</button>  
                                    </div>
                                    <div className="col-4 pt-4">

                                   {  
                                     fechaInicio && fechaFin && datosTabla.data.length ? 
                                    <Button href={handleUrlExcelDescarga()} variant='primary' className='btn-icon'>
                                        <FontAwesomeIcon icon={faFileExcel} />
                                        Descargar
                                    </Button> : null
                                    }
                                    
                                    </div>
                                </div>
                            </div> 
    );
};

export default FormularioFiltroVentasAsesor;