import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faListAlt, faEye, faSearch, faClone, faTrash} from "@fortawesome/free-solid-svg-icons";
import DataTableComponent from "../../components/plugins/DataTableComponent";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalLarge } from "../../../actions/modalActions";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ModalLargewhithRedux from "../../../containers/ModalLargewhithRedux";
import { duplicarPlantillaCorreo, eliminarPlantilla, traerPlantillas, traerPlantillasProyecto } from "../../../actions/leadsActions";
import VistaPrevia from './VistaPrevia'
import useSelectProyecto from "../../../hooks/useSelectProyectos";
const ListaPlantillas = () => {
  //State
  const dispatch = useDispatch();


  const {usuario} = useSelector(state=>state.usuario)
  const [busqueda,setBusqueda]=useState('')
  const [plantillas,setPlantillas]=useState([])
  const [loading,setLoading] = useState(false)
  const [proyecto, SelectProyecto] = useSelectProyecto(null)

  const handleChange = e => {
    setBusqueda(e.target.value)
  }
  
  const handleDuplicar = (registro) =>{
    dispatch(duplicarPlantillaCorreo(registro,setPlantillas,plantillas))
  }

  const handleEliminar = async (id) => {
    await dispatch(eliminarPlantilla(id,setPlantillas,plantillas))
  }

  useEffect(() => {
    const traerDatos = async () =>{
      setLoading(true)
      const rpta = await dispatch(traerPlantillas())
     // console.log(rpta)
      if(rpta)
      {
        
        setPlantillas(rpta)
        setLoading(false)
      }
    }

    traerDatos()
  }, []);

  const traerData = async () =>{
    if(proyecto)
    { 
      setLoading(true)
      const rpta = await dispatch(traerPlantillasProyecto(proyecto.id_proyecto))
    // console.log(rpta)
      if(rpta)
      {
        
        setPlantillas(rpta)
        setLoading(false)
      }
    }
    else
    {
      setLoading(true)
      const rpta = await dispatch(traerPlantillas())
    // console.log(rpta)
      if(rpta)
      {
        
        setPlantillas(rpta)
        setLoading(false)
      }
    }

  }

  const handleVistaPrevia = (registro) =>{
    dispatch(updateStateModalLarge(<VistaPrevia registro={registro} /> ,'Vista Previa'));
  }

   return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            
              <HeaderContent title="Plantillas" icon="group" />
              <Breadcrumb
                      link={[
                              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                              { url: "", nombre: "Plantillas" },
                            ]}
                    />
            
              <div className="row">
                      <div className="col-md-6">
                      <div className="card">
                            <div className="card-body">
                            <div className="row">
                            <div className="col-md-7 mb-2">
                              <label htmlFor="">Producto:</label>
                              <SelectProyecto/>
                            </div>
                            <div className="col-md-5 pt-4">
                            <button className="btn btn-primary" onClick = {traerData} >Consultar</button>
                            </div>
                            </div>
                            </div>
                      </div>
                      </div> 
                     
              </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-1">
          <ModalLargewhithRedux />
            <SpinnerLoading loading={loading}>
              <div className="card mt-2">
                <div className="d-flex justify-content-between p-3">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Plantillas
                  </h5>
                  <div className="col-md-3 d-flex align-items-center pull-right">
                    <input className="form-control" value={busqueda} onChange={handleChange} style={{marginRight:'5px'}} placeholder="Filtrar por Nombre o Producto" />
                    <FontAwesomeIcon icon={faSearch} size="lg"/>
                  </div>
                </div>
                <div className="card-body">
                  <DataTableComponent
                    colums={colums(handleVistaPrevia,handleDuplicar,handleEliminar)}
                    tabla={plantillas}
                    keyField="id_plantilla"
                    hideHeader={true}
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListaPlantillas;

/**====================== TABLA =========== */

const colums = (handleVistaPrevia,handleDuplicar,handleEliminar) => [

  {
    name: "Fecha Registro",
    selector: "fecha_registro",
    align: "center",
    className: "fecha_registro",
    sortable: true,
    width:"150px",
    compact:true
  },
  {
    name: "Nombre Plantilla",
    selector: "nombre_plantilla",
    align: "center",
    sortable: true,
    className: "nombre_plantilla",
    compact:true,
    wrap:true,
    width:"250px",
  },
  {
    name: "Producto",
    selector: "nombre_proyecto",
    align: "center",
    sortable: true,
    className: "nombre_proyecto",
    compact:true,
    wrap:true,
  }
  ,
  {
    name: "Acciones",
    selector: "Acciones",
    align: "center",
    width:"80px",
    className: "Acciones",
    sortable: false,
    cell:(record) => <p>
    <button className="btn btn-primary mr-1 btn-xs" onClick={() => handleVistaPrevia(record)} type="button" title="Vista previa">
          <FontAwesomeIcon icon={faEye} />
     </button>
     <button className="btn btn-success mr-1 btn-xs" onClick={() => handleDuplicar(record)} type="button" title="Duplicar plantilla">
          <FontAwesomeIcon icon={faClone} />
     </button>
    <Link
       className="btn btn-warning btn-xs"
       to={`email-editor/plantilla/${record.id_plantilla}`}
       title="Editar plantilla"
      >
      <FontAwesomeIcon icon={faEdit} />
    </Link>
    <button className="btn btn-danger mr-1 btn-xs" onClick={() => handleEliminar(record.id_plantilla)} type="button" title="Eliminar plantilla">
          <FontAwesomeIcon icon={faTrash} />
     </button>
  </p>
  }
  
];
