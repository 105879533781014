import React from 'react';
import { Card } from 'react-bootstrap';
import {Pie} from 'react-chartjs-2';


const PieChart = (info) => {
  
    const  backgroundColorSelector = [
      'rgba(255, 99, 132, 0.4)',
      'rgba(54, 162, 235, 0.4)',
      'rgba(255, 206, 86, 0.4)',
      'rgba(75, 192, 192, 0.4)',
      'rgba(153, 102, 255, 0.4)',
      'rgba(255, 159, 64, 0.4)'
    ];
    const borderColorSelector = [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)'
    ];

    const state = {
        labels: info.info.labels,
        datasets: [
          {
            label: info.info.label,
            backgroundColor: backgroundColorSelector ,
            borderColor:borderColorSelector,
            borderWidth: 1,
            data: info.info.data
          }
        ]
      }
      
   return (
    <Card className="p-1 mb-3">
      <Card.Body>
              <Pie
              data={state}
              options={{
                title:{
                  display:true,
                  text:info.info.title,
                  fontSize:20
                },
                legend:{
                  display:true,
                  position:'bottom'
                }
              }}
            >  
            </Pie>
      </Card.Body>
    </Card>
    );
};

export default PieChart;