import {
  campaigntypes
} from "./types";


import authAxios from "../config/authAxios";
//import response from '../config/response';
import { avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";
import Swal from "sweetalert2/src/sweetalert2";
import { updateStateModalLarge } from "./modalActions";
//import * as moment from 'moment';


export const listarAnunciosCampaign = () => {

  return async dispatch => {
    dispatch({
      type: campaigntypes.LISTAR_ANUNCIOS
    });
    try {

      const leads = await authAxios.get(
        `/admin/listarAnunciosCampaign`
      );
      //console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: campaigntypes.LISTAR_ANUNCIOS_EXITO
      });

    } catch (error) {
      avisoError('Hubo un error al listar los anuncios')
    }
  }
}

export const filtrarAnuncios = (datos) => {

  return async dispatch => {
    dispatch({
      type: campaigntypes.LISTAR_ANUNCIOS
    });
    try {

      const leads = await authAxios.post(
        `/admin/filtrarAnuncios`, datos
      );
      // console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: campaigntypes.LISTAR_ANUNCIOS_EXITO
      });
      dispatch({
        payload: leads.data.totales,
        type: campaigntypes.TOTALES
      })

    } catch (error) {
      avisoError('Hubo un error al listar los anuncios')
    }
  }
}

export const filtrarRecursos = (id_proyecto) => {

  return async dispatch => {
    dispatch({
      type: campaigntypes.LISTAR_ANUNCIOS
    });
    try {

      const leads = await authAxios.get(
        `/admin/filtrarRecursosC/${id_proyecto}`
      );
      //console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: campaigntypes.LISTAR_RECURSOS_EXITO
      });


    } catch (error) {
      avisoError('Hubo un error al listar los recursos')
    }
  }
}


export const listarWebinars = () => {

  return async dispatch => {
    dispatch({
      type: campaigntypes.LISTAR_WEBINARS
    });
    try {

      const leads = await authAxios.get(
        `/admin/listarWebinars`
      );
      //console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: campaigntypes.LISTAR_WEBINARS_EXITO
      });

    } catch (error) {
      avisoError('Hubo un error al listar los anuncios')
    }
  }
}

export const traerConfiguracionCampaign = (datos) => {

  return async dispatch => {

    try {

      const leads = await authAxios.get(
        `/admin/traerConfiguracionCampaign/${datos.proyecto.id_proyecto}`
      );
      // console.log(leads);

      dispatch({
        payload: leads.data.configuracion,
        type: campaigntypes.CONFIGURACION_CAMPAIGN
      })


    } catch (error) {
      avisoError('Hubo un error al listar los anuncios')
    }
  }
}



export const filtrarWebinars = (datos) => {

  return async dispatch => {
    dispatch({
      type: campaigntypes.LISTAR_WEBINARS
    });
    try {

      const leads = await authAxios.post(
        `/admin/filtrarWebinars`, datos
      );
      // console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: campaigntypes.LISTAR_WEBINARS_EXITO
      });
      dispatch({
        payload: leads.data.resumen_webinar,
        type: campaigntypes.RESUMEN_WEBINAR
      })

    } catch (error) {
      avisoError('Hubo un error al listar los anuncios')
    }
  }
}


export const reporteStatusCampaign = () => {

  return async dispatch => {

    try {

      const reporte = await authAxios.get(
        `/admin/reporteStatusCampaign`
      );
      //console.log(leads);
      return reporte.data


    } catch (error) {
      avisoError('Hubo un error al listar los anuncios')
    }
  }
}

export const datosGeneralCampaign = (datos) => {

  return async dispatch => {

    try {

      const reporte = await authAxios.get(
        `/admin/reporteGeneralCampaign/${datos.producto}`
      );
      //console.log(leads);
      return reporte.data


    } catch (error) {
      avisoError('Hubo un error al listar los anuncios')
    }
  }
}

export const datosGeneralIngresos = (periodo) => {

  return async dispatch => {

    try {

      const reporte = await authAxios.get(
        `/admin/reporte/funnelRecaudacion/${periodo.year}/${periodo.month}`
      );
      //console.log(leads);
      return reporte.data.funnel[0]

    } catch (error) {
      avisoError('Hubo un error al listar los anuncios')
    }
  }
}



export const listarCampaigns = () => {

  return async dispatch => {
    dispatch({
      type: campaigntypes.LISTAR_CAMPAIGNS
    });
    try {

      const leads = await authAxios.get(
        `/admin/listarCampaigns`
      );
      //console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: campaigntypes.LISTAR_CAMPAIGNS_EXITO
      });

    } catch (error) {
      avisoError('Hubo un error al listar las campañas')
    }
  }
}

export const listarCampaignsN = () => {

  return async dispatch => {

    try {

      const leads = await authAxios.get(
        `/admin/listarCampaigns`
      );
      //console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: campaigntypes.LISTAR_CAMPAIGNS_EXITO
      });

    } catch (error) {
      avisoError('Hubo un error al listar las campañas')
    }
  }
}

export const listarTiposRecursosCampaign = () => {


  return async dispatch => {

    try {

      const trcamp = await authAxios.get(
        `/admin/listarTiposRecursosCampaign`
      );
      //console.log(leads);

      dispatch({
        payload: trcamp.data.data,
        type: campaigntypes.LISTAR_TIPOS_RECURSO_EXITO
      });

    } catch (error) {
      avisoError('Hubo un error al listar los recursos')
    }
  }
}


export const insertarCampaign = (datos) => async dispatch => {


  // console.log(datos);

  try {
    let respuesta = await authAxios.post(`/admin/insertarCampaign`, datos);
    if (respuesta.data) {

      dispatch(updateStateModalLarge())

      dispatch({
        payload: respuesta.data.data,
        type: campaigntypes.LISTAR_CAMPAIGNS_EXITO
      })
      avisoCorrecto('Se guardó correctamente')
    }
    else {
      avisoError('No se pudo guardar la campaña')
    }

  } catch (error) {
    avisoError('No se pudo guardar el campaña')
  }
};

export const actualizarComentarioWebinar = (datos) => async dispatch => {


  // console.log(datos);

  try {
    let respuesta = await authAxios.post(`/admin/actualizarComentarioWebinar`, datos);
    // console.log(respuesta)
    if (!respuesta.error) {

      dispatch(updateStateModalLarge())

      dispatch({
        payload: datos,
        type: campaigntypes.ACTUALIZAR_COMENTARIO
      })
      avisoCorrecto('Se guardó correctamente')
    }
    else {
      avisoError('No se pudo guardar la información')
    }

  } catch (error) {
    console.log(error.response.data)
    avisoError('No se pudo guardar la información')
  }
};

export const listarRecursos = () => {

  return async dispatch => {
    dispatch({
      type: campaigntypes.LISTAR_RECURSOS
    });
    try {

      const leads = await authAxios.get(
        `/admin/listarRecursosC`
      );
      //console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: campaigntypes.LISTAR_RECURSOS_EXITO
      });

    } catch (error) {
      avisoError('Hubo un error al listar los recursos')
    }
  }
}

export const insertarWebinar = (datos) => async dispatch => {


  // console.log(datos);

  try {
    let respuesta = await authAxios.post(`/admin/insertarWebinar`, datos);
    //console.log(respuesta)
    if (respuesta.data) {

      dispatch(updateStateModalLarge())

      dispatch({
        payload: respuesta.data.data,
        type: campaigntypes.LISTAR_WEBINARS_EXITO
      })
      avisoCorrecto('Se guardó correctamente')
    }
    else {
      avisoError('No se pudo guardar el webinar.')
    }

  } catch (error) {
    console.log(error.response.data)
    avisoError('No se pudo guardar el webinar...')
  }
};

export const insertarRecurso = (datos) => async dispatch => {


  // console.log(datos);

  try {
    let respuesta = await authAxios.post(`/admin/insertarRecursoC`, datos);
    if (respuesta.data) {

      dispatch(updateStateModalLarge())

      dispatch({
        payload: respuesta.data.data,
        type: campaigntypes.LISTAR_RECURSOS_EXITO
      })
      avisoCorrecto('Se guardó correctamente')
    }
    else {
      avisoError('No se pudo guardar el recurso.')
    }

  } catch (error) {
    avisoError('No se pudo guardar el recurso...')
  }
};

export const insertarAnuncioCampaign = (datos) => async dispatch => {


  // console.log(datos);

  try {
    let respuesta = await authAxios.post(`/admin/insertarAnuncioCampaign`, datos);
    if (respuesta.data) {

      dispatch(updateStateModalLarge())

      dispatch({
        payload: respuesta.data.data,
        type: campaigntypes.LISTAR_ANUNCIOS_EXITO
      })
      avisoCorrecto('Se guardó correctamente')
    }
    else {
      avisoError('No se pudo guardar el anuncio.')
    }

  } catch (error) {
    avisoError('No se pudo guardar el anuncio...')
  }
};



export const actualizarRecursoCampaign = (datosCampaign, recursos) => {
  return async dispatch => {
    try {

      await authAxios.post(
        `/admin/actualizarRecursoCampaign`, datosCampaign
      );


      recursos.forEach((recurso) => {
        if (recurso.id_recurso === datosCampaign.id_recurso) {
          recurso.nombre_recurso = datosCampaign.nombre_recurso
          recurso.url_recurso = datosCampaign.url_recurso
          recurso.estado = datosCampaign.estado
        }
      })
      dispatch({
        payload: recursos,
        type: campaigntypes.LISTAR_RECURSOS_EXITO
      });
      //dispatch(listarLeads(usuario));
      dispatch(updateStateModalLarge())
      avisoCorrecto('Recurso editado correctamente.')

    } catch (error) {
      avisoError('Hubo un error al modificar el registro...')
    }
  }
}

export const actualizarAnuncioCampaign = (datosCampaign, anuncios) => {
  return async dispatch => {
    try {

      await authAxios.post(
        `/admin/actualizarAnuncioCampaign`, datosCampaign
      );


      anuncios.forEach((anuncio) => {
        if (anuncio.id_anuncio === datosCampaign.id_anuncio) {
          anuncio.nombre_anuncio = datosCampaign.nombre_anuncio
          anuncio.inversion = datosCampaign.inversion
          anuncio.estado = datosCampaign.estado
          anuncio.publico = datosCampaign.publico
          anuncio.objetivo = datosCampaign.objetivo
          anuncio.fecha_inicio = datosCampaign.fecha_inicio
          anuncio.fecha_fin = datosCampaign.fecha_fin
          anuncio.cantidad_leads = datosCampaign.cantidad_leads
          anuncio.costo_lead = datosCampaign.inversion && datosCampaign.cantidad_leads ? parseFloat(datosCampaign.inversion / datosCampaign.cantidad_leads).toFixed(2) : '0'
          anuncio.observaciones = datosCampaign.observaciones
          anuncio.pagina = datosCampaign.pagina
          anuncio.fuente = datosCampaign.fuente
        }
      })
      dispatch({
        payload: anuncios,
        type: campaigntypes.LISTAR_ANUNCIOS_EXITO
      });
      //dispatch(listarLeads(usuario));
      dispatch(updateStateModalLarge())
      avisoCorrecto('Recurso editado correctamente.')

    } catch (error) {
      avisoError('Hubo un error al modificar el anuncio...')
    }
  }
}

export const actualizarWebinar = (datosCampaign, webinars) => {
  return async dispatch => {
    try {

      await authAxios.post(
        `/admin/actualizarWebinar`, datosCampaign
      );


      webinars.forEach((webinar) => {
        if (webinar.id_webinar === datosCampaign.id_webinar) {
          webinar.nombre_evento = datosCampaign.nombre_evento
          webinar.fecha_realizacion = datosCampaign.fecha_realizacion
          webinar.desde = datosCampaign.desde
          webinar.hasta = datosCampaign.hasta
          webinar.cantidad_leads = datosCampaign.cantidad_leads
          webinar.total_asistentes = datosCampaign.total_asistentes
          webinar.promedio_asistentes_mx = datosCampaign.promedio_asistentes_mx
          webinar.promedio_asistentes_mn = datosCampaign.promedio_asistentes_mn
          webinar.inscritos = datosCampaign.inscritos
          webinar.p_asistencia = datosCampaign.total_asistentes && datosCampaign.cantidad_leads ? parseFloat((datosCampaign.total_asistentes / datosCampaign.cantidad_leads) * 100).toFixed(2) : '0'
          webinar.p_inscritos = datosCampaign.inscritos && datosCampaign.total_asistentes ? parseFloat((datosCampaign.inscritos / datosCampaign.total_asistentes) * 100).toFixed(2) : '0'
          webinar.grabacion = datosCampaign.grabacion
          webinar.observaciones = datosCampaign.observaciones
        }
      })
      dispatch({
        payload: webinars,
        type: campaigntypes.LISTAR_WEBINARS_EXITO
      });
      //dispatch(listarLeads(usuario));
      dispatch(updateStateModalLarge())
      avisoCorrecto('Recurso editado correctamente.')

    } catch (error) {
      avisoError('Hubo un error al modificar el anuncio...')
    }
  }
}

export const actualizarConfiguracion = (datos) => {
  return async dispatch => {
    try {

      const leads_ = await authAxios.post(
        `/admin/actualizarConfiguracion`, datos
      );

      if (!leads_.error) {
        dispatch(updateStateModalLarge())
        avisoCorrecto('Configuración editada correctamente.')
      }
      else {
        avisoError('Hubo un error al actualizar los datos')
      }

    } catch (error) {
      avisoError('Hubo un error al actualizar la campaña')
    }
  }
}


export const actualizarCampaign = (datosCampaign, campaigns) => {
  return async dispatch => {
    try {

      await authAxios.post(
        `/admin/actualizarCampaign`, datosCampaign
      );



      campaigns.forEach((campaign) => {
        if (campaign.id_camp === datosCampaign.id_camp) {

          campaign.fecha_inicio = datosCampaign.fecha_inicio
          campaign.fecha_fin = datosCampaign.fecha_fin
          campaign.estado = datosCampaign.estado

        }
      })
      dispatch({
        payload: campaigns,
        type: campaigntypes.LISTAR_CAMPAIGNS_EXITO
      });
      //dispatch(listarLeads(usuario));
      dispatch(updateStateModalLarge())
      avisoCorrecto('Camapaña editada correctamente.')

    } catch (error) {
      avisoError('Hubo un error al actualizar la campaña')
    }
  }
}

export const eliminarWebinar = (id, webinars) => {

  return async dispatch => {

    const swalWithBootstrapButtons = Swal.mixin({

    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "Se eliminara todo registro",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function () {
          try {

            await authAxios.get(
              `/admin/eliminarWebinar/${id}`
            );
            //console.log(leads);
            const nuevosWebinar = webinars.filter(webinar => webinar.id_webinar !== id)
            dispatch({
              payload: nuevosWebinar,
              type: campaigntypes.LISTAR_WEBINARS_EXITO
            });
            return true


          } catch (error) {
            avisoError('Hubo un error al eliminar el webinar')
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        // console.log(result)
        if (result.value === true) {

          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Webinar se eliminó correctamente",
            "success"
          );

        }
      });
  };
}


export const eliminarAnuncioCampaign = (id) => {

  return async dispatch => {

    const swalWithBootstrapButtons = Swal.mixin({

    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "Se eliminara todo registro",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function () {
          try {

            const leads = await authAxios.get(
              `/admin/eliminarAnuncioCampaign/${id}`
            );
            // console.log(leads);

            dispatch({
              payload: leads.data.data,
              type: campaigntypes.LISTAR_ANUNCIOS_EXITO
            });
            return true


          } catch (error) {
            avisoError('Hubo un error al eliminar el anuncio')
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        // console.log(result)
        if (result.value === true) {

          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Anuncio se eliminó correctamente",
            "success"
          );

        }
      });
  };
}

export const eliminarCampaign = (id) => {

  return async dispatch => {

    const swalWithBootstrapButtons = Swal.mixin({

    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "Se eliminara todo registro",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function () {
          try {

            const leads = await authAxios.get(
              `/admin/eliminarCampaign/${id}`
            );
            // console.log(leads);

            dispatch({
              payload: leads.data.data,
              type: campaigntypes.LISTAR_CAMPAIGNS_EXITO
            });
            return true


          } catch (error) {
            avisoError('Hubo un error al eliminar la campaña')
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        // console.log(result)
        if (result.value === true) {

          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Campaña se eliminó correctamente",
            "success"
          );

        }
      });
  };
}

export const eliminarRecursoCampaign = (id, recursos) => {

  return async dispatch => {

    const swalWithBootstrapButtons = Swal.mixin({

    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "Se eliminara todo registro",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function () {
          try {

            await authAxios.get(
              `/admin/eliminarRecursoCampaign/${id}`
            );
            // console.log(leads);

            const recursosNuevos = recursos.filter(recurso => recurso.id_recurso !== id)

            dispatch({
              payload: recursosNuevos,
              type: campaigntypes.LISTAR_RECURSOS_EXITO
            });
            return true


          } catch (error) {
            avisoError('Hubo un error al eliminar el recurso')
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        // console.log(result)
        if (result.value === true) {

          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Recurso se eliminó correctamente",
            "success"
          );

        }
      });
  };
}


