import React from 'react';
import { Button } from 'react-bootstrap';
import { faMoneyBill, faTimes, faPercentage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccionPagarPension = ({ handleModalPagar, handleFraccionarPension, handleModalRevisarPago, estadoCuota }) => {
  return ( 
    <div className='AccionPagarPension w-100 text-center'>
    {
      estadoCuota !== 'PAGADO' && estadoCuota !== 'REPORTADO'  ? <>
      <Button variant='danger' size='lg' className='btn-icon' onClick={handleFraccionarPension}>
        <FontAwesomeIcon icon={faPercentage} />
        Fraccionar Pago
      </Button>
      <Button variant='info' size='lg' className='btn-icon' onClick={handleModalPagar}>
        <FontAwesomeIcon icon={faMoneyBill} />
        Registrar Pago
      </Button>
      </> : estadoCuota === 'PAGADO' ? <>
        <div className='d-flex align-items-center'>
          <h4 className='mr-2'>Esta pension ya esta pagada.</h4> 
          <Button variant='danger' className='btn-icon' size='lg' onClick={handleModalPagar}>
            <FontAwesomeIcon icon={faTimes} />
            Anular pago
          </Button>
        </div>
      </> : <div className='d-flex align-items-center'>
          <h4 className='mr-2'>Esta pension ha sido reportada por el discente.</h4> 
          <Button variant='warning' className='btn-icon' size='lg' onClick={handleModalRevisarPago}>
            <FontAwesomeIcon icon={faTimes} />
            Validar Pago
          </Button>
        </div>
    }
      
    </div>
  );
}
 
export default AccionPagarPension;