import React from 'react';

const urlsite = 'http://localhost:3011/images/';
const ListItem = ({index,name,img,n_ventas,gender}) => {
    return (

           <li key={name} className={index>3?'list-sm':'leader'}>
                <img src={img ?  process.env.REACT_APP_API_URL+'/'+img : gender==='F' ? 'https://sistema.inedi.edu.pe/images/user_2.png' : 'https://sistema.inedi.edu.pe/images/user_3.png' } className={index>3?'avatar avatar-sm':'avatar avatar-lg'}/>
                <p className="list-index">{index}</p>
               { index===1 ? <div className='img-copa'>
                <img src='/images/copa.png' style={{maxWidth:'80px'}} />
                </div>:null}
                <div className="userinfo">
                <p className="username">{name}</p>
                <span>N° Ventas: {n_ventas}/25</span>
                </div>
            </li> 

    );
};

export default ListItem;