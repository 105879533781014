import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es.js';
import './sass/styles.scss';
import './sass/agenda.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const localizer = momentLocalizer(moment)

const SesionesEvento = ({ eventos }) => {
  
  return(
      <div className="bigCalendar-container">
        <Calendar
          events={eventos}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={event =>  Swal.fire({ title: 'Información',
                html:  `<div style='text-align:left'><strong>Sesión:</strong>${event.title} <br/> <strong>Producto:</strong> ${event.producto} <br/> <strong>Docente:</strong> ${event.docente.length ? event.docente[0].nombre_usuario +' '+event.docente[0].apellido_usuario:'<span class="badge badge-warning">Pendiente</span>'}</div>`,
                icon: "info",})}
          eventPropGetter={(event)=>{
              //console.log(event);
              var backgroundColor = event.docente.length ? '#1DBC72' : '#DF2E1B'
              var style = {
                  backgroundColor: backgroundColor,
              };
              return {
                  style: style
              };
          }}
          style={{ height: 500}}
          localizer={localizer}
          messages={{
                  next: <FontAwesomeIcon icon={faArrowRight} /> ,
                  previous: <FontAwesomeIcon icon={faArrowLeft} />,
                  today: "Hoy",
                  month: "Mes",
                  week: "Semana",
                  day: "Día"
                }}
          />
      </div>
  )
}

export default SesionesEvento;