import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Layout from '../../../../../app/Layout';
import MaterialTableDemo from '../../../../components/plugins/MaterialTableDemo';
import Breadcrumb from '../../../General/Breadcrumb';
import HeaderContent from '../../../General/HeaderContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faList, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalBasicwithRedux from '../../../../../containers/ModalBasicwithRedux';
import FormularioProgramacion from '../../../../components/ProgramacionAnual/FormularioProgramacion'
import SpinnerLoading from '../../../../components/Spinners/SpinnerLoading';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalBasic } from '../../../../../actions/modalActions';
import { eliminarProgramacion,
  obtenerProgramaciones,
  programacionActiva
} from '../../../../../actions/programacionesActions';

const ProgramacionAnual = () => {

  const dispatch = useDispatch();
  const { programaciones, loading, error } = useSelector(state => state.programaciones);

  const handleNuevo = () => {
    //Si encuentra una programacion activa lo resetea
    dispatch( programacionActiva() )
    dispatch( updateStateModalBasic(<FormularioProgramacion />, 'Nuevo Prototipo') );
  }

  const handleEditar = idProgrmAnual => {
    //Guarda la programacion como activo
    dispatch( programacionActiva(idProgrmAnual) )
    dispatch( updateStateModalBasic(<FormularioProgramacion />, 'Editar Prototipo') );
  }

  const handleEliminar = idProgrmAnual => {
    dispatch( eliminarProgramacion(idProgrmAnual) )
  }


  const botones = ({ idProgrmAnual }) => {
    return <>
        <Button
          variant='danger'
          size='xs'
          onClick={() => handleEliminar(idProgrmAnual)}
        >
          <FontAwesomeIcon icon={faTrash}/>
        </Button>
        <Button
          variant='info'
          size='xs'
          onClick={() => handleEditar(idProgrmAnual)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Link
          to={`/prototipado/${idProgrmAnual}`}
          className='btn btn-warning btn-xs'
        >
          <FontAwesomeIcon icon={faEye} />
        </Link>
      </>
  }

  useEffect(() => {
    if(!programaciones.length) {
      dispatch(obtenerProgramaciones());
    }
  }, [])

  return ( 
    <Layout>
      <div className="ProgramacionAnual">
        <ModalBasicwithRedux />
        <div className="ProgramacionAnual__head">
          <div className="ProgramacionAnual__title">
            <HeaderContent
              title='Prototipado'
            />
          </div>
          <div className="ProgramacionAnual__breadcrumb">
            <Breadcrumb 
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Área de Trabajo" },
                { url: "", nombre: "Prototipado" },
              ]}
            />
          </div>
        </div>
        <div className="ProgramacionAnual__body">
        <SpinnerLoading loading={loading} error={error}>
          <div className="ProgramacionAnual__acciones w-100 text-right my-4">
            <Button
              variant='primary'
              className='btn-icon'
              onClick={handleNuevo}
            >
              <FontAwesomeIcon icon={faPlus}/>
              Nuevo Prototipo
            </Button>
          </div>
          <div className="ProgramacionAnual__tabla">
            <div className="box box-primary mt-4">
              <div className="box-header with-border">
                <h5
                    style={{ color: "#434343" }}
                >
                  <FontAwesomeIcon icon={faList} className='mr-2'/> Lista de prototipos
                </h5>
              </div>
              <div className="box-body">
                <MaterialTableDemo
                  colums={columns(botones)}
                  tabla={programaciones}
                />
              </div>
            </div>
          </div>
        </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default ProgramacionAnual;

const columns = (botones) => [
  {
    text: '#',
    key: 'index',
    sortable: true,
    className: 'text-center',
    width: 50,
    cell: (_, index) => index+1
  },
  {
    text: "Id",
    key: "idProgrmAnual",
    align: "center",
    sortable: true,
    className: "text-center px-2",
    width: 50
  },
  {
    text: "Prototipos",
    key: "programacionAnual",
    align: "center",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "URL Inversión",
    key: "prototipa",
    align: "center",
    className: "text-center",
    cell: record => {
      if (record.prototipa) {
        return <a href={`${record.prototipa}`} target='_blank' rel="noopener noreferrer">
          {record.prototipa}
        </a>
      }
      return 'No hay enlace'
    }
  },
  {
    text: "URL Medición",
    key: "mide",
    align: "center",
    className: "text-center",
    cell: record => {
      if (record.mide) {
        return <a href={`${record.mide}`} target='_blank' rel="noopener noreferrer">
          {record.mide}
        </a>
      }
      return 'No hay enlace'
    }
  },
  {
    text: "URL Formulación",
    key: "formula",
    align: "center",
    className: "text-center",
    cell: record => {
      if (record.formula) {
        return <a href={`${record.formula}`} target='_blank' rel="noopener noreferrer">
          {record.formula}
        </a>
      }
      return 'No hay enlace'
    }
  },
  {
    text: "Fecha creación",
    key: "fecha",
    align: "center",
    sortable: true,
    className: "text-center",
    cell: record => moment(record.fecha).format('DD MMM YYYY')
  },
  {
    text: "Acciones",
    sortable: false,
    align: 'center',
    className: "text-center",
    width: 150,
    cell: record => botones(record)
  }
]