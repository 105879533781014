import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';

const ConceptoItemResumen = ({ concepto, idModalidad, handleEditarConcepto }) => {
  
  const { id_dt_plan, monto, monto_aux, fecha_limite, nombre_concepto } = concepto
  
  return ( 
    <div className="ConceptoItemResumen">
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Form.Row>
            <Form.Label column md={4}>{nombre_concepto}</Form.Label>
            <Col md={4}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>S/</InputGroup.Text>
                  <Form.Control type='text' value={ monto_aux ? monto_aux : monto} onChange={null} disabled />
                </InputGroup.Prepend>
              </InputGroup>
            </Col>
            {
              idModalidad !== 2 ? <Col md={4}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </InputGroup.Text>
                    <Form.Control
                    type='date'
                    value={fecha_limite}
                    onChange={e => {
                      handleEditarConcepto(id_dt_plan, e.target.value);
                    }}
                 disabled />
                </InputGroup.Prepend>
              </InputGroup>
              </Col> : null
            }
          </Form.Row>
        </Form.Group>
      </Form.Row>
    </div>
  );
}
 
export default ConceptoItemResumen;