import React from 'react';

const Accesos = () => {
    return (
        <>
          <h3>Accesos</h3>
            <ul>
              <li>
                <a href="https://gather.town/app" target="_blank"  rel="noopener noreferrer">Gather Town</a>
              </li>
              <li>
               <a href="https://docs.google.com/document/d/1_nXOXdO8VLHgDKFZAIZOr3TQ38MQ33CSGWITBa-i6T0/edit" target="_blank"  rel="noopener noreferrer">Organigrama</a>
              </li>
              <li>
                  <a href="https://docs.google.com/spreadsheets/d/1ybM9m42o_Qf75MwoLlgKa7IF9LTf-XpDzjPA0CmoReM/edit#gid=0" target="_blank"  rel="noopener noreferrer">Sprints</a>
              </li>
              <li>
                  <a href="https://drive.google.com/drive/u/0/search?q=nuevo%20organigrama" target="_blank"  rel="noopener noreferrer">Reglamento de Trabajo</a>
              </li>
              <li>
                  <a href="https://portal.inngresa.com/login" target="_blank"  rel="noopener noreferrer">Marcador de Asistencia</a>
              </li>
              <li>
                  <a href="https://us02web.zoom.us/j/6859508788?pwd=dXc4SktsdzViMlFRUUU1WERkc1dUUT09" target="_blank"  rel="noopener noreferrer">Oficina Zoom</a>
              </li>
              <li>
                  <a href="https://app.clickup.com/" target="_blank"  rel="noopener noreferrer">ClickUp</a>
              </li>
              <li>
              <a href="https://teams.microsoft.com/" target="_blank"  rel="noopener noreferrer">MS Teams</a>
              </li>
              <li>
                  <a href="https://docs.google.com/document/d/16k4SXDln8koRZDh9607EWZOhOyGq_Sky/edit" target="_blank"  rel="noopener noreferrer">Teletrabajo</a>
              </li>
            </ul>   
        </>
    );
};

export default Accesos;