import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
//Reduxt
import {useDispatch, useSelector } from "react-redux";
import {actualizarConfiguracion, traerConfiguracionCampaign } from "../../../actions/campaignsActions";

 
const ConfiguracionEscenarios = ({id_proyecto=null}) => {
  //State
 
  const dispatch = useDispatch();
  const initialState = {
    mejor_asistentes:'',mejor_inscritos:'',peor_asistentes:'',peor_inscritos:'',id_camp:''
  }

  const[configuracion,setConfiguracion]=useState(initialState)
  const{configuracion_campaign,loading} = useSelector(state=>state.campaigns)
  const{mejor_asistentes,mejor_inscritos,peor_asistentes,peor_inscritos,id_camp} = configuracion
  useEffect(() => {
   dispatch(traerConfiguracionCampaign({proyecto:{
     id_proyecto:id_proyecto
   }}))
  }, []);
  
  const handleChange = (e)=>{
    const valor = e.target.value
    setConfiguracion({
      ...configuracion,
      [e.target.name]:valor
    })
  }

 useEffect(() => {
    if(configuracion_campaign)
    {
      setConfiguracion(configuracion_campaign)
    }
   }, [configuracion_campaign]);



   const handleSubmit = (e)=>{
      e.preventDefault()
      const datos={
        id_camp:id_camp,
        config:configuracion
      }

      dispatch(actualizarConfiguracion(datos))
      console.log(configuracion)

   }

  return (
            <form onSubmit={handleSubmit}>
                  <div className="row">
                  
                        <div className="col-md-6">
                        <h3>Mejor Escenario</h3>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>% Asistencia:</label>
                          <input type="text" className="form-control" name="mejor_asistentes"  onChange={handleChange} value={mejor_asistentes} required/>
                        </Box>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>% Inscritos:</label>
                          <input type="text" className="form-control" name="mejor_inscritos"  onChange={handleChange} value={mejor_inscritos} required/>
                        </Box>
                        </div>
                        <div className="col-md-6">
                        <h3>Peor Escenario</h3>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>% Asistencia:</label>
                          <input type="text" className="form-control" name="peor_asistentes" onChange={handleChange} value={peor_asistentes} required/>
                        </Box>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>% Inscritos:</label>
                          <input type="text" className="form-control" name="peor_inscritos" onChange={handleChange} value={peor_inscritos} required/>
                        </Box>
                        </div>
                        <div className="col-md-12">
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <button className="btn btn-primary"> Guardar </button>
                        </Box>
                        </div>
                  </div>
            </form>
  );
};

export default ConfiguracionEscenarios;
