import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';

const BarMontoVentasMultiAxis = ({ datos }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current && chartRef.current.chartInstance) {
            chartRef.current.chartInstance.update();
        }
    }, [datos]);

    const colors = [
        'rgb(0,128,128)',
        'rgb(0,255,0)',
        'rgb(255,255,0)',
        'rgb(255,0,0)',
        'rgb(255,165,0)',
        'rgb(128,128,0)',
        'rgb(128,0,0)',
        'rgb(128,0,128)',
        'rgb(255,0,255)',
        'rgb(0,0,255)',
        'rgb(0,255,255)',
        'rgb(165,42,42)',
        'rgb(75,0,130)',
        'rgb(255,192,203)'
    ];

    const data = {
        labels: datos.labels,
        datasets: datos.dataSets.map((set, index) => ({
            label: set.label,
            data: set.data,
            backgroundColor: set.backgroundColor || colors[index % colors.length],
            borderColor: set.borderColor || colors[index % colors.length],
            borderWidth: 1,
        })),
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            text: datos.title,
            fontSize: 20
        },
        legend: {
            display: true,
            position: 'top'
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    return (
        <div style={{ height: '400px' }}>
            <Bar ref={chartRef} data={data} options={options} />
        </div>
    );
};

export default BarMontoVentasMultiAxis;
