import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
//Redux
import { useDispatch, useSelector } from 'react-redux';

import { actualizarTrato } from '../../../actions/leadsActions';
const EditarTrato = ({registro}) => {

  const usuario = useSelector(state=> state.usuario.usuario);
  const {tratos,fases} = useSelector(state=> state.leads);
  const dispatch = useDispatch();
  const initialState = {
    id_trato: registro.id_trato,
    id_usuario: usuario ? usuario.id_usuario : null,
    id_tp_nivel_usuario: usuario ? usuario.id_tp_nivel_usuario : null,
    fase:registro.fase,
    importe:registro.importe,
    descripcion:registro.descripcion_trato?registro.descripcion_trato:'',   
  }
  const [datosLead,setdatosLead]=useState(initialState)

  const {fase, importe, descripcion} = datosLead

  const handleChange = (evt) => {
    const value = evt.target.value;
    setdatosLead({
      ...datosLead,
      [evt.target.name]: value
    });
  }


  const editarLead = async (e) =>{
    e.preventDefault();
   await dispatch(actualizarTrato(datosLead,tratos,fases))
  //console.log(datosLead)
  }

  return (
    <div style={{padding:'15px'}}>
      <form onSubmit={(e) => editarLead(e)}>
        <div className="row">
          <div className="col-12">
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Contacto:</label>
            <p>{registro.nombres} {registro.apellidos}</p>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Producto:</label>
            <p>{registro.nombre_proyecto}</p>
          </Box>
          </div>
          <div className="col-12">
          <Box component="fieldset" mb={3} borderColor="transparent">
          <label>Fase:</label>
          {  /*<select name="fase" className='form-control' value={fase} onChange={handleChange} required >
            <option value="">Escoge una opción...</option>
              { fases.length>0 ? fases.map((fase,i)=>{
               return <option key={i} value={fase.id_fase}>{fase.nombre_fase}</option>
              }):null}
            </select>*/}
            <div className='d-flex flex-wrap'>
              { fases.length>0 ? fases.map((fase_,i)=>{
                return <span key={i} className={`badge ${fase_.id_fase===fase ? 'badge-success':'badge-light'}  m-1`} style={{cursor:'pointer'}} onClick={()=>setdatosLead({...datosLead,fase:fase_.id_fase})}>{fase_.nombre_fase}</span>
              }):null
              }
            </div>
          </Box>        
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Importe:</label>
            <input name="importe" value={importe} className='form-control' placeholder='0.00' pattern='[0-9]+([.])?([0-9]+)?' onChange={handleChange} required />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Descripción:</label>
            <textarea name="descripcion" className="form-control" value={descripcion} onChange={handleChange} />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent" >
          <button type="submit" className="btn btn-primary btn-submit mr-2" >
           Guardar Información
          </button>
          </Box>
          </div>
        </div>
      </form>
  </div>
   );
}
 
export default EditarTrato;