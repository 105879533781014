import React, { useState } from "react";
import {Badge} from "react-bootstrap";
import DataTableComponent from "../../../components/plugins/DataTableComponent";
import FormularioLlamarAhora from "../FormularioLlamarAhora";
import { updateStateModalBasic } from "../../../../actions/modalActions";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
const ListaDellamadas = ({llamadas=[],contacto=false}) => {
  const dispatch = useDispatch()
  const formatTiempo = (seconds) =>{
    let divisor_for_minutes = seconds % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let secs = Math.ceil(divisor_for_seconds);

    return `${minutes<10?'0'+minutes:minutes}:${secs<10?'0'+secs:secs}`
  } 
  const handleLlamarAhora = (registro) =>{
    dispatch(updateStateModalBasic(<FormularioLlamarAhora registro={registro} />,'Ejecutar llamada'));
  }
    const [asignarSeleccion, setSeleccion]=useState(null)
    if(!llamadas.length)
    {
        return <p style={{margin:'15px'}}>No hay llamadas realizadas.</p>
    }
    return (
     /*   <ul>
        { 
            llamadas.map((llamada,i)=>(
            <Llamada key={i} llamada={llamada} contacto={contacto} />
            ))
        }
        </ul>*/
        <DataTableComponent
        colums={colums(formatTiempo,handleLlamarAhora)}
        tabla={llamadas}
        keyField="id_llamada"
        setSeleccion={setSeleccion}
        select={false}
      />
    );
};

export default ListaDellamadas;


const colums = (formatTiempo,handleLlamarAhora) => [
    {
      name: "Proposito Llamada",
      selector: "nombre_proposito",
      align: "center",
      className: "nombre_proposito",
      sortable: true,
      compact:true
    },
    {
      name: "Fecha Programada",
      selector: "fecha_hora",
      align: "center",
      sortable: true,
      className: "fecha_hora",
      compact:true,
      wrap:true,
    }
    ,
    {
      name: "Anotaciones",
      selector: "anotaciones",
      align: "center",
      sortable: true,
      className: "anotaciones",
      compact:true,
      wrap:true,
    }
    ,
    {
      name: "Notificado",
      selector: "notificado",
      align: "center",
      sortable: true,
      className: "notificado",
      compact:true,
      wrap:true,
      cell:(record) => <Badge variant={record.notificado!=='1'?'warning':'success'}>{record.notificado!=='1'?'Pendiente':'Completo'}</Badge>
    }
    ,
    {
      name: "Completado",
      selector: "completado",
      align: "center",
      className: "completado",
      sortable: true,
      compact:true,
      wrap:true,
      cell:(record)=> <Badge variant={record.completado!=='1'?'warning':'success'}>{record.completado!=='1'?'Pendiente':'Completo'}</Badge>
    }
    ,
    {
      name: "Duración de llamada",
      selector: "tiempo_llamada",
      align: "center",
      className: "proyecto",
      sortable: true,
      width:"80px",
      cell:(record) => <p>{formatTiempo(record.tiempo_llamada)}</p>
    }
    ,
    {
      name: "Tiempo Conversado",
      selector: "tiempo_conversado",
      align: "center",
      className: "proyecto",
      sortable: true,
      width:"80px",
      cell:(record) => <p>{formatTiempo(record.tiempo_conversado)}</p>
    }
    ,
    {
      name: "Acciones",
      selector: "Acciones",
      align: "center",
      width:"80px",
      className: "Acciones",
      sortable: false,
      cell:(record) =>  
        <p>
        {  record.completado!=="1" ? <button className="btn btn-primary mr-1 btn-xs" onClick={() => handleLlamarAhora(record)} type="button" title="Ejecutar llamada">
          <FontAwesomeIcon icon={faPhone} />
        </button>:null}
        </p>
    }
    
  ];
  