import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faEdit, faFile, faListAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { useDispatch, useSelector } from "react-redux";
import FormularioEmitidos from "./FormularioEmitidos";
import { Badge } from "react-bootstrap";
import { aprobarFirma, eliminarCertificado } from "../../../actions/basesCertActions";
 
const AprobarCertificados = () => {

  //State
  const dispatch=useDispatch()
  const {emitidos, loading} = useSelector(state=>state.bases)
  const {usuario} = useSelector(state=>state.usuario)
  const handleAprobar = (id_cert,producto)=>{
    dispatch(aprobarFirma(id_cert,producto))
  }
  const handleEliminar = (id,producto) =>{
    dispatch(eliminarCertificado(id,usuario.id_usuario,producto))
  }
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Aprobación de diplomas emitidos" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Diplomas emitidos" },
              ]}
            />
          <div className="row mt-2">
            <div className="col-md-6">
              <FormularioEmitidos aprobar={true}/>
            </div>
          </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions">
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Listado de Diplomas
                  </h5>
        
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(handleAprobar,handleEliminar)}
                    tabla={emitidos}
                    keyCol="id_certificado"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AprobarCertificados;

/**====================== TABLA =========== */

const colums = (handleAprobar,handleEliminar) => [
  {
    text: 'ID',
    key: 'id_certificado',
    className: 'id_registro',
    width: 50
  }
  ,
  {
    text: "Fecha Emisión",
    key: "fecha_emision",
    align: "center",
    sortable: true,
    className: "periodo text-center",
  }
  ,
  {
    text: "DNI",
    key: "dni",
    align: "center",
    sortable: true,
    className: "dni",
  }
  ,
  {
    text: "Discente",
    key: "nombre_usuario",
    align: "center",
    sortable: true,
    className: "periodo",
    cell: (record) => <p>{record.nombre_usuario} {record.apellido_usuario}</p>
  }
  ,
  {
    text: "Nota",
    key: "nota",
    align: "center",
    sortable: true,
    className: "periodo",
  }
  ,
  {
    text: "Título",
    key: "titulo_certificado",
    align: "center",
    sortable: true,
    className: "titulo",
    cell: (record) => 
    <div className="text-center" dangerouslySetInnerHTML={{__html:record.titulo_certificado}}>
    </div>
  
  }
  ,
  {
    text:"N° Evento",
    key: "n_evento",
    align: "center",
    className: "n_evento text-center",
    sortable: true
  }
  ,
  {
    text:"N° Libro",
    key: "n_libro",
    align: "center",
    className: "n_libro text-center",
    sortable: true
    
  }
  ,
  {
    text:"Certificado",
    key: "archivo",
    align: "center",
    className: "archivo",
    sortable: true,
    cell: (record) => 
      <div className="d-flex justify-content-center">
      { record.estado!=="1" ? <a className="btn btn-info btn-xs" href={`${process.env.REACT_APP_API_URL}/files_inedi/certificados_emitidos/${record.archivo}`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>:<a className="btn btn-info btn-xs" href={`${process.env.REACT_APP_API_URL}/files_inedi/certificados_firmados/${record.archivo}`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>}
      </div>
    
  }
  ,
  {
    text:"Estado",
    key: "estado",
    align: "center",
    className: "estado",
    sortable: true,
    cell: (record) => 
      <div className="d-flex justify-content-center">
      { record.aprobado!=="1" ? <Badge variant={'danger'} >Por aprobar</Badge> :<Badge variant={'success'} >Aprobado</Badge> }
      </div> 
  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <>
    <button className="btn btn-info btn-xs me-2" onClick={()=>handleAprobar(record.id_certificado,record.producto)} > <FontAwesomeIcon icon={faCheck} /> </button>
    <button className="btn btn-danger btn-xs" onClick={()=>handleEliminar(record.id_certificado,record.producto)} > <FontAwesomeIcon icon={faTrash} /> </button>
  </>
  }
  
  
];
