import { faTimes, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{useEffect, useState} from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { agregarIntegrante, eliminarIntegrante, listarEstudiantesProducto, traerDetalleGrupo } from '../../../actions/gruposAcademicosActions';
import MaterialTableDemo from '../../components/plugins/MaterialTableDemo';
import { avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
const FormularioIntegrantes = ({registro}) => {

    const dispatch=useDispatch()
    const [listaIntengrantes,setLista]=useState([])
    const {estudiantes,loading} = useSelector(state=>state.gruposAcademicos)

    const bd_agregarIntegrante = async (integrante) => {
        const rpta = await dispatch(agregarIntegrante(integrante))
        return rpta
    }

    const bd_eliminarIntegrante = async(integrante) =>{
        const rpta = await dispatch(eliminarIntegrante(integrante))
        return rpta
    }

    const handleAgregarIntegrante = async (integrante)=>{

        const item ={
            dni:integrante.dni,
            grupo:registro.id_grupo,
            nombre_usuario:integrante.nombre_usuario,
            apellido_usuario:integrante.apellido_usuario,
            estudiante:integrante.id_usuario,
        }

        const agregado = await bd_agregarIntegrante(item)
        //console.log(agregado)
        if(agregado.respuesta_consulta)
        {       item.id_detalle=agregado.ultimo_id
                const listaAux = [...listaIntengrantes,item];
                setLista(listaAux)
        }
        else
        {
            avisoError('El/la discente ya forma parte del grupo')
        }
    }

    const handleEliminarIntegrante= async (integrante)=>{

        const item ={
            dni:integrante.dni,
            grupo:registro.id_grupo,
            nombre_usuario:integrante.nombre_usuario,
            apellido_usuario:integrante.apellido_usuario,
            estudiante:integrante.id_usuario,
            id_detalle:integrante.id_detalle
        }

        const eliminado = await bd_eliminarIntegrante(item)
      
        if(eliminado)
        {
            const listaAux = listaIntengrantes.filter(elemento=>elemento.dni!==item.dni)
            setLista(listaAux)
        }


    }

    useEffect(()=>{
    const fetchData= async (grupo)=>{
        const datos = await dispatch(traerDetalleGrupo(grupo.id_grupo))
        if(datos)
        {   
            console.log(datos.integrantes)
            setLista(datos.integrantes)
        }
    }

    if(registro)
    {
      //console.log(registro)
      fetchData(registro)
    } 

    },[])

    useEffect(()=>{
        if(registro)
        dispatch(listarEstudiantesProducto(registro.producto))
    },[])
    return (
        <div className='row'>   
            <div className='col-12'>
            <h3>Integrantes</h3>
                <Table>
                    <thead>
                        <tr>  
                                <th>
                                    DNI
                                </th>
                                <th>
                                    Integrante
                                </th>
                                <th>
                                    Acciones
                                </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaIntengrantes.length?listaIntengrantes.map((integrante,i)=>(
                            <tr key={i}>
                            <td>{integrante.dni}</td>
                            <td>{integrante.nombre_usuario} {integrante.apellido_usuario}</td>
                            <td className='text-center'><button className='btn btn-danger' onClick={()=>handleEliminarIntegrante(integrante)}> <FontAwesomeIcon icon={faTimes} /> </button></td>
                            </tr>
                        )):<tr>
                            <td colSpan={3}>No cuenta con integrantes</td>
                           </tr>}
                    </tbody>
                </Table>
            </div>  
            <div className='col-12 mt-5'>
            <h3>Alumnos Matriculados</h3>
                <MaterialTableDemo
                    colums={colums(handleAgregarIntegrante)}
                    tabla={estudiantes}
                    keyCol="dni"
                  />
            </div>  
        </div>
    );
};

export default FormularioIntegrantes;

const colums = (handleAgregarIntegrante) => [
    {
      text: 'DNI',
      key: 'dni',
      className: 'dni',
      width: 50
    }
    ,
    
    {
      text: "Nombre",
      key: "nombre_usuario",
      align: "center",
      sortable: true,
      className: "nombre_proyecto",
    }
    ,
    {
      text: "Apellido",
      key: "apellido_usuario",
      align: "center",
      className: "apellido_usuario",
      sortable: true
    }
    ,
    {
      text: "Acciones",
      key: "Acciones",
      align: "center",
      className: "Acciones",
      sortable: false,
      cell:(record) =>  <div className='text-center'>
      <Button
        variant='defult'
        size='xs'
        type="button"
        onClick={() => handleAgregarIntegrante(record)}
      >
        <FontAwesomeIcon icon={faUserPlus} />
      </Button>
     
    </div>
    }
    
  ];
  