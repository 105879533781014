import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCalculator, faEdit, faFileImport, faListAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalLarge } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalLargewhithRedux";
import FormularioWebinar from './FormularioWebinar';
import ResultadosFiltro from "./ResultadosFiltro"
import "./Trabajadores.scss";
import Box from '@material-ui/core/Box';
//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { eliminarWebinar, filtrarWebinars, listarWebinars } from "../../../actions/campaignsActions";
import { Button } from "react-bootstrap";
import EditarWebinar from "./EditarWebinar";
import ConfiguracionEscenarios from './ConfiguracionEscenarios';
 
const ListaWebinarsTab = ({id_proyecto=null}) => {
  //State
  const dispatch = useDispatch();
  const [calculadora,setCalculadora]=useState({
    mejor_asistentes:0,
    mejor_inscritos:0,
    peor_asistentes:0,
    peor_inscritos:0,
  })

  const[resultado_m_asistentes,setMejorAsistentes] = useState(0)
  const[resultado_m_inscritos,setMejorInscritos] = useState(0)
  const[resultado_p_asistentes,setPeorAsistentes] = useState(0)
  const[resultado_p_inscritos,setPeorInscritos] = useState(0)

  const[cantidadLeads,setCantidadLeads]=useState(0)

  const {webinars,loading,resumen_webinar,configuracion_campaign} = useSelector(state=>state.campaigns)

  useEffect(() => {
   dispatch(filtrarWebinars({proyecto:{
     id_proyecto:id_proyecto
   }}))
  }, []);

  const handleModalNuevo = () => {
    dispatch(updateStateModalLarge(<FormularioWebinar />, 'Nuevo Webinar'));
  }
  const handleEditar = (registro) => {
    dispatch(updateStateModalLarge(<EditarWebinar registro={registro} />, 'Editar Webinar'));
  }

  const handleConfiguracion = () => {
    dispatch(updateStateModalLarge(<ConfiguracionEscenarios id_proyecto={id_proyecto} /> , 'Configurar Escenario'));
  }
  const handleEliminar = (id) =>{
    dispatch(eliminarWebinar(id,webinars))
  }
  
  const handleChangeLead =(e)=>{
    const valor=e.target.value
    setCantidadLeads(valor)

    if(parseInt(valor)>0)
    {
      const resultado = (parseInt(valor)*parseInt(calculadora.mejor_asistentes)/100)
      setMejorAsistentes(resultado)
    }

    if(parseInt(valor)>0)
    {
      const resultado = (parseInt(valor)*parseInt(calculadora.peor_asistentes)/100)
      setPeorAsistentes(resultado)
    }
    
  }

  const handleChangeCalculadora = (e)=>{
      const porcentaje=e.target.value
      setCalculadora({
        ...calculadora,
        [e.target.name]:porcentaje
      })

      console.log(e.target.name,porcentaje)
      switch (e.target.name) {
        case 'mejor_asistentes':
          if(parseInt(cantidadLeads)>0)
          {
            if(parseInt(porcentaje)>0)
            {
              const resultado = (parseInt(cantidadLeads)*parseInt(porcentaje)/100)
              setMejorAsistentes(resultado)
            }
          }
          break;
        case 'mejor_inscritos':
          if(parseInt(resultado_m_asistentes)>0)
          {
            if(parseInt(porcentaje)>0)
            {
              const resultado = (parseInt(resultado_m_asistentes)*parseInt(porcentaje)/100)
              setMejorInscritos(resultado)
            }
          }
          
          break;
          case 'peor_asistentes':
            if(parseInt(cantidadLeads)>0)
            {
              if(parseInt(porcentaje)>0)
              {
                const resultado = (parseInt(cantidadLeads)*parseInt(porcentaje)/100)
                setPeorAsistentes(resultado)
              }
            }
            
            break;
            case 'peor_inscritos':
              if(parseInt(resultado_p_asistentes)>0)
              {
                if(parseInt(porcentaje)>0)
                {
                  const resultado = (parseInt(resultado_p_asistentes)*parseInt(porcentaje)/100)
                  setPeorInscritos(resultado)
                }
              }
              
            break;

      }

     
  }

  useEffect(()=>{
    if(parseInt(resultado_m_asistentes)>0){
      const resultado = (parseInt(resultado_m_asistentes)*parseInt(calculadora.mejor_inscritos)/100)
      setMejorInscritos(resultado)
    }
    if(parseInt(resultado_p_asistentes)>0){
      const resultado = (parseInt(resultado_p_asistentes)*parseInt(calculadora.peor_inscritos)/100)
      setPeorInscritos(resultado)
    }
  },[resultado_m_asistentes,resultado_p_asistentes])

  return (

      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
         
            <div className="row mt-1">
          
            
              <div className="col-md-6">
                {   resumen_webinar && resumen_webinar.campaign ?  <ResultadosFiltro resultados={resumen_webinar} configuracion={configuracion_campaign} /> : '' }
              </div>
              <div className="col-md-6">
              <div className="box">
              <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faCalculator} /> Calculadora
                  </h5>
                
                </div>
                <div className="row my-4 p-2">
                  <div className="col-md-4 d-flex justify-content-start align-items-center">
                    <h4>N° de Leads</h4>
                  </div>
                  <div className="col-md-4">
                    <input type="text" className="form-control" value={cantidadLeads} onChange={handleChangeLead} />
                  </div>
                </div>
                <div className="row">
        
                    <div className="col-md-6">
                      <h4>Mejor Escenario</h4>
                      <div className="row mt-3">
                        <div className="col-md-6">
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>% Asistencia:</label>
                          <input type="text" className="form-control" value={calculadora.mejor_asistentes} name="mejor_asistentes" onChange={handleChangeCalculadora} />
                        </Box>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>% Inscritos:</label>
                          <input type="text" className="form-control" value={calculadora.mejor_inscritos} name="mejor_inscritos" onChange={handleChangeCalculadora} />
                        </Box>

                        </div>
                        <div className="col-md-6">
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>Cantidad:</label>
                          <input type="text" className="form-control" value={resultado_m_asistentes} name="m_asistencia" readOnly/>
                        </Box>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>Cantidad:</label>
                          <input type="text" className="form-control" value={resultado_m_inscritos} name="m_inscritos" readOnly/>
                        </Box>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                    <h4>Peor Escenario</h4>
                    <div className="row mt-3">
                
                      <div className="col-md-6">
                        <Box component="fieldset" mb={3} borderColor="transparent">
                        <label>% Asistencia:</label>
                        <input type="text" className="form-control" value={calculadora.peor_asistentes} name="peor_asistentes" onChange={handleChangeCalculadora} />
                        </Box>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>% Inscritos:</label>
                          <input type="text" className="form-control" value={calculadora.peor_inscritos} name="peor_inscritos" onChange={handleChangeCalculadora} />
                        </Box>
                      </div>
                      <div className="col-md-6">
                      <Box component="fieldset" mb={3} borderColor="transparent">
                        <label>Cantidad:</label>
                        <input type="text" className="form-control" value={resultado_p_asistentes} name="p_asistencia" readOnly/>
                        </Box>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                          <label>Cantidad:</label>
                          <input type="text" className="form-control" value={resultado_p_inscritos} name="p_inscritos" readOnly/>
                        </Box>
                      </div>
                    </div>
                  
                   
                    </div>
                </div>
              </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end align-items-end">
                <button className="btn btn-primary" onClick={()=>handleModalNuevo()}> Nuevo Webinar </button>
                <button className="btn btn-primary" onClick={()=>handleConfiguracion()}>Configurar Escenario</button>
              </div>
            </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 mt-1 content-actions">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-1">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Webinars
                  </h5>
                
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(handleEliminar,handleEditar)}
                    tabla={webinars}
                    keyCol="id_webinar"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>

  );
};

export default ListaWebinarsTab;

/**====================== TABLA =========== */

const colums = (handleEliminar,handleEditar) => [
  {
    text: 'ID',
    key: 'id_webinar',
    className: 'id_webinar',
    width: 50
  }
  ,
  {
    text: "Fecha Realización",
    key: "fecha_realizacion",
    align: "center",
    sortable: true,
    className: "fecha_realizacion"
  }
  ,
  {
    text: "Nombre Evento",
    key: "nombre_evento",
    align: "center",
    sortable: true,
    className: "nombre_evento",
  },
  {
    text: "Campaña",
    key: "nombre_proyecto",
    align: "center",
    sortable: true,
    className: "nombre_proyecto"
  }
  ,
  {
    text: "Desde",
    key: "desde",
    align: "center",
    className: "desde",
    sortable: true,
  }
  ,
  {
    text: "Hasta",
    key: "hasta",
    align: "center",
    className: "hasta",
    sortable: true
  }
  ,

  {
    text: "Cant. Leads",
    key: "cantidad_leads",
    align: "center",
    className: "cantidad_leads",
    sortable: true
  },
  {
    text: "Total Asistentes",
    key: "total_asistentes",
    align: "center",
    sortable: true,
    className: "total_asistentes"
  }
  ,
  {
    text: "Prom. Asist. Máx.",
    key: "promedio_asistentes_mx",
    align: "center",
    sortable: true,
    className: "promedio_asistentes_mx"
  }
  ,
  {
    text: "Prom. Asist. Mín.",
    key: "promedio_asistentes_mn",
    align: "center",
    sortable: true,
    className: "promedio_asistentes_mn"
  }
  ,
  {
    text: "Inscritos",
    key: "inscritos",
    align: "center",
    sortable: true,
    className: "inscritos"
  }
  ,
  {
    text: "% Asistencia",
    key: "p_asistencia",
    align: "center",
    sortable: true,
    className: "p_asistencia",
    cell: (record)=> record.bandera_asistentes.includes('ROJO') ? <span className="badge badge-danger"> { record.p_asistencia} </span>:<span className="badge badge-success"> { record.p_asistencia} </span>
  }
  ,
  {
    text: "% Inscritos",
    key: "p_inscritos",
    align: "center",
    sortable: true,
    className: "p_inscritos",
    cell: (record)=> record.bandera_inscritos.includes('ROJO') ? <span className="badge badge-danger"> { record.p_inscritos} </span>:<span className="badge badge-success"> { record.p_inscritos} </span>
 
  }
  ,
  {
    text: "Observaciones",
    key: "observaciones",
    align: "center",
    sortable: true,
    className: "observaciones",
    width:'200'
  }
  ,
  {
    text: "Grabación",
    key: "grabacion",
    align: "center",
    sortable: true,
    className: "grabacion",
    cell:(record) => record.grabacion ? <span style={{display:'inline'}}><a href={record.grabacion} className='btn btn-info' aria-label='see' target='_blank'><i className='fas fa-link'></i></a></span> : ''

  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <p className='text-center'>
    <Button
      variant='warning'
      size='xs'
      type="button"
      onClick={() => handleEditar(record)}
    >
      <FontAwesomeIcon icon={faEdit} />
    </Button>
    <Button
      variant='danger'
      size='xs'
      type="button"
      onClick={() => handleEliminar(record.id_webinar)}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  </p>
  }
  
];
