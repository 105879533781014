import React, { useState } from 'react';
import Layout from '../../../app/Layout';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import DataTableComponent from "../../components/plugins/DataTableComponent";
import PieChart from './PieChart';
import { faHashtag,faListAlt } from '@fortawesome/free-solid-svg-icons';
import FormularioFiltroTratosR from './FormularioFiltroTratosR';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ReporteTratos = () => {

   
    const [loading, setLoading] = useState(false)
    const [datosTabla,setDatosTabla] = useState({data:[],grafico:null})
    const {usuario} = useSelector(state=>state.usuario)


    return (
        <Layout>
            <div className="ControlReportes">
                <div className="ControlReportes__header">
                <HeaderContent title='Reporte de Tratos' />
                <Breadcrumb
                    link={[
                    { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                    { url: "", nombre: "Reportes" },
                    { url: "", nombre: "Tratos" },
                    ]}
                />
                </div>
                <div className="ControlReportes__body">
                    <div className='row'>
                        <div className='col-md-6'>
                          <FormularioFiltroTratosR setDatosTabla={setDatosTabla} setLoading={setLoading} usuario={usuario} />  
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-8'>
                            <SpinnerLoading loading={loading}>
                                <div className="card">
                               
                                    <div className="card-body">
                                    <h5
                                        style={{ color: "#434343" }}
                                        >
                                        <FontAwesomeIcon icon={faListAlt} /> Lista de Tratos
                                    </h5>
                                    <DataTableComponent
                                        colums={colums(usuario)}
                                        tabla={datosTabla.data}
                                        keyField="id_lead"
                                    />
                                    </div>
                                </div>
                            </SpinnerLoading>
                        </div> 
                        { datosTabla.grafico ? <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                <PieChart info={datosTabla.grafico}/> 
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-4 d-flex justify-content-center align-items-center'>
                                                <FontAwesomeIcon icon={faHashtag} size='3x' color={'#27314A'} />
                                            </div>
                                            <div className='col-md-8 text-center'>
                                                <h3>{datosTabla.data.length}</h3>
                                                <p>N° de registros</p>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div> : <div className='col-md-4'><div className='card'><div className='card-body'>Sin datos por mostrar</div></div></div>} 
                    </div> 
                </div>

            </div>
        </Layout>
    );
};

export default ReporteTratos;

const colums = (formatTiempo,usuario) => [
    {
      name: "Nombres",
      selector: "nombres",
      align: "center",
      sortable: true,
      className: "nombres",
      compact:true,
      wrap:true,
      width:"100px"
    },
    {
      name: "Apellidos",
      selector: "apellidos",
      align: "center",
      sortable: true,
      className: "apellidos",
      compact:true,
      wrap:true,
      width:"100px"
    }
    ,
    {
      name: "Fase",
      selector: "nombre_fase",
      align: "center",
      className: "nombre_fase",
      sortable: true,
      width:"100px",
      wrap:true
    }

    ,
    {
      name: "Fecha Cierre",
      selector: "fecha_cierre",
      align: "center",
      className: "fecha_cierre",
      sortable: false,
      wrap:true,
    }
    ,
    {
      name: "Importe",
      selector: "importe",
      align: "center",
      className: "importe",
      sortable: true
    }
    ,
    {
      name: "Asesor",
      selector: "nombre_usuario",
      align: "center",
      className: "nombre_asesor",
      sortable: true,
      width:"100px",
      wrap:true,
    }
    
  ];