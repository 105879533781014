import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faListAlt, faTrash, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalLarge } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalLargewhithRedux";
import FormularioGrupoAcademico from './FormularioGrupoAcademico';
import FormularioFiltroGrupos from './FormularioFiltroGrupos'
import "./Trabajadores.scss";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import EditarGrupo from "./EditarGrupo";
import { eliminarGrupoAcademico, listarGruposAcademicos } from "../../../actions/gruposAcademicosActions";
import FormularioIntegrantes from "./FormularioIntegrantes";
 
const Campaigns = () => {
  //State
  const dispatch = useDispatch();

  const {grupos,loading} = useSelector(state=>state.gruposAcademicos)

  useEffect(() => {
   dispatch(listarGruposAcademicos())
  }, []);

  const handleModalNuevo = () => {
    dispatch(updateStateModalLarge(<FormularioGrupoAcademico />, 'Nuevo Grupo'));
  }
  const handleEditar = (registro) => {
    dispatch(updateStateModalLarge(<EditarGrupo registro={registro} />, 'Editar Grupo'));
  }
  const handleAgregarIntegrantes = (registro)=>{
    dispatch(updateStateModalLarge(<FormularioIntegrantes registro={registro} />, 'Editar Grupo'));
  }
  const handleEliminar = (id) =>{
    dispatch(eliminarGrupoAcademico(id))
  }
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Grupos Académicos" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Grupos Académicos" },
              ]}
            />
            <button className="btn btn-primary" onClick={()=>handleModalNuevo()}> Nuevo Grupo </button>
            <div className="row">
               <div className="col-md-6">
                <FormularioFiltroGrupos />
               </div>
            </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 mt-1">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Grupos
                  </h5>
                
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(handleEliminar,handleEditar,handleAgregarIntegrantes)}
                    tabla={grupos}
                    keyCol="id_grupo"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Campaigns;

/**====================== TABLA =========== */

const colums = (handleEliminar,handleEditar,handleAgregarIntegrantes) => [
  {
    text: 'ID',
    key: 'id_grupo',
    className: 'id_grupo',
    width: 50
  }
  ,
  
  {
    text: "Producto",
    key: "nombre_proyecto",
    align: "center",
    sortable: true,
    className: "nombre_proyecto",
  }
  ,
  {
    text: "Creado el",
    key: "fecha_creacion",
    align: "center",
    className: "fecha_creacion",
    sortable: true
  }
  ,
  {
    text: "Nombre Grupo",
    key: "nombre_grupo",
    align: "center",
    className: "nombre_grupo",
    sortable: true
  }
  ,
  {
    text: "Descripción del Grupo",
    key: "descripcion_grupo",
    align: "center",
    className: "descripcion_grupo",
    sortable: true,
  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <>
    <Button
      size='xs'
      type="button"
      onClick={() => handleAgregarIntegrantes(record)}
    >
      <FontAwesomeIcon icon={faUserPlus} />
    </Button>
     <Button
      variant='warning'
      size='xs'
      type="button"
      onClick={() => handleEditar(record)}
    >
      <FontAwesomeIcon icon={faEdit} />
    </Button>

    <Button
      variant='danger'
      size='xs'
      type="button"
      onClick={() => handleEliminar(record.id_grupo)}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
   
  </>
  }
  
];
