import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faListAlt, faEye} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalLarge } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalLargewhithRedux";
import "./Trabajadores.scss";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import {listarEquipoAsesores} from "../../../actions/trabajadoresActions";
import { Badge, Button } from "react-bootstrap";
import EditarAsesor from "./EditarAsesor";
import { Link } from "react-router-dom"; 
const ListaAsesores = () => {
  //State
  const dispatch = useDispatch();
  const {usuario} = useSelector(state=>state.usuario)
  const {asesores,loading} = useSelector(state=>state.trabajadores)

  useEffect(() => {
   dispatch(listarEquipoAsesores())
  }, []);

  const handleEditar = (registro) => {
    dispatch(updateStateModalLarge(<EditarAsesor registro={registro} />, 'Editar Asesor'));
  }
  const handleEliminar = (id) =>{
  //  dispatch(eliminarRecursoCampaign(id,recursos))
  }
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Equipo de Ventas" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Equipo de Ventas" },
              ]}
            />
          <div className="row">
              <div className="col-md-6">
                
              </div>
              <div className="col-md-6 d-flex justify-content-end align-items-end">
                  <Link className="btn btn-primary" to={`/configurar-asignacion`}> Configurar asignación </Link>
              </div>
          </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-5">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Asesores
                  </h5>
                
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(handleEliminar,handleEditar,usuario)}
                    tabla={asesores}
                    keyCol="id_usuario"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListaAsesores;

/**====================== TABLA =========== */

const colums = (handleEliminar,handleEditar,usuario) => [
  {
    text: 'ID',
    key: 'id_usuario',
    className: 'id_usuario',
    width: 50
  }
  ,
  {
    text: "Nombres",
    key: "nombre_usuario",
    align: "center",
    sortable: true,
    className: "nombre_usuario"
  }
  ,
  {
    text: "Apellidos",
    key: "apellido_usuario",
    align: "center",
    sortable: true,
    className: "apellido_usuario",
  },
  {
    text: "Estado",
    key: "id_tp_estado",
    align: "center",
    className: "id_tp_estado",
    sortable: true,
    cell:(record) => record.id_tp_estado!=="1" ? <p className="text-center"> <Badge variant={'danger'}>Inactivo</Badge> </p> : <p className="text-center"> <Badge variant={'success'}>Activo</Badge> </p> 
  }
  ,
  {
    text: "Asignación Auto.",
    key: "asignacion_automatica",
    align: "center",
    className: "asignacion_automatica",
    sortable: true,
    cell:(record) => record.asignacion_automatica!=="1" ?  <p className="text-center"> <Badge variant={'danger'}>Inhabilitado</Badge> </p> : <p className="text-center"> <Badge variant={'success'}>Habilitado</Badge> </p>
  }
  ,
  {
    text: "Descargar leads",
    key: "descarga_leads",
    align: "center",
    className: "asignacion_automatica",
    sortable: true,
    cell:(record) => record.descarga_leads!=="1" ?  <p className="text-center"> <Badge variant={'danger'}>Inhabilitado</Badge> </p> : <p className="text-center"> <Badge variant={'success'}>Habilitado</Badge> </p>
  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) => <p className='text-center'>
    <Button
      variant='warning'
      size='xs'
      type="button"
      onClick={() => handleEditar(record)}
    >
      <FontAwesomeIcon icon={faEdit} />
    </Button>
    <Link className="btn btn-info btn-xs" to={`detalle-asesor/${record.id_usuario}`} >
    <FontAwesomeIcon icon={faEye} />
    </Link>
  </p>
  }
  
];
