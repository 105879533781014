import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt, faSearch} from "@fortawesome/free-solid-svg-icons";
import DataTableComponent from "../../components/plugins/DataTableComponent";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalBasic } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
import FormularioFiltro from './FormularioFiltro'
import FormularioNuevaLlamada from './FormularioNuevaLlamada'
import "./Trabajadores.scss";
import * as moment from 'moment';
//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { enviarCorreoLeadProducto, listarEstados, listarObservaciones, registrarClick} from "../../../actions/leadsActions";
import { Badge } from "react-bootstrap";
import EditarLead from "./EditarLead";
import FormularioAsignacionManual from "./FormularioAsignacionManual";
import FormularioFiltroAsesor from "./FormularioFiltroAsesor";
import { Link } from "react-router-dom";
import FormularioNuevoTrato from "./FormularioNuevoTrato";

const ListaContactos = () => {
  //State
  const dispatch = useDispatch();

  const {contactos,loading} = useSelector(state=>state.leads)
  const {usuario} = useSelector(state=>state.usuario)
  const [busqueda,setBusqueda]=useState('')
  const [asignarSeleccion, setSeleccion]=useState(null)

  const handleChange = e => {
    setBusqueda(e.target.value)
  }
  useEffect(() => {
  // dispatch(listarLeads(usuario))
   dispatch(listarEstados())
   dispatch(listarObservaciones())
  }, []);

  const formatTiempo = (seconds) =>{
    let divisor_for_minutes = seconds % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let secs = Math.ceil(divisor_for_seconds);

    return `${minutes<10?'0'+minutes:minutes}:${secs<10?'0'+secs:secs}`
  } 

  const handleEditarRegistro = (registro) =>{
    dispatch(updateStateModalBasic(<EditarLead registro={registro} contacto={true} />, 'Editar Contacto'));
  }

  const handleCrearLlamada = (registro) =>{
    dispatch(updateStateModalBasic(<FormularioNuevaLlamada registro={registro} registros={contactos} contacto={true} />,'Programar llamada'));
  }

  const handleNuevoTrato = (registro) =>{
    dispatch(updateStateModalBasic(<FormularioNuevoTrato registro={registro} />,'Nuevo trato'));
  }

  const handleRegistrarClick = (registro,tipo)=>{
    const datos = {
      autor:usuario.id_usuario,
      id_lead: registro.id_lead,
      tipo:tipo,
    }

    dispatch(registrarClick(datos));
  }
  const handleAsignarManualmente = () =>{
    dispatch(updateStateModalBasic(<FormularioAsignacionManual leads={asignarSeleccion.selectedRows} resetSeleccion={setSeleccion} />, 'Asignar Leads'));
  }

  const handleEnviarCorreo =  (datos) => {
    dispatch(enviarCorreoLeadProducto(datos))
   }
  
  const leadsBusqueda = contactos ? contactos.filter(
    lead =>
       ( lead.nombres &&  
        lead
        .nombres
        .toLowerCase()
        .includes(busqueda.toLowerCase()) )||
       ( lead
        .apellidos && 
        lead
        .apellidos
        .toLowerCase()
        .includes(busqueda.toLowerCase()) )||
       ( lead
        .correo &&
        lead
        .correo
        .toLowerCase()
        .includes(busqueda.toLowerCase()) )||
       ( lead
        .fuente &&
        lead
        .fuente 
        .toLowerCase()
        .includes(busqueda.toLowerCase())) ||
        (lead
        .telefono &&
        lead
        .telefono 
        .toLowerCase()
        .includes(busqueda.toLowerCase()))  
    

  ) : null

    
   return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            
              <HeaderContent title="Contactos" icon="group" />
              <Breadcrumb
                      link={[
                              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                              { url: "", nombre: "Contactos" },
                            ]}
                    />
                    <div className="row">
                    <div className="col-md-6 d-flex flex-row"> 
           
                  { usuario.id_tp_nivel_usuario!=="11" && asignarSeleccion && asignarSeleccion.selectedCount>0 ?  
                        <> <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Acciones de Selección
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" onClick={()=>handleAsignarManualmente()}> Asignar Manualmente </a>
                            </div>
                          </div></>
                        : '' 
                  }
                  </div>
                  </div>
              <div className="row">
                      {
                      usuario.id_tp_nivel_usuario!=='11' ? <div className="col-md-6">
                      <FormularioFiltro contacto={true} />
                      </div>
                      :
                      <div className="col-md-6">
                      <FormularioFiltroAsesor contacto={true} />
                      </div> 
                      }

              </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-1">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border clearfix">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Contactos
                  </h5>
                  <div className="col-3 d-flex align-items-center pull-right">
                    <input className="form-control" value={busqueda} onChange={handleChange} style={{marginRight:'5px'}} placeholder="Filtrar por Nombres o Apellidos..." />
                    <FontAwesomeIcon icon={faSearch} size="lg"/>
                  </div>
                </div>
                <div className="box-primary">
                  <DataTableComponent
                    colums={colums(handleEditarRegistro,handleRegistrarClick,handleEnviarCorreo,formatTiempo,handleCrearLlamada,handleNuevoTrato)}
                    tabla={leadsBusqueda}
                    keyField="id_lead"
                    setSeleccion={setSeleccion}
                    select={usuario.id_tp_nivel_usuario!=="11"?true:false}
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListaContactos;

/**====================== TABLA =========== */

const colums = (handleEditarRegistro,handleRegistrarClick,handleEnviarCorreo,formatTiempo,handleCrearLlamada,handleNuevoTrato) => [
  {
    name: '',
    selector: 'llamadas',
    className: 'text-center',
    width:"110px",
    cell:(record)=> 
    <ul style={{listStyle:'none',margin:'0', padding:'0'}}>
     { record.llamadas ? record.llamadas.map((llamada,i)=>{
        const a = moment(llamada.fecha_hora).format('YYYY-MM-DD H:mm')
        const b = moment().format('YYYY-MM-DD H:mm')
        const tipo =  new Date(a) - new Date(b) < 0 && llamada.completado ==="0" ? 'danger': llamada.completado ==="0" ? 'warning' : 'success'
        return <li key={i}><Badge variant={tipo} className="p-1"> {moment(llamada.fecha_hora).locale('es').format('MMM  -DD')}  <i className="fas fa-phone"></i> </Badge></li>
      }):null  }
  </ul>
  }
  ,
  {
    name: 'Fuente',
    selector: 'nombre_fuente',
    className: 'fuente',
    width:"100px",
    wrap:true
  }
  ,
  {
    name: "Fecha Registro",
    selector: "fecha_registro_lead",
    align: "center",
    className: "fecha_registro_lead",
    sortable: true,
    width:"150px",
    compact:true
  },
  {
    name: "Nombres",
    selector: "nombres",
    align: "center",
    sortable: true,
    className: "nombres",
    compact:true,
    wrap:true,
    width:"100px"
  },
  {
    name: "Apellidos",
    selector: "apellidos",
    align: "center",
    sortable: true,
    className: "apellidos",
    compact:true,
    wrap:true,
    width:"100px"
  }
  ,
  {
    name: "Correo",
    selector: "correo",
    align: "center",
    className: "correo",
    sortable: true,
    compact:true,
    wrap:true
  }
  ,
  {
    name: "Teléfono",
    selector: "telefono",
    align: "center",
    className: "telefono",
    sortable: true,
    compact:true,
    wrap:true
  }
  ,
  {
    name: "Llamada/WhatsApp",
    selector: "telefono",
    align: "center",
    className: "telefono",
    width: "150px",
    //<a href={`tel:${record.telefono}`} className='btn btn-warning' aria-label='see' rel='noopener noreferrer' onClick={()=> handleRegistrarClick(record,2)}><i className='fas fa-mobile-alt'></i></a>
    cell:(record) => <div className="d-flex justify-content-between"><a href={`tel:${record.telefono}`} className='btn btn-warning' aria-label='see' rel='noopener noreferrer' onClick={()=> handleRegistrarClick(record,2)}><i className='fas fa-mobile-alt'></i></a> <a href={`https://api.whatsapp.com/send?phone=${record.telefono}&name=`} className='btn btn-success' aria-label='see' target='_blank' rel='noopener noreferrer' onClick={()=> handleRegistrarClick(record,1)}><i className='fab fa-whatsapp'></i></a>{/*<a href={`https://app.toky.co/business/dialer#?call=${record.telefono}`} className='btn btn-info' aria-label='see' target='_blank' rel='noopener noreferrer'><i className='fa fa-phone'></i></a>*/}</div>
  }
  ,
  {
    name: "Acciones",
    selector: "Acciones",
    align: "center",
    width:"120px",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <div className="d-flex flex-wrap justify-content-center">
      <button className="btn btn-primary mr-1" style={{width:'42px'}} onClick={() => handleNuevoTrato(record)} type="button" title="Nuevo trato">
      <i className='fas fa-file-alt'></i>
     </button>
     <button className="btn btn-success mr-1" style={{width:'42px'}} onClick={() => handleCrearLlamada(record)} type="button" title="Programar Llamada">
     <i className='fas fa-mobile-alt'></i>
     </button>
    <Link
       className="btn btn-warning" style={{width:'42px'}}
       to={`detalleRegistro/${record.id_lead}`}
      >
     <i className='fas fa-edit'></i>
    </Link>
    <button className="btn btn-info mr-1" style={{width:'42px'}} onClick={() => handleEnviarCorreo(record)} type="button" title="Enviar Correo">
    <i className='fas fa-envelope'></i>
     </button>
  </div>
  }
  ,
  
  {
    name: "Producto",
    selector: "nombre_proyecto",
    align: "center",
    className: "proyecto",
    sortable: true,
    width:"180px",
    wrap:true
  }
  ,
  {
    name: "Estado",
    selector: "nombre_estado",
    align: "center",
    className: "estado",
    sortable: true,
    width:"100px",
    wrap:true
  }

  ,
  {
    name: "Asesor",
    selector: "nombre_usuario",
    align: "center",
    className: "nombre_asesor",
    sortable: true,
    width:"100px",
    wrap:true,
  }
  ,
  {
    name: "Notas",
    selector: "notas",
    align: "center",
    className: "notas",
    sortable: true,
    wrap:true,
  }
  ,
  {
    name: "Historial",
    selector: "historial",
    align: "center",
    className: "historial",
    sortable: true,
    wrap:true
  }
  ,
  {
    name: "Observaciones",
    selector: "observacion_n",
    align: "center",
    className: "notas",
    sortable: true,
    wrap:true,
  }
  ,
  {
    name: "Fecha Llamada",
    selector: "fecha_llamada",
    align: "center",
    className: "notas",
    sortable: true
  }
  ,
  {
    name: "Duración de llamada",
    selector: "tiempo_llamada",
    align: "center",
    className: "proyecto",
    sortable: true,
    width:"80px",
    cell:(record) => <p>{formatTiempo(record.tiempo_llamada)}</p>
  }

  
];
