import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editarPerfilTrabajador } from '../../../actions/trabajadoresActions';

const FormularioDatosPersonales = ({usuario}) => {

    const dispatch=useDispatch();
    const [datosPersonales,setDatosPersonales]=useState({
        id_usuario:usuario.id_usuario,
        id_empleado:usuario.id_empleado,
        nombres_us:usuario.nombre_usuario,
        apellidos_us:usuario.apellido_usuario,
        correo_us:usuario.email?usuario.email:'',
        fecha_nc_us:usuario.fecha_nac,
        dni_us:usuario.dni,
        direccion_us:usuario.direccion?usuario.direccion:'',
        departamento_us:usuario.departamento?usuario.departamento:'',
        provincia_us:usuario.provincia?usuario.provincia:'',
        distrito_us:usuario.distrito?usuario.distrito:'',
        telefono_us:usuario.celular?usuario.celular:'',
        sexo_us:usuario.sexo?usuario.sexo:'',
    })

    const {
        nombres_us,
        apellidos_us,
        correo_us,
        fecha_nc_us,
        dni_us,
        direccion_us,
        departamento_us,
        provincia_us,
        distrito_us,
        telefono_us,
        sexo_us
    } = datosPersonales

    const handleChange = (e)=>{
        setDatosPersonales({
            ...datosPersonales,
            [e.target.name]:e.target.value
        })
    }

    const handleSubmit = (e) =>
    {
        e.preventDefault()
       // console.log(datosPersonales)
        dispatch(editarPerfilTrabajador(datosPersonales))
    }

    return (
        <form onSubmit={handleSubmit}>
       <div className='row' >
            <div className='col-md-6'>
              
                <div className="form-row align-items-center mb-3">
                    <div className="col-md-12">
                        <label htmlFor="nombres_us" className="mb-2">
                        Nombres:
                        </label>
                        <input
                        name="nombres_us"
                        onChange={handleChange}
                        value={nombres_us}
                        type="text"
                        className="form-control"
                        id="nombres_us"
                        required
                        />
                    </div>
                </div>
                <div className="form-row align-items-center mb-3">
                    <div className="col-md-12">
                        <label htmlFor="apellidos_us" className="mb-2">
                        Apellidos:
                        </label>
                        <input
                        name="apellidos_us"
                        value={apellidos_us}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="apellidos_us"
                        required
                        />
                    </div>
                </div>
                <div className="form-row align-items-center mb-3">

                    <div className="col-md-12">
                        <label htmlFor="fecha_nc_us" className="mb-2">
                        Fecha Nac.:
                        </label>
                        <input
                        name="fecha_nc_us"
                        value={fecha_nc_us}
                        onChange={handleChange}
                        type="date"
                        className="form-control"
                        id="fecha_nc_us"
                        required
                        />
                    </div>
                </div>
                <div className="form-row align-items-center mb-3">
                   
                    <div className="col-md-6">
                        <label htmlFor="dni_us" className="mb-2">
                        DNI:
                        </label>
                        <input
                        name="dni_us"
                        value={dni_us}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="dni_us"
                        required
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="dni_us" className="mb-2">
                        Sexo:
                        </label>
                        <select className='form-control' name="sexo_us" value={sexo_us} onChange={handleChange} required>
                            <option value="">Seleccione una opción</option>
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                        </select>
                    </div>
                </div>
                <div className="form-row align-items-center mb-3">
                    <div className="col-md-12">
                        <label htmlFor="correo_us" className="mb-2">
                        Correo:
                        </label>
                        <input
                        name="correo_us"
                        value={correo_us}
                        onChange={handleChange}
                        type="email"
                        className="form-control"
                        id="correo_us"
                        required
                        />
                    </div>
                </div>
         
            </div>
            <div className='col-md-6'>
            <div className="form-row align-items-center mb-3">
                    <div className="col-md-12">
                        <label htmlFor="direccion_us" className="mb-2">
                        Dirección:
                        </label>
                        <input
                        name="direccion_us"
                        value={direccion_us}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="direccion_us"
                        required
                        />
                    </div>
                </div>
                <div className="form-row align-items-center mb-3">
                    <div className="col-md-4">
                        <label htmlFor="departamento_us" className="mb-2">
                        Departamento:
                        </label>
                        <input
                        name="departamento_us"
                        value={departamento_us}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="departamento_us"
                        required
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="provincia_us" className="mb-2">
                        Provincia:
                        </label>
                        <input
                        name="provincia_us"
                        value={provincia_us}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="provincia_us"
                        required
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="distrito_us" className="mb-2">
                        Distrito:
                        </label>
                        <input
                        name="distrito_us"
                        value={distrito_us}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="distrito_us"
                        required
                        />
                    </div>
                </div>
                <div className="form-row align-items-center mb-3">
                    <div className="col-md-12">
                        <label htmlFor="telefono_us" className="mb-2">
                        Teléfono:
                        </label>
                        <input
                        name="telefono_us"
                        value={telefono_us}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="telefono_us"
                        required
                        />
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <div className="form-row align-items-center mt-3">
                    <div className="col-md-12">
                       <button className='btn btn-primary' type='submit'>
                            Guardar
                       </button>
                    </div>
                </div>
            </div>
       </div> 
       </form>
    );
};

export default FormularioDatosPersonales;