import React, { useEffect } from "react";
import { useState } from "react";
import Error from "../../../components/AvisosMensajes/Error";
import Editor from "../../mensajeria/componentesMensajeria/Editor";
//REDUX
import { connect } from "react-redux";
import {
  insertarSesionesManual,
  editarSesion,
} from "../../../../actions/cursosActions";
import { useRef } from "react";
import { avisoCargando, avisoError, avisoCorrecto } from "../../../components/AvisosMensajes/MensajesSwalf";
import { buscarId } from "../../../../helpers/functions";
import { Form } from "react-bootstrap";

const FormInsersionManual = ({
  insertarSesionesManual,
  sesion,
  editarSesion,
  idCurso,
}) => {
  //Ref
  //console.log(sesion)
  const [arrayNombreSesion, setArrayNombreSesion] = useState([]);
  const [idCursoActual, setIdCursoActual] = useState(idCurso);
  const [fechaSesion, setFechaSesion] = useState("");
  const [horasSesion, setHorasSesion] = useState("");
  const [error, setError] = useState();
  const [temas, setTemas] = useState("");
  const [mostrarCalif,setMostrarCalif]=useState(false)
  const [promediable,setPromediable]=useState(false)
  const [esExamen,setExamen]=useState(false)
  const [esTaller,setEsTaller]=useState(false)
  const [esRecursos,setEsRecursos]=useState(false)

  useEffect(()=>{
    
    if(sesion.mostrar_calificacion)
    {
      setMostrarCalif(sesion.mostrar_calificacion==="1" ? true : false)
      setPromediable(sesion.promediable==="1" ? true : false)
      setEsTaller(sesion.taller==="1" ? true : false)
      setEsRecursos(sesion.recursos==="1" ? true : false)
      setExamen(sesion.id_tipo_sesion==="1" ? false : true)
      setArrayNombreSesion([{nombre_sesion:sesion.nombre_sesion}])
      setFechaSesion(sesion.fecha_sesion)
      setHorasSesion(sesion.horas_sesion)
    }
    else
    {
      setMostrarCalif(false)
      setPromediable(false)
      setExamen(false)
      setEsTaller(false)
      setEsRecursos(false)
      setArrayNombreSesion([])
      setFechaSesion("")
      setHorasSesion("")
    }

  },[sesion])
  const insertarNuevoSesionIndividual = async (e) => {
    e.preventDefault();
    const form = buscarId('form-insersion')
    avisoCargando("Guardando...");

    if (Object.keys(sesion).length === 0) {
      if (arrayNombreSesion.length < 1 || fechaSesion === "") {
        setError(true);
        return;
      }
      setError(false);

      const dataNuevoCurso = {
        array_nombre_sesion: [
          {
            nombre_sesion: arrayNombreSesion[0].nombre_sesion,
            fecha_sesion: fechaSesion,
            horas_sesion: horasSesion,
            temas_sesion: temas,
            mostrar_calificacion:mostrarCalif,
            promediable:promediable,
            taller:esTaller,
            recursos:esRecursos,
            id_tipo_sesion:esExamen,
          },
        ],
        id_modulo: idCursoActual,
      };
      console.log(dataNuevoCurso)
      const {mensaje, textMensaje} = await insertarSesionesManual(dataNuevoCurso);
      if (mensaje) {
        avisoCorrecto(textMensaje)
        form.reset()
      }
     else{ avisoError(textMensaje)}
      return;
    }
    
    //Llamando a funcion editar    
    editarSesionModulo(form)
   
  };

  const editarSesionModulo = async (form) => {
    const dataEditar = {
      id_modulo: idCurso,
      nombre_sesion: arrayNombreSesion[0].nombre_sesion,
      horas_sesion: horasSesion,
      fecha_sesion:fechaSesion,
      temas_sesion: temas,
      mostrar_calificacion:mostrarCalif,
      promediable:promediable,
      taller:esTaller,
      recursos:esRecursos,
      id_tipo_sesion:esExamen,
    };

    const {mensaje, textMensaje} = await editarSesion(dataEditar, sesion.id_sesion);

    if (mensaje) {
      avisoCorrecto(textMensaje)
      //form.reset()
      return
    }

    avisoError(textMensaje)

  };

  return (
    <div className="container">
      <form id='form-insersion' onSubmit={(e) => insertarNuevoSesionIndividual(e)}>
        <div className="form-group">
          <label htmlFor="nombre-sesion">Nombre Sesion:</label>
          <input
            type="text"
            id="nombre-sesion"
            className="form-control"
            onChange={(e) => {
              setArrayNombreSesion([{ nombre_sesion: e.target.value }]);
              setError(false);
            }}
            value={arrayNombreSesion[0]?.nombre_sesion ? arrayNombreSesion[0].nombre_sesion : ''}
          // ref={nombreRef}
          />
        </div>
        <div className="form-group">
          <label htmlFor="nombre-sesion">Fecha a realizarse</label>
          <input
            type="date"
            id="fecha-sesion"
            className="form-control"
            onChange={(e) => {
              setFechaSesion(e.target.value);
              setError(false);
            }}
            value={fechaSesion}
           //ref={fechaRef}
          />
        </div>
        <div className="form-group">
          <label htmlFor="horas-sesion">Horas Sesion</label>
          <input
            type="number"
            className="form-control"
            name="horas-sesion"
            id="horas-sesion"
            step="0.5"
            onChange={(e) => setHorasSesion(e.target.value)}
            value={horasSesion}
           // ref={horasRef}
          />
        </div>
        <div className="row">
          <div className="col-md-6" style={{borderRight:'1px dashed #ebe'}}>
            <div className="form-group">
              <label htmlFor="mcal">Mostrar en tabla de Calificación:</label>
              <Form.Check type="checkbox" label="" checked={mostrarCalif} value={mostrarCalif} onChange={() => {setMostrarCalif(!mostrarCalif);}} />
            </div>
            <div className="form-group">
              <label htmlFor="mcal">Sesión Promediable (Evaluación Modular):</label>
              <Form.Check type="checkbox" label="" checked={promediable} value={promediable} onChange={() => {setPromediable(!promediable);}} />
            </div>
            <div className="form-group">
              <label htmlFor="mcal">Sesión (TAF):</label>
              <Form.Check type="checkbox" label="" checked={esExamen} value={esExamen} onChange={() => {setExamen(!esExamen);}} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="mcal">Sesion Q&A/Feedback/Taller:</label>
              <Form.Check type="checkbox" label="" checked={esTaller} value={esTaller} onChange={() => {setEsTaller(!esTaller);}} />
            </div>
            <div className="form-group">
              <label htmlFor="mcal">Sesión de Recursos:</label>
              <Form.Check type="checkbox" label="" checked={esRecursos} value={esRecursos} onChange={() => {setEsRecursos(!esRecursos);}} />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="nombre-sesion">Temas</label>
          <Editor
            setMensaje={setTemas}
            dataDefault={sesion.temas_sesion || ""}
          />
        </div>

        {error ? (
          error ? (
            <Error mensaje={"El campo debe estar Lleno"} />
          ) : null
        ) : null}
        <div className="content-button-sesiones mt-5">
          <button type="submit" className="btn btn-danger mr-1">
            Guardar
          </button>
          <button
            type="reset"
            className="btn btn-dark"
            data-dismiss="modal"
            aria-label=""
          >
            Cerrar
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStatsToProps = (state) => ({});

export default connect(mapStatsToProps, {
  editarSesion,
  insertarSesionesManual,
})(FormInsersionManual);
