import {
  LISTAR_PROFESORES,
  INSERTAR_PROFESOR_SESION,
  LISTAR_PROFESORES_SESION,
  ELIMINAR_PROFESOR,
  LISTAR_PROFESORES_FALTANTES,
  AGREGAR_INDICADOR_DOCENTE_EXITO,
  ELIMINAR_INDICADOR_DOCENTE,
  OBTENER_DOCENTE_EXITO,
  INSERTAR_PROFESOR_CURSO,
  LISTAR_PROFESORES_CURSO,
  INSERTAR_DOCENTE,
  INSERTAR_DOCENTE_EXITO,
  INSERTAR_DOCENTE_ERROR,
  QUITAR_INSERTAR_DOCENTE_EXITO,
  EDITAR_PROFESOR,
  ELIMINAR_DOCENTE_CURSO,
  LISTAR_CURSOS_PROFESORES,
  ELIMINAR_DOCENTE_SESION,
  OBTERNER_TEMAS_AREA_EXITO,
  OBTENER_TEMAS_AREA_EXITO,
  AREA_DOCENTE_ACTIVO,
  ASIGNAR_AREA_DICTADO_DOCENTE,
  ASIGNAR_TEMA_DOCENTE,
  GUARDAR_NUEVO_ORDEN_TEMAS,
  ELIMINAR_TEMA_DOCENTE,
  ELIMINAR_TEMA_DOCENTE_EXITO,
  GUARDAR_CV_DOCENTE,
  ARCHIVO_ADJUNTO_SUBIDO,
  ELIMINAR_ARCHIVO_DOCENTE,
  EDITAR_HONORARIO_DOCENTE,
  EDITAR_USUARIO_DOCENTE,
  EDITAR_PASSWORD_DOCENTE,
  EDITAR_REGISTRO_DOCENTE,
  SUBIR_AVATAR_DOCENTE,
  OBTENER_DESEMPENO_PROYECTO_EXITO,
  OBTENER_RANKING_DOCENTES_EXITO,
  OBTENER_LISTA_PROMEDIOS_DOCENTES_EXITO,
  OBTENER_REPORTE_ERROR
} from "./types";

import Swal from 'sweetalert2'
import authAxios from "../config/authAxios";
import { avisoCargando, avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";
import { updateStateModalBasic } from "./modalActions";

const mostrarError = error => {
    const msg = error.response !== undefined ? error.response.data.msg : error;
    avisoError(msg)
}


export const obtenerDesempenoProyecto = () => async (dispatch) => {
    try {
      const response = await authAxios.get('/admin/desempeno/proyecto');
      console.log("Respuesta de la API (desempeño proyecto):", response.data); // <-- Verifica si los datos llegan aquí
      dispatch({
        type: OBTENER_DESEMPENO_PROYECTO_EXITO,
        payload: response.data,
      });
    } catch (error) {
      console.error("Error al obtener el desempeño por proyecto:", error);
      dispatch({
        type: OBTENER_REPORTE_ERROR,
        payload: error.response?.data?.error || 'Error al obtener el desempeño por proyecto',
      });
    }
  };
  
  
  export const obtenerRankingDocentes = () => async (dispatch) => {
    try {
      const response = await authAxios.get('/admin/desempeno/ranking-docentes');
      dispatch({
        type: OBTENER_RANKING_DOCENTES_EXITO,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: OBTENER_REPORTE_ERROR,
        payload: error.response?.data?.error || 'Error al obtener el ranking de docentes',
      });
    }
  };
  
  export const obtenerListaPromediosDocentes = () => async (dispatch) => {
    try {
      const response = await authAxios.get('/admin/desempeno/lista-docentes');
      dispatch({
        type: OBTENER_LISTA_PROMEDIOS_DOCENTES_EXITO,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: OBTENER_REPORTE_ERROR,
        payload: error.response?.data?.error || 'Error al obtener la lista de promedios de docentes',
      });
    }
  };

export const listarProximasSesionesDocente = id_docente => async dispatch => {
    try {
    const sesiones = await authAxios.get(`/admin/proximasSesionesDoc/${id_docente}`)
    return sesiones.data
  
  } catch (error) {
    console.log(error)
  }
}


export const enviarCorreoDocente = (datos) => {
    return async (dispatch) => {
        try {
           
          
          const respuesta = await authAxios.post(`/admin/envioCorreoDocente`,datos)
            if(!respuesta.error)
            {
                if(respuesta.data.status===202)
                {
                    avisoCorrecto(respuesta.data.mensaje)
                }
                else
                {
                    avisoError(respuesta.data.mensaje)
                }
                dispatch(updateStateModalBasic())
            }
            else
            {
                avisoError("No se pudo enviar el correo.")
            }
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}


export const listarProfesores = () => async dispatch => {
    let profesores = await authAxios.get(`/admin/mostrarDocentes`)

    dispatch({
        payload: profesores.data,
        type: LISTAR_PROFESORES
    })
}

export const listarCursosProfesores = idProfesor => async dispatch => {
    let cursos = await authAxios.get(`/admin/mostrarCursoIdDocente/${idProfesor}`)

    dispatch({
        payload: cursos.data,
        type: LISTAR_CURSOS_PROFESORES
    })
}

export const listarDocente = idDocente => {
    return async (dispatch, getState) => {
        
        if (!getState().profesores.profesores.length) {
            await dispatch( listarProfesores() )
        }

        //Verificar si esta guardado en el state
        const existeDocente = getState().profesores.docente[idDocente];

        if (!existeDocente) {
            //Llamar a la api
            const respuesta = await authAxios(`/admin/docente/mostrarDatosDocente_IdDocente/${idDocente}`);
            const docentesSeleccionados = getState().profesores.docente;
            dispatch({
                type: OBTENER_DOCENTE_EXITO,
                payload: {...docentesSeleccionados, [idDocente]: respuesta.data}
            })
        }

        //Obterner el area activa del docente
        const existeArea = getState().profesores.docente[idDocente].area.id_tp_area_dictado;
        if (existeArea) {
            dispatch( setAreaActiva(existeArea)) 
        }
    }
}

export const nuevoDocente = dataProfesor => {

    return async dispatch => {
        avisoCargando('Guardando')
        dispatch({
            type: INSERTAR_DOCENTE
        })
        try {
            delete dataProfesor['clasificacion'];
            delete dataProfesor['area']
            const respuesta = await authAxios.post(`/admin/docente/registrarDocente`, dataProfesor)
            dispatch({
                payload: respuesta.data,
                type: INSERTAR_DOCENTE_EXITO
            })
            dispatch( listarDocente(respuesta.data.id_docente) )
            avisoCorrecto('Docente ingresado correctamente')
            dispatch({
                type: QUITAR_INSERTAR_DOCENTE_EXITO
            })
        } catch (error) {
            console.log(error)
            dispatch({
                type: INSERTAR_DOCENTE_ERROR
            })
            mostrarError(error)
        }
    }
}

export const editarRegistroDocente = (datos, idDocente) => {
    return async dispatch => {
        avisoCargando('Guardando...')
        try {
            const respuesta = await authAxios.put(`/admin/docente/actualizarDatosDocente/${idDocente}`, datos)

            dispatch({
                type: EDITAR_REGISTRO_DOCENTE,
                payload: {
                    idDocente,
                    nuevosDatos: respuesta.data
                }
            })
            avisoCorrecto('Datos guardado correctamente')
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const subirAvatarDocente = (file, idDocente) => {
    return async dispatch => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
            });
            swalWithBootstrapButtons
            .fire({
                title: '¿Estas seguro?',
                text: "No se podrá recuperar la imagen anterior.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Si, continuar!",
                cancelButtonText: "No, cancelar!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: async function() {
                //consultando para ver el mensaje de aprovación
                try {
                    const datos = new FormData();
                    datos.append('img_usuario', file);            
                    const respuesta = await authAxios.post(`admin/docente/SubirImgUsuario/${idDocente}`, datos)

                    dispatch({
                        type: SUBIR_AVATAR_DOCENTE,
                        payload: {
                            idDocente,
                            nuevoAvatar: respuesta.data.img_usuario
                        }
                    })
                    return true;
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error.response.data.msg
                    })
                }
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
            .then(result => {
                //mensaje que se cambio la contraseña satisfactoriamente
                if (result.value === true) {
                swalWithBootstrapButtons.fire(
                    "Hecho!",
                    "Avatar actualizado correctamente",
                    "success"
                );
                }
            });
    }
}

export const agregarIndicador = (idClasificacion, idDocente) => {
    return async dispatch => {
        avisoCargando('Agregando indicador')
        try {

            const data = {
                id_docente: idDocente,
                id_tp_clasificacion_dc: idClasificacion
            } 

            const respuesta = await authAxios.post('/admin/docente/insertarClasificacionDocente', data)

            dispatch({
                type: AGREGAR_INDICADOR_DOCENTE_EXITO,
                payload: {
                    idDocente,
                    nuevaIndicador: respuesta.data.clasificacion.data,
                    nuevaClasificacion: respuesta.data.clasificacion.dt_clasificacion
                }
            })

            avisoCorrecto('Indicador agregado exitosamente');
        
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const eliminarIndicadorDocente = (idClasificacion, idDocente) => {
    return async dispatch => {
        try {
            dispatch({
                type: ELIMINAR_INDICADOR_DOCENTE,
                payload: {
                    idClasificacion,
                    idDocente
                }
            })
            const respuesta = await authAxios.delete(`/admin/docente/eliminarClasificacionDc/${idClasificacion}`)
        
            // dispatch({
            //     type: ELIMINAR_INDICADOR_DOCENTE_EXITO,
            //     payload: {
            //         idDocente,
            //     }
            // })
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const asignarAreaDictadoDocente = (idDocente, idArea) => {
    return async dispatch => {

        const datos = {
            id_docente: idDocente,
            id_tp_area_dictado: idArea
        }
       // console.log(idDocente)
        try {
            const respuesta = await authAxios.post('/admin/docente/registrarAreaDictadoDocente', datos)
            dispatch({
                type: ASIGNAR_AREA_DICTADO_DOCENTE,
                payload: {
                    idDocente,
                    nuevaArea: respuesta.data
                }
            })
            avisoCorrecto('Área asignado al docente correctamente')
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const setAreaActiva = (idArea) => {
    return (dispatch, getState) => {
        const areasDictado = getState().profesores.area.areas;
        const areaActiva = areasDictado.find(area => area.id === parseInt(idArea));
       
        dispatch({
            type: AREA_DOCENTE_ACTIVO,
            payload: areaActiva
        })
    }
}

export const guardarOrdenTemasDocente = (idDocente, temas) => {
    return async (dispatch, getState) => {
        
        avisoCargando('Guardando...')

        const { id_area_dc } = getState().profesores.docente[idDocente].area;
        
        if (!id_area_dc) {
            avisoError('No hay area asignado');
            return;
        }
        const datos = {
            id_area_dc,
            temas
        }

        try {
            const respuesta = await authAxios.put('/admin/docente/ordenarDtTemasAreaDocente', datos)
            
            dispatch({
                type: GUARDAR_NUEVO_ORDEN_TEMAS,
                payload: {
                    idDocente,
                    nuevoOrden: respuesta.data
                }
            })
            avisoCorrecto('Orden guardado correctamente')
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const eliminarTemaDocente = (idTemaDocente, idDocente) => {
    return async dispatch => {
        try {
            dispatch({
                type: ELIMINAR_TEMA_DOCENTE,
                payload: {
                    idDocente,
                    idTemaDocente
                }
            })

            await authAxios.delete(`/admin/docente/eliminarDtTemasAreaDocente__IdTemaArea/${idTemaDocente}`)
            dispatch({
                type: ELIMINAR_TEMA_DOCENTE_EXITO
            })
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const obtenerTemasArea = idArea => {
    return async (dispatch) => {
        try {
            
            const respuesta = await authAxios.get(`/admin/docente/mostrarTemas_IdTpArea/${idArea}`)
            dispatch({
                type: OBTERNER_TEMAS_AREA_EXITO,
                payload: respuesta.data
            })

        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const obtenerTemas = () => {
    return async (dispatch) => {
        try {
            
            const respuesta = await authAxios.get(`/admin/obtenerTemas`)
            dispatch({
                type: OBTENER_TEMAS_AREA_EXITO,
                payload: respuesta.data
            })

        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const guardarTema = (datosTema) => {
    return async (dispatch) => {
        try {
           
          
          const respuesta = await authAxios.post(`/admin/guardarTema`,datosTema)
            if(!respuesta.error)
            {
                avisoCorrecto('Tema insertado')
            dispatch(obtenerTemas())
            dispatch(updateStateModalBasic())
            }
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}
export const eliminarTema = (datosTema) => {
    return async dispatch => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
            });
            swalWithBootstrapButtons
            .fire({
                title: '¿Estas seguro de eliminar el regitro?',
                text: "No se podra recuperar el registro",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Si, continuar!",
                cancelButtonText: "No, cancelar!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: async function() {
                //consultando para ver el mensaje de aprovación
                try {
                    let respuesta = await authAxios.post(`/admin/eliminarTema`,datosTema);
                    if (!respuesta.data.error) {
                        dispatch(obtenerTemas())   
                    }
                    return true;
                } catch (error) {
                    console.log(error)
                    const msg = error.response !== undefined ? error.response.data.msg : error 
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: msg
                    })
                }
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
            .then((result,dispatch) => {
                //mensaje que se elimino el archivo correctamente
                if (result.value === true) {
                swalWithBootstrapButtons.fire(
                    "Eliminado!",
                    "Se elimino el registro correctamente",
                    "success"
                );
             
                }
            });
           
    }
}

export const asignarTemaAreaDocente = (idTema, idDocente) => {
    return async (dispatch, getState) => {
        
        const { id_area_dc } = getState().profesores.docente[idDocente].area;

        const datos = {
            id_area_dc,
            "id_tema_area_dc": idTema
        }

        try {
            const respuesta = await authAxios.post('/admin/docente/insertarDtTemasAreaDocente__IdTemaIdArea', datos)
        
            dispatch({
                type: ASIGNAR_TEMA_DOCENTE,
                payload: {
                    idDocente,
                    nuevoTema: respuesta.data
                }
            })
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const registrarCvDocente = (cv, idDocente) => {
    return async dispatch => {
        avisoCargando('Guardando...')

        const datos = {
            descripcion: cv
        }

        try {
            const respuesta = await authAxios.put(`/admin/docente/actualizarResumenCV_IdDocente/${idDocente}`, datos)

            dispatch({
                type: GUARDAR_CV_DOCENTE,
                payload: {
                    idDocente,
                    nuevoCv: respuesta.data.descripcion
                }
            })
            avisoCorrecto('Guardado exitosamente')

        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const insertarArchivosAdjuntos = (datos, idDocente, idTipo) => {
    return async dispatch => {
        avisoCargando('Subiendo archivo')
        const file = new FormData;
        file.append('file', datos);
        file.append('id_docente', idDocente);
        file.append('id_tp_documento_usuario', idTipo)

        try {
            const respuesta = await authAxios.post('/admin/docente/registrarDtDocumentoDocente', file)

            const nombreDocumento = getTipoArchivo(idTipo)

            dispatch({
                type: ARCHIVO_ADJUNTO_SUBIDO,
                payload: {
                    idDocente,
                    nombreDocumento,
                    nuevoArchivo: respuesta.data
                }
            })
            avisoCorrecto('Archivo subido correctamente')
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

const getTipoArchivo = idTipo => {
    switch (idTipo) {
        case 1:
            return 'Curriculum_vitae';
        case 3:
            return 'Titulo';
        case 4:
            return 'Experiencia';
        case 5:
            return 'Publicaciones';
        default:
            break;
    }
}

export const eliminarArchivosAdjuntos = (idDocumento, idTipo, idDocente) => {
    return async dispatch => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
            });
            swalWithBootstrapButtons
            .fire({
                title: '¿Estas seguro de eliminar este archivo?',
                text: "No se podra recuperar el archivo",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Si, continuar!",
                cancelButtonText: "No, cancelar!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: async function() {
                //consultando para ver el mensaje de aprovación
                try {
                    await authAxios.delete(`/admin/docente/eliminarDocumentoUser__IdDocUser/${idDocumento}`);
                    const nombreDocumento = getTipoArchivo(idTipo)
                    
                    dispatch({
                        type: ELIMINAR_ARCHIVO_DOCENTE,
                        payload: {
                            idDocente,
                            idDocumento,
                            nombreDocumento
                        }
                    })
                    return true;
                } catch (error) {
                    console.log(error)
                    const msg = error.response !== undefined ? error.response.data.msg : error 
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: msg
                    })
                }
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
            .then(result => {
                //mensaje que se elimino el archivo correctamente
                if (result.value === true) {
                swalWithBootstrapButtons.fire(
                    "Eliminado!",
                    "Se elimino el archivo correctamente",
                    "success"
                );
                }
            });
    }
}



export const guardarHonorariosDocente = (datos, idDocente) => {
    return async dispatch => {
        avisoCargando('Guardando...');
        try {
            await authAxios.put(`/admin/docente/registrarHonorariosDocente/${idDocente}`, datos)
            dispatch({
                type: EDITAR_HONORARIO_DOCENTE,
                payload: {
                    datos,
                    idDocente
                }
            })
            avisoCorrecto('Guardado correctamente')
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const guardarEditarUsuarioDocente = (nombreUsuario, idDocente) => {
    return async dispatch => {
        avisoCargando('Guardando...')
        try {
            const datos = {
                usuario: nombreUsuario
            }
            await authAxios.put(`/admin/docente/actualizarUserDocente/${idDocente}`, datos)
            dispatch({
                type: EDITAR_USUARIO_DOCENTE,
                payload: {
                    idDocente,
                    nombreUsuario
                }
            })
            avisoCorrecto('Se cambio el nombre de usuario correctamente')
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const guardarEditarPasswordDocente = (nuevoPassword, idDocente) => {
    return async dispatch => {
        avisoCargando('Guardando')
        const datos = {
            n_password: nuevoPassword,
            m_password: nuevoPassword
        }
        try {
            const respuesta = await authAxios.put(`/admin/docente/actualizarPasswordDocente/${idDocente}`, datos)
            dispatch({
                type: EDITAR_PASSWORD_DOCENTE,
                payload: {
                    idDocente,
                    nuevoHash: respuesta.data.password
                }
            })
            avisoCorrecto('Contraseña editado correctamente')
        } catch (error) {
            console.log(error)
            mostrarError(error)
        }
    }
}

export const listarProfesoresCursos = idCurso => async dispatch => {
    let profesores = await authAxios.get(`/admin/mostrarDocentesIdCurso/${idCurso}`)

    dispatch({
        payload: profesores.data,
        type: LISTAR_PROFESORES_CURSO
    })
}


export const insertarDocenteCurso = dataInstertar => async dispatch => {
    let respuesta = await authAxios.post(`/admin/insertarDocenteCurso`, dataInstertar)
   // console.log(respuesta)
    if (!(respuesta.data.error)) {
        dispatch({
            payload: respuesta.data,
            type: INSERTAR_PROFESOR_CURSO
        })
        return {mensaje: true, textMensaje: 'Insertado Correctamente'}
    }

    return {mensaje: false, textMensaje: respuesta.data.error}
}

export const listarProfesoresFaltantes = idCurso => async dispatch => {
    let profesores = await authAxios.get(`/admin/filtroDocenteCurso/${idCurso}`)

    dispatch({
        payload: profesores.data,
        type: LISTAR_PROFESORES_FALTANTES
    })
}


export const listarProfesoresSesiones = idSesion => async dispatch => {
    
    let profesores = await authAxios.get(`/admin/mostrarDocenteIdSesion/${idSesion}`)
    if(!profesores.data.error){
        dispatch({
            payload: profesores.data,
            type: LISTAR_PROFESORES_SESION
        })
        return
    }
    dispatch({
        payload: {},
        type: LISTAR_PROFESORES_SESION
    })
}

export const eliminarProfesor = idUsuario => async dispatch => {

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
        });
        swalWithBootstrapButtons
        .fire({
            title: '¿Estas seguro?',
            text: "Se eliminaran toda información con relacion con el docente",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, continuar!",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: async function() {
            //consultando para ver el mensaje de aprovación
            try {
                const response = await authAxios.delete(`/admin/deshabilitarUsuario/${idUsuario}`)
                //console.log(response);
                dispatch({
                    payload: idUsuario,
                    type: ELIMINAR_PROFESOR
                })
                return response.data.status;
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.response.data.msg
                })
            }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
        .then(result => {
            //mensaje que se cambio la contraseña satisfactoriamente
            if (result.value === true) {
            swalWithBootstrapButtons.fire(
                "Eliminado!",
                "Docente se elimino correctamente",
                "success"
            );
            }
        });
}

export const insertarDocenteSesion = dataInsertar => async dispatch => {
    try {
        let respuesta = await authAxios.post(`/admin/insertarDocenteSesion`, dataInsertar);
        
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: INSERTAR_PROFESOR_SESION
            })

            return {mensaje: true, textMensaje: 'Docente asignado'}
        }

        return {mensaje: false, textMensaje: respuesta.data.error}
        
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
    
    
}

export const eliminarDocenteCurso = (idDocenteCurso, idCurso) => async dispatch => {
    try {
        let respuesta = await authAxios.delete(`/admin/eliminarDocenteCurso/${idDocenteCurso}/${idCurso}`)
    
        if (!respuesta.data.erro) {
            dispatch({
                payload: respuesta.data,
                type: ELIMINAR_DOCENTE_CURSO
            })
            return{mensaje: true}
        }
        return{mensaje: false}
    } catch (error) {
        console.log(error)
        return {mensaje: false}
    }
    
    
}

export const editarProfesor = (idProfesor, data) => async dispatch => {
    try {
        let respuesta = await authAxios.put(`/admin/editarDocente/${idProfesor}`, data)
        
        if (!(respuesta.data.error)) {
            dispatch({
                payload: respuesta.data,
                type: EDITAR_PROFESOR
            })

            return {mensaje: true, textMensaje: 'Editado correctamente'}
        }

        return {mensaje: false, textMensaje: respuesta.data.error}
    } catch (error) {
        console.log(error)
        return{mensaje: false, textMensaje: error}
    }

}

export const eliminarProfesorSesion = (idDocenteSesion, idSesion) => async dispatch => {
    try {
        let respuesta = await authAxios.delete(`/admin/eliminarDocenteSesion/${idDocenteSesion}/${idSesion}`)
    
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: ELIMINAR_DOCENTE_SESION
            })

            return {mensaje:true, textMensaje: 'Docente eliminado de la sesión'}
        }

        return {mensaje: false, textMensaje: respuesta.data.error}
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
}
