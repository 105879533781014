import React, { useEffect, useState } from 'react';
import './InicioTutor.scss'
import Contadores from '../../components/plugins/Contadores';
import HeaderContent from '../General/HeaderContent';
import ProximasSesiones from '../../components/ProximasSesiones/ProximasSesiones';
import { Card } from 'react-bootstrap';
import { listarProximasSesionesDocente } from '../../../actions/profesoresActions';
import { useSelector,useDispatch } from 'react-redux';

const InicioDocente = () => {

  const [resumenes, setResumenes] = useState(null)
  const usuario = useSelector(state=>state.usuario.usuario)
  const dispatch = useDispatch()
    useEffect(()=>{
    const fetchData = async () =>{
        
       const datos = await dispatch(listarProximasSesionesDocente(usuario.id_empleado))
       setResumenes(datos)
      }
      fetchData()
    },[])


  return ( 
    <div className="inicio-tutor">
      <HeaderContent title='Inicio'/>
      <div className="inicio-tutor__content box">
        <div className="vision-mision row">
          <div className="mision m-4 col-md-6">
            <p className="titulo">Bienvenido a la interfaz del docente</p>
            <p className="texto">
             Desde este espacio podrá responder las dudas o consultas comentadas en las sesiones que ha desarrollado.
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-4">
            <div className="col-12 col-md-5 mb-4">
              <Card className="p-4">
                  <h2>Próximas Sesiones</h2>
                  <div>
                    <ProximasSesiones sesiones={resumenes? resumenes.sesiones:null}/>
                  </div>

              </Card>
            </div>
      </div>
    </div>
   );
}
 
export default InicioDocente;