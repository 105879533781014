import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cambiarEstadoDocente, cambiarEstadoPubli, obtenerDesempenoDocente, guardarDesempenoDocente } from '../../../../../actions/controlCapacitacionesActions';
import { updateStateModalBasic } from '../../../../../actions/modalActions';
import FormularioDatosCorreo from '../../../../pages/Docentes/FormularioDatosCorreo';
import { avisoError } from '../../../AvisosMensajes/MensajesSwalf';

const TablaItemPonente = ({ docente = {} }) => {
  const { idCapacitacion } = useParams();
  const dispatch = useDispatch();
  const { usuario } = useSelector(state => state.usuario);

  // Obtener el desempeño del docente
  const desempeno = useSelector(state =>
    state.control_capacitacion.desempenoDocentes?.[docente.id_filtro_docente] || {}
  );

  // Estados locales para los valores de "puntualidad" y "claridad"
  const [puntualidad, setPuntualidad] = useState(desempeno.puntualidad || '');
  const [claridad, setClaridad] = useState(desempeno.claridad || '');
  const [editable, setEditable] = useState(!desempeno.puntualidad && !desempeno.claridad); // Determina si los campos son editables
  const [botonTexto, setBotonTexto] = useState(editable ? 'Guardar' : 'Actualizar'); // Texto del botón
  const [clasificacion, setClasificacion] = useState(docente.id_estado_capacitacion); // Monitorea la clasificación

  useEffect(() => {
    if (docente.id_filtro_docente) {
      dispatch(obtenerDesempenoDocente(docente.id_filtro_docente));
    }
  }, [docente.id_filtro_docente, dispatch]);

  // Actualizar los valores locales cuando cambie el desempeño en el estado global
  useEffect(() => {
    setPuntualidad(desempeno.puntualidad || '');
    setClaridad(desempeno.claridad || '');
    setEditable(!desempeno.puntualidad && !desempeno.claridad);
    setBotonTexto(!desempeno.puntualidad && !desempeno.claridad ? 'Guardar' : 'Actualizar');
  }, [desempeno]);

  const handleGuardar = () => {
    if (!puntualidad || !claridad) {
      avisoError('Debes seleccionar opciones para Puntualidad y Claridad.');
      return;
    }

    if (editable) {
      dispatch(guardarDesempenoDocente(docente.id_filtro_docente, puntualidad, claridad));
      setEditable(false); // Deshabilitar campos después de guardar
      setBotonTexto('Actualizar');
    } else {
      setEditable(true); // Habilitar campos para edición al hacer clic en "Actualizar"
      setBotonTexto('Guardar');
    }
  };

  // Deshabilitar el botón si la clasificación no es "Invitado", "Confirmado", o "Contratado"
  const isBotonDisabled = !['2', '3', '4'].includes(clasificacion);

  return (
    <tr>
      <td>{docente.nombre_usuario} {docente.apellido_usuario}</td>
      <td className='align-item-center'>
        <div className='d-flex justify-content-between align-item-center'>
          <i
            className={`fa ${docente.publicitado === "0" ? 'fa-flag-o' : 'fa-flag'}`}
            style={{ color: docente.publicitado !== "0" ? 'red' : 'gray', cursor: 'pointer' }}
            onClick={() => dispatch(cambiarEstadoPubli(docente.id_filtro_docente, docente.publicitado === "1" ? "0" : "1", idCapacitacion))}
          ></i>
          <i
            className='fa fa-envelope'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (usuario.id_tp_nivel_usuario === "4" || usuario.id_tp_nivel_usuario === "1") {
                dispatch(updateStateModalBasic(<FormularioDatosCorreo docente={docente} ctrl_cc={true} />, 'Nuevo Correo'));
              } else {
                avisoError("El usuario no cuenta con permisos");
              }
            }}
          ></i>
        </div>
      </td>
      <td>
        <select
          value={clasificacion}
          onChange={e => {
            setClasificacion(e.target.value);
            dispatch(cambiarEstadoDocente(docente.id_filtro_docente, e.target.value, idCapacitacion));
          }}
          className='form-control'
        >
          <option value="1">Ninguno</option>
          <option value="2">Invitado</option>
          <option value="3">Confirmado</option>
          <option value="4">Contratado</option>
        </select>
      </td>
      {/* Columnas para Puntualidad y Claridad */}
      <td>
        <select
          value={puntualidad}
          onChange={e => setPuntualidad(e.target.value)}
          className='form-control'
          disabled={!editable} // Deshabilitar si no es editable
        >
          <option value="">-</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </td>
      <td>
        <select
          value={claridad}
          onChange={e => setClaridad(e.target.value)}
          className='form-control'
          disabled={!editable} // Deshabilitar si no es editable
        >
          <option value="">-</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </td>
      <td>
        <button
          className='btn btn-primary'
          onClick={handleGuardar}
          disabled={isBotonDisabled} // Deshabilitar el botón según la clasificación
        >
          {botonTexto}
        </button>
      </td>
    </tr>
  );
};

export default TablaItemPonente;
