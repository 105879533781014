import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faListAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";

import "./ReporteGeneralCampaign.scss";

//Reduxt
import { Button } from "react-bootstrap";
import FormularioFiltro from "./FormularioFiltro";
import ResultadosFiltro from "./ResultadosFiltro";

const ReporteGeneralCampaign = () => {
  //State

  const [totales, setTotales] = useState(null)
  const [loading, setLoading] = useState(false)


  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Reporte General por Campaña" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Reporte General por Campaña" },
              ]}
            />
            <div className="row mt-1">
              <div className="col-md-6">
                <FormularioFiltro setTotales={setTotales} setLoading={setLoading} />
              </div>

            </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions">
            <SpinnerLoading loading={loading}>
              <div className="box mt-1">
                <div className="box-header with-border">
                  <h5
                    style={{ color: "#434343" }}
                  >
                    <FontAwesomeIcon icon={faListAlt} /> Resultados de Campaña
                  </h5>

                </div>
                <div className="box-primary">
                  {
                    totales ? <ResultadosFiltro resultados={totales} /> : <div style={{ padding: '15px' }}><p>No hay información</p></div>
                  }
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReporteGeneralCampaign;

/**====================== TABLA =========== */

const colums = (handleEliminar, handleEditar) => [
  {
    text: 'ID',
    key: 'id_anuncio',
    className: 'id_anuncio',
    width: 50
  }
  ,
  {
    text: "Fecha Registro",
    key: "fecha_registro",
    align: "center",
    sortable: true,
    className: "fecha_registro"
  }
  ,
  {
    text: "Nombre Anuncio",
    key: "nombre_anuncio",
    align: "center",
    sortable: true,
    className: "nombre_anuncio",
  },
  {
    text: "Campaña",
    key: "nombre_proyecto",
    align: "center",
    sortable: true,
    className: "nombre_proyecto"
  }
  ,
  {
    text: "Tipo",
    key: "tipo_anuncio",
    align: "center",
    className: "nombre_tipo_r",
    sortable: true,
    cell: (record) => record.tipo_anuncio !== "2" ? 'Google' : 'Facebook'
  }
  ,
  {
    text: "Fecha Inicio",
    key: "fecha_inicio",
    align: "center",
    className: "fecha_inicio",
    sortable: true
  }
  ,

  {
    text: "Fecha Fin",
    key: "fecha_fin",
    align: "center",
    className: "fecha_fin",
    sortable: true
  },
  {
    text: "Inversión",
    key: "inversion",
    align: "center",
    sortable: true,
    className: "inversion"
  }
  ,
  {
    text: "Cant. Leads",
    key: "cantidad_leads",
    align: "center",
    sortable: true,
    className: "cantidad_leads"
  }
  ,
  {
    text: "Costo x Leads",
    key: "costo_lead",
    align: "center",
    sortable: true,
    className: "costo_lead"
  }
  ,
  {
    text: "Objetivo",
    key: "objetivo",
    align: "center",
    sortable: true,
    className: "objetivo"
  }
  ,
  {
    text: "Público",
    key: "publico",
    align: "center",
    sortable: true,
    className: "publico"
  }
  ,
  {
    text: "Observaciones",
    key: "observaciones",
    align: "center",
    sortable: true,
    className: "observaciones"
  }
  ,
  {
    text: "Página",
    key: "pagina",
    align: "center",
    sortable: true,
    className: "pagina"
  }
  ,
  {
    text: "Estado",
    key: "estado",
    align: "center",
    className: "estado",
    sortable: true,
    cell: (record) => record.estado !== "1" ? 'Inactivo' : 'Activo'
  }
  ,
  {
    text: "Fuente",
    key: "fuente",
    align: "center",
    className: "fuente",
    sortable: true,
    cell: (record) => {
      switch (record.fuente) {
        case "1": return "Facebook ADS Form";
        case "2": return "Facebook ADS Google Form";
        case "3": return "Google ADS";
        case "4": return "Webinar";
        case "5": return "Landing Page";
        case "6": return "LinkedIn";
        case "7": return "Cartera";
        case "8": return "LinkedIn ADS";
        case "9": return "WhatsApp";
        case "10": return "TikTok ADS";
        case "11": return "Aliados";
        case "12": return "Webinar automático";
        case "13": return "Registro en Plataforma";
        case "14": return "Registro de Marketing";
        default: return "Desconocido";
      }
    }
  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell: (record) => <p className='text-center'>
      <Button
        variant='warning'
        size='xs'
        type="button"
        onClick={() => handleEditar(record)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      <Button
        variant='danger'
        size='xs'
        type="button"
        onClick={() => handleEliminar(record.id_anuncio)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </p>
  }

];
