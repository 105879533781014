import React, {useState, useEffect} from 'react';
//


//Redux
import { useDispatch, useSelector } from 'react-redux';
import { cambiarEstadoDocente } from '../../../actions/controlCapacitacionesActions';
import { enviarCorreoDocente } from '../../../actions/profesoresActions';

import useSelectProyecto from "../../../hooks/useSelectProyectos";
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const FormularioDatosCorreo = ({docente,ctrl_cc=false}) => {
const [proyecto, SelectProyecto] = useSelectProyecto(null)
const [enlaceProgramacion, setEnlaceProgramacion] = useState('');
const [enlaceAndro, setEnlaceAndro] = useState('');
const {usuario} = useSelector(state=>state.usuario)
const dispatch = useDispatch();

const handleChangeEP = (e)=>{
  setEnlaceProgramacion(e.target.value)
}

const handleChangeA = (e)=>{
  setEnlaceAndro(e.target.value)
}

const handleSubmit = (e) =>
{
    e.preventDefault();

    const datos ={
      docente:docente,
      enlace_p:enlaceProgramacion,
      enlace_a:enlaceAndro,
      proyecto:proyecto,
      usuario:usuario,
    }
   // console.log(datos)
    dispatch(enviarCorreoDocente(datos))
    if(ctrl_cc)
    {
      dispatch( cambiarEstadoDocente(docente.id_filtro_docente,2,proyecto.id_proyecto) )
    }
}

 

 return (
 
  <form id="formNuevo2" style={{padding:'15px'}} onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Producto:
                      </label>
                    </div>
                    <div className="col-md-7">
                       <SelectProyecto/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Enlace de programación del Evento:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                         type="text"  className='form-control' value={enlaceProgramacion} onChange={handleChangeEP}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Enlace Metodología ANDRO:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                         type="text" className='form-control' value={enlaceAndro} onChange={handleChangeA}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>          
                  <div className="form-group">
                   
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-upload"></i> Enviar Correo
                  </button>
                </form>
  
    );
};

export default FormularioDatosCorreo;
const initialValues = {
    TITULO:'',
    MENSAJE:'',
    ENLACE:''
  }