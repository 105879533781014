import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarEstados } from '../actions/leadsActions';

const useSelectFases = (initialSelect = null) => {

  const dispatch = useDispatch(); 
  const {fases} = useSelector(state => state.leads)

  const [estadoSeleccionado, setEstadoSeleccionado] = useState(null); 

  const handleInitialAction = () => {
    if(initialSelect) {
      const estadoSelect = fases.find(fase => fase.id_fase === parseInt(initialSelect)); 
      if(estadoSelect) {
        setEstadoSeleccionado(estadoSelect);
      }
    }
  }

  useEffect(() => {
    if(!fases.length) {
      dispatch(listarEstados())
    }
  }, [])

  useEffect(() => {
    handleInitialAction();
  }, [fases])

  const SimpleSelect = props => {
    return (
      <Select
        isDisabled={false}
        isClearable={true}
        isRtl={false}
        isLoading={!fases.length}
        loadingMessage={() => 'Cargando...'}
        isSearchable={true}
        onChange={fase => setEstadoSeleccionado(fase)}
        value={estadoSeleccionado}
        getOptionValue={fases => fases.id_fase}
        getOptionLabel={fases => fases.nombre_fase}
        options={fases}
        placeholder='--Seleccione--'
        noOptionsMessage={() => 'No hay resultados'}
        {...props}
      />
    )
  }

  return [estadoSeleccionado, SimpleSelect, setEstadoSeleccionado]

}
 
export default useSelectFases;