import React from 'react';
import Layout from '../../../app/Layout';
import HeaderContent from '../General/HeaderContent';
import Breadcrumb from '../General/Breadcrumb';
import { Tab, Tabs } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faVideo, faFilter, faFileSignature } from '@fortawesome/free-solid-svg-icons';

import { useParams } from 'react-router-dom';

//Redux
import ListaAnunciosTab from '../AnunciosCampaña/ListaAnunciosTab';
import ListaRecursosTab from '../RecursosCampaña/ListaRecursosTab';
import ListaWebinarsTab from '../Webinars/ListaWebinarsTab';
import ReporteGeneralCampaignTab from '../ReporteGeneralCampaña/ReporteGeneralCampaignTab';
const ExaminarCampaign = () => {


  const { idProyecto } = useParams();

  const mostrarContainerTabs = () => {
    if (idProyecto) return <ContenedorTabs id_proyecto = {idProyecto} />
    
  }

  return ( 
    <Layout>
      <div className="ExaminarProyecto">
        <div className="ExaminarProyecto__header">
          <HeaderContent title='Examinar Campaña' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/campaigns", nombre: "Campañas" },
              { url: "", nombre: "Examinar Campaña" },
            ]}
          />
        </div>
        <div className="ExaminarProyecto__body">
          {mostrarContainerTabs()}
        </div>
      </div>
    </Layout>
  );
}
 
export default ExaminarCampaign;


const ContenedorTabs = ({id_proyecto}) => {

  return (
    <>
      <Tabs
        id="controlador-seccion-proyecto"
        defaultActiveKey={'panel-control'}
      >
        <Tab
          eventKey="panel-control"
          title={
            <div className='text-center'>
              <FontAwesomeIcon icon={faFileSignature} />
              <p className='mt-2'>Recursos</p>
            </div>
          }
        >
         <ListaRecursosTab id_proyecto = {id_proyecto} />
        </Tab>
        <Tab
          eventKey="academico"
          title={
            <div className='text-center'>
              <FontAwesomeIcon icon={faVideo} />
              <p className='mt-2'>Webinars</p>
            </div>
          }
        >
          <ListaWebinarsTab id_proyecto={id_proyecto} />
        </Tab>
        <Tab
          eventKey="horario"
          title={
            <div className='text-center'>
              <FontAwesomeIcon icon={faCalendarAlt} />
              <p className='mt-2'>Anuncios</p>
            </div>
          }
        >
        <ListaAnunciosTab id_proyecto={id_proyecto} />
        </Tab>
        <Tab
          eventKey="finanzas"
          title={
            <div className='text-center'>
              <FontAwesomeIcon icon={faFilter} />
              <p className='mt-2'>Funnel</p>
            </div>
          }
        >
        <ReporteGeneralCampaignTab id_proyecto={id_proyecto} />
        </Tab>
      </Tabs>
    </>
  )
}
