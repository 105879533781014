import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { editarGrupo } from '../../../actions/gruposAcademicosActions';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const EditarGrupo = ({registro}) => {


const [nombreGrupo, setNombreGrupo]=useState(registro.nombre_grupo)
const [descripcion, setDescripcion]=useState(registro.descripcion_grupo)
const {grupos} = useSelector(state=>state.gruposAcademicos)
  const dispatch = useDispatch();

  const editGroup = async (e) =>{
    e.preventDefault();
    
    const datosGrupo = {
      id_grupo: registro.id_grupo,
      nombre_grupo:nombreGrupo,
      descripcion_grupo:descripcion,
    }

    await dispatch(editarGrupo(datosGrupo,grupos))
   // console.log(datosGrupo)
  }
 
 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={(e) => editGroup(e)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Producto:
                      </label>
                    </div>
                    <div className="col-md-7">
                        {registro.nombre_proyecto}
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Nombre Grupo
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input type="text" name="nombreGrupo" value={nombreGrupo} className="form-control" onChange={(e)=>setNombreGrupo(e.target.value)}/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Descripción Grupo
                      </label>
                    </div>
                    <div className="col-md-7">
                    <textarea name="descripcion" value={descripcion} className="form-control" onChange={(e)=>setDescripcion(e.target.value)}></textarea>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default EditarGrupo;
