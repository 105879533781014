import React, { useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { useDispatch } from "react-redux";
import { cargarReporteSemanalMedios } from "../../../actions/userActions";
import BarMontoVentasMultiAxis from "../inicio/ChartComponents/BarMontoVentasMultiAxis";
import TablaReporte from "./TablaReporte";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ReporteMediosComunicacion = () => {
  const dispatch = useDispatch();
  const [datosComercial, setDatosComercial] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [reporteTipo, setReporteTipo] = useState("medios");

  const fetchData = async () => {
    setLoading(true);
    const datos_comercial = await dispatch(cargarReporteSemanalMedios(fechaInicio.toISOString().split('T')[0], fechaFin.toISOString().split('T')[0]));
    if (datos_comercial) {
      setDatosComercial(datos_comercial);
    }
    setLoading(false);
  };

  const transformDataForAsesores = (datos) => {
    const colors = [
        'rgb(0,128,128)',
        'rgb(0,255,0)',
        'rgb(255,255,0)',
        'rgb(255,0,0)',
        'rgb(255,165,0)',
        'rgb(128,128,0)',
        'rgb(128,0,0)',
        'rgb(128,0,128)',
        'rgb(255,0,255)',
        'rgb(0,0,255)',
        'rgb(0,255,255)',
        'rgb(165,42,42)',
        'rgb(75,0,130)',
        'rgb(255,192,203)'
    ];

    const transformedDataSets = datos.Detalle_dia.map((detalleDia, i) => {
        return Object.keys(detalleDia).reduce((acc, dia) => {
            detalleDia[dia].forEach((asesorDetalle) => {
                if (!acc[asesorDetalle.asesor]) {
                    acc[asesorDetalle.asesor] = {
                        label: asesorDetalle.asesor,
                        backgroundColor: colors[Object.keys(acc).length % colors.length],
                        borderColor: colors[Object.keys(acc).length % colors.length],
                        data: Array(datos.labels.length).fill(0)
                    };
                }
                const diaIndex = datos.labels.indexOf(dia);
                if (diaIndex !== -1) {
                    acc[asesorDetalle.asesor].data[diaIndex] += parseInt(asesorDetalle.cantidad);
                }
            });
            return acc;
        }, {});
    });

    const transformedData = {
        labels: datos.labels,
        title: "Reporte Semanal de Asesores",
        dataSets: Object.values(transformedDataSets.reduce((acc, curr) => Object.assign(acc, curr), {})),
        Totales: datos.Totales,
        Total_periodo: datos.Total_periodo,
        Total_medio: datos.Total_medio,
        Detalle_dia: datos.Detalle_dia
    };
    
    return transformedData;
};

  const datosParaMostrar = reporteTipo === "asesores" && datosComercial ? transformDataForAsesores(datosComercial) : datosComercial;

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title={`Reporte Semanal de ${reporteTipo === "medios" ? "Medios" : "Asesores"}`} icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: `Reporte Semanal de ${reporteTipo === "medios" ? "Medios" : "Asesores"}` },
              ]}
            />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-6">
                      <label>Fecha Inicio</label>
                      <DatePicker
                        selected={fechaInicio}
                        onChange={(date) => setFechaInicio(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Fecha Fin</label>
                      <DatePicker
                        selected={fechaFin}
                        onChange={(date) => setFechaFin(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-12 mt-3">
                      <button className="btn btn-primary" onClick={fetchData}>Buscar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <button className={`btn ${reporteTipo === "medios" ? "btn-success" : "btn-secondary"}`} onClick={() => setReporteTipo("medios")}>Medios</button>
                <button className={`btn ${reporteTipo === "asesores" ? "btn-success" : "btn-secondary"}`} onClick={() => setReporteTipo("asesores")}>Asesores</button>
              </div>
            </div>
          </div>
          <div className="Trabajadores__body container-fluid px-0 content-actions mt-3">
            <SpinnerLoading loading={loading}>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {datosParaMostrar ? <BarMontoVentasMultiAxis datos={datosParaMostrar} /> : 'Sin datos...'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body d-flex justify-content-center">
                      {datosParaMostrar ? <TablaReporte datos={datosParaMostrar} reporteTipo={reporteTipo} /> : 'Sin datos...'}
                    </div>
                  </div>
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReporteMediosComunicacion;
