import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Layout from '../../../app/Layout';
import HeaderContent from '../General/HeaderContent';
import Breadcrumb from '../General/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faQuestionCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import { updateStateModalBasic, updateStateModalLarge } from '../../../actions/modalActions';
import ModalBasicwithRedux from '../../../containers/ModalBasicwithRedux';
import ModalLargewhithRedux from '../../../containers/ModalLargewhithRedux';
import FormularioNuevaLlamada from './FormularioNuevaLlamada';
import FormularioLlamarAhora from './FormularioLlamarAhora';
import FormularioDatosCorreo from './FormularioDatosCorreo';
import FormularioConversion from './FormularioConversion';
import ListaDellamadas from './components/ListaDellamadas';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import Tour from 'reactour';
import Swal from 'sweetalert2'; // Importamos SweetAlert2
import { actualizarLead, listarEstados, listarObservaciones, registrarClick, traerDetallesLead } from '../../../actions/leadsActions';
import { listarProyectos } from '../../../actions/cursosActions';
import './Trabajadores.scss';
import * as moment from 'moment';

const DetallesLead = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id_lead } = useParams();
  const { usuario } = useSelector(state => state.usuario);
  const { proyectos } = useSelector(state => state.cursos);
  const { estados, observaciones, contactos, leads } = useSelector(state => state.leads);

  const [lead, setLead] = useState({});
  const [loading, setLoading] = useState(true);
  const [showTour, setShowTour] = useState(false);
  const [currentLeadId, setCurrentLeadId] = useState(id_lead);
  const [currentLeadIndex, setCurrentLeadIndex] = useState(leads.findIndex(lead => lead.id_lead === id_lead));

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);
  const accentColor = "#5cb7b7";

  const handleToggleTour = () => {
    setShowTour(!showTour);
  };

  const tourConfig = [
    {
      selector: '#step-one',
      content: `Envía el número del posible cliente a tu dispositivo de llamada.`
    },
    {
      selector: '#step-two',
      content: `Inicia el simulador de llamada desde el botón 'Timbrar', si te responde, presiona el botón 'Respondido', cuando termines la llamada, completa los datos faltantes y presiona el botón 'Guardar llamada'`,
    },
    {
      selector: '#step-three',
      content: 'Terminada la llamada, realiza seguimiento: Programando otra llamada, enviando información por correo o whatsApp, o convierte a tu posible cliente en contacto.'
    },
    {
      selector: '#step-four',
      content: "Según sea el resultado de la llamada, modifica el estado del lead, notas u observaciones, asegúrate de presionar el botón de 'Guardar Información'"
    },
    {
      selector: '#step-five',
      content: "Aqui encontrarás el historial de llamadas ejecutadas o programadas,desde aquí también podrás completar tus llamadas programadas."
    },
  ];

  const fetchLeadDetails = async (leadId) => {
    const datosLead = await dispatch(traerDetallesLead(leadId));
    if (datosLead) {
      // console.log(`Fetched details for lead ID: ${leadId}`, datosLead[0]);
      setLead(datosLead[0]);
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log(`Component mounted with lead ID: ${id_lead}`);
    dispatch(listarEstados());
    dispatch(listarObservaciones());
    dispatch(listarProyectos());
    fetchLeadDetails(currentLeadId);
  }, [currentLeadId]);

  const handlePreviousLead = () => {
    if (currentLeadIndex > 0) {
      const newIndex = currentLeadIndex - 1;
      setCurrentLeadIndex(newIndex);
      const newLeadId = leads[newIndex].id_lead;
      // console.log(`Navigating to previous lead ID: ${newLeadId}`);
      setCurrentLeadId(newLeadId);
      Swal.fire({
        icon: 'info',
        title: 'Cargando lead anterior...',
        showConfirmButton: false,
        timer: 1500
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No hay leads anteriores',
        text: 'Este es el primer lead en la lista.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  const handleNextLead = () => {
    if (currentLeadIndex < leads.length - 1) {
      const newIndex = currentLeadIndex + 1;
      setCurrentLeadIndex(newIndex);
      const newLeadId = leads[newIndex].id_lead;
      // console.log(`Navigating to next lead ID: ${newLeadId}`);
      setCurrentLeadId(newLeadId);
      Swal.fire({
        icon: 'info',
        title: 'Cargando lead siguiente...',
        showConfirmButton: false,
        timer: 1500
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No hay más leads',
        text: 'Este es el último lead en la lista.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    // console.log(`Changing ${evt.target.name} to ${value}`);
    setLead({
      ...lead,
      [evt.target.name]: value
    });
  };

  const formatTiempo = (seconds) => {
    let divisor_for_minutes = seconds % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let secs = Math.ceil(divisor_for_seconds);
    return `${minutes < 10 ? '0' + minutes : minutes}:${secs < 10 ? '0' + secs : secs}`;
  };

  const handleCrearLlamada = (registro) => {
    // console.log(`Creating call for lead ID: ${registro.id_lead}`);
    dispatch(updateStateModalBasic(<FormularioNuevaLlamada registro={registro} registros={contactos} contacto={true} detalle={true} />, 'Programar llamada'));
  };

  const handleLlamarAhora = (registro) => {
    // console.log(`Calling now for lead ID: ${registro.id_lead}`);
    dispatch(updateStateModalBasic(<FormularioLlamarAhora registro={registro} />, 'Nueva llamada'));
  };

  const handleEnviarCorreo = (registro) => {
    // console.log(`Sending email for lead ID: ${registro.id_lead}`);
    dispatch(updateStateModalLarge(<FormularioDatosCorreo registro={registro} />, 'Enviar correo'));
  };

  const handleConversion = (registro) => {
    // console.log(`Converting lead ID: ${registro.id_lead}`);
    dispatch(updateStateModalBasic(<FormularioConversion registro={registro} />, 'Convertir'));
  };

  const handleRegistrarClick = (registro, tipo) => {
    const datos = {
      autor: usuario.id_usuario,
      id_lead: registro.id_lead,
      tipo: tipo,
    };
    // console.log(`Registering click for lead ID: ${registro.id_lead} with type: ${tipo}`);
    dispatch(registrarClick(datos));
  };

  const editarLead = async (e) => {
    e.preventDefault();
    // console.log(`Editing lead ID: ${lead.id_lead}`);
    await dispatch(actualizarLead(lead, proyectos, contactos, estados, observaciones, true, true));
  };

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Detalles de Registro" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "/leads", nombre: 'Leads' },
                { url: "", nombre: "Detalles" },
              ]}
            />
            <button className="btn btn-primary" onClick={handleToggleTour}><FontAwesomeIcon icon={faQuestionCircle} /></button>
          </div>
          <div className="Trabajadores__body container-fluid px-0 content-actions mt-1">
            <ModalBasicwithRedux />
            <ModalLargewhithRedux />
            <SpinnerLoading loading={loading}>
              <div className="row">
                <div className="col-md-4">
                  <div className="box mt-2 p-3">
                    <div className="row">
                      <div className="col-md-12 p-4">
                        <div className="mb-3">
                          <div id="step-one">
                            <h2>Llamar ahora:</h2>
                            <a href={`skype:+${lead.telefono}`} className='btn btn-info' aria-label='see' rel='noopener noreferrer' title="Enviar a Skype"><i className='fa fa-skype'></i></a>
                            <a href={`tel:+${lead.telefono}`} className='btn btn-warning' aria-label='see' rel='noopener noreferrer' title="Enviar al teléfono"><i className='fas fa-mobile-alt'></i></a>
                          </div>
                          <div id="step-two"><FormularioLlamarAhora registro={lead} /></div>
                        </div>
                        <div id="step-three">
                          <h2>Seguimiento:</h2>
                          <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                            <li>
                              <span className="btn btn-primary mt-2" style={{ cursor: 'pointer' }} onClick={() => handleCrearLlamada(lead)}> + Programar llamada </span>
                            </li>
                            <li>
                              <div style={{ marginTop: '10px' }}>
                                <span style={{ cursor: 'pointer' }} rel='noopener noreferrer' className="btn btn-info" onClick={() => handleEnviarCorreo(lead)} title="Enviar Correo">
                                  <FontAwesomeIcon icon={faMailBulk} color={'#fff'} />
                                </span>
                                <a href={`https://api.whatsapp.com/send/?phone=${lead.telefono}&name=`} className='btn btn-success' aria-label='see' target='_blank' rel='noopener noreferrer' title="Enviar a WhatsApp" onClick={() => handleRegistrarClick(lead, 1)}><i className='fab fa-whatsapp'></i></a>
                                <button className="btn btn-primary mr-1" onClick={() => handleConversion(lead)} type="button" title="Convertir">
                                  <FontAwesomeIcon icon={faUserPlus} />
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="box mt-2 p-3">
                    <div className="row">
                      <form onSubmit={(e) => editarLead(e)} id="step-four">
                        <div className="col-md-6 p-4">
                          <h2>Datos de lead:</h2>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <label>Nombres:</label>
                            <input type="text" className="form-control" name="nombres" value={lead.nombres || ''} onChange={handleChange} required />
                          </Box>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <label>Apellidos:</label>
                            <input type="text" className="form-control" name="apellidos" value={lead.apellidos || ''} onChange={handleChange} required />
                          </Box>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <label>Correo:</label>
                            <input type="email" className="form-control" name="correo" value={lead.correo || ''} onChange={handleChange} required />
                          </Box>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <label>Teléfono:</label>
                            <input type="text" className="form-control" name="telefono" value={lead.telefono || ''} readOnly />
                          </Box>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <label>Producto:</label>
                            <select name="producto" className="form-control" value={lead.producto} onChange={handleChange}>
                              {proyectos.length > 0 ? proyectos.map((producto, i) => {
                                return <option key={i} value={producto.id_proyecto}>{producto.nombre_proyecto}</option>
                              }) : null}
                            </select>
                          </Box>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <h2>Tiempo dedicado total:</h2>
                            <h2>{formatTiempo(lead.tiempo_llamada)}</h2>
                          </Box>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <button type="submit" className="btn btn-primary btn-submit mr-2">
                              Guardar información
                            </button>
                          </Box>
                        </div>
                        <div className="col-md-6 p-4">
                          <h2>Estado de lead</h2>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <label>Estado:</label>
                            <select name="estado" className="form-control" value={lead.estado} onChange={handleChange}>
                              {estados.length > 0 ? estados.map((estado, i) => {
                                return <option key={i} value={estado.id_estado}>{estado.nombre_estado}</option>
                              }) : null}
                            </select>
                          </Box>
                          <Box component="fieldset" mb={3} borderColor="transparent" className={`${lead.estado === '5' ? 'd-block' : 'd-none'}`}>
                            <label>Observaciones:</label>
                            <select name="observacion_l" className="form-control" value={lead.observacion_l || ''} onChange={handleChange}>
                              {observaciones.length > 0 ? observaciones.map((observacion_x, i) => {
                                return <option key={i} value={observacion_x.id_observacion}>{observacion_x.observacion_n}</option>
                              }) : null}
                            </select>
                          </Box>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <label>Notas:</label>
                            <textarea name="notas" className="form-control" value={lead.notas || ''} onChange={handleChange} />
                          </Box>
                        </div>
                      </form>
                      <div className="col-12" id="step-five">
                        <Box component="fieldset" mt={3} borderColor="transparent">
                          <h4>Historial de llamadas:</h4>
                          <ListaDellamadas llamadas={lead.llamadas} contacto={true} />
                        </Box>
                      </div>
                      <div className="col-12">
                        <Box component="fieldset" mt={3} borderColor="transparent">
                          <div className="d-flex justify-content-between">
                            <button className="btn btn-secondary" onClick={handlePreviousLead} disabled={currentLeadIndex === 0}>
                              Anterior
                            </button>
                            <button className="btn btn-secondary" onClick={handleNextLead} disabled={currentLeadIndex === leads.length - 1}>
                              Siguiente
                            </button>
                          </div>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
      <Tour
        onRequestClose={handleToggleTour}
        steps={tourConfig}
        isOpen={showTour}
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
    </Layout>
  );
};

export default DetallesLead;
