import React from 'react';
import Layout from '../../../app/Layout'

//Redux
import {connect} from 'react-redux'
import InicioAcademica from './InicioAcademica';
import InicioContable from './InicioContable';
import InicioTutor from './InicioTutor';
import InicioAdministracion from './InicioAdministracion';
import InicioDocente from './InicioDocente';
import InicioEmpresa from './InicioEmpresa';
import InicioComercial from './InicioComercial';
const Inicio = (props) => {

  const {usuario} = props
  //console.log(usuario)
    return (
      <Layout>
    
        {
          usuario.id_tp_nivel_usuario === '17'?
          <InicioAcademica
            usuario={usuario}
          />
          :
          usuario.id_tp_nivel_usuario === '8'?
          <InicioAcademica
            usuario={usuario}
          />
          :
          usuario.id_tp_nivel_usuario === '16'?
          <InicioAcademica
            usuario={usuario}
          />
          :
          usuario.id_tp_nivel_usuario === '15'?
          <InicioAcademica
            usuario={usuario}
          />
          : usuario.id_tp_nivel_usuario === '14'?
          <InicioEmpresa
            usuario={usuario}
          />
          : usuario.id_tp_nivel_usuario === '5'?
          <InicioAcademica
            usuario={usuario}
          />
          : usuario.id_tp_nivel_usuario === '4'?
          <InicioAcademica
            usuario={usuario}
          />
          : usuario.id_tp_nivel_usuario === '1' ?
          <InicioAdministracion
            usuario={usuario}
          />
          : usuario.id_tp_nivel_usuario === '11' ?
          <InicioComercial
            usuario={usuario}
          />
           : usuario.id_tp_nivel_usuario === '12' ?
          <InicioAcademica
            usuario={usuario}
          /> 
          : usuario.id_tp_nivel_usuario === '13' ?
          <InicioAcademica
            usuario={usuario}
          />
           : usuario.id_tp_nivel_usuario === '2' ?
          <InicioDocente
            usuario={usuario}
          />
          : usuario.id_tp_nivel_usuario === '6' ? 
            <InicioContable
              usuario={usuario}
            />
          : usuario.id_tp_nivel_usuario === '7' ? 
            <InicioTutor/>
          :null}
    
      </Layout>
    );
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Inicio);