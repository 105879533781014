import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import * as moment from "moment";

//Redux
import { connect } from "react-redux";

const styles = {
  borderHeader: {
    borderBottom: "1px solid #000",
    borderTop: "1px solid #000",
  },
  border: {
    borderRight: "1px solid #000",
  },
};

const ExportExcel = (props) => {
  const { reporte } = props;

  return (
    <div className="reporte d-none">
      <table id="tabla-reporte" className="table table-hover">
        
        <thead>
          <tr style={styles.borderHeader}>
            <th scope="col" style={styles.border} colSpan="3">
              SEMÁFORO
            </th>
            <th scope="col" style={styles.border} colSpan="4">
              DATOS
            </th>
            <th scope="col" style={styles.border}>
              ESTADO ECONÓMICO
            </th>
            <th scope="col" style={styles.border} colSpan="5">
              ESTADO ACADÉMICO
            </th>
            <th scope='col'>PROGRESO</th>
            <th scope="col" style={styles.border}>
              OBSERVACIONES
            </th>
            <th scope="col" style={styles.border}>
              FECHA
            </th>
            <th scope="col" style={styles.border}>
              HORA
            </th>
          </tr>
          <tr style={styles.borderHeader}>
            <th style={{ background: "#18BB60" }} scope="col">
              Verde
            </th>
            <th style={{ background: "#F39C12" }} scope="col">
              Ambar
            </th>
            <th style={{ background: "#E12E1C" }} scope="col">
              Rojo
            </th>
            <th scope="col">NOMBRE</th>
            <th scope="col">APELLIDO</th>
            <th scope="col">TELÉFONO</th>
            <th style={styles.border} scope="col">
              CORREO
            </th>

            <th style={styles.border} scope="col">
              DEUDA
            </th>

            <th scope="col">TOTAL SESIONES</th>
            <th scope="col">TOTAL SESIONES REALIZADAS</th>
            <th scope="col">TOTAL SESIONES ASISTIDAS</th>
            <th scope='col'>NOTA ÓPTIMA</th>
            <th style={styles.border} scope="col">
              NOTA PROMEDIO
            </th>
       
            <th style={styles.border} scope="col"></th>
            <th style={styles.border} scope="col"></th>
            <th style={styles.border} scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {reporte.map((report, index) => {
            return (
              <tr key={index}>
                <td
                  style={{
                    background: `${
                      report.color_estado === "AMBAR"
                        ? "#F39C12"
                        : report.color_estado === "ACTIVO"
                        ? "#18BB60"
                        : "#E12E1C"
                    }`,
                    border: "1px solid #000",
                  }}
                  colSpan="3"
                >
                  {report.color_estado}
                </td>

                <td>{report.nombre_usuario}</td>
                <td>{report.apellido_usuario}</td>
                <td style={{ textAlign: "center" }}>{report.celular}</td>
                <td style={styles.border}>{report.email}</td>

                <td style={styles.border}>
                  {parseFloat(report.importe_deuda)}
                </td>

                <td>{report.cant_total_sesiones}</td>
                <td>{report.cant_se_realizada || "0"}</td>
                <td>{report.cant_asistidas}</td>
                <td style={styles.border}>
                  {parseFloat(report.promedio_optimo)}
                </td>
                <td style={styles.border}>
                  {parseFloat(report.promedio_final)}
                </td>
                <td style={styles.border}>
                  {Math.round(report.progreso)}%
                </td>
                <td style={styles.border}>
                 { /*<select
                    name="observaciones"
                    id="observaciones"
                    className="form-control"
                    defaultValue={report.observaciones || ""}
                  >
                    <option value="">--seleccionar--</option>
                    <option value="cancela_deuda">Cancelara deuda</option>
                    <option value="activo">Estará activo</option>
                    <option value="justificar_asistencia">
                      Justificará asistencia
                    </option>
                    <option value="participa_clase">
                      Participará mas en clases
                    </option>
                    <option value="rendira_evaluacion">
                      Rendirá evaluación extemporánea
                    </option>
                  </select>*/}
                  {report.observaciones}
                </td>

                <td style={styles.border} scope="col">
                  {report.fecha_edicion
                    ? moment(report.fecha_edicion).format("DD MMMM YYYY")
                    : "---"}
                </td>
                <td style={styles.border} scope="col">
                  {report.fecha_edicion
                    ? moment(report.fecha_edicion).format("LTS")
                    : "---"}
                </td>
                {/* <td>{p.ContactNum}</td>
                  <td>{p.Salary}</td>
                  <td style={{ paddingRight: "114px" }}>{p.Department}</td>
                  <td>
                    <select name="seleccion" defaultValue='5' id="seleccion">
                      <option value="1">opcion 1</option>
                      <option value="2">opcion 2</option>
                      <option value="3">opcion 3</option>
                      <option value="4">opcion 4</option>
                      <option value="5">opcion 5</option>
                      <option value="6">opcion 6</option>

                    </select>
                  </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="tabla-reporte"
        filename="tablexls"
        sheet="tablexls"
        buttonText="Download as XLS"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  reporte: state.reporte.reporte,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExportExcel);