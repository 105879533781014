import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faEdit, faLink, faListAlt, faPhone, faSearch, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import DataTableComponent from "../../components/plugins/DataTableComponent";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalBasic } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
import "./Trabajadores.scss";
import * as moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import { listarFases, listarTratos, registrarClick } from "../../../actions/leadsActions";
import { Badge, Button } from "react-bootstrap";
import EditarTrato from "./EditarTrato";
import FormularioFiltroAsesorTratos from "./FormularioFiltroAsesorTratos";
import FormularioFiltroTratos from "./FormularioFiltroTratos";
import FormularioNuevaLlamada from './FormularioNuevaLlamada'
import { Link } from "react-router-dom";
import { avisoCorrecto } from "../../components/AvisosMensajes/MensajesSwalf";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ListaTratos = () => {
  const dispatch = useDispatch();
  const { tratos, contactos, loading } = useSelector(state => state.leads);
  const { usuario } = useSelector(state => state.usuario);
  const [busqueda, setBusqueda] = useState('');
  const [asignarSeleccion, setSeleccion] = useState(null);

  const handleChange = e => {
    setBusqueda(e.target.value);
  };

  useEffect(() => {
    dispatch(listarTratos(usuario));
    dispatch(listarFases());
  }, [dispatch, usuario]);

  const handleEditarRegistro = (registro) => {
    dispatch(updateStateModalBasic(<EditarTrato registro={registro} />, 'Editar Trato'));
  };

  const handleCrearLlamada = (registro) => {
    dispatch(updateStateModalBasic(<FormularioNuevaLlamada registro={registro} registros={contactos} contacto={true} />, 'Programar llamada'));
  };

  const handleEliminar = (id) => {
    // dispatch(eliminarComunicado(id))
  };

  const handleRegistrarClick = (registro, tipo) => {
    const datos = {
      autor: usuario.id_usuario,
      id_lead: registro.id_lead,
      tipo: tipo,
    };

    dispatch(registrarClick(datos));
  };

  const leadsBusqueda = tratos ? tratos.filter(
    lead =>
      (lead.nombres && lead.nombres.toLowerCase().includes(busqueda.toLowerCase())) ||
      (lead.apellidos && lead.apellidos.toLowerCase().includes(busqueda.toLowerCase())) ||
      (lead.correo && lead.correo.toLowerCase().includes(busqueda.toLowerCase())) ||
      (lead.telefono && lead.telefono.toLowerCase().includes(busqueda.toLowerCase()))
  ) : null;

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(leadsBusqueda);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tratos");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'Tratos.xlsx');
  };

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Tratos" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Tratos" },
              ]}
            />
            <div className="row">
              <div className="col-md-6 d-flex flex-row"></div>
            </div>
            <div className="row">
              {
                usuario.id_tp_nivel_usuario !== '11' ? <div className="col-md-6">
                  <FormularioFiltroTratos />
                </div> :
                  <div className="col-md-6">
                    <FormularioFiltroAsesorTratos />
                  </div>
              }
            </div>
          </div>
          <div className="Trabajadores__body container-fluid px-0 content-actions mt-1">
            <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border clearfix">
                  <h5 style={{ color: "#434343" }}>
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Tratos
                  </h5>
                  <div className="col-3 d-flex align-items-center pull-right">
                    <input className="form-control" value={busqueda} onChange={handleChange} style={{ marginRight: '5px' }} placeholder="Filtrar por Nombres o Apellidos..." />
                    <FontAwesomeIcon icon={faSearch} size="lg" />
                    <Button className="btn btn-primary ml-2" onClick={exportToExcel}>
                      <FontAwesomeIcon icon={faFileExcel} /> Exportar
                    </Button>
                  </div>
                </div>
                <div className="box-primary">
                  <DataTableComponent
                    colums={colums(handleEliminar, handleEditarRegistro, handleRegistrarClick, handleCrearLlamada, avisoCorrecto)}
                    tabla={leadsBusqueda}
                    keyField="id_trato"
                    setSeleccion={setSeleccion}
                    select={usuario.id_tp_nivel_usuario !== "11"}
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListaTratos;

const colums = (handleEliminar, handleEditarRegistro, handleRegistrarClick, handleCrearLlamada, avisoCorrecto) => [
  {
    name: '',
    selector: 'llamadas',
    className: 'text-center',
    width: "110px",
    cell: (record) =>
      <ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
        {record.llamadas ? record.llamadas.map((llamada, i) => {
          const a = moment(llamada.fecha_hora).format('YYYY-MM-DD H:mm')
          const b = moment().format('YYYY-MM-DD H:mm')
          const tipo = new Date(a) - new Date(b) < 0 && llamada.completado === "0" ? 'danger' : llamada.completado === "0" ? 'warning' : 'success'
          return <li key={i}><Badge variant={tipo} className="p-1"> {moment(llamada.fecha_hora).locale('es').format('MMMM  -DD')}  <i className="fas fa-phone"></i> </Badge></li>
        }) : null}
      </ul>
  },
  {
    name: "Fuente",
    selector: "nombre_fuente",
    align: "center",
    className: "nombre_fuente",
    sortable: true,
    wrap: true,
    width: "150px",
  },
  {
    name: "Enlace de Contrato",
    selector: "codigo_formulario",
    align: "center",
    className: "codigo_formulario",
    sortable: true,
    wrap: true,
    cell: (record) => <button className="btn btn-info" onClick={() => {
      navigator.clipboard.writeText('https://contratos.inedi.edu.pe/form/' + record.codigo_formulario)
      avisoCorrecto("Enlace copiado en el clipboard ✔")
    }}> <FontAwesomeIcon icon={faLink} /> </button>
  },
  {
    name: "Fecha Registro",
    selector: "fecha_registro",
    align: "center",
    className: "fecha_registro",
    sortable: true,
    width: "120px",
    compact: true
  },
  {
    name: "Nombres",
    selector: "nombres",
    align: "center",
    sortable: true,
    className: "nombres",
    compact: true,
    wrap: true,
    width: "100px"
  },
  {
    name: "Apellidos",
    selector: "apellidos",
    align: "center",
    sortable: true,
    className: "apellidos",
    compact: true,
    wrap: true,
    width: "100px"
  },
  {
    name: "Telefono",
    selector: "telefono",
    align: "center",
    sortable: true,
    className: "telefono",
    compact: true,
    wrap: true,
    width: "100px"
  },
  {
    name: "Importe",
    selector: "importe",
    align: "center",
    className: "importe",
    sortable: true,
    compact: true,
    wrap: true
  },
  {
    name: "Fecha de Cierre",
    selector: "fecha_cierre",
    align: "center",
    className: "fecha_cierre",
    sortable: true,
    width: "120px",
    compact: true
  },
  {
    name: "Producto",
    selector: "nombre_proyecto",
    align: "center",
    className: "proyecto",
    sortable: true,
    width: "180px",
    wrap: true
  },
  {
    name: "Llamada/WhatsApp",
    selector: "acciones_llamada",
    align: "center",
    className: "acciones_llamada",
    width: "130px",
    cell: (record) => <div className="d-flex justify-content-between"><a href={`tel:${record.telefono}`} className='btn btn-warning' aria-label='see' rel='noopener noreferrer' onClick={() => handleRegistrarClick(record, 2)}><i className='fas fa-mobile-alt'></i></a> <a href={`https://api.whatsapp.com/send/?phone=${record.telefono}&name=`} className='btn btn-success' aria-label='see' target='_blank' rel='noopener noreferrer' onClick={() => handleRegistrarClick(record, 1)}><i className='fab fa-whatsapp'></i></a></div>
  },
  {
    name: "Acciones",
    selector: "Acciones",
    align: "center",
    width: "80px",
    className: "Acciones",
    sortable: false,
    cell: (record) => <p>
      <button className="btn btn-primary mr-1 btn-xs" onClick={() => handleCrearLlamada(record)} type="button" title="Programar Llamada">
        <FontAwesomeIcon icon={faPhone} />
      </button>
      <Button
        variant='warning'
        size='xs'
        type="button"
        onClick={() => handleEditarRegistro(record)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      <Link
        className="btn btn-info btn-xs"
        to={`detalleRegistro/${record.id_lead}`}
      >
        <FontAwesomeIcon icon={faAddressBook} />
      </Link>
    </p>
  },
  {
    name: "Estado",
    selector: "nombre_fase",
    align: "center",
    className: "nombre_fase",
    sortable: true,
    wrap: true
  },
  {
    name: "Firma Contrato",
    selector: "activo",
    align: "center",
    className: "activo",
    sortable: true,
    wrap: true,
    cell: (record) => <Badge variant={record.activo === "1" ? 'warning' : 'success'} >{record.activo === "1" ? 'Pendiente' : 'Completado'}</Badge>
  },
  {
    name: "Asesor",
    selector: "nombre_usuario",
    align: "center",
    className: "nombre_asesor",
    sortable: true,
    width: "100px",
    wrap: true,
  }
];
