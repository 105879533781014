import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'



//Redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerReporteCalificacion } from '../../../actions/cursosActions';
import ResultadoCalificacion from './resultadoCalificacion';
import YearPicker from './YearPicker';

const ReporteNaranja = () => {

  const { loading, error, reporteCalificacion } = useSelector(state => state.reporteCalificacion);
  const [periodo,setPeriodo]=useState(null)
  const dispatch = useDispatch();

  useEffect(() => {
    if(periodo)
     dispatch(obtenerReporteCalificacion(periodo));
  }, [periodo])

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='Reporte de Satisfacción' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Reportes" },
              { url: "", nombre: "Satisfacción" },
            ]}
          />

          <div className="row my-4">
            <div className='col-md-6'>
              <div className='card'>
                <div className='card-body'>
                  <div className="d-flex flex-column justify-content-start">
                      <h4>Periodo:</h4>
                      <YearPicker setYear={setPeriodo}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ControlReportes__body">
            
          <SpinnerLoading loading={loading} error={error}>
            {
              <ResultadoCalificacion calificaciones={reporteCalificacion.calificaciones} />
            }
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default ReporteNaranja;