import {
 gruposAcademicosTypes, CLOSE_MODAL_LARGE
} from "./types";


import authAxios from "../config/authAxios";
//import response from '../config/response';
import { avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";
import Swal from "sweetalert2/src/sweetalert2";
import { updateStateModalLarge } from "./modalActions";
//import * as moment from 'moment';



export const listarGruposAcademicosIdProyecto = (idProyecto) => {

  return async dispatch => {
      dispatch({
        type: gruposAcademicosTypes.LISTAR_GRUPOS_ACA
      });
      try {
  
          const grupos = await authAxios.get(
              `/admin/listarGruposAcademicosIdProyecto/${idProyecto}`
            );
        
          dispatch({
            payload: grupos.data.grupos,
            type: gruposAcademicosTypes.LISTAR_GRUPOS_ACA_EXITO
          });
      
      } catch (error) {
        avisoError('Hubo un error al listar los grupos')
      }
    }
}

export const listarGruposAcademicos = () => {

  return async dispatch => {
      dispatch({
        type: gruposAcademicosTypes.LISTAR_GRUPOS_ACA
      });
      try {
  
          const grupos = await authAxios.get(
              `/admin/listarGruposAcademicos`
            );
        
          dispatch({
            payload: grupos.data.grupos,
            type: gruposAcademicosTypes.LISTAR_GRUPOS_ACA_EXITO
          });
      
      } catch (error) {
        avisoError('Hubo un error al listar los grupos')
      }
    }
}

export const listarGruposAcademicosAD = () => {

  return async dispatch => {
     
      try {
  
          const grupos = await authAxios.get(
              `/admin/listarGruposAcademicos`
            );
        
          dispatch({
            payload: grupos.data.grupos,
            type: gruposAcademicosTypes.LISTAR_GRUPOS_ACA_EXITO
          });
      
      } catch (error) {
        avisoError('Hubo un error al listar los grupos')
      }
    }
}



export const insertarGrupo = (datos) => async dispatch => {
  

  // console.log(datos);

try {
   let respuesta = await authAxios.post(`/admin/insertarGrupoAcademico`,datos);
   if (respuesta.data) {
     
      dispatch(updateStateModalLarge())
   
      dispatch({
        payload: respuesta.data.grupos,
        type: gruposAcademicosTypes.LISTAR_GRUPOS_ACA_EXITO
      })
    avisoCorrecto('Se guardó correctamente')
   }
   else
   {
     avisoError('No se pudo guardar')
   }

 } catch (error) {
  avisoError('No se pudo guardar')
 }
};

export const editarGrupo = (datos,grupos) => async dispatch => {
  

  // console.log(datos);

try {
   let respuesta = await authAxios.post(`/admin/editarGrupoAcademico`,datos);
   if (!respuesta.data.error) {

        grupos.forEach((grupo)=> {
          if(grupo.id_grupo===datos.id_grupo)
          {     
            grupo.nombre_grupo=datos.nombre_grupo
            grupo.descripcion_grupo=datos.descripcion_grupo
          }
        })

     
      dispatch({
        payload: grupos,
        type: gruposAcademicosTypes.LISTAR_GRUPOS_ACA_EXITO
      })
      dispatch(updateStateModalLarge())
      avisoCorrecto('Se guardó correctamente')
   }
   else
   {
     avisoError('No se pudo actualizar')
   }

 } catch (error) {
  avisoError('No se pudo actualizar')
 }
};


export const listarEstudiantesProducto = (id_proyecto) => {

  return async dispatch => {

      try {
  
          const respuesta = await authAxios.get(
              `/admin/listarEstudiantesProducto/${id_proyecto}`
            );
        
          dispatch({
            payload: respuesta.data.estudiantes,
            type: gruposAcademicosTypes.LISTAR_ESTUDIANTES_PRODUCTO_EXITO
          });
      
      } catch (error) {
        avisoError('Hubo un error al listar los grupos')
      }
    }
}


export const traerDetalleGrupo = (id_grupo) => {

  return async dispatch => {

      try {
  
          const respuesta = await authAxios.get(
              `/admin/traerDetalleGrupo/${id_grupo}`
            );
        
        return respuesta.data
      
      } catch (error) {
        avisoError('Hubo un error al listar los integrantes')
      }
    }
}

export const agregarIntegrante = (datos) => {

  return async dispatch => {

      try {
  
          const respuesta = await authAxios.post(
              `/admin/agregarIntegrante`,datos
            );
        
        if(!respuesta.data.error)
        { 
          return respuesta.data
        }
        else
        {
          return false
        }
      } catch (error) {
        avisoError('Hubo un error al agregar el integrante')
        return false
      }
    }
}

export const eliminarIntegrante = (datosIntegrante) =>{
  return async dispatch => {


         try {
           let respuesta = await authAxios.delete(`/admin/eliminarIntegrante/${datosIntegrante.id_detalle}`);
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
               return true;
            }
               
          } catch (error) {
            
              avisoError("No se pudo eliminar integrante")
          }
     
  }
}


export const eliminarGrupoAcademico = datosGrupo =>{
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "No se podrá recuperar la información.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          cancelButtonColor: "#d33",
          preConfirm: async function() {

          try {
           let respuesta = await authAxios.delete(`/admin/eliminarGrupoAcademico/${datosGrupo}`);
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
                return true;
          } catch (error) {
            
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: 'Error al realizar la operación...'
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
          dispatch(listarGruposAcademicosAD())
          swalWithBootstrapButtons.fire(
              "Éxito!",
              "Grupo eliminado.",
              "success"
          );
        
          }
      });
  }
}
