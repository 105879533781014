import {
  REPORTE_GENEREAL_POSVENTA,
  EDITANDO_REPORTE,
  ERROR_OBTENER_REPORTE,
  OBTENIENDO_REPORTE,
  LIMPIAR_STATES_REPORTES,
  ESTADO_DISCENTE_CAMBIADO,
  ELIMINADO_DISCENTE_TABLA_DESERTADOS,
  ELIMINADO_DISCENTE_TABLA_SEGUIMIENTO,
  ERROR_EDITAR_REPORTE,
  NUEVO_LIMITE,
  REPORTE_ASISTENCIA_OBTENIDO,
  REPORTE_EDITADO,
  DATA_TABLA_ASISTENCIA_GENERADA,
  OBTENIENDO_NOTAS_DISCENTES,
  NOTAS_DISCENTE_OBTENIDO,
  TABLA_NOTAS_GENERADA,
  OBTENER_REPORTE_ENCUESTAS_EXITO,
  OBTENER_REPORTE_DESERTADOS,
  SELECCION_INICIAL_TUTORIA,
  REPORTE_DESERTADOS_EXITO,
  SET_SELECT_DESERTADOS,
  indicadoresTypes
} from './types';

import authAxios from '../config/authAxios';
import * as moment from 'moment'
import { avisoError, avisoCargando, avisoCorrecto } from '../view/components/AvisosMensajes/MensajesSwalf';

export const listarReporteGeneralPosventa = (proyectos) => {

  return async (dispatch) => {
    dispatch ( loading() )
    
    try {
      const reporte = await authAxios.post(`/admin/es_listarReporteGeneral_Id_Proyecto`,proyectos)
     // console.log(reporte);
      if (!reporte.data.error && !reporte.data.message) {
       // console.log(reporte.data);
        dispatch( obtenerReportegeneralPosventa(reporte) )
      } else {
        dispatch( mensajeError(reporte.data.error || reporte.data.message) )
        avisoError(reporte.data.error || reporte.data.message)
      }

    } catch (error) {
      console.log(error)
      dispatch( mensajeError() )

      avisoError(error)
    }
  }
  
}

const obtenerReportegeneralPosventa = (reporte) => {
    return ({
        payload: reporte.data,
        type: REPORTE_GENEREAL_POSVENTA
    })
}


//Editar reporte del estudiante
export const editarReporteEstudiante = (idReporteProyecto, observaciones, proyectos) => {
  return async dispatch => {
 //   console.log(idReporteProyecto, observaciones, proyectos)
    dispatch( (loadingEditarReporte()) )
    avisoCargando('Guardando...')
    let datosEnvio = {
      id_proyecto:idReporteProyecto,
      observaciones:observaciones,
      proyectos:proyectos
    }
    try {
      //hacer el objto a enviar
     
      const respuesta = await authAxios.post(`/admin/es_actualizar_ObservacionesReporte__IdReporte`, datosEnvio)
      
      if (!respuesta.data.error && !respuesta.data.message) {
      //  console.log(respuesta.data)
        dispatch( guardarEdicion(respuesta.data) )
        avisoCorrecto('Guardado correctamente')
      } else {
        dispatch( errorEditar(respuesta.data.error || respuesta.data.message) )
        avisoError(respuesta.data.error || respuesta.data.message)
      }

    } catch (error) {
      console.log(error)
      errorEditar(error)
      avisoError(error)
    }

  }
}

const loadingEditarReporte = () => {
  return ({
    type: EDITANDO_REPORTE
  })
}

const guardarEdicion = data => {
  return ({
    type: REPORTE_EDITADO,
    payload: data
  })
}

const errorEditar = () => {
  return ({
    type: ERROR_EDITAR_REPORTE
  })
}


//action para para actualizar el limitador
export const actualizarLimitador = limite => {
  return dispatch => {
    dispatch( nuevoLimite(limite) )
  }
}

const nuevoLimite = limite => {
  return ({
    type: NUEVO_LIMITE,
    payload: limite
  })
}

//Action para limpiar el state cuando se cierre sesion
export const limpiarState = () => {
  return (dispatch) => {
    dispatch({
      type: LIMPIAR_STATES_REPORTES
    })
  }
}

//REPORTES ASISTENCIA

export const obtenerAsistencias = (data) => {

  return async dispatch => {
    dispatch( loading() )
    console.log(data)
    try {
      const reportes = await authAxios.get(`
        /admin/es_se_mostrar_asistencia_PrytFechaEst/${data.idProyecto}/${data.idEstudiante}/${data.start}/${data.end}
      `)

      dispatch( guardarRerporteAsistencia(reportes.data) )
      dispatch( guardarReporteAsistenciaTabla(reportes.data) )
    } catch (error) {
      console.log(error)
      dispatch( mensajeError(error) )
    }
  }

}

//fucion para traer guardar reporte de asistencia
const guardarRerporteAsistencia = (data) => {
  return {
    type: REPORTE_ASISTENCIA_OBTENIDO,
    payload: data
  }
}

const guardarReporteAsistenciaTabla = asistencias => {

  let asistenciastabla = []
  asistencias.map(asistencia => {
    asistenciastabla = [...asistenciastabla, {
      id: asistencia.id_estudiante_sesion,
      estado: asistencia.nombre_tp_tiempo_ingreso,
      nombre: asistencia.nombre_usuario,
      apellido: asistencia.apellido_usuario,
      turno: asistencia.turno,
      ingreso_m: asistencia.hora_ingreso_tur_m || '---',
      salida_m: asistencia.hora_salida_tur_m || '---',
      ingreso_t: asistencia.hora_ingreso_tur_t || '---',
      salida_t: asistencia.hora_salida_tur_t || '---',
      fecha: asistencia.fecha_sesion
    }]
  })

  return {
    type: DATA_TABLA_ASISTENCIA_GENERADA,
    payload: asistenciastabla
  }
}


//Funcion que indica que est cargando consulta
const loading = () => {
  return ({
      type: OBTENIENDO_REPORTE,
      payload: true
    })
}

const mensajeError = (error) => {
  return ({
    type: ERROR_OBTENER_REPORTE,
    payload: error
  })
}


//REPROTES PARA SABER LAS NOTAS DE CADA SESION DE LOS DISCENTES
const loadingNotas = () => {
  return {
    type: OBTENIENDO_NOTAS_DISCENTES
  }
}

const notasObtenido = notasDiscente => {
  return {
    type: NOTAS_DISCENTE_OBTENIDO,
    payload: notasDiscente
  }
}

const crearTablaNotas = notasDiscente => {
  let notasTabla = []

  notasDiscente.map((nota, index) => {
    notasTabla = [...notasTabla, {
      id: nota.id_estudiante_sesion,
      nombresesion: nota.nombre_sesion.substr(0, 100)+'...',
      sesion: (index + 1),
      fecha: moment(nota.fecha_sesion).format('DD-MM-YYYY'),
      puntos: nota.puntos_adicionales,
      nota: nota.nota,
      asistencia: nota.nombre_tp_tiempo_ingreso
    }]
  })

  return {
    type: TABLA_NOTAS_GENERADA,
    payload: notasTabla
  }
} 

export const listarNotasDiscente = idMatriculaDiscente => {
  return async dispatch => {
    dispatch( loadingNotas() )

    try {
      const notasDiscente = await authAxios.get(`/admin/es_se_mostrarSesiones__IdMatricula/${idMatriculaDiscente}`)
      
      dispatch( notasObtenido(notasDiscente.data) )
      dispatch( crearTablaNotas(notasDiscente.data) )
      
    } catch (error) {
      console.log(error)
      mensajeError(error)
    }
  }
}

const reporteEncuestaObtenido = reporte => ({
  type: OBTENER_REPORTE_ENCUESTAS_EXITO,
  payload: reporte
})

const crearStateSeleciones = reporte => {
  
  const cantidadSesiones = reporte.docente.categories;
 
  const valoresSesionesIncial = new Array(cantidadSesiones.length)

  for (let index = 0; index < valoresSesionesIncial.length; index++) {
    valoresSesionesIncial[index] = true
  }
 // console.log(valoresSesionesIncial)
  return {
    type: SELECCION_INICIAL_TUTORIA,
    payload: valoresSesionesIncial
  }
}

export const actualizarStateInicial = nuevoState => {
  
  return dispatch => dispatch({
    type: 'ACTUALIZAR_STATE_INICIAL_SELECT',
    payload: nuevoState
  })

}

export const listarReporteEncuesta = idProyecto => {
  
  return async dispatch => {
    // dispatch( loading(OBTENER_REPORTE_ENCUESTAS) )

    try {
      const reporte = await authAxios.get(`/admin/rptet__mostrarReporteFormulario__IdProyecto/${idProyecto}`)

      if (!reporte.data.error) {
        dispatch( reporteEncuestaObtenido(reporte.data) )
        dispatch( crearStateSeleciones(reporte.data) )
        return
      }

    } catch (error) {
      console.log(error)
    }
  }

}

const guardarEncuestaDocente = reporte => {
  return {
    type: 'ENCUESTA_DOCENTE_OBTENIDO',
    payload: reporte
  }
}

export const obtenerEncuestaDocente = idDocenteFormulario => {
  return async dispatch => {
    try {
      const reporte = await authAxios.get(`/admin/rprtet_mostrarEncuestaDocente__IdFormEncu/${idDocenteFormulario}`)
      
      dispatch( guardarEncuestaDocente( reporte.data ) )
      
    } catch (error) {
      console.log(error)
    }
  }
}

//Reporte de Desertados
export const obternerReporteDesertados = idProyecto => {
  return async dispatch => {

    dispatch({
      type: OBTENER_REPORTE_DESERTADOS
    })

    try {
      const reporte = await authAxios.post(`/admin/rpte_MostrarReporteDesertado__IdProyecto`,idProyecto)
      
      if (reporte.data.length) {
    
        reporte.data.map((proyecto) => {
        //  console.log(proyecto)
            proyecto.reporte_desertado.map((item,index)=>{  
            item.id = index
            item.monto_por_asistencia = item.monto_por_asistencia ? item.monto_por_asistencia.toFixed(2) : '0'
            item['20%'] = item['20%'] ? item['20%'].toFixed(2) : '0'
            item.cantidad_asistencia = item.cantidad_asistencia ? item.cantidad_asistencia : '0'
            item.inversion_por_sesion = item.inversion_por_sesion ? item.inversion_por_sesion.toFixed(2) : '0'
            item.monto_abonado = item.monto_abonado ? item.monto_abonado : '0'
            item['deuda_cubre_20%'] = item['deuda_cubre_20%'] ? item['deuda_cubre_20%'].toFixed(2) : '0'
            item['monto_a_cobrar_20%'] = item['monto_a_cobrar_20%'] ? item['monto_a_cobrar_20%'].toFixed(2) : '---'
            item['monto_a_cobrar_100%'] = item['monto_a_cobrar_100%'] ? item['monto_a_cobrar_100%'].toFixed(2) : '---'
          })
        })
      }
      dispatch({
        type: REPORTE_DESERTADOS_EXITO,
        payload: reporte.data
      })

    } catch (error) {
      
    }
  }
}

export const guardarProyectoSelect = datos => {

  return async dispatch => {
    dispatch({
      type: SET_SELECT_DESERTADOS,
      payload: datos
    })
  }

}

export const actualizarEstadoDesertado = (idMatricula, idEstado) => {
  return async (dispatch, getState) => {
    try {
      //Haciendo la consulta en la api
      await authAxios.post(`/admin/rptesg__insertarDiscenteDesertado__IdMatricula/${idMatricula}/${idEstado}`)

      dispatch({
        type: ESTADO_DISCENTE_CAMBIADO
      })

      const { reporte: { desertados, reporte } } = getState()

      if (idEstado === '1') {
        //Filtro para que desaparesca el discente con el que se cambia de estado
        const copia_desertados = [...desertados.reporteDesertados];
        const nuevos_desertados = copia_desertados.filter(desertado => desertado.id_matricula !== idMatricula)

        dispatch({
          type: ELIMINADO_DISCENTE_TABLA_DESERTADOS,
          payload: nuevos_desertados
        })
      } else {
        //Filtro para que desaparesca el discente con el que se cambia de estado
        const copia_seguimiento = [...reporte];
        const nuevos_seguimiento = copia_seguimiento.filter(item => item.id_matricula !== idMatricula)

        dispatch({
          type: ELIMINADO_DISCENTE_TABLA_SEGUIMIENTO,
          payload: nuevos_seguimiento
        })
      }
      
    } catch (error) {
      console.log(error)
    }
  }
}
export const obtenerIndicadores = (proyecto) => async dispatch => {

  try {
    dispatch({
      type: indicadoresTypes.OBTENER_INDICADORES
    })
    const indicadores = await authAxios.post(`/admin/reporteIndicadoresPosVenta`,proyecto);
   //console.log(indicadores.data)
    dispatch({
      payload: indicadores.data,
      type: indicadoresTypes.OBTENER_INDICADORES_EXITO
    })
  } catch (error) {
    dispatch({
      payload:error,
      type: indicadoresTypes.OBTENER_INDICADORES_ERROR
    })
  }
  
}
