import {
  LISTAR_TRABAJADORES_SISTEMA, 
  LISTAR_TRABAJADORES_SISTEMA_EXITO,
  LISTAR_TRABAJADORES_SISTEMA_ERROR,
  ELIMINAR_TRABAJADOR_SISTEMA,
  INSERTAR_NUEVO_TRABAJADOR,
  OBTENER_TRABAJADOR,
  OBTENER_TRABAJADOR_EXITO,
  OBTENER_TRABAJADOR_ERROR,
  EDITAR_CLASIFICACION_TRABAJADOR,
  EDITAR_CV_TRABAJADOR,
  EDITAR_HONORARIOS_TRABAJADOR,
  ARCHIVO_ADJUNTO_SUBIDO_TRABAJADOR,
  ELIMINAR_ARCHIVO_TRABAJADOR,
  EDITAR_NOMBRE_USUARIO_TRABAJADOR,
  EDITAR_PASSWORD_TRABAJADOR,
  EDITAR_REGISTRO_TRABAJADOR,
  ACTUALIZAR_AVATAR_TRABAJADOR,
  AREA_TRABAJADOR_ACTIVO,
  AREAS_TRABAJADOR_OBTENIDO,
  AGREGAR_TEMA_TRABAJADOR,
  ELIMINAR_TEMA_TRABAJADOR,
  GUARDAR_NUEVO_ORDEN_TEMAS_TRABAJADOR,
  trabajadoresTypes
} from '../actions/types';

const initialState = {
  trabajadores: [],
  asesores:[],
  productoAsesores:[],
  trabajador: {},
  clasificacion: {
    datos: [],
    loading: false,
    error: null
  },
  areas: {
    todo: [],
    activo: null
  },
  temas: [],
  loading: null,
  error: null,
}

export default function( state = initialState, action ) {
  switch (action.type) {
    case LISTAR_TRABAJADORES_SISTEMA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LISTAR_TRABAJADORES_SISTEMA_EXITO:
      return {
        ...state,
        trabajadores: action.payload,
        error: null,
        loading: null
      };
    case LISTAR_TRABAJADORES_SISTEMA_ERROR:
      return {
        ...state,
        error: true,
        loading: null
      };
    case INSERTAR_NUEVO_TRABAJADOR:
      return {
        ...state,
        trabajadores: [...state.trabajadores, action.payload]
      };
    case OBTENER_TRABAJADOR:
      return {
        ...state,
        loading: true,
        error: null
      };
    case OBTENER_TRABAJADOR_EXITO:
      return {
        ...state,
        trabajador: action.payload,
        loading: null
      };
    case OBTENER_TRABAJADOR_ERROR:
      return {
        ...state,
        error: true
      };
    case EDITAR_REGISTRO_TRABAJADOR:
      return {
        ...state,
        trabajadores: [...state.trabajadores].map(
          trabajador => trabajador.id_empleado ===  action.payload.idTrabajador ? action.payload.nuevosDatos : trabajador ),
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            ...action.payload.nuevosDatos
          }
        }
      };
    case ACTUALIZAR_AVATAR_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            img_usuario: action.payload.nuevoAvatar
          }
        }
      };
    case EDITAR_CLASIFICACION_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            clasificacion: action.payload.clasificacion
          }
        }
      };
    case AREAS_TRABAJADOR_OBTENIDO:
      return {
        ...state,
        areas: {
          ...state.areas,
          todo: action.payload
        }
      }
    case AREA_TRABAJADOR_ACTIVO:
      return {
        ...state,
        areas: {
          ...state.areas,
          activo: action.payload
        }
      }
    case AGREGAR_TEMA_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            temas: [...state.trabajador[action.payload.idTrabajador].temas, action.payload.nuevoTema]
          } 
        }
      };
    case ELIMINAR_TEMA_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            temas: [...state.trabajador[action.payload.idTrabajador].temas].filter(
              tema => tema.id_tema_area !== action.payload.idTemaTrabajador   
            )
          }
        }
      }
    case GUARDAR_NUEVO_ORDEN_TEMAS_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            temas: action.payload.nuevoOrden
          }
        }
      }
    case EDITAR_CV_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            descripcion: action.payload.descripcion
          }
        }
      };
    case EDITAR_HONORARIOS_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            honorarios: action.payload.honorarios
          }
        }
      };
    case ARCHIVO_ADJUNTO_SUBIDO_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            documentos: {
              ...state.trabajador[action.payload.idTrabajador].documentos,
              [action.payload.nombreDocumento]: action.payload.nuevoArchivo
            }
          }
        }
      }
    case ELIMINAR_ARCHIVO_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            documentos: {
              ...state.trabajador[action.payload.idTrabajador].documentos,
              [action.payload.nombreDocumento]: null
            }
          }
        }
      }
    case EDITAR_NOMBRE_USUARIO_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            usuario: action.payload.nombreUsuario
          }
        }
      };
    case EDITAR_PASSWORD_TRABAJADOR:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: {
            ...state.trabajador[action.payload.idTrabajador],
            password: action.payload.nuevoHash
          }
        } 
      }
    case ELIMINAR_TRABAJADOR_SISTEMA:
      return {
        ...state,
        trabajadores: [...state.trabajadores].filter(
          trabajador => trabajador.id_usuario !== action.payload
        )
      };
    case trabajadoresTypes.OBTENER_LISTA_CLASIFICACION:
      return {
        ...state,
        clasificacion: {
          ...state.clasificacion,
          loading: true,
          error: null
        }
      }
    case trabajadoresTypes.OBTENER_LISTA_CLASIFICACION_EXITO:
      return {
        ...state,
        clasificacion: {
          ...state.clasificacion,
          datos: action.payload,
          loading: false,
          error: null
        }
      }
    case trabajadoresTypes.LISTAR_EQUIPO_ASESORES:
      return {
        ...state,
        loading:true,
        asesores:[]
      }
    case trabajadoresTypes.LISTAR_EQUIPO_ASESORES_EXITO:
        return {
          ...state,
          loading:false,
          asesores:action.payload
        }
    case trabajadoresTypes.LISTAR_PRODUCTO_ASESORES:
      return {
        ...state,
        loading:true,
        productoAsesores:[]
      }
    case trabajadoresTypes.LISTAR_PRODUCTO_ASESORES_EXITO:
      return {
        ...state,
        loading:false,
        productoAsesores:action.payload
      }
    case trabajadoresTypes.OBTENER_LISTA_CLASIFICACION_ERROR:
      return {
        ...state,
        clasificacion: {
          ...state.clasificacion,
          loading: false,
          error: action.payload
        }
      }
    case trabajadoresTypes.EDITAR_CONFIGURACION_AREA_TRABAJADOR_EXITO:
      return {
        ...state,
        trabajador: {
          ...state.trabajador,
          [action.payload.idTrabajador]: action.payload.data
        }
      }
    default:
      return state;
  }
}
