import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listarTratos, listarFuentes, filtrarTratosCliente } from "../../../../actions/leadsActions";
import { Pie, Bar } from "react-chartjs-2";
import Layout from "../../../../app/Layout";
import HeaderContent from "../../General/HeaderContent";
import Breadcrumb from "../../General/Breadcrumb";
import SpinnerLoading from "../../../components/Spinners/SpinnerLoading";
import useSelectProyecto from '../../../../hooks/useSelectProyectos';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const GraficoTratos = () => {
  const dispatch = useDispatch();
  const { tratos, fuentes, loading } = useSelector((state) => state.leads);
  const { usuario } = useSelector((state) => state.usuario);
  const [proyecto, SelectProyecto] = useSelectProyecto(null);
  const [showCharts, setShowCharts] = useState(false);

  useEffect(() => {
    dispatch(listarFuentes());
  }, [dispatch]);

  const handleFiltrosChange = () => {
    const datosFiltro = {
      proyecto: proyecto ? proyecto.id_proyecto : null,
    };

    dispatch(filtrarTratosCliente(datosFiltro, usuario));
    setShowCharts(true);
  };

  const fuenteData = tratos.reduce((acc, trato) => {
    const nombre_fuente = trato.nombre_fuente || "Desconocida";
    if (!acc[nombre_fuente]) {
      acc[nombre_fuente] = 0;
    }
    acc[nombre_fuente] += 1;
    return acc;
  }, {});

  const responsableData = tratos.reduce((acc, trato) => {
    const nombre_responsable = trato.nombre_usuario || "Desconocido";
    if (!acc[nombre_responsable]) {
      acc[nombre_responsable] = 0;
    }
    acc[nombre_responsable] += 1;
    return acc;
  }, {});

  const dataPie = {
    labels: Object.keys(fuenteData),
    datasets: [
      {
        data: Object.values(fuenteData),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  };

  const dataBar = {
    labels: Object.keys(fuenteData),
    datasets: [
      {
        label: "Cantidad de Tratos por Fuente",
        data: Object.values(fuenteData),
        backgroundColor: "#36A2EB",
        borderColor: "#36A2EB",
        borderWidth: 1,
      },
    ],
  };

  const dataPieResponsable = {
    labels: Object.keys(responsableData),
    datasets: [
      {
        data: Object.values(responsableData),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  };

  const dataBarResponsable = {
    labels: Object.keys(responsableData),
    datasets: [
      {
        label: "Cantidad de Tratos por Responsable",
        data: Object.values(responsableData),
        backgroundColor: "#36A2EB",
        borderColor: "#36A2EB",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const optionsPie = {
    ...options,
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          const dataset = context.chart.data.datasets[0];
          const total = dataset.data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
      },
    },
  };

  const optionsBarResponsable = {
    ...options,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Gráfico de Tratos" icon="chart-pie" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Reporte de Tratos" },
              ]}
            />
            <div className="row">
              <div className="col-md-6">
                <div className="box">
                  <div className="box-header with-border">
                    <h5 style={{ color: "#434343" }}>
                      <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro:
                    </h5>
                  </div>
                  <div className="row p-2">
                    <div className="col-12 mb-2">
                      <label htmlFor="">Producto:</label>
                      <SelectProyecto />
                    </div>
                    <div className="col-12 pt-4">
                      <button className="btn btn-primary" onClick={handleFiltrosChange}>Consultar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Trabajadores__body container-fluid px-0 content-actions mt-1">
            {!showCharts ? (
              <div className="alert alert-info" role="alert">
                Debe de seleccionar el Producto para ver los gráficos.
              </div>
            ) : (
              <SpinnerLoading loading={loading}>
                <div className="box mt-2">
                  <div className="box-header with-border clearfix">
                    <h5 style={{ color: "#434343" }}>
                      Gráfico de Tratos por Fuente
                    </h5>
                  </div>
                  <div className="box-primary">
                    <div className="row">
                      <div className="col-md-6" style={{ height: "400px", marginBottom: "20px" }}>
                        <Pie data={dataPie} options={optionsPie} />
                      </div>
                      <div className="col-md-6" style={{ height: "400px" }}>
                        <Bar data={dataBar} options={optionsBarResponsable} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box mt-4">
                  <div className="box-header with-border clearfix">
                    <h5 style={{ color: "#434343" }}>
                      Gráfico de Tratos por Asesor
                    </h5>
                  </div>
                  <div className="box-primary">
                    <div className="row">
                      <div className="col-md-6" style={{ height: "400px", marginBottom: "20px" }}>
                        <Pie data={dataPieResponsable} options={optionsPie} />
                      </div>
                      <div className="col-md-6" style={{ height: "400px" }}>
                        <Bar data={dataBarResponsable} options={optionsBarResponsable} />
                      </div>
                    </div>
                  </div>
                </div>
              </SpinnerLoading>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GraficoTratos;
