import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const TablaReporte = ({ datos, reporteTipo }) => {
    console.log(datos); // Añadir este console.log para verificar los datos

    const calcularTotalesPorDia = () => {
        const totales = datos.labels.map(() => 0);
        datos.dataSets.forEach(medio => {
            medio.data.forEach((cantidad, i) => {
                totales[i] += parseInt(cantidad) || 0;
            });
        });
        return totales;
    };

    const calcularPorcentajePorMedio = () => {
        const totalPeriod = datos.dataSets.reduce((acc, medio) => acc + medio.data.reduce((a, b) => a + (parseInt(b) || 0), 0), 0);
        return datos.dataSets.map(medio => {
            const totalMedio = medio.data.reduce((a, b) => a + (parseInt(b) || 0), 0);
            const porcentajeMedio = totalPeriod > 0 ? (totalMedio / totalPeriod) * 100 : 0;
            return parseFloat(porcentajeMedio.toFixed(2));
        });
    };

    const totalesPorDia = calcularTotalesPorDia();
    const porcentajesPorMedio = calcularPorcentajePorMedio();

    return (
        <table className='table table-responsive'>
            <thead>
                <tr>
                    <th>{reporteTipo === "medios" ? "Medio de comunicación" : "Asesor"}</th>
                    {datos.labels.map((label, i) => (
                        <th key={i}>{label}</th>
                    ))}
                    <th>Totales</th>
                    <th>%</th>
                </tr>
            </thead>
            <tbody>
                {datos.dataSets.map((medio, i) => (
                    <tr key={i}>
                        <td>{medio.label}</td>
                        {medio.data.map((cantidad, j) => (
                            <td style={{ textAlign: 'right' }} key={j}>
                                {parseInt(cantidad) > 0 ? (
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover>
                                        <Popover.Title as="h3">{reporteTipo === "medios" ? "Asesores" : "Clics"}</Popover.Title>
                                        <Popover.Content>
                                            {datos.Detalle_dia && datos.Detalle_dia[i] && datos.Detalle_dia[i][datos.labels[j]]?.map((detalle, k) => (
                                                <div key={k} className='d-flex justify-content-between'>
                                                    <span className='p-1 mr-1'>{detalle.asesor || detalle.medio}</span>
                                                    <span className='p-1'>{detalle.cantidad}</span>
                                                </div>
                                            ))}
                                        </Popover.Content>
                                    </Popover>}>
                                        <span style={{ cursor: 'pointer' }}>{cantidad}</span>
                                    </OverlayTrigger>
                                ) : (
                                    <span>{cantidad}</span>
                                )}
                            </td>
                        ))}
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{medio.data.reduce((a, b) => a + parseInt(b || 0), 0)}</td>
                        <td style={{ textAlign: 'right' }}>{porcentajesPorMedio[i]}%</td>
                    </tr>
                ))}
                <tr style={{ fontWeight: 'bold' }}>
                    <td>Totales:</td>
                    {totalesPorDia.map((cantidad, j) => (
                        <td style={{ textAlign: 'right' }} key={j}>{cantidad}</td>
                    ))}
                    <td style={{ textAlign: 'right' }}>{totalesPorDia.reduce((a, b) => a + b, 0)}</td>
                    <td style={{ textAlign: 'right' }}>
                        100%
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default TablaReporte;
