import {
  leadstypes,
  PROFORMA_GUARDAR_EXITO,
  PROFORMA_GUARDAR_ERROR,
  PROFORMA_LISTAR_EXITO,
  PROFORMA_LISTAR_ERROR
} from "./types";


import authAxios from "../config/authAxios";
import { avisoCargando, avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";
//import Swal from "sweetalert2/src/sweetalert2";
import { updateStateModalBasic, updateStateModalLarge } from "./modalActions";
import * as moment from 'moment';
import Swal from "sweetalert2";



export const guardarProforma = (proforma) => async (dispatch) => {
  try {
    const response = await authAxios.post('/admin/insertarProforma/', proforma);
    dispatch({
      type: PROFORMA_GUARDAR_EXITO,
      payload: response.data,
    });
    Swal.fire({
      title: 'Guardado',
      text: 'Proforma guardada exitosamente.',
      icon: 'success',
    });
  } catch (error) {
    dispatch({
      type: PROFORMA_GUARDAR_ERROR,
      payload: error.message,
    });
    Swal.fire({
      title: 'Error',
      text: 'Hubo un problema al guardar la proforma.',
      icon: 'error',
    });
  }
};


export const listarProformas = (filters = {}) => async (dispatch) => {
  try {
    const response = await authAxios.get('/admin/listarProforma/', { params: filters });
    dispatch({
      type: PROFORMA_LISTAR_EXITO,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: PROFORMA_LISTAR_ERROR,
      payload: error.message,
    });
    Swal.fire({
      title: 'Error',
      text: 'Hubo un problema al listar las proformas.',
      icon: 'error',
    });
  }
};


export const crearConstancia = (nombreGeneral, idProyecto) => {
  return async dispatch => {
    dispatch({ type: leadstypes.CREAR_CONSTANCIA });

    try {
      const response = await authAxios.post('/admin/crearConstancia', {
        nombre_general: nombreGeneral,
        id_proyecto: idProyecto
      });
      dispatch({
        type: leadstypes.CREAR_CONSTANCIA_EXITO,
        payload: response.data.message
      });

      Swal.fire({
        icon: 'success',
        title: 'Éxito',
        text: 'Constancia creada exitosamente',
      });
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Error al agregar archivo-proyecto';
      dispatch({
        type: leadstypes.CREAR_CONSTANCIA_ERROR,
        payload: errorMsg
      });

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMsg,
      });
    }
  };
};
export const listarLeads = (usuario, contacto) => {

  return async dispatch => {
    dispatch({
      type: leadstypes.LISTAR_LEADS
    });
    try {

      const leads = await authAxios.get(
        `/admin/listarLeads/${usuario.id_usuario}/${usuario.id_tp_nivel_usuario}/${contacto ? 1 : 0}`
      );
      //  console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: contacto ? leadstypes.FILTRAR_CONTACTOS : leadstypes.LISTAR_LEADS_EXITO
      });

    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}

export const traerDetallesLead = (id_lead) => {

  return async dispatch => {

    try {

      const respuesta = await authAxios.get(
        `/admin/traerDetallesLead/${id_lead}`
      );

      if (!respuesta.data.error) {
        dispatch({
          type: leadstypes.DETALLE_LEAD_EXITO,
          payload: respuesta.data.lead[0]
        });

        return respuesta.data.lead
      }
      return null

    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}


export const traerRecordatoriosLlamadas = (usuario) => {

  return async dispatch => {
    try {

      const recordatorios = await authAxios.get(
        `/admin/traerRecordatoriosLlamadas/${usuario.id_usuario}`
      );
      //  console.log(leads);

      return recordatorios.data.data


    } catch (error) {
      avisoError('Hubo un error al listar los recordatorios')
    }
  }
}

export const actualizarLlamada = (datosLead) => {
  return async dispatch => {

    try {

      await authAxios.post(
        `/admin/actualizarLlamada/`, datosLead
      );

    } catch (error) {
      // avisoError(error.response.data?.msg)
    }
  }
}


export const actualizarLlamadaAhora = (datosLead) => {
  return async dispatch => {

    try {

      await authAxios.post(
        `/admin/actualizarLlamadaAhora/`, datosLead
      );

      dispatch(traerDetallesLead(datosLead.destino))
      dispatch(updateStateModalBasic())

    } catch (error) {
      // avisoError(error.response.data?.msg)
    }
  }
}

export const actualizarLlamadaYNotasLead = (datosLead) => {
  return async dispatch => {
    try {
      await authAxios.post('/admin/actualizarLlamadaYNotasLead/', datosLead);
      dispatch(traerDetallesLead(datosLead.id_lead));
      dispatch(updateStateModalBasic());
    } catch (error) {
      // Handle the error here, for example:
      console.error("Error updating call and lead notes:", error);
    }
  };
};

export const finalizarRecordatorio = (reminder, registros, contacto) => {
  return async dispatch => {

    try {

      await authAxios.post(`/admin/finalizarRecordatorio`, reminder)
      registros.forEach((lead) => {
        if (lead.id_lead === reminder.destino) {

          lead.llamadas.forEach((llamada) => {
            if (llamada.id_llamada === reminder.id_llamada) {
              llamada.completado = "1"
            }
          })

        }
      })

      dispatch({
        payload: registros,
        type: contacto ? leadstypes.FILTRAR_CONTACTOS : leadstypes.FILTRAR_LEADS
      })


    } catch (error) {
      // avisoError(error.response.data?.msg)
    }
  }
}


export const listarTratos = (usuario) => {
  return async dispatch => {
    dispatch({
      type: leadstypes.LISTAR_LEADS
    });
    try {
      const leads = await authAxios.get(
        `/admin/listarTratos/${usuario.id_usuario}/${usuario.id_tp_nivel_usuario}`
      );
      dispatch({
        payload: leads.data.data,
        type: leadstypes.LISTAR_TRATOS
      });
    } catch (error) {
      avisoError('Hubo un error al listar los tratos')
    }
  }
}


export const filtrarTratosCliente = (datosLeads, usuario) => {
  return async dispatch => {
    dispatch({
      type: leadstypes.LISTAR_LEADS
    });
    try {
      const leads = await authAxios.post(
        `/admin/filtrarTratosCliente/${usuario.id_usuario}/${usuario.id_tp_nivel_usuario}`, datosLeads
      );
      console.log('Datos recibidos:', leads.data.data); // Agrega este console.log para verificar los datos
      dispatch({
        payload: leads.data.data,
        type: leadstypes.LISTAR_TRATOS
      });
    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}

export const filtrarTratos = (datosLeads, usuario) => {
  return async dispatch => {
    dispatch({
      type: leadstypes.LISTAR_LEADS
    });
    try {
      const leads = await authAxios.post(
        `/admin/filtrarTratos/${usuario.id_usuario}/${usuario.id_tp_nivel_usuario}`, datosLeads
      );
      console.log('Datos recibidos:', leads.data.data); // Agrega este console.log para verificar los datos
      dispatch({
        payload: leads.data.data,
        type: leadstypes.LISTAR_TRATOS
      });
    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}


export const filtrarLeads = (datosLeads, usuario, contacto) => {

  return async dispatch => {
    dispatch({
      type: leadstypes.LISTAR_LEADS
    });
    try {

      const leads = await authAxios.post(
        `/admin/filtrarLeads/${usuario.id_usuario}/${usuario.id_tp_nivel_usuario}/${contacto ? 1 : 0}`, datosLeads
      );
      //  console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: contacto ? leadstypes.FILTRAR_CONTACTOS : leadstypes.FILTRAR_LEADS
      });

    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}


export const filtrarLeads2 = (datosLeads, usuario, contacto) => {

  return async dispatch => {
    dispatch({
      type: leadstypes.LISTAR_LEADS
    });
    try {

      const leads = await authAxios.post(
        `/admin/filtrarLeads2/${usuario.id_usuario}/${usuario.id_tp_nivel_usuario}/${contacto ? 1 : 0}`, datosLeads
      );
      //  console.log(leads);

      dispatch({
        payload: leads.data.data,
        type: contacto ? leadstypes.FILTRAR_CONTACTOS : leadstypes.FILTRAR_LEADS
      });

    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}




export const filtrarLlamadas = (datosFiltro) => {

  return async dispatch => {

    try {

      const llamadas = await authAxios.post(
        `/admin/filtrarLlamadas/${datosFiltro.nivel}`, datosFiltro
      );

      console.log(llamadas);
      return llamadas.data


    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}


export const filtrarTratosR = (datosFiltro) => {

  return async dispatch => {

    try {

      const tratos = await authAxios.post(
        `/admin/filtrarTratosR/${datosFiltro.nivel}`, datosFiltro
      );

      // console.log(tratos);
      return tratos.data


    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}

export const guardarPlantillaCorreo = (datosPlantilla) => {

  return async dispatch => {

    try {

      const respuesta = await authAxios.post(
        `/admin/insertarPlantillaCorreo`, datosPlantilla
      );

      if (respuesta.data.id) {
        avisoCorrecto("Plantilla guardada con éxito.")
      }


    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}

export const duplicarPlantillaCorreo = (datosPlantilla, setPlantillas, plantillas) => {

  return async dispatch => {

    try {

      const respuesta = await authAxios.post(
        `/admin/insertarPlantillaCorreo`, datosPlantilla
      );

      if (respuesta.data.id) {

        const nuevoItem = {
          id_plantilla: respuesta.data.id,
          nombre_plantilla: datosPlantilla.nombre_plantilla,
          nombre_proyecto: datosPlantilla.nombre_proyecto,
          producto: datosPlantilla.producto,
          fecha_registro: datosPlantilla.fecha_registro,
          formato_html: datosPlantilla.formato_html,
          formato_json: datosPlantilla.formato_json,
        }
        const nuevoArray = [...plantillas, nuevoItem]
        setPlantillas(nuevoArray)
        avisoCorrecto("Plantilla duplicada con éxito.")
      }


    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}

export const eliminarPlantilla = (id, setPlantillas, plantillas) => {

  return async dispatch => {

    const swalWithBootstrapButtons = Swal.mixin({

    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Eliminar registro?",
        text: "No se podrá recuperar la información",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function () {
          try {

            let correo = await authAxios.delete(`/admin/eliminarPlantillaCorreo/${id}`)
            if (!correo.data.error) {
              const plantillasBackUp = plantillas.filter((plantilla) => { return plantilla.id_plantilla !== id })
              setPlantillas(plantillasBackUp)
              return true
            }

          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.msg
            })
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        // console.log(result)
        if (result.value === true) {

          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "El registro ha sido eliminado correctamente.",
            "success"
          );
          return true
        }
      });
  };
}

export const actualizarPlantillaCorreo = (datosPlantilla) => {

  return async dispatch => {

    try {

      const respuesta = await authAxios.post(
        `/admin/actualizarPlantillaCorreo`, datosPlantilla
      );

      if (!respuesta.error) {
        avisoCorrecto("Plantilla actualizada con éxito.")
      }


    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}
export const traerPlantilla = (id_plantilla) => {

  return async dispatch => {

    try {

      const respuesta = await authAxios.get(
        `/admin/traerPlantillaCorreo/${id_plantilla}`
      );

      return respuesta.data[0]


    } catch (error) {
      avisoError('Hubo un error al listar los comunicados')
    }
  }
}

export const traerPlantillas = () => {

  return async dispatch => {

    try {

      const respuesta = await authAxios.get(
        `/admin/traerPlantillasCorreo`
      );
      // console.log(respuesta)
      return respuesta.data


    } catch (error) {
      avisoError('Hubo un error')
    }
  }
}

export const traerPlantillasProyecto = (id_proyecto) => {

  return async dispatch => {

    try {

      const respuesta = await authAxios.get(
        `/admin/traerPlantillasCorreoProyecto/${id_proyecto}`
      );
      // console.log(respuesta)
      return respuesta.data


    } catch (error) {
      avisoError('Hubo un error')
    }
  }
}


export const asignarLeadsManualmente = (datosLeads, usuario) => {

  return async dispatch => {

    try {

      await authAxios.post(
        `/admin/asignarLeadsManual/`, datosLeads
      );

      // dispatch(listarLeads(usuario,false));
      dispatch(updateStateModalBasic())
    } catch (error) {
      avisoError('Hubo un error al asignar leads')
    }
  }
}
export const registrarClick = (datosClick) => {

  return async dispatch => {

    try {

      await authAxios.post(
        `/admin/controlWhatsApp`, datosClick
      );


    } catch (error) {
      avisoError('Hubo un error al registrar evento')
    }
  }
}


export const enviarCorreoLeadProducto_v2 = datosLead => {

  return async dispatch => {

    const swalWithBootstrapButtons = Swal.mixin({

    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Enviar correo?",
        text: "Recuerda no reenviar correos repetidamente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function () {
          try {

            let correo = await authAxios.post(`/admin/enviarCorreoLeadProducto_v2`, datosLead)
            if (!correo.data.error) {
              return true
            }

          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.msg
            })
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        // console.log(result)
        if (result.value === true) {
          dispatch(updateStateModalLarge())
          swalWithBootstrapButtons.fire(
            "Enviado!",
            "Correo enviado con éxito.",
            "success"
          );

        }
      });
  };

}


export const enviarCorreoLeadProducto = datosLead => {

  return async dispatch => {

    const swalWithBootstrapButtons = Swal.mixin({

    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Enviar correo?",
        text: "Recuerda no reenviar correos repetidamente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function () {
          try {

            let correo = await authAxios.post(`/admin/enviarCorreoLeadProducto`, datosLead)
            if (!correo.data.error) {
              return true
            }

          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.msg
            })
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        // console.log(result)
        if (result.value === true) {

          swalWithBootstrapButtons.fire(
            "Enviado!",
            "Correo enviado con éxito.",
            "success"
          );

        }
      });
  };

}


export const listarAsesores = () => {

  return async dispatch => {
    try {

      const asesores = await authAxios.get(
        `/admin/listarAsesores/`
      );
      //  console.log(leads);

      dispatch({
        payload: asesores.data.data,
        type: leadstypes.LISTAR_ASESORES
      });

    } catch (error) {
      avisoError('Hubo un error al listar los asesores')
    }
  }
}

export const listarFuentes = () => {

  return async dispatch => {
    try {

      const fuentes = await authAxios.get(
        `/admin/listarFuentes/`
      );
      //  console.log(leads);

      dispatch({
        payload: fuentes.data.data,
        type: leadstypes.LISTAR_FUENTES
      });

    } catch (error) {
      avisoError('Hubo un error al listar las fuentes')
    }
  }
}


export const listarEstados = () => {

  return async dispatch => {
    try {

      const estados = await authAxios.get(
        `/admin/listarEstadosLeads/`
      );
      //  console.log(estados);

      dispatch({
        payload: estados.data.data,
        type: leadstypes.LISTAR_ESTADOS_LEAD
      });

    } catch (error) {
      avisoError('Hubo un error al listar los estados')
    }
  }
}

export const listarFases = () => {

  return async dispatch => {
    try {

      const estados = await authAxios.get(
        `/admin/listarFasesTrato/`
      );
      //  console.log(estados);

      dispatch({
        payload: estados.data.data,
        type: leadstypes.LISTAR_FASES
      });

    } catch (error) {
      avisoError('Hubo un error al listar los estados')
    }
  }
}


export const listarObservaciones = () => {

  return async dispatch => {
    try {

      const observaciones = await authAxios.get(
        `/admin/listarObservaciones/`
      );
      //  console.log(observaciones);

      dispatch({
        payload: observaciones.data.data,
        type: leadstypes.LISTAR_OBSERVACIONES_LEAD
      });

    } catch (error) {
      avisoError('Hubo un error al listar los estados')
    }
  }
}

export const reporteDiario = (fecha, usuario) => {

  //console.log(fecha)
  return async dispatch => {

    if (usuario.id_tp_nivel_usuario !== '11') {
      try {

        const reporte = await authAxios.get(
          `/admin/reporteMKT_prueba/${fecha}`
        );
        //  console.log(estados);

        return reporte.data.data

        /*  dispatch({
              payload: estados.data.data,
              type: leadstypes.LISTAR_ESTADOS_LEAD
            });*/

      } catch (error) {
        avisoError('Hubo un error al listar los estados')
      }
    }
    else {

      try {

        const reporte = await authAxios.get(
          `/admin/reporteAsesor_Escalera/${fecha}/${usuario.id_usuario}`
        );
        //  console.log(estados);

        return reporte.data.data

        /*  dispatch({
              payload: estados.data.data,
              type: leadstypes.LISTAR_ESTADOS_LEAD
            });*/

      } catch (error) {
        avisoError('Hubo un error al listar los estados')
      }


    }
  }
}

export const reporteLeadsGeneral = (fecha, usuario) => {

  return async dispatch => {

    if (usuario.id_tp_nivel_usuario !== '11') {
      try {

        const reporte = await authAxios.get(
          `/admin/reporteOrganico/${fecha.proyecto}/${fecha.fechaInicio}/${fecha.fechaFin}`
        );
        //  console.log(estados);

        return reporte.data

        /*  dispatch({
              payload: estados.data.data,
              type: leadstypes.LISTAR_ESTADOS_LEAD
            });*/

      } catch (error) {
        avisoError('Hubo un error al listar los estados')
      }
    }

  }
}

export const getGoogleContacts = (datos) => {

  return async dispatch => {

    try {

      const reporte = await authAxios.post(
        `/admin/getGoogleContacts`, datos
      );
      //  console.log(estados);

      return reporte.data

      /*  dispatch({
            payload: estados.data.data,
            type: leadstypes.LISTAR_ESTADOS_LEAD
          });*/

    } catch (error) {
      avisoError('Hubo un error al listar los estados')
    }


  }
}



export const importarLeads = (archivo, proyecto, asignarAuto, mailChimp, id_usuario, nivel_usuario) => {
  return async dispatch => {
    const file = new FormData();
    file.append('archivo', archivo);
    file.append('proyecto', proyecto);
    file.append("asignar", asignarAuto ? '1' : '0');
    file.append("mailChimp", mailChimp ? '1' : '0');

    const usuario = {
      id_usuario: id_usuario,
      id_tp_nivel_usuario: nivel_usuario
    }

    try {
      avisoCargando('Importando datos, espere por favor...');
      const leads = await authAxios.post(
        `/admin/importarLeads/`, file, { headers: { 'Context-type': 'multipart/form-data' } }
      );

      dispatch({
        payload: leads.data,
        type: leadstypes.IMPORTAR_LEADS_EXITO
      });
      dispatch(listarLeads(usuario, false));
      dispatch(updateStateModalBasic());
    } catch (error) {
      console.log(error);
      avisoError('Hubo un error al importar los leads');
    }
  }
}


export const importarLlamadasDialFire = (archivo) => {

  return async dispatch => {

    const file = new FormData();
    file.append('archivo', archivo);

    try {
      avisoCargando('Importando datos, espere por favor...')
      const leads = await authAxios.post(
        `/admin/importarLlamadasDialFire/`, file, { headers: { 'Context-type': 'multipart/form-data' } }
      );

      avisoCorrecto('Datos procesados correctamente.')
      dispatch(updateStateModalBasic())

    } catch (error) {
      console.log(error)
      avisoError('Hubo un error al importar los leads')
    }
  }
}



export const registrarTrato = (datosLead, leads, estados) => {
  return async dispatch => {

    try {

      await authAxios.post(
        `/admin/nuevoTrato/`, datosLead
      );

      //dispatch(listarLeads(usuario));
      dispatch(updateStateModalBasic())
      avisoCorrecto('Trato registrado correctamente.')

    } catch (error) {
      avisoError(error.response.data?.msg)
    }
  }
}

export const actualizarTrato = (datosLead, leads, estados) => {
  return async dispatch => {

    try {

      await authAxios.post(
        `/admin/actualizarTrato/`, datosLead
      );


      const nestado = estados.filter(estado => estado.id_fase === datosLead.fase)
      leads.forEach((lead) => {
        if (lead.id_trato === datosLead.id_trato) {

          lead.nombre_fase = nestado[0].nombre_fase
          lead.importe = datosLead.importe
          lead.descripcion = datosLead.descripcion
        }
      })
      dispatch({
        payload: leads,
        type: leadstypes.LISTAR_TRATOS
      });

      //dispatch(listarLeads(usuario));
      dispatch(updateStateModalBasic())
      avisoCorrecto('Trato editado correctamente.')

    } catch (error) {
      avisoError(error.response.data?.msg)
    }
  }
}

export const guardarLead = (datosLead, proyectos, leads, estados, observaciones, contacto, detalle = false) => {
  return async dispatch => {

    try {

      await authAxios.post(
        `/admin/registrarLeadSN/`, datosLead
      );

      dispatch(updateStateModalBasic())
      avisoCorrecto('Lead guardado correctamente.')

    } catch (error) {
      avisoError(error.response.data?.msg)
    }
  }
}


export const actualizarEstadoLeads = (leads, nuevoEstado) => {
  return async (dispatch) => {
    try {
      const ids = leads.map((lead) => lead.id_lead);
      await authAxios.post(`/admin/actualizarEstadoLeads`, { ids, nuevoEstado });
      dispatch(listarLeads(/* parámetros necesarios */));
      avisoCorrecto("Estado de leads actualizado correctamente.");
    } catch (error) {
      avisoError("Hubo un error al actualizar el estado de los leads");
    }
  };
};



export const actualizarLead = (datosLead, proyectos, leads, estados, observaciones, contacto, detalle = false) => {
  return async dispatch => {

    try {

      await authAxios.post(
        `/admin/actualizarLead/`, datosLead
      );


      const nestado = estados.filter(estado => estado.id_estado === datosLead.estado)
      const nobservacion = observaciones.filter(observacion => observacion.id_observacion === datosLead.observacion_l)
      const nproyecto = proyectos.filter(proyecto => proyecto.id_proyecto === parseInt(datosLead.producto))
      leads.forEach((lead) => {

        if (lead.id_lead === datosLead.id_lead) {
          //console.log(nproyecto,lead,datosLead)
          lead.notas = datosLead.notas
          lead.fecha_llamada = moment().format('YYYY-MM-DD')
          lead.estado = datosLead.estado
          lead.nombre_estado = nestado[0].nombre_estado
          lead.observacion_n = nobservacion[0].observacion_n
          lead.observaciones = datosLead.observacion_l
          lead.tiempo_llamada = datosLead.tiempo_llamada
          lead.nombres = datosLead.nombres
          lead.apellidos = datosLead.apellidos
          lead.correo = datosLead.correo
          lead.nombre_proyecto = nproyecto[0].nombre_proyecto
          lead.producto = nproyecto[0].id_proyecto
        }
      })
      // console.log(leads)
      if (!contacto) {
        dispatch({
          payload: leads,
          type: leadstypes.LISTAR_LEADS_EXITO
        })
      }
      else {
        dispatch({
          payload: leads,
          type: leadstypes.FILTRAR_CONTACTOS
        })
      }
      //dispatch(listarLeads(usuario));
      if (!detalle)
        dispatch(updateStateModalBasic())
      avisoCorrecto('Registro editado correctamente.')

    } catch (error) {
      avisoError(error.response.data?.msg)
    }
  }
}


export const convertirEnContacto = (datosLead, leads) => {
  return async dispatch => {

    try {

      await authAxios.post(
        `/admin/convertirLead/`, datosLead
      );

      const lds = leads.filter(lead => lead.id_lead !== datosLead.lead)
      dispatch({
        payload: lds,
        type: leadstypes.LISTAR_LEADS_EXITO
      })
      //dispatch(listarLeads(usuario));
      dispatch(updateStateModalBasic())
      avisoCorrecto('Lead convertido correctamente.')

    } catch (error) {
      avisoError(error.response.data?.msg)
    }
  }
}

export const crearLlamada = (datosLead, leads, contacto, detalle) => {
  return async dispatch => {

    try {

      const respuesta = await authAxios.post(
        `/admin/crearLlamada/`, datosLead
      );

      leads.forEach((lead) => {
        if (lead.id_lead === datosLead.id_lead) {
          const llamada = {
            fecha_hora: datosLead.fecha_hora,
            completado: "0",
            asunto: datosLead.asunto,
            proposito_llamada: datosLead.proposito_llamada,
          }
          lead.llamadas = [...lead.llamadas, llamada]
        }
      })
      if (detalle) {
        const registro = {
          llamadas: respuesta.data.datos_llamada[0].llamadas
        }
        dispatch({
          payload: registro,
          type: leadstypes.DETALLE_LEAD_EXITO
        })
      }
      // console.log(leads)

      dispatch({
        payload: 1,
        type: leadstypes.NUEVA_LLAMADA
      })
      //dispatch(listarLeads(usuario));
      dispatch(updateStateModalBasic())
      avisoCorrecto('Llamada programada.')

    } catch (error) {
      avisoError(error.response.data?.msg)
    }
  }
}

export const crearLlamadaAhora = (datosLead, registro) => {
  return async dispatch => {

    try {

      const rpta = await authAxios.post(
        `/admin/crearLlamadaAhora/`, datosLead
      );


      registro.llamadas = rpta.data.datos_llamada[0].llamadas
      dispatch({
        type: leadstypes.DETALLE_LEAD_EXITO,
        payload: registro
      });

      /*     dispatch({
             payload: 1,
             type: leadstypes.NUEVA_LLAMADA
           })*/
      //dispatch(listarLeads(usuario));
      // dispatch(updateStateModalBasic())
      avisoCorrecto('Llamada terminada.')

    } catch (error) {
      avisoError(error.response.data?.msg)
    }
  }
}




export const exportarPosiblesClientes = (datos = [], usuario) => {

  let leads = [];
  return new Promise(async (resolve, reject) => {
    try {
      if (!datos.length) {
        reject({
          exito: false,
          message: 'No se encontraron datos para exportar.'
        });
      }

      leads = datos.map(item => ({
        Zip_Code: item.id_lead,
        First_Name: item.nombres ? item.nombres : '',
        Last_Name: item.apellidos ? item.apellidos : '',
        Mobile: item.telefono ? item.telefono : '',
        Email: item.correo ? item.correo : '',
        Description: item.nombre_proyecto ? item.nombre_proyecto : '',
        Fecha_de_registro: moment().format('YYYY-MM-DD'),
        Observaciones_llamada: item.notas,
        Estado_de_llamada: item.nombre_estado
      }));

      const datosEnviar = {
        data: leads
      }

      await authAxios.post('/servicio/asesor/leads/' + usuario, datosEnviar);
      //console.log(rpta)
      resolve({
        exito: true,
        message: 'Se exporto correctamente.'
      });
    } catch (error) {
      console.log(error);
      reject({
        exito: false,
        message: 'Ocurrió un error inesperado al exportar.'
      });
    }
  });
}