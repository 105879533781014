import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch } from 'react-redux';
import useSelectCampaigns from "../../../hooks/useSelectCampaigns";
import useSelectTiposRecurso from "../../../hooks/useSelectTiposRecurso";
import {insertarAnuncioCampaign} from '../../../actions/campaignsActions';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const FormularioAnuncio = () => {

const [campaign, SelectCampaigns] = useSelectCampaigns(null)
const [tipo_anuncio, setTipoAnuncio] = useState('')
const [nombre_anuncio, setNombreAnuncio]=useState('')
const [objetivo, setObjetivo]=useState('')
const [pagina, setPagina]=useState('')
const [inversion, setInversion]=useState('')
const [publico, setPublico]=useState('')
const [estado, setEstado]=useState('')
const [fechaInicio, setFechaInicio]=useState(null)
const [fechaFin, setFechaFin]=useState(null)
const [fuente, setFuente]=useState('')
const dispatch = useDispatch();

  const handleSubmit = (e) =>{
    e.preventDefault();
    const datosCamp={
      campaign: campaign?campaign:null,
      tipo_anuncio: tipo_anuncio!=='' ? tipo_anuncio :null,
      nombre_anuncio: nombre_anuncio!==''?nombre_anuncio:null,
      inversion: inversion!==''? parseFloat(inversion):null,
      estado: estado!==''?estado:null,
      publico: publico !=='' ? publico : null,
      pagina: pagina !=='' ? pagina : null,
      objetivo: objetivo !=='' ? objetivo : null,
      fecha_inicio: fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null,
      fecha_fin: fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null,
      fuente: fuente!=='' ? fuente :null,
    }
  console.log(datosCamp)
  const retornarListaActualizada = async () =>{
     await (dispatch(insertarAnuncioCampaign(datosCamp)))
    }
  
    
  // console.log(datosPop)
    if(campaign && tipo_anuncio && nombre_anuncio!=='' && estado!=='' )
    { 
      retornarListaActualizada()
    }
    else
    {
      avisoError('Faltan campos por rellenar...')
    }
  }

 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Campaña:
                      </label>
                    </div>
                    <div className="col-md-7">
                        <SelectCampaigns/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Tipo Anuncio:
                      </label>
                    </div>
                    <div className="col-md-7">
                    <select name="tipo_anuncio" className="form-control" value={tipo_anuncio} onChange={(e)=>setTipoAnuncio(e.target.value)} >
                      <option value="">Seleccione una opción</option>
                      <option value="1">Google</option>
                      <option value="2">Facebook</option>
                    </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Nombre Anuncio:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="nombre_recurso" value={nombre_anuncio} onChange={(e)=>setNombreAnuncio(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Inversión:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="inversion" value={inversion} onChange={(e)=>setInversion(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Objetivo:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="objetivo" value={objetivo} onChange={(e)=>setObjetivo(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Público:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="publico" value={publico} onChange={(e)=>setPublico(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Página:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="pagina" value={pagina} onChange={(e)=>setPagina(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Fecha inicio:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <DatePicker
                        selected={fechaInicio}
                        onChange={(date) =>setFechaInicio(date)}
                        
                        dateFormat="dd/MM/yyyy"
                        
                        name="fechaFiltro"
                      // startDate={formik.values.fechaInicio}
                        className="form-control"
                      
                      // endDate={formik.values.fechaFin}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                       Estado:
                      </label>
                    </div>
                    <div className="col-md-7">
                    <select name="estado" className="form-control" value={estado} onChange={(e)=>setEstado(e.target.value)} >
                      <option value="">Seleccione una opción</option>
                      <option value="1">Activo</option>
                      <option value="2">Inactivo</option>
                    </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                       Fuente de anuncio:
                      </label>
                    </div>
                    <div className="col-md-7">
                    <select name="fuente" className="form-control" value={fuente} onChange={(e)=>setFuente(e.target.value)} >
                      <option value="">Seleccione una opción</option>
                      <option value="1">Facebook ADS Form</option>
                      <option value="2">Facebook ADS Google Form</option>
                      <option value="3">Google ADS</option>
                      <option value="4">Webinar</option>
                      <option value="5">Landing Page</option>
                      <option value="6">LinkedIn</option>
                      <option value="7">Cartera</option>
                      <option value="8">LinkedIn ADS</option>
                      <option value="9">WhatsApp</option>
                      <option value="10">TikTok ADS</option>
                      <option value="11">Aliados</option>
                      <option value="12">Webinar automático</option>
                      <option value="13">Registro en Plataforma</option>
                      <option value="14">Registro de Marketing</option>
                    </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default FormularioAnuncio;
