import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportarPosiblesClientes, filtrarLeads2, listarLeads } from '../../../actions/leadsActions';
import useSelectEstadosLeads from "../../../hooks/useSelectEstadosLeads";
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { avisoCorrecto, avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import useSelectFuenteMulti from '../../../hooks/useSelectFuenteMulti';
import Swal from 'sweetalert2';

const style = {
  boton: {
    background: "#28314A",
    color: "#fff"
  },
  buttonText: {
    fontSize: "13px",
    background: "#28314A",
    color: '#fff'
  },
  textArchivo: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#aaa"
  }
}

const FormularioFiltroAsesor = ({ contacto = false }) => {
  const dispatch = useDispatch();
  const { usuario } = useSelector(state => state.usuario);
  const { leads, filtrado } = useSelector(state => state.leads);

  const [estado, SelectEstado, setEstado] = useSelectEstadosLeads(null);
  const [proyecto, SelectProyecto, setProyecto] = useSelectProyecto(null);
  const [fechaDesde, setFechaDesde] = useState(null); // Cambiado a "Desde:"
  const [fechaHasta, setFechaHasta] = useState(null); // Cambiado a "Hasta:"
  const [fechaLlamada, setFechaLlamada] = useState(null); // Fecha Llamada
  const [fuente, SelectFuente, setFuente] = useSelectFuenteMulti(null);
  const [checkOmitir, setCheckOmitir] = useState(false);

  useEffect(() => {
    const savedEstado = localStorage.getItem('estado');
    const savedProyecto = localStorage.getItem('proyecto');
    const savedFechaDesde = localStorage.getItem('fechaDesde'); // Guardar "Desde:"
    const savedFechaHasta = localStorage.getItem('fechaHasta'); // Guardar "Hasta:"
    const savedFechaLlamada = localStorage.getItem('fechaLlamada'); // Guardar "Fecha Llamada"
    const savedFuente = localStorage.getItem('fuente');
    const savedCheckOmitir = localStorage.getItem('checkOmitir');

    if (savedEstado) setEstado(JSON.parse(savedEstado));
    if (savedProyecto) setProyecto(JSON.parse(savedProyecto));
    if (savedFechaDesde) setFechaDesde(new Date(savedFechaDesde));
    if (savedFechaHasta) setFechaHasta(new Date(savedFechaHasta));
    if (savedFechaLlamada) setFechaLlamada(new Date(savedFechaLlamada));
    if (savedFuente) setFuente(JSON.parse(savedFuente));
    if (savedCheckOmitir) setCheckOmitir(JSON.parse(savedCheckOmitir));
  }, []);

  useEffect(() => {
    localStorage.setItem('estado', JSON.stringify(estado));
    localStorage.setItem('proyecto', JSON.stringify(proyecto));
    if (fechaDesde) localStorage.setItem('fechaDesde', fechaDesde.toISOString());
    if (fechaHasta) localStorage.setItem('fechaHasta', fechaHasta.toISOString());
    if (fechaLlamada) localStorage.setItem('fechaLlamada', fechaLlamada.toISOString());
    localStorage.setItem('fuente', JSON.stringify(fuente));
    localStorage.setItem('checkOmitir', JSON.stringify(checkOmitir));
  }, [estado, proyecto, fechaDesde, fechaHasta, fechaLlamada, fuente, checkOmitir]);

  const handleExportar = async () => {
    const respuesta = await exportarPosiblesClientes(leads, usuario.id_usuario);
    if (respuesta.exito) {
      avisoCorrecto(respuesta.message);
    } else {
      avisoError(respuesta.message);
    }
  };

  const traerDatos = () => {
    if ((fechaDesde && fechaHasta) && fechaLlamada) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No puedes aplicar filtros por Fecha de Registro y Fecha de Llamada al mismo tiempo.',
      });
      return;
    }

    if (!estado && !proyecto && (!fechaDesde || fechaDesde === '') && !fechaHasta && !fechaLlamada && (!fuente || fuente === '') && !checkOmitir) {
      dispatch(listarLeads(usuario, contacto));
    } else {
      const datosFiltro = {
        estado: estado,
        fechaDesde: fechaDesde ? moment(fechaDesde).format('YYYY-MM-DD') : null,
        fechaHasta: fechaHasta ? moment(fechaHasta).format('YYYY-MM-DD') : null,
        fechaLlamada: fechaLlamada ? moment(fechaLlamada).format('YYYY-MM-DD') : null,
        proyecto: proyecto ? proyecto : null,
        fuente: fuente !== '' ? fuente : null,
        checkOmitir: checkOmitir
      };
      dispatch(filtrarLeads2(datosFiltro, usuario, contacto));
    }
  };

  return (
    <div className="box">
      <div className="box-header with-border">
        <h5 style={{ color: "#434343" }}>
          <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
        </h5>
      </div>
      <div className="row p-2">
        <div className="col-5 mb-2">
          <label htmlFor="">Estado:</label>
          <SelectEstado />
        </div>
        <div className="col-7 mb-2">
          <label htmlFor="">Producto:</label>
          <SelectProyecto />
        </div>
        <div className="col-4 mb-2">
          <label htmlFor="">Fuente:</label>
          <SelectFuente />
        </div>
        <div className="col-4">
          <label htmlFor="">Desde (Fecha Registro):</label>
          <DatePicker
            selected={fechaDesde}
            onChange={(date) => setFechaDesde(date)}
            selectsStart
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            name="fechaDesde"
            className="form-control"
          />
        </div>
        <div className="col-4">
          <label htmlFor="">Hasta (Fecha Registro):</label>
          <DatePicker
            selected={fechaHasta}
            onChange={(date) => setFechaHasta(date)}
            selectsStart
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            name="fechaHasta"
            className="form-control"
          />
        </div>
        <div className="col-4">
          <label htmlFor="">Fecha Llamada:</label>
          <DatePicker
            selected={fechaLlamada}
            onChange={(date) => setFechaLlamada(date)}
            selectsStart
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            name="fechaLlamada"
            className="form-control"
          />
        </div>
        <div className="col-12 pt-2">
          <button className="btn btn-primary" onClick={traerDatos} >Consultar</button>
        </div>
      </div>
    </div>
  );
};

export default FormularioFiltroAsesor;
