import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faListAlt, faMailBulk, faClipboard, faSearch, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import DataTable from 'react-data-table-component';
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalBasic, updateStateModalLarge } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
import FormularioLead from "./FormularioLead";
import FormularioFiltro from "./FormularioFiltro";
import FormularioConversion from "./FormularioConversion";
import FormularioNuevaLlamada from "./FormularioNuevaLlamada";
import "./Trabajadores.scss";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { listarEstados, listarFases, listarObservaciones, registrarClick, filtrarLeads } from "../../../actions/leadsActions";
import { Badge, Modal, Button, Form } from "react-bootstrap";
import EditarLead from "./EditarLead";
import FormularioAsignacionManual from "./FormularioAsignacionManual";
import FormularioFiltroAsesor from "./FormularioFiltroAsesor";
import ResultadosImportacion from "./ResultadosImportacion";
import * as moment from "moment";
import { Link } from "react-router-dom";
import FormularioDatosCorreo from "./FormularioDatosCorreo";
import ModalLargewhithRedux from "../../../containers/ModalLargewhithRedux";
import FormularioNuevoLead from "./FormularioNuevoLead";
import DescargaGoogleContactAsesor from "./ReporteDiario/DescargaGoogleContactAsesor";
import FormularioLeadsDialFire from "./FormularioLeadsDialFire";
import FormularioCambioEstado from "./components/FormularioCambioEstado";

const ListaLeads = () => {
  const dispatch = useDispatch();
  const { leads, loading, respuesta_importacion } = useSelector((state) => state.leads);
  const { usuario } = useSelector((state) => state.usuario);
  const [busqueda, setBusqueda] = useState("");
  const [asignarSeleccion, setSeleccion] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [showModal, setShowModal] = useState(false);

  const nivel_usu = [11, 13];
  const handleChange = (e) => {
    setBusqueda(e.target.value);
  };

  useEffect(() => {
    dispatch(listarEstados());
    dispatch(listarFases());
    dispatch(listarObservaciones());

    const savedVisibility = localStorage.getItem('columnVisibility');
    if (savedVisibility) {
      setColumnVisibility(JSON.parse(savedVisibility));
    }

    // Ejecución de la función de filtrado al cargar la página
    const savedFilters = localStorage.getItem('filters');
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      dispatch(filtrarLeads(parsedFilters, usuario));
    }
  }, [dispatch, usuario]);

  const exportToExcel = () => {
    // Filtrar columnas visibles
    const visibleColumns = columns(handleEditarRegistro, handleRegistrarClick, handleEnviarCorreo, formatTiempo, handleConversion, handleCrearLlamada)
      .filter(column => !columnVisibility[column.selector]);

    // Crear el formato de datos para Excel
    const exportData = leadsBusqueda.map(lead => {
      const rowData = {};
      visibleColumns.forEach(column => {
        rowData[column.name] = lead[column.selector];
      });
      return rowData;
    });

    // Generar el archivo Excel
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads Filtrados");

    // Guardar el archivo
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, `Leads_${new Date().toISOString().slice(0, 10)}.xlsx`);
  };



  const formatTiempo = (seconds) => {
    let divisor_for_minutes = seconds % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let secs = Math.ceil(divisor_for_seconds);

    return `${minutes < 10 ? "0" + minutes : minutes}:${secs < 10 ? "0" + secs : secs}`;
  };

  const handleModalNuevo = () => {
    dispatch(updateStateModalBasic(<FormularioLead />, "Importar Leads"));
  };

  const handleNuevoLead = () => {
    dispatch(updateStateModalBasic(<FormularioNuevoLead />, "Nuevo lead"));
  };

  const handleDescargarLeads = () => {
    dispatch(updateStateModalLarge(<DescargaGoogleContactAsesor />, "Descargar"));
  };

  const handleEditarRegistro = (registro) => {
    dispatch(updateStateModalBasic(<EditarLead registro={registro} />, "Editar Lead"));
  };

  const handleConversion = (registro) => {
    dispatch(updateStateModalBasic(<FormularioConversion registro={registro} />, "Convertir"));
  };

  const handleCrearLlamada = (registro) => {
    dispatch(updateStateModalBasic(<FormularioNuevaLlamada registro={registro} registros={leads} />, "Programar llamada"));
  };

  const handleEnviarCorreo = (registro) => {
    setTimeout(() => {
      dispatch(updateStateModalLarge(<FormularioDatosCorreo registro={registro} />, "Enviar correo"));
    }, 100);
  };

  const handleRegistrarClick = (registro, tipo) => {
    const datos = {
      autor: usuario.id_usuario,
      id_lead: registro.id_lead,
      tipo: tipo,
    };

    dispatch(registrarClick(datos));
  };

  const handleAsignarManualmente = () => {
    dispatch(updateStateModalBasic(<FormularioAsignacionManual leads={asignarSeleccion.selectedRows} resetSeleccion={setSeleccion} />, "Asignar Leads"));
  };

  const handleImportarDialFire = () => {
    dispatch(updateStateModalBasic(<FormularioLeadsDialFire />, "Importar llamadas"));
  };

  const handleCambiarEstadoGlobal = () => {
    if (asignarSeleccion && asignarSeleccion.selectedRows.length > 0) {
      dispatch(updateStateModalBasic(<FormularioCambioEstado leads={asignarSeleccion.selectedRows} resetSeleccion={setSeleccion} />, "Cambiar Estado"));
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No hay LEDs seleccionados',
        text: 'Por favor, seleccione al menos un LED para cambiar su estado.',
      });
    }
  };

  const handleCopyAllVisiblePhones = () => {
    if (asignarSeleccion && asignarSeleccion.selectedRows.length > 0) {
      // Obtener los teléfonos de las filas seleccionadas
      const selectedPhones = asignarSeleccion.selectedRows.map(lead => lead.telefono).join('\n');
      
      // Copiar los números al portapapeles
      navigator.clipboard.writeText(selectedPhones).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Teléfonos copiados!',
          text: `Se han copiado ${asignarSeleccion.selectedRows.length} teléfonos.`,
          timer: 1500,
          showConfirmButton: false,
        });
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un problema al copiar los números.',
          timer: 1500,
          showConfirmButton: false,
        });
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No hay filas seleccionadas',
        text: 'Por favor, seleccione al menos una fila para copiar los números de teléfono.',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };
  


  const leadsBusqueda = leads
    ? leads.filter(
      (lead) =>
        (lead.nombres && lead.nombres.toLowerCase().includes(busqueda.toLowerCase())) ||
        (lead.apellidos && lead.apellidos.toLowerCase().includes(busqueda.toLowerCase())) ||
        (lead.correo && lead.correo.toLowerCase().includes(busqueda.toLowerCase())) ||
        (lead.telefono && lead.telefono.toLowerCase().includes(busqueda.toLowerCase())) ||
        (lead.observacion_n && lead.observacion_n.toLowerCase().includes(busqueda.toLowerCase())) ||
        (lead.notas && lead.notas.toLowerCase().includes(busqueda.toLowerCase())) ||
        (lead.nombre_estado && lead.nombre_estado.toLowerCase().includes(busqueda.toLowerCase())) ||
        (lead.nombre_proyecto && lead.nombre_proyecto.toLowerCase().includes(busqueda.toLowerCase())) ||
        (lead.historial && lead.historial.toLowerCase().includes(busqueda.toLowerCase()))
    )
    : null;

  const toggleColumn = (columnName) => {
    const updatedVisibility = { ...columnVisibility, [columnName]: !columnVisibility[columnName] };
    setColumnVisibility(updatedVisibility);
    localStorage.setItem('columnVisibility', JSON.stringify(updatedVisibility));
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Leads" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Leads" },
              ]}
            />
            <div className="row">
              <div className="col-md-6 d-flex flex-row">
                {!nivel_usu.includes(parseInt(usuario.id_tp_nivel_usuario)) ? (
                  <button className="btn btn-primary mb-2" onClick={handleModalNuevo}>Importar Leads</button>
                ) : ""}
                {usuario.id_tp_nivel_usuario !== "11" && asignarSeleccion && asignarSeleccion.selectedCount > 0 ? (
                  <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Acciones de Selección
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" onClick={handleAsignarManualmente}>Asignar Manualmente</a>
                    </div>
                  </div>
                ) : ""}
              </div>
            </div>
            <div className="row">
              {usuario.id_tp_nivel_usuario !== "11" ? (
                <div className="col-md-6">
                  <FormularioFiltro />
                </div>
              ) : (
                <div className="col-md-6">
                  <FormularioFiltroAsesor />
                </div>
              )}
              {respuesta_importacion ? (
                <div className="col-md-6">
                  <ResultadosImportacion resultados={respuesta_importacion} />
                </div>
              ) : ""}
            </div>
          </div>
          <div className="Trabajadores__body container-fluid px-0 content-actions mt-1">
            {usuario.id_tp_nivel_usuario === "11" ||
              usuario.id_tp_nivel_usuario === "13" ||
              usuario.id_tp_nivel_usuario === "1" ||
              usuario.id_tp_nivel_usuario === "12" ? (
              <div className="d-flex justify-content-end align-items-end">
                <button className="btn btn-primary" onClick={handleShowModal}>Columnas</button>
                <button className="btn btn-primary" onClick={handleNuevoLead}>Nuevo lead</button>
                <button className="btn btn-primary" onClick={exportToExcel}>Descargar Excel</button>
                <button className="btn btn-primary" onClick={handleCambiarEstadoGlobal}>Cambiar Estado</button>
                <button className="btn btn-primary" onClick={handleCopyAllVisiblePhones}>Copiar Teléfonos</button>

                <div className="dropdown">
                  <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Acciones DialFire
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={handleImportarDialFire}>Importar DialFire</a>
                  </div>
                </div>
              </div>
            ) : null}
            <ModalBasicwithRedux />
            <ModalLargewhithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border clearfix">
                  <h5 style={{ color: "#434343" }}><FontAwesomeIcon icon={faListAlt} /> Lista de Leads</h5>
                  <div className="col-3 d-flex align-items-center pull-right">
                    <input className="form-control" value={busqueda} onChange={handleChange} style={{ marginRight: "5px" }} placeholder="Filtrar..." />
                    <FontAwesomeIcon icon={faSearch} size="lg" />
                  </div>
                </div>
                <div className="box-primary">
                  <DataTable
                    columns={columns(handleEditarRegistro, handleRegistrarClick, handleEnviarCorreo, formatTiempo, handleConversion, handleCrearLlamada).map(column => ({
                      ...column,
                      omit: columnVisibility[column.selector]
                    }))}
                    data={leadsBusqueda}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 65, 75, 85, 100, 200]}
                    selectableRows
                    selectableRowsVisibleOnly
                    onSelectedRowsChange={setSeleccion}
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} className="modal-columnas">
        <Modal.Header closeButton>
          <Modal.Title>Configuración de Columnas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {columns(handleEditarRegistro, handleRegistrarClick, handleEnviarCorreo, formatTiempo, handleConversion, handleCrearLlamada).map(column => (
              <Form.Check
                key={column.selector}
                type="switch"
                id={column.selector}
                label={column.name}
                checked={!columnVisibility[column.selector]}
                onChange={() => toggleColumn(column.selector)}
                className="mb-2"
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default ListaLeads;

/**====================== TABLA =========== */

const columns = (
  handleEditarRegistro,
  handleRegistrarClick,
  handleEnviarCorreo,
  formatTiempo,
  handleConversion,
  handleCrearLlamada
) => [
    {
      name: "Fuente",
      selector: "nombre_fuente",
      className: "fuente",
      width: "130px",
      wrap: true,
    },
    {
      name: "Fecha Registro",
      selector: "fecha_registro_lead",
      align: "center",
      className: "fecha_registro_lead",
      sortable: true,
      width: "150px",
      compact: true,
    },
    {
      name: "Nombres",
      selector: "nombres",
      align: "center",
      sortable: true,
      className: "nombres",
      compact: true,
      wrap: true,
      width: "100px",
    },
    {
      name: "Apellidos",
      selector: "apellidos",
      align: "center",
      sortable: true,
      className: "apellidos",
      compact: true,
      wrap: true,
      width: "100px",
    },
    {
      name: "Correo",
      selector: "correo",
      align: "center",
      className: "correo",
      compact: true,
      wrap: true,
    },
    {
      name: "Teléfono",
      selector: "telefono",
      align: "center",
      className: "telefono",
      compact: true,
      wrap: true,
    },
    {
      name: "Llamada/WhatsApp",
      selector: "acciones_llamada",
      align: "center",
      className: "telefono",
      width: "200px", // Ajustar el ancho para acomodar el nuevo icono
      cell: (record) => (
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-info mr-1 btn-xs icon-btn"
            onClick={() => handleEnviarCorreo(record)}
            type="button"
            title="Enviar Correo"
          >
            <FontAwesomeIcon icon={faMailBulk} className="icon" />
          </button>
          <button
            className="btn btn-secondary btn-xs icon-btn"
            onClick={() => {
              handleCopyPhoneNumber(record.telefono);
              handleRegistrarClick(record, 1);
            }}
            type="button"
            title="Copiar Número"
          >
            <FontAwesomeIcon icon={faClipboard} className="icon" />
          </button>
        </div>
      ),
    },

    {
      name: "Acciones",
      selector: "Acciones",
      align: "center",
      width: "80px",
      className: "Acciones",
      sortable: false,
      cell: (record) => (
        <p>
          <Link className="btn btn-warning btn-xs" to={`detalleRegistro/${record.id_lead}`}>
            <FontAwesomeIcon icon={faEdit} />
          </Link>
        </p>
      ),
    },
    {
      name: "Producto",
      selector: "nombre_proyecto",
      align: "center",
      className: "proyecto",
      sortable: true,
      wrap: true,
    },
    {
      name: "Estado",
      selector: "nombre_estado",
      align: "center",
      className: "estado",
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Asesor",
      selector: "nombre_usuario",
      align: "center",
      className: "nombre_asesor",
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Notas",
      selector: "notas",
      align: "center",
      className: "notas",
      sortable: true,
      wrap: true,
    },
    {
      name: "Historial",
      selector: "historial",
      align: "center",
      className: "historial",
      sortable: true,
    },
    {
      name: "Observaciones",
      selector: "observacion_n",
      align: "center",
      className: "notas",
      sortable: true,
      wrap: true,
    },
    {
      name: "Fecha Llamada",
      selector: "fecha_llamada",
      align: "center",
      className: "proyecto",
      sortable: true,
      width: "80px",
    },
    {
      name: "Duración de llamada",
      selector: "tiempo_llamada",
      align: "center",
      className: "proyecto",
      sortable: true,
      width: "80px",
      cell: (record) => <p>{formatTiempo(record.tiempo_llamada)}</p>,
    },
    {
      name: "Enviado a DialFire",
      selector: "fecha_dialfire",
      align: "center",
      className: "fecha_dialfire",
      sortable: true,
      width: "80px",
    },
  ];

const handleCopyPhoneNumber = (phoneNumber) => {
  navigator.clipboard.writeText(phoneNumber).then(() => {
    Swal.fire({
      icon: 'success',
      title: 'Número copiado!',
      text: `El número ${phoneNumber} ha sido copiado al portapapeles.`,
      timer: 1500,
      showConfirmButton: false,
    });
  }).catch(err => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Hubo un problema al copiar el número.',
      timer: 1500,
      showConfirmButton: false,
    });
  });
};
