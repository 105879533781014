
import {
    CLOSE_MODAL_LARGE,
    LISTAR_CONTRATOS_P,
    LISTAR_CONTRATOS_P_EXITO,
    LISTAR_INFO_EMPRESA, LISTAR_OFERTAS, LISTAR_OFERTAS_EXITO, LISTAR_PROVEEDORES, LISTAR_PROVEEDORES_EXITO
} from './types';
import authAxios from '../config/authAxios';
import { avisoCorrecto, avisoError } from '../view/components/AvisosMensajes/MensajesSwalf';
import Swal from 'sweetalert2';


export const listarInfoEmpresa = () => async dispatch => {
    const respuesta = await authAxios.get(`/admin/inst_listarDatosEmpresa`)

    dispatch({
        payload: respuesta.data,
        type: LISTAR_INFO_EMPRESA
    })
} 


export const listarProveedores = () => async dispatch => {
    dispatch({
            type:LISTAR_PROVEEDORES,
            })
    
    try {
        const respuesta = await authAxios.get(`/admin/listarProveedores`)

        if(!respuesta.data.error)
        {
          
            dispatch({
                type:LISTAR_PROVEEDORES_EXITO,
                payload: respuesta.data.proveedores
                })

            return respuesta.data.proveedores
        }

        return []
    } catch (error) {
        return []
    }
 
   
} 

export const traerSelectProveedores = () => async dispatch => {
    
    try {
        const respuesta = await authAxios.get(`/admin/listarProveedores`)

        if(!respuesta.data.error)
        {
            return respuesta.data.proveedores
        }

        return []
    } catch (error) {
        return []
    }
 
   
} 

export const listarContratosP = () => async dispatch => {
    dispatch({
            type:LISTAR_CONTRATOS_P,
            })
    
    try {
        const respuesta = await authAxios.get(`/admin/listarContratosP`)

        if(!respuesta.data.error)
        {
          
            dispatch({
                type:LISTAR_CONTRATOS_P_EXITO,
                payload: respuesta.data.contratos
                })
        }


    } catch (error) {

    }
 
   
} 


export const listarEmpleos = (id_usuario) => async dispatch => {
    dispatch({
            type:LISTAR_OFERTAS,
            })
    
    try {
        const respuesta = await authAxios.get(`/admin/listarEmpleosAdmin/${id_usuario}`)

        if(!respuesta.data.error)
        {
          
            dispatch({
                type:LISTAR_OFERTAS_EXITO,
                payload: respuesta.data.empleos
                })
        }


    } catch (error) {

    }
 
   
} 
export const nuevoEmpleo = (datos) => async dispatch => {

    try {
        const respuesta = await authAxios.post(`/admin/nuevoEmpleo`,datos)

        if(!respuesta.data.error)
        {  
            dispatch({
                type: CLOSE_MODAL_LARGE
              })

            dispatch({
                    type:LISTAR_OFERTAS_EXITO,
                    payload: respuesta.data.empleos
                    })
        }
        else
        {
            avisoError(respuesta.data.error)
        }
    } catch (error) {
        console.log(error)
    }
   
} 

export const nuevoContratoP = (archivo,datos,responsable) => async dispatch => {

    try {

        const file = new FormData();
        file.append('archivo', archivo);
        file.append('razon_contrato', datos.razon_contrato);
        file.append('proveedor', datos.proveedor);
        file.append('id_usuario', responsable);

        const respuesta = await authAxios.post(`/admin/nuevoContratoP`,file,{headers:{'Context-type':'multipart/form-data'}})

        if(!respuesta.data.error)
        {  
            dispatch({
                type: CLOSE_MODAL_LARGE
              })

            dispatch({
            type:LISTAR_CONTRATOS_P_EXITO,
            payload: respuesta.data.contratos
            })
        }
        else
        {
            avisoError(respuesta.data.error)
        }
    } catch (error) {
        console.log(error)
    }
   
} 



export const nuevoProveedor = (datos,archivo) => async dispatch => {

    const datos_file = new FormData();
    datos_file.append('archivo',archivo)
    Object.keys(datos).forEach(key => datos_file.append(key, datos[key]));

    try {
        const respuesta = await authAxios.post(`/admin/nuevoProveedor`,datos_file)

        if(!respuesta.data.error)
        {  
            dispatch({
                type: CLOSE_MODAL_LARGE
              })

            dispatch({
            type:LISTAR_PROVEEDORES_EXITO,
            payload: respuesta.data.proveedores
            })
        }
        else
        {
            avisoError(respuesta.data.error)
        }
    } catch (error) {
        console.log(error)
    }
   
} 

export const editarProveedor = (datos) => async dispatch => {

    try {
        const respuesta = await authAxios.post(`/admin/editarProveedor`,datos)
        
        if(!respuesta.data.error)
        {  
            dispatch({
                type: CLOSE_MODAL_LARGE
              })

            dispatch({
                    type:LISTAR_PROVEEDORES_EXITO,
                    payload: respuesta.data.proveedores
                    })
        }
        else
        {
            avisoError(respuesta.data.error)
        }
    } catch (error) {
        console.log(error)
    }
   
}

export const editarEmpleo = (datos) => async dispatch => {

    try {
        const respuesta = await authAxios.post(`/admin/editarEmpleo`,datos)
        
        if(!respuesta.data.error)
        {  
            dispatch({
                type: CLOSE_MODAL_LARGE
              })

            dispatch({
                    type:LISTAR_OFERTAS_EXITO,
                    payload: respuesta.data.empleos
                    })
        }
        else
        {
            avisoError(respuesta.data.error)
        }
    } catch (error) {
        console.log(error)
    }
   
} 


export const eliminarContratoP = (id_contrato) => {

    return async dispatch => {
        const swalWithBootstrapButtons = Swal.mixin({
          });
          swalWithBootstrapButtons
          .fire({
              title: "¿Estas seguro?",
              text: "No se podrá recuperar la información.",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Si, continuar!",
              cancelButtonText: "No, cancelar!",
              reverseButtons: false,
              showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
              cancelButtonColor: "#d33",
              preConfirm: async function() {
              
                try {
                    const respuesta = await authAxios.delete(`/admin/deleteContratoP/${id_contrato}`)
            
                    if(!respuesta.data.error)
                    {  
            
                    dispatch({
                            type:LISTAR_CONTRATOS_P_EXITO,
                            payload: respuesta.data.contratos
                            })
                    return true
                    }
                    else
                    {
                     return false
                    }
                } catch (error) {
                    console.log(error)
                }
              },
              allowOutsideClick: () => !Swal.isLoading()
          })
          .then(result => {
              if (result.value === true) {
                swalWithBootstrapButtons.fire(
                    "Eliminado!",
                    "Se eliminó el registro correctamente",
                    "success"
                );
              }
          });


        }
   
} 


export const eliminarProveedor = (id_proveedor) => {

    return async dispatch => {
        const swalWithBootstrapButtons = Swal.mixin({
          });
          swalWithBootstrapButtons
          .fire({
              title: "¿Estas seguro?",
              text: "No se podrá recuperar la información.",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Si, continuar!",
              cancelButtonText: "No, cancelar!",
              reverseButtons: false,
              showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
              cancelButtonColor: "#d33",
              preConfirm: async function() {
              
                try {
                    const respuesta = await authAxios.delete(`/admin/deleteProveedor/${id_proveedor}`)
            
                    if(!respuesta.data.error)
                    {  
            
                    dispatch({
                            type:LISTAR_PROVEEDORES_EXITO,
                            payload: respuesta.data.proveedores
                            })
                    return true
                    }
                    else
                    {
                     return false
                    }
                } catch (error) {
                    console.log(error)
                }
              },
              allowOutsideClick: () => !Swal.isLoading()
          })
          .then(result => {
              if (result.value === true) {
                swalWithBootstrapButtons.fire(
                    "Eliminado!",
                    "Se eliminó el registro correctamente",
                    "success"
                );
              }else{
                swalWithBootstrapButtons.fire(
                    "Error!",
                    "No se pudo eliminar el registro",
                    "error"
                );
              }
          });


        }
   
} 

export const eliminarEmpleo = (id_trabajo) => {

    return async dispatch => {
        const swalWithBootstrapButtons = Swal.mixin({
          });
          swalWithBootstrapButtons
          .fire({
              title: "¿Estas seguro?",
              text: "No se podrá recuperar la información.",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Si, continuar!",
              cancelButtonText: "No, cancelar!",
              reverseButtons: false,
              showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
              cancelButtonColor: "#d33",
              preConfirm: async function() {
              
                try {
                    const respuesta = await authAxios.delete(`/admin/deleteEmpleo/${id_trabajo}`)
            
                    if(!respuesta.data.error)
                    {  
            
                    dispatch({
                            type:LISTAR_OFERTAS_EXITO,
                            payload: respuesta.data.empleos
                            })
                    return true
                    }
                    else
                    {
                     return false
                    }
                } catch (error) {
                    console.log(error)
                }
              },
              allowOutsideClick: () => !Swal.isLoading()
          })
          .then(result => {
              if (result.value === true) {
                swalWithBootstrapButtons.fire(
                    "Eliminado!",
                    "Se eliminó el registro correctamente",
                    "success"
                );
              }else{
                swalWithBootstrapButtons.fire(
                    "Error!",
                    "No se pudo eliminar el registro",
                    "error"
                );
              }
          });


        }
   
} 