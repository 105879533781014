import {
  OBTENER_CAPACITACIONES_CONTROL,
  OBTENER_CAPACITACIONES_CONTROL_EXITO,
  GUARDAR_CAPACITACION_ACTIVO,
  OBTENER_INFO_CONTROL_EXITO,
  OBTENER_INFO_CONTROL,
  AGREGAR_DOCENTE_CAPACITACION_EXITO,
  ELIMINAR_DOCENTE_CAPACITACION_EXITO,
  SESION_ACTIVA,
  GUARDAR_PONENTE_TENTATIVO_EXITO,
  GUARDAR_PONENTE_TENTATIVO_CONFIRMADO_EXITO,
  NUEVA_IMAGEN_EXITO,
  NUEVA_EVIDENCIA_EXITO,
  ELIMINAR_IMAGEN_CONFIRMACION_EXITO,
  ELIMINAR_EVIDENCIA_CONFIRMACION_EXITO,
  CHANGE_VALUES,
  GUARDAR_DATOS_FORM,
  DOCENTE_ELIMINADO_TENTATIVO_CONFIRMADO_EXITO,
  CAMBIAR_ESTADO_DOCENTE_EXITO,
  ACTUALIZAR_ESTADO_INDICADOR_CONTROL,
  ACTUALIZAR_ESTADO_AJUSTE_CONTROL,
  OBTENER_DESEMPENO_DOCENTE_EXITO,
  OBTENER_DETALLES_SESION_EXITO,
  OBTENER_DETALLES_SESION_ERROR,
  AGREGAR_LINK_DRIVE_EXITO,
  AGREGAR_LINK_DRIVE_ERROR
} from '../actions/types';

const initialState = {
  capacitaciones: {
    todos: [],
    activo: {},
    loading: null,
    error: null
  },
  area_trabajo: {
    datos: {},
    activo: null,
    loading: null,
    error: null
  },
  desempenoDocentes: {}, // <--- Inicializar aquí
  detallesSesion: null,
  linkDriveAgregado: null,
  errorLinkDrive: null,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case OBTENER_CAPACITACIONES_CONTROL:
      return {
        ...state,
        capacitaciones: {
          ...state.capacitaciones,
          loading: true,
          error: null
        }
      }
    case OBTENER_CAPACITACIONES_CONTROL_EXITO:
      return {
        ...state,
        capacitaciones: {
          ...state.capacitaciones,
          loading: null,
          error: null,
          todos: action.payload
        }
      }
    case GUARDAR_CAPACITACION_ACTIVO:
      return {
        ...state,
        capacitaciones: {
          ...state.capacitaciones,
          activo: action.payload
        }
      }
    case OBTENER_INFO_CONTROL:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          loading: true,
          error: null
        }
      }
    case OBTENER_INFO_CONTROL_EXITO:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          loading: null,
          error: null,
          datos: action.payload
        }
      }
    case AGREGAR_DOCENTE_CAPACITACION_EXITO:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          datos: {
            ...state.area_trabajo.datos,
            [action.payload.id_proyecto]: {
              ...state.area_trabajo.datos[action.payload.id_proyecto],
              docentes_filtro: [
                ...state.area_trabajo.datos[action.payload.id_proyecto].docentes_filtro,
                action.payload.docenteNuevo
              ]
            }
          }
        }
      }
    case CAMBIAR_ESTADO_DOCENTE_EXITO:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          datos: {
            ...state.area_trabajo.datos,
            [action.payload.idProyecto]: {
              ...state.area_trabajo.datos[action.payload.idProyecto],
              docentes_filtro: state.area_trabajo.datos[action.payload.idProyecto].docentes_filtro.map(
                docente => docente.id_filtro_docente === action.payload.idFiltroDocente
                  ? action.payload.nuevaFila
                  : docente
              )
            }
          }
        }
      }
    case ELIMINAR_DOCENTE_CAPACITACION_EXITO:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          datos: {
            ...state.area_trabajo.datos,
            [action.payload.id_proyecto]: {
              ...state.area_trabajo.datos[action.payload.id_proyecto],
              docentes_filtro: state.area_trabajo.datos[action.payload.id_proyecto].docentes_filtro.filter(
                docente => docente.id_filtro_docente !== action.payload.idFiltroDocente
              )
            }
          }
        }
      }
    case SESION_ACTIVA:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          activo: action.payload
        }
      }
    case GUARDAR_PONENTE_TENTATIVO_EXITO:
    case GUARDAR_PONENTE_TENTATIVO_CONFIRMADO_EXITO:
    case NUEVA_IMAGEN_EXITO:
    case NUEVA_EVIDENCIA_EXITO:
    case ELIMINAR_IMAGEN_CONFIRMACION_EXITO:
    case ELIMINAR_EVIDENCIA_CONFIRMACION_EXITO:
    case GUARDAR_DATOS_FORM:
    case DOCENTE_ELIMINADO_TENTATIVO_CONFIRMADO_EXITO:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          datos: {
            ...state.area_trabajo.datos,
            [action.payload.idProyecto]: {
              ...state.area_trabajo.datos[action.payload.idProyecto],
              tabla_sesiones: state.area_trabajo.datos[action.payload.idProyecto].tabla_sesiones.map(
                sesion => sesion.id_sesion === action.payload.idSesion ? action.payload.nuevaSesion : sesion
              )
            }
          },
          activo: action.payload.nuevaSesion
        }
      }
    case CHANGE_VALUES:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          activo: {
            ...state.area_trabajo.activo,
            [action.payload.key]: action.payload.value
          }
        }
      }
    case ACTUALIZAR_ESTADO_INDICADOR_CONTROL:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          datos: {
            ...state.area_trabajo.datos,
            [action.payload.idProyecto]: {
              ...state.area_trabajo.datos[action.payload.idProyecto],
              tabla_sesiones: state.area_trabajo.datos[action.payload.idProyecto].tabla_sesiones.map(
                sesion => sesion.id_sesion !== action.payload.idSesion ? sesion : {
                  ...sesion,
                  proceso: sesion.proceso.map(
                    element => element.id_dt_estado_sesion === action.payload.idEstadoSesion
                      ? { ...element, estado: action.payload.valor }
                      : element
                  )
                }
              )
            }
          },
          activo: {
            ...state.area_trabajo.activo,
            proceso: [...state.area_trabajo.activo.proceso].map(
              element => element.id_dt_estado_sesion === action.payload.idEstadoSesion
                ? { ...element, estado: action.payload.valor }
                : element
            )
          }
        }
      }
    case ACTUALIZAR_ESTADO_AJUSTE_CONTROL:
      return {
        ...state,
        area_trabajo: {
          ...state.area_trabajo,
          datos: {
            ...state.area_trabajo.datos,
            [action.payload.idProyecto]: {
              ...state.area_trabajo.datos[action.payload.idProyecto],
              tabla_sesiones: state.area_trabajo.datos[action.payload.idProyecto].tabla_sesiones.map(
                sesion => sesion.id_sesion !== action.payload.idSesion ? sesion : {
                  ...sesion,
                  calidad: sesion.calidad.map(
                    element => element.id_dt_calidad_sesion === action.payload.idEstadoSesion
                      ? { ...element, estado: action.payload.valor }
                      : element
                  )
                }
              )
            }
          },
          activo: {
            ...state.area_trabajo.activo,
            calidad: [...state.area_trabajo.activo.calidad].map(
              element => element.id_dt_calidad_sesion === action.payload.idEstadoSesion
                ? { ...element, estado: action.payload.valor }
                : element
            )
          }
        }
      }
    case OBTENER_DETALLES_SESION_EXITO:
      return {
        ...state,
        detallesSesion: action.payload,
        error: null
      };
    case OBTENER_DETALLES_SESION_ERROR:
      return {
        ...state,
        detallesSesion: null,
        error: action.payload
      };
    case AGREGAR_LINK_DRIVE_EXITO:
      return {
        ...state,
        linkDriveAgregado: action.payload,
        errorLinkDrive: null
      };
    case AGREGAR_LINK_DRIVE_ERROR:
      return {
        ...state,
        linkDriveAgregado: null,
        errorLinkDrive: action.payload
      };
    case OBTENER_DESEMPENO_DOCENTE_EXITO:
      return {
        ...state,
        desempenoDocentes: {
          ...state.desempenoDocentes,
          [action.payload.idFiltroDocente]: action.payload.desempeno
        }
      };

    default:
      return state;
  }
}