import {
  REPORTE_GENEREAL_POSVENTA,
  REPORTE_EDITADO,
  OBTENIENDO_REPORTE,
  ERROR_OBTENER_REPORTE,
  LIMPIAR_STATES_REPORTES,
  NUEVO_LIMITE,
  REPORTE_ASISTENCIA_OBTENIDO,
  DATA_TABLA_ASISTENCIA_GENERADA,
  OBTENIENDO_NOTAS_DISCENTES,
  NOTAS_DISCENTE_OBTENIDO,
  TABLA_NOTAS_GENERADA,
  OBTENER_REPORTE_ENCUESTAS_EXITO,
  SELECCION_INICIAL_TUTORIA,
  OBTENER_REPORTE_DESERTADOS,
  REPORTE_DESERTADOS_EXITO,
  SET_SELECT_DESERTADOS,
  ELIMINADO_DISCENTE_TABLA_SEGUIMIENTO,
  ELIMINADO_DISCENTE_TABLA_DESERTADOS,
  OBTENER_DESEMPENO_PROYECTO_EXITO,
  OBTENER_RANKING_DOCENTES_EXITO,
  OBTENER_LISTA_PROMEDIOS_DOCENTES_EXITO,
  OBTENER_REPORTE_ERROR
} from '../actions/types';

const slct_pro_desertados = localStorage.getItem('slct_pro_desertados')

const initialState = {
  desempenoProyecto: {},
  rankingDocentes: [],
  listaPromediosDocentes: [],
  reporte: [],
  cantidadesTotales: [],
  reporteAsistencia: [],
  notasSesionesDiscente: [],
  notasSesionesTabla: [],
  reporteAsistenciaTabla: [],
  reporteTutoria: {
    docente: {
      categories: [],
      data: []
    },
    tutor: {
      categories: [],
      data: []
    }
  },
  desertados: {
    reporteDesertados: [],
    cantidadesTotales: [],
    proyectoSelect: slct_pro_desertados ? JSON.parse(slct_pro_desertados) : null
  },
  sesionesSelecionadas: [],
  loadingNotas: false,
  loading: null,
  limitador: 10,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case OBTENIENDO_REPORTE:
    case OBTENER_REPORTE_DESERTADOS:
      return {
        ...state,
        loading: true,
        error: null
      }
    case OBTENIENDO_NOTAS_DISCENTES:
      return {
        ...state,
        loadingNotas: true,
        error: null
      }
    case REPORTE_GENEREAL_POSVENTA:
      return {
        ...state,
        reporte: action.payload.reporte,
        cantidadesTotales: action.payload.datos_totales,
        loading: false
      }
    case REPORTE_ASISTENCIA_OBTENIDO:
      // console.log(action.payload)
      return {
        ...state,
        reporteAsistencia: action.payload,
        loading: false,
        error: null,
      }
    case NOTAS_DISCENTE_OBTENIDO:
      return {
        ...state,
        notasSesionesDiscente: action.payload,
        loadingNotas: false,
        error: null
      }
    case DATA_TABLA_ASISTENCIA_GENERADA:
      return {
        ...state,
        reporteAsistenciaTabla: action.payload
      }
    case TABLA_NOTAS_GENERADA:
      return {
        ...state,
        notasSesionesTabla: action.payload
      }
    case REPORTE_EDITADO:
      return {
        ...state,
        reporte: action.payload.reporte
      }
    case ELIMINADO_DISCENTE_TABLA_SEGUIMIENTO:
      return {
        ...state,
        reporte: action.payload
      }
    case ELIMINADO_DISCENTE_TABLA_DESERTADOS:
      return {
        ...state,
        desertados: {
          ...state.desertados,
          reporteDesertados: action.payload
        }
      }
    case OBTENER_REPORTE_ENCUESTAS_EXITO:
      return {
        ...state,
        reporteTutoria: action.payload
      }
    case REPORTE_DESERTADOS_EXITO:
      return {
        ...state,
        desertados: {
          ...state.desertados,
          reporteDesertados: action.payload,
          cantidadesTotales: action.payload
        },
        loading: null,
        error: null
      }
    case SET_SELECT_DESERTADOS:
      localStorage.setItem('slct_pro_desertados', JSON.stringify(action.payload))
      return {
        ...state,
        desertados: {
          ...state.desertados,
          proyectoSelect: action.payload
        }
      }
    case SELECCION_INICIAL_TUTORIA:
      return {
        ...state,
        sesionesSelecionadas: action.payload
      }
    case 'ACTUALIZAR_STATE_INICIAL_SELECT':
      return {
        ...state,
        sesionesSelecionadas: action.payload
      }
    case ERROR_OBTENER_REPORTE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case NUEVO_LIMITE:
      return {
        ...state,
        limitador: action.payload
      }
    case LIMPIAR_STATES_REPORTES:
      return {
        ...state,
        loading: null,
        error: null,
        limitador: 10,
        reporte: []
      }
    case OBTENER_DESEMPENO_PROYECTO_EXITO:
      console.log("Reducer (desempeño proyecto):", action.payload); // <-- Verifica si el reducer recibe los datos
      return {
        ...state,
        desempenoProyecto: action.payload,
        error: null
      };
    case OBTENER_RANKING_DOCENTES_EXITO:
      return {
        ...state,
        rankingDocentes: action.payload,
        error: null
      };
    case OBTENER_LISTA_PROMEDIOS_DOCENTES_EXITO:
      return {
        ...state,
        listaPromediosDocentes: action.payload,
        error: null
      };
    case OBTENER_REPORTE_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state
  }
}