import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerDesempenoProyecto, obtenerRankingDocentes, obtenerListaPromediosDocentes } from "../../../actions/profesoresActions";
import { Card, CardContent, Typography, Avatar, Grid, Box } from "@mui/material";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StarIcon from '@mui/icons-material/Star';
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { useTable, usePagination, useFilters } from 'react-table';
import "./ReporteDesempenoDocente.scss";

const ReporteDesempenoDocente = () => {
  const dispatch = useDispatch();
  const { desempenoProyecto, rankingDocentes, listaPromediosDocentes, error } = useSelector((state) => state.reporte);

  useEffect(() => {
    dispatch(obtenerDesempenoProyecto());
    dispatch(obtenerRankingDocentes());
    dispatch(obtenerListaPromediosDocentes());
  }, [dispatch]);

  const columns = useMemo(() => [
    { Header: 'Nombre', accessor: 'nombre' },
    { Header: 'Apellido', accessor: 'apellido' },
    { Header: 'Puntualidad', accessor: 'promedio_puntualidad' },
    { Header: 'Responsabilidad', accessor: 'promedio_responsabilidad' },
    { Header: 'Promedio General', accessor: 'promedio_general' }
  ], []);

  const data = useMemo(() => listaPromediosDocentes, [listaPromediosDocentes]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    usePagination
  );

  if (error) return <p>Error: {error}</p>;

  return (
    <Layout>
      <div className="DesempenoDocente">
        <div className="DesempenoDocente__container">
          <div className="DesempenoDocente__head">
            <HeaderContent title="Reporte de Desempeño de Docentes" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Reporte de Desempeño de Docentes" },
              ]}
            />
          </div>
          <div className="DesempenoDocente__body container-fluid  px-0 content-actions">
            <Grid container spacing={3}>     
              {/* Proyecto con Mejores Docentes a la Izquierda */}
              <Grid item xs={12} md={6}>
                <Card className="proyecto-mejor-card">
                  <CardContent>
                    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                      <EmojiEventsIcon className="icono-metrica" />
                      <Typography variant="h5" align="center" style={{ marginLeft: "10px" }}>
                        Proyecto con Mejores Docentes
                      </Typography>
                    </Box>
                    {desempenoProyecto && Object.keys(desempenoProyecto).length > 0 ? (
                      <div className="proyecto-info">
                        <Typography className="nombre-proyecto">{desempenoProyecto.nombre_proyecto}</Typography>
                        <Grid container spacing={3} className="proyecto-metricas">
                          <Grid item xs={4}>
                            <div className="metric-item">
                              <Typography variant="body1" className="titulo-proyecto">Puntualidad</Typography>
                              <Typography variant="h6" className="icono-metrica">{desempenoProyecto.promedio_puntualidad}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className="metric-item">
                              <Typography variant="body1" className="titulo-proyecto">Responsabilidad</Typography>
                              <Typography variant="h6" className="icono-metrica">{desempenoProyecto.promedio_responsabilidad}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className="metric-item">
                              <Typography variant="body1" className="titulo-proyecto">Promedio General</Typography>
                              <Typography variant="h6" className="icono-metrica">{desempenoProyecto.promedio_general}</Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <Typography align="center">Cargando...</Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>

              {/* Ranking de los Mejores Docentes a la Derecha 
              <Grid item xs={12} md={6}>
                <Card className="ranking-container">
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      ✨ Ranking de los Mejores Docentes ✨
                    </Typography>
                    <Grid container justifyContent="center" spacing={5}>
                      {rankingDocentes.slice(0, 3).map((docente, index) => (
                        <Grid item key={index}>
                          <div className={`ranking-card ranking-position-${index + 1}`}>
                            <Avatar
                              src={docente.avatar} // Puedes usar una URL o un placeholder
                              alt={docente.nombre}
                              sx={{ width: 100, height: 100 }}
                            />
                            <Typography variant="h6" align="center">
                              {docente.nombre} {docente.apellido}
                            </Typography>
                            <Typography align="center">Promedio General: {docente.promedio_general}</Typography>
                            <div className={`ranking-badge badge-${index + 1}`}>
                              <StarIcon />
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>*/}

              {/* Tabla de Promedios de Docentes */}
              <Grid item xs={12}>
                <Card className="lista-promedios-container">
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      Promedios de Docentes
                      <select className='form-control'>
                        <option value="1">Ninguno</option>
                        <option value="2">Invitado</option>
                        <option value="3">Confirmado</option>
                        <option value="4">Contratado</option>
                      </select>
                    </Typography>
                    <Box overflow="auto">
                      <table {...getTableProps()} className="tabla-react">
                        <thead>
                          {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map(row => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Box>
                    <div className="pagination">
                      <button onClick={() => previousPage()} disabled={!canPreviousPage}>Anterior</button>
                      <span>
                        Página{' '}
                        <strong>
                          {pageIndex + 1} de {pageOptions.length}
                        </strong>
                      </span>
                      <button onClick={() => nextPage()} disabled={!canNextPage}>Siguiente</button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReporteDesempenoDocente;
