import React, { useState } from 'react';
import * as moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactDatePicker from 'react-datepicker';
import useSelectAsesores from '../../../hooks/useSelectAsesores';
import { filtrarLlamadas } from '../../../actions/leadsActions';
import { useDispatch } from 'react-redux';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';

const FormularioFiltroLlamadas = ({setDatosTabla,setLoading,usuario}) => {
    const[fechaInicio,setFechaInicio] = useState(null)
    const[fechaFin,setFechaFin] = useState(null)
    const [asesor, SelectAsesor] = useSelectAsesores(null)
    const[estado,setEstado] = useState('')
    const [turno,setTurno] = useState('')
    const dispatch = useDispatch()
    const traerDatos = async () =>{
        if(fechaInicio && fechaFin)
        {  
            const datosFiltro = {
                asesor:asesor?asesor:null,
                fechaFiltroDesde: fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null,
                fechaFiltroHasta: fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null,
                estado:estado!==''? estado:null,
                nivel:usuario.id_tp_nivel_usuario,
                turno:turno
            }

            setLoading(true)
            const rpta = await dispatch(filtrarLlamadas(datosFiltro))

            if(rpta)
            {
                setDatosTabla(rpta)
                setLoading(false)
            }
        }
        else
        {
            avisoError("Debes seleccionar un rango de fecha como mínimo")
        }
    }

    return (
        <div className="box">
                                <div className="box-header with-border">
                                    <h5
                                        style={{ color: "#434343" }}
                                        >
                                        <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                                    </h5>   
                                </div>
                                <div className="row p-3">
                                    <div className="col-6 mb-3">
                                        <label htmlFor="">Asesor:</label>
                                        <SelectAsesor />
                                    </div>
                                    <div className="col-6 mb-3">
                                        <label htmlFor="">Turno:</label>
                                        <select name='turno' className='form-control' value={turno} onChange={(e)=>setTurno(e.target.value)}>
                                            <option value="">Seleccione una opción...</option>
                                            <option value="1">Turno 1</option>
                                            <option value="2">Turno 2</option>
                                        </select>
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="">Desde:</label>
                                        <ReactDatePicker
                                        selected={fechaInicio}
                                        onChange={(date) =>setFechaInicio(date)}
                                        selectsStart
                                        dateFormat="dd/MM/yyyy"
                                        name="fechaInicio"
                                        className="form-control"
                                        autoComplete='off'
                                    />
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="">Hasta:</label>
                                        <ReactDatePicker
                                        selected={fechaFin}
                                        onChange={(date) =>setFechaFin(date)}
                                        dateFormat="dd/MM/yyyy"
                                        name="fechaFin"
                                        className="form-control"
                                        startDate={fechaInicio}
                                        endDate={fechaFin}
                                        minDate={fechaInicio}
                                        autoComplete='off'
                                    />
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="">Estado:</label>
                                        <select name='estado' className='form-control' value={estado} onChange={(e)=>setEstado(e.target.value)}>
                                            <option value="">Seleccione una opción...</option>
                                            <option value="1">Completado</option>
                                            <option value="0">Pendiente</option>
                                            <option value="2">No atendidas</option>
                                        </select>
                                    </div>
                                    <div className="col-4 pt-4">
                                        <button className="btn btn-primary" onClick={()=>traerDatos()} >Consultar</button>  
                                    </div>
                                </div>
                            </div> 
    );
};

export default FormularioFiltroLlamadas;