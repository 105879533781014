import { faFileImport, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
import { Button, Table } from 'react-bootstrap';
import * as moment from 'moment';
//Redux
const ResultadosFiltro = ({resultados}) => {

  const handleUrlDescarga = () => {
    return `${process.env.REACT_APP_API_URL}/admin/csv/export/mailchimp/${moment().format('YYYY-MM-DD')}`;
  }


 return (
 

      <div className="row p-2">
        <div className="col-12 mb-2">
         <h4>Campaña: {resultados.campaign} </h4>
         <br/>
         <br/>
         <Table striped bordered hover style={{textAlign:'center'}}>
            <thead>
                <tr>
                <th>Total Inversion</th>
                <th>Cantidad Leads</th>
                <th>Costo x Lead</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                  <td>S/. {resultados.inversion_t}</td>
                  <td>{resultados.leads_t}</td>
                  <td>S/. {isNaN(resultados.inversion_t/resultados.leads_t) ? 0 : parseFloat(resultados.inversion_t/resultados.leads_t).toFixed(2) } </td>
                </tr>
               
            </tbody>
            </Table>
         
        </div>     
      </div>

  
  
    );
};

export default ResultadosFiltro;
