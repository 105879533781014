import React from 'react';
import "./gradient.css"
const ProgressGradientBar = ({truePercent}) => {
    return (
        <div id="progress-bar-container" className="row">
            <div className="progress-bar-child progress"></div>
            <div className="progress-bar-child shrinker" style={{width:`${100-truePercent}%`}}></div>
        </div>
    );
};

export default ProgressGradientBar;