import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
//Redux
import { useDispatch, useSelector } from "react-redux";

import { listarProyectos } from "../../../actions/cursosActions";
import { guardarLead } from "../../../actions/leadsActions";
import useSelectFuente from "../../../hooks/useSelectFuente";
import useSelectMedioContacto from "../../../hooks/useSelectMedioContacto";

const FormularioNuevoLead = () => {
	
	const usuario = useSelector((state) => state.usuario.usuario);
	const { proyectos } = useSelector((state) => state.cursos);
	const [fuente,SelectFuente, setFuente] =useSelectFuente(null);
	const [medio, SelectMedios, setMedioSeleccionado] = useSelectMedioContacto(null);
	const dispatch = useDispatch();
	
	const initialState = {
		nombres: "",
		apellidos: "",
		correo: "",
		telefono: "",
		producto: "",
		fuente: fuente ? fuente.id_fuente : '',
		asesor: usuario.id_usuario,
		medio_contacto: ""
	};
	const [datosLead, setdatosLead] = useState(initialState);
	
	/* Agregando select de fuente */
	
	const { nombres, apellidos, correo, telefono, producto } = datosLead;
	
	const handleChange = (evt) => {
		const value = evt.target.value;
		setdatosLead({
			...datosLead,
			[evt.target.name]: value,
		});
	};
	
	const nuevoLead = async (e) => {
		e.preventDefault();
		await dispatch(guardarLead(datosLead));
		console.log(datosLead);
	};
	
	useEffect(() => {
		setdatosLead({
			...datosLead,
			fuente: fuente ? fuente.id_fuente : '',
		});
	}, [fuente]);
	
	useEffect(() => {
		setdatosLead({
			...datosLead,
			medio_contacto: medio ? medio.id :  '',
		});
	}, [medio]);
	
	useEffect(() => {
		dispatch(listarProyectos());
	}, []);
	
	return (
		<div style={{ padding: "10px" }}>
			<form onSubmit={(e) => nuevoLead(e)}>
				<div className="row">
					<div className="col-md-12 p-4">
						<h2>Datos de registro:</h2>
						<Box
							component="fieldset"
							mb={3}
							borderColor="transparent"
						>
							<label>Nombres:</label>
							<input
								type="text"
								className="form-control"
								autoComplete="off"
								name="nombres"
								value={nombres}
								onChange={handleChange}
								required
							/>
						</Box>
						<Box
							component="fieldset"
							mb={3}
							borderColor="transparent"
						>
							<label>Apellidos:</label>
							<input
								type="text"
								className="form-control"
								autoComplete="off"
								name="apellidos"
								value={apellidos}
								onChange={handleChange}
							/>
						</Box>
						<Box
							component="fieldset"
							mb={3}
							borderColor="transparent"
						>
							<label>Correo:</label>
							<input
								type="email"
								className="form-control"
								autoComplete="off"
								name="correo"
								value={correo}
								onChange={handleChange}
								required
							/>
						</Box>
						<Box
							component="fieldset"
							mb={3}
							borderColor="transparent"
						>
							<label>Teléfono:</label>
							<input
								type="text"
								className="form-control"
								autoComplete="off"
								name="telefono"
								value={telefono}
								onChange={handleChange}
							/>
						</Box>
						<Box
							component="fieldset"
							mb={3}
							borderColor="transparent"
						>
							<label htmlFor="">Fuente:</label>
							<SelectFuente />
						</Box>
						<Box
							component="fieldset"
							mb={3}
							borderColor="transparent"
						>
							<label htmlFor="">Medio de contacto:</label>
							<SelectMedios />
						</Box>
						<Box
							component="fieldset"
							mb={3}
							borderColor="transparent"
						>
							<label>Producto:</label>
							<select
								name="producto"
								className="form-control"
								value={producto}
								onChange={handleChange}
								required
							>
								<option value="">
									Seleccione un producto...
								</option>
								{proyectos.length > 0
									? proyectos.map((producto, i) => {
											return (
												<option
													key={i}
													value={producto.id_proyecto}
												>
													{producto.nombre_proyecto}
												</option>
											);
									  })
									: null}
							</select>
						</Box>
					</div>

					<div className="col-12">
						<Box
							component="fieldset"
							mb={3}
							borderColor="transparent"
						>
							<button
								type="submit"
								className="btn btn-primary btn-submit mr-2"
							>
								Guardar Información
							</button>
						</Box>
					</div>
				</div>
			</form>
		</div>
	);
};

export default FormularioNuevoLead;
