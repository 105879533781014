import {
    MOSTRAR_USUARIO,
    REMOVER_USUARIO,
    LISTAR_USUARIOS_ACCESO,
    USUARIO_TRAER,
    INSERTAR_USUARIO,
    ELIMINAR_USUARIO_SISTEMA,
    LISTAR_USAURIO_SISTEMA,
    EDITAR_USUARIO_SISTEMA,
    EDITAR_NOMBRE_USUARIO_SISTEMA,
    EDITAR_CLAVE_USUARIO_SISTEMA,
    ACTUALIZAR_AVATAR_USUARIO,
} from "../actions/types";

const initalState = {
    usuario: {},
    isLogin: undefined,
    error: '',
    loading: false,
    usuarioSistema: {},
    usuariosSistema: []
}

export default function(state = initalState, action){
    switch (action.type) {
        case 'VALIDAR_INICIO_SESION':
            return{
                ...state,
                loading: true,
                isLogin: undefined,
                usuario: {},
                error: ''
            }
        case 'VALIDAR_INICIO_SESION_RESET':
            return {
                ...state,
                error: ''
            }
        case 'VALIDAR_INICIO_SESION_EXITO':
            return{
                ...state,
                loading: false,
                isLogin: true,
                usuario: action.payload,
                error: ''
            }
        case 'ACTUALIZAR_AVATAR_USUARIO':
            return{
                ...state,
                usuario:{
                    ...state.usuario,
                    img_usuario:action.payload.nuevoAvatar
                }
            }
        case 'VALIDAR_INICIO_SESION_ERROR':
            return{
                ...state,
                loading: false,
                isLogin: false,
                usuario: {},
                error: action.payload
            }
        case REMOVER_USUARIO:
            return{
                ...state,
                loading: false,
                isLogin: undefined,
                usuario: {},
                error: ''
            }
        case USUARIO_TRAER:
            return{
                ...state,
                usuario: action.payload
            }
        case LISTAR_USUARIOS_ACCESO:
            return{
                ...state,
                usuariosSistema: action.payload
            }
        case INSERTAR_USUARIO:
            return{
                ...state,
                usuariosSistema: action.payload
            }
        case LISTAR_USAURIO_SISTEMA:
            return{
                ...state,
                usuarioSistema: action.payload
            }
        case EDITAR_USUARIO_SISTEMA:
            return{
                ...state,
                usuariosSistema: action.payload
            }
        case EDITAR_NOMBRE_USUARIO_SISTEMA:
            return{
                ...state,
                usuarioSistema: action.payload
            }
        case EDITAR_CLAVE_USUARIO_SISTEMA:
            return{
                ...state,
                usuarioSistema: action.payload
            }
        case ELIMINAR_USUARIO_SISTEMA:
            return{
                ...state,
                usuariosSistema: action.payload
            }
        default:
            return state
    }
} 