import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../app/Layout';
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import FormularioDatosPersonales from './FormularioDatosPersonales';
import { editarAvatarPerfil } from '../../../actions/trabajadoresActions';
import { avisoCorrecto } from '../../components/AvisosMensajes/MensajesSwalf';

const PerfilDeUsuario = () => {

    const {usuario} = useSelector(state=>state.usuario)
    const fileRef = useRef();
    const dispatch=useDispatch()
    const handleClick = () => {
      fileRef.current.click()
    }

    const handleChangeAvatar = e => {
        dispatch(editarAvatarPerfil(e.target.files[0], usuario.id_empleado) )
      }
    return (
     <Layout>
        <div className='row mb-4'>
            <div className='col-md-12'>
            <HeaderContent title="Mi perfil" icon="group" />
            <Breadcrumb
                      link={[
                              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                              { url: "", nombre: "Datos personales" },
                            ]}
                    />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-3'>
                <div className='card'>
                    <div className='card-body d-flex flex-column justify-content-center'>
                    <div className='mb-4 text-center'><h5>Foto de Perfil</h5></div>
                    <img
                        src={usuario.img_usuario ? `${process.env.REACT_APP_API_URL}/${usuario.img_usuario}` : "/images/user.png"}
                        className="mx-auto img-fluid img-circle d-block cursor-pointer"
                        alt="avatar"
                        onClick={handleClick}
                        />
                        <div className="custom-file">
                            <input type="file" ref={fileRef} onChange={handleChangeAvatar} id="file" className="custom-file-input" />
                        </div>

                   {usuario.img_usuario ? <button className='btn btn-primary' onClick={()=> { navigator.clipboard.writeText(`${process.env.REACT_APP_API_URL}/${usuario.img_usuario}`) 
      avisoCorrecto("Enlace copiado en el clipboard ✔")}}>Copiar URL de la imagen</button>:null}
                    </div>
                </div>
            </div>
            <div className='col-md-9'>
                <div className='card'>
                    <div className='card-body'>
                        <h5>Datos personales</h5>
                        <br/>
                        <br/>
                        <FormularioDatosPersonales usuario={usuario} />
                    </div>
                </div>
            </div>
        </div>
     </Layout>
    );
};

export default PerfilDeUsuario;