import React, { useEffect, useState } from 'react';
import Layout from '../../../../../app/Layout';
import { useParams } from 'react-router-dom';
import HeaderContent from '../../../General/HeaderContent';
import TbResumenCtrlCapacitaciones from '../../../../components/Tablas/ControlCapacitaciones/TbResumenCtrlCapacitaciones/TbResumenCtrlCapacitaciones';
import SpinnerLoading from '../../../../components/Spinners/SpinnerLoading';
import TablaPonenteCtrl from '../../../../components/Tablas/ControlCapacitaciones/TablaPonentesCtrl/TablaPonentesCtrl';
import ModalLargewhithRedux from '../../../../../containers/ModalLargewhithRedux';
import ModalBasicWithRedux from '../../../../../containers/ModalBasicwithRedux';
import LightBoxwithRedux from '../../../../../containers/LightBoxwithRedux';
import TablaControlCapacitaciones from '../../../../../containers/ControlCapacitacionesComtainers/TablaControlCapacitaciones';
import { Row, Col, Button, Modal, Form, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faEdit } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { obtenerInfoControl } from '../../../../../actions/controlCapacitacionesActions'; 
import AccionesTablaControl from '../../../../components/Tablas/ControlCapacitaciones/AccionesTablaControl';
import CurriculumDocente from '../../../../components/Tablas/ControlCapacitaciones/CurriculumDocente';
import ProcesosIntranet from '../../../../components/Tablas/ControlCapacitaciones/ProcesosIntranet';
import EstadoCalidad from '../../../../components/Tablas/ControlCapacitaciones/AjustesCalidad';
import { guardarSesionCapacitacionActivo } from '../../../../../actions/controlCapacitacionesActions';
import { updateStateModalBasic, updateStateModalLarge } from '../../../../../actions/modalActions';

const ControlCapacitacion = () => {
  const { idCapacitacion } = useParams();
  const dispatch = useDispatch();
  const { area_trabajo: { datos, loading } } = useSelector(state => state.control_capacitacion);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!(idCapacitacion in datos)) {
      dispatch(obtenerInfoControl(idCapacitacion));
    }
    const savedVisibility = localStorage.getItem('columnVisibilityCtrlCap');
    if (savedVisibility) {
      setColumnVisibility(JSON.parse(savedVisibility));
    }
  }, [dispatch, idCapacitacion]); // Elimina 'datos' si causa renderizados repetidos
  

  const toggleColumn = (columnName) => {
    const updatedVisibility = { ...columnVisibility, [columnName]: !columnVisibility[columnName] };
    setColumnVisibility(updatedVisibility);
    localStorage.setItem('columnVisibilityCtrlCap', JSON.stringify(updatedVisibility));
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  

  const mostrarBadge = ({ proceso }) => {
    const totalIndicadores = proceso.length;
    let indicadoresCompletados = 0;
    [...proceso].forEach(element => {
      if (element.estado === 'Completo') {
        indicadoresCompletados++;
      }
    });
    const resultado = (indicadoresCompletados / totalIndicadores) * 100;
    return (
      <Badge
        variant={`${resultado < 50 ? 'danger' : resultado < 100 ? 'warning' : 'success'}`}
        className='text-white px-2'
      >
        {parseFloat(resultado).toFixed(2)}%
      </Badge>
    );
  };

  const handleAccioneButton = ({ id_sesion }) => {
    dispatch(guardarSesionCapacitacionActivo(id_sesion, idCapacitacion));
    dispatch(updateStateModalLarge(<AccionesTablaControl />, 'Acciones para los docentes'));
  };

  const handleViewCurriculum = ({ id_sesion }) => {
    dispatch(guardarSesionCapacitacionActivo(id_sesion, idCapacitacion));
    dispatch(updateStateModalLarge(<CurriculumDocente />, 'Curriculum Vitae'));
  };

  const handleViewProcesos = ({ id_sesion }) => {
    dispatch(guardarSesionCapacitacionActivo(id_sesion, idCapacitacion));
    dispatch(updateStateModalBasic(<ProcesosIntranet />, 'Progreso de la intranet'));
  };

  const handleViewEstado = ({ id_sesion }) => {
    dispatch(guardarSesionCapacitacionActivo(id_sesion, idCapacitacion));
    dispatch(updateStateModalBasic(<EstadoCalidad />, 'Acciones sobre la calificación'));
  };

  const mostrarBotones = (record) => {
    return (
      <>
        <Button
          variant='info'
          title='Acciones para el Docente'
          size='xs'
          onClick={() => handleAccioneButton(record)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          variant='warning'
          title='Progreso de la Intranet'
          size='xs'
          onClick={() => handleViewProcesos(record)}
        >
          <FontAwesomeIcon icon={faTasks} />
        </Button>
      </>
    );
  };

  const estadoCalidad = (record) => {
    return (
      <>
        <Button
          variant='info'
          title='Progreso de Intranet'
          size='xs'
          onClick={() => handleViewEstado(record)}
        >
          <FontAwesomeIcon icon={faTasks} />
        </Button>
      </>
    );
  };

  const anchorSesiones = (record) => {
    return (
      <p
        type='button'
        className='btn btn-sm td__curriculum__btn--color'
        onClick={() => handleViewCurriculum(record)}
      >
        Ver curriculum
      </p>
    );
  };

  const colums = [
    {
      text: "Semaforo",
      key: "estado_semaforo",
      align: "center",
      sortable: false,
      className: "estado_semaforo text-center",
      width: 20,
      cell: (record) => { 
        return (
          <div
            style={{ color: 'white', background: `${
              record.estado_semaforo === 'AMBAR' ? '#F39C12' :
              record.estado_semaforo === 'VERDE' ? '#18BB60' : '#E12E1C'
            }` }}
            className='estado_semaforo'
          >
            {record.estado_semaforo}
          </div>
        );
      } 
    },
    {
      text: "Item",
      key: "index",
      align: "center",
      sortable: true,
      className: "text-center",
      width: 20,
      cell: (_, index) => { 
        return (<>{index + 1}</>);
      } 
    },
    {
      text: "ID",
      key: "id_sesion",
      align: "center",
      sortable: true,
      className: "text-center",
      width: 20,
    },
    {
      text: "Fecha",
      key: "fecha_sesion",
      align: "center",
      className: "tabla__td tabla__td__nombre-sesion",
      sortable: true,
      cell: (record) => {
        return <span style={{ width: '80px', display: 'block' }}>{moment(record.fecha_sesion).format('DD-MMM-YYYY')}</span>;
      }
    },
    {
      text: "Horas de ponencia",
      key: "horas_sesion",
      align: "center",
      className: "text-center tabla__td__fecha",
      sortable: true,
    },
    {
      text: "Módulo",
      key: "nombre_modulo",
      align: "center",
      className: "tabla__td tabla__td__modulo",
      sortable: true,
    },
    {
      text: "Sesión",
      key: "nombre_sesion",
      align: "center",
      className: "tabla__td tabla__td__temas",
      sortable: true,
    },
    {
      text: "Docentes tentativos/Jurado/Clausura",
      key: "docentes_tentativos",
      align: "center",
      className: "tabla__td--tentativo",
      width: 400,
      cell: record => { 
        return (
          <ul>
            {record.docentes_tentativos.map((docente) => (
              <li key={docente.id_filtro_docente}>
                {`${docente.nombre_usuario} ${docente.apellido_usuario}`}
              </li>
            ))}
          </ul>
        );
      }
    },
    {
      text: "Docente confirmado",
      key: "docente_confirmado",
      align: "center",
      className: "tabla__td--confirmado",
      width: 400,
      cell: record => { 
        return (
          <ul>
            {record.docente_confirmado && record.docente_confirmado.map((docente) => (
              <li key={docente.id_filtro_docente}>
                {`${docente.nombre_usuario} ${docente.apellido_usuario}`}
              </li>
            ))}
          </ul>
        );
      }
    },
    {
      text: "Curriculum Vitae",
      key: "cv",
      align: "center",
      className: "td__curriculum",
      cell: record => {
        return (
          <>{anchorSesiones(record)}</>
        );
      }
    },
    {
      text: "Cópia del correo de confirmación",
      key: "archivo_confirmacion",
      align: "center",
      className: "tabla__td",
      width: 500,
      cell: record => { 
        return (
          <>
            {record.archivo_confirmacion.length ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${record.archivo_confirmacion[0].ruta_archivo}`}
                className='img img-fluid'  alt="..."
              />
            ) : (
              'No hay imagen'
            )}
          </>
        );
      }
    },
    {
      text: "Calificación de Discentes",
      key: "calificacion",
      align: "center",
      className: "text-center",
      TrOnlyClassName: "tabla__th",
      sortable: true,
       cell: record => {
        return (
          <>
            {isNaN(record.calificacion) ? '0' : `${record.calificacion}%`}
          </>
        );
      }
    },
    {
      key: "estado",
      text: "Acciones sobre la calificación",
      className: "td__estado text-center",
      align: "center",
      sortable: false,
      cell: record => { 
        return (
          <>
            {estadoCalidad(record)}
          </>
        );
      }
    },
    {
      text: "Evidencia de acciones",
      key: "archivo_evidencia",
      align: "center",
      className: "evidencia__td",
      width: 500,
      cell: record => { 
        return (
          <>
            {record.archivo_evidencia.length ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${record.archivo_evidencia[0].ruta_archivo}`}
                className='img img-fluid'  alt='...'
              />
            ) : (
              'No hay imagen'
            )}
          </>
        );
      }
    },
    {
      text: "Actualización de intranet",
      key: 'infoActualizacion',
      align: "center",
      className: "text-center",
      sortable: false,
      cell: record => {
        return (
          <>
            {mostrarBadge(record)}
          </>
        );
      }
    },
    {
      key: "action",
      text: "Acciones",
      className: "td__action text-center",
      align: "center",
      sortable: false,
      cell: record => { 
        return (
          <>
            {mostrarBotones(record)}
          </>
        );
      }
    }
  ];

  return (
    <Layout>
      <HeaderContent title='Control capacitacion' icon='supervisor_account'/>
      <SpinnerLoading loading={loading}>
        <Row>
          <Col md={5}>
            <TbResumenCtrlCapacitaciones/>
          </Col>
          <Col md={7}>
            <TablaPonenteCtrl/>
          </Col>
        </Row>
        <div className="d-flex justify-content-end mb-2">
          <Button variant="primary" onClick={handleShowModal}>Columnas</Button>
        </div>
        <TablaControlCapacitaciones columnVisibility={columnVisibility} />
        <ModalLargewhithRedux/>
        <ModalBasicWithRedux/>
        <LightBoxwithRedux/>
      </SpinnerLoading>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Configuración de Columnas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {colums.map(column => (
              <Form.Check
                key={column.key}
                type="switch"
                id={column.key}
                label={column.text}
                checked={!columnVisibility[column.key]}
                onChange={() => toggleColumn(column.key)}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default ControlCapacitacion;
