import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap';
import FormularioIndicadores from './FormularioIndicadoresInicio'
import BarChart from './BarChart';
import { useDispatch, useSelector } from 'react-redux';
import { cargarSesionesEventos, listarProyectosGantt } from '../../../actions/cursosActions';
import GanttDiagram from './GanttDiagram'
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import { handleFileSelect } from '../../../helpers/functions';
import Comunicado from './Comunicado';
import { listarUltimosComunicados, ultimoComunicado,updateMostrarComunicado } from '../../../actions/popupsActions';
import Accesos from './Accesos';
import EventsCalendar from './Horario/EventsCalendar.js'
import { cargarCumples } from '../../../actions/userActions';
import SesionesEvento from './Horario/SesionesEvento';
const InicioAdministracion = ({usuario}) => {
    
    const dispatch = useDispatch();
    const [proyectos,setProyectos] = useState(null)
    const [loading,setLoading] = useState(true)
    const [modo,setModo] = useState('year')
    const [comunicado,setComunicado] = useState(null)
    const {comunicados} = useSelector(state=>state.comunicados)
    const [eventos, setEventos]=useState([])
    const [sesionesEvento,setSesionesEvento]=useState([])
    const handleModo = (modo)=>{
        setModo(modo)
    }
    const {show}=useSelector(state=>state.popUp)

    const cargarEventos = async (events) =>{
     // console.log(events)
      let dataEventos = [];
      let thisYear = new Date();
      events.map(evento => {
        
          dataEventos = [...dataEventos,{
              title: 'Felíz cumpleaños - '+evento.nombre_usuario,
              start: new Date(evento.fecha_naci+'-'+thisYear.getFullYear()),
              end: new Date(evento.fecha_naci+'-'+thisYear.getFullYear())
          }]
          //console.log(dataEventos)
      })
    //console.log(dataEventos)
      setEventos(dataEventos)
    }
  
    const cargarEventosSe = async (events) =>{
      // console.log(events)
       let dataEventos = [];
       let thisYear = new Date();
       events.map(evento => {
         
           dataEventos = [...dataEventos,{
               title: evento.nombre_sesion,
               start: new Date(evento.fecha_sesion),
               end: new Date(evento.fecha_sesion),
               producto:evento.nombre_proyecto,
               docente:evento.docente,
           }]
           //console.log(dataEventos)
       })
     //console.log(dataEventos)
       setSesionesEvento(dataEventos)
     }
  
    useEffect(() => {
      if(!proyectos) {
        const fetchData = async () =>{
            const respuesta = await (dispatch(listarProyectosGantt()))
            setProyectos(respuesta)
            setLoading(false)
            const dato = await (dispatch(ultimoComunicado()))
            const hb_events = await (dispatch(cargarCumples()))
            const sesiones_eventos = await (dispatch(cargarSesionesEventos()))

            if(hb_events)
            {
             await cargarEventos(hb_events)
            }

            if(sesiones_eventos)
            {
             await cargarEventosSe(sesiones_eventos.sesiones)
            }
  

            setComunicado(dato.data)
            if(dato.data)        
            dispatch(updateMostrarComunicado())
            dispatch(listarUltimosComunicados())
          }
          fetchData();
        }
    }, [proyectos])

    return (
    <>
       { comunicado ? <Comunicado comunicado={comunicado} show={show} cerrarComunicado={updateMostrarComunicado} /> : ''}
        <Row className="w-100 mt-2">
            <Col md={12}>
            <div className="box box-primary">
              <div className="box-body">
              <Row>  

              <Col md={6}> <h3>Productos en Ejecución</h3></Col>
              <Col md={6} className="p-2 text-right"> <button className="btn btn-primary" onClick={()=>handleModo('month')}> Mes </button> <button className="btn btn-primary" onClick={()=>handleModo('year')}> Año</button></Col>

              </Row>

                <SpinnerLoading loading={loading} >
                <div style={{height:'400px'}}>
                {  proyectos ? <GanttDiagram proyectos={proyectos} modo={modo}/> : ''}
                </div>
                </SpinnerLoading>   
                         
                </div>
             
            </div>
            </Col>
        </Row>
     
    <Row className="w-100 mt-2">
    <Col md={6}>
    <Card className="p-3 mb-3">
         <Card.Body>
         <h3>Comunicados</h3>
        {
            comunicados.length!=0 ? comunicados.map((comunicado,i)=>
              (  <div key={i} className="row">
                    <div className="col-12 p-2 mb-2" style={{border:'2px dashed #ebebeb', borderRadius:'8px'}}>
                        <span className="pull-right" style={{fontWeight:'600'}}>{comunicado.fecha_registro}</span>
                        <div className="p-2"><h5> {comunicado.titulo} </h5></div>
                        <div className="p-2 mt-2"  dangerouslySetInnerHTML={{__html:comunicado.mensaje}}></div>  
                       { comunicado.enlace!='' ? <div className="p-2 mt-2"> <a className="btn btn-danger mt-3" href={comunicado.enlace} target="_blank">Más información...</a></div> :''}
                        
                    </div>
                </div>)
            ) : 'No hay comunicados recientes.'
        }
         </Card.Body>
    </Card>
    </Col>
    <Col md={6}>
      <Row>
        <Col md={12}>
        <Card className="p-1 mb-3">
            <Card.Body>
            <h3>Cronograma de sesiones</h3>
            <SesionesEvento eventos={sesionesEvento} />
            </Card.Body>
        </Card>
        </Col>
        <Col md={12}>
        <Card className="p-1 mb-3">
            <Card.Body>
                <Accesos/>
            </Card.Body>
        </Card>
        </Col>
        <Col md={12}>
        <Card className="p-1 mb-3">
            <Card.Body>
            <h3>Cumpleaños</h3>
            <EventsCalendar eventos={eventos} />
            </Card.Body>
        </Card>
        </Col>
      </Row>
    </Col>
    </Row>
  </>
     );
}
 
export default InicioAdministracion;