import React from "react";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import "./Rating.scss";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		"& > * + *": {
			marginTop: theme.spacing(1),
		},
	},
}));

const labelsTooltip = {
	1: "😟 Mejorare",
	2: "😐 Mejorare mejor",
	3: "😏 Medio",
	4: "😊 Bien",
	5: "🤩 Excelente",
};

export default function HalfRating(props) {
	const classes = useStyles();

	const [stars, setStars] = useState(0);
	const [hover, setHover] = React.useState(-1);
	const {
		defaultStars = 0,
		index,
		getStars,
		labelsTooltip = {
      1: '10%',
      2: '20%',
      3: '30%',
      4: '40%',
      5: '50%',
      6: '60%',
      7: '70%',
      8: '80%',
      9: '90%',
      10: '100%',
    },
		disabled = false,
	} = props;

	const handleChange = (newValue) => {
		setStars(newValue || 0);
		if (getStars) {
			getStars(newValue || 0);
		}
	};

	useEffect(() => {
		setStars(defaultStars);
	}, [defaultStars]);

	return (
		<div className="rating">
			<Tooltip
				title={
					stars !== null &&
					labelsTooltip[hover !== -1 ? hover : stars]
				}
				placement="top-start"
			>
				<div className={classes.root}>
					<Rating
						name={`size-large-${index}`}
						onChange={(_, newValue) => handleChange(newValue)}
						value={stars}
						onChangeActive={(_, newHover) => {
							setHover(newHover);
						}}
						size="large"
						disabled={disabled}
            max={10}
					/>
				</div>
			</Tooltip>
		</div>
	);
}
