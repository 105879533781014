import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import "./Trabajadores.scss";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom"; 
import { cargarDatosComercial } from "../../../actions/userActions";
import LineHistorialVentas from "../inicio/ChartComponents/LineHistorialVentas";
const DetalleAsesor = () => {
  //State
  const dispatch = useDispatch();
  const {usuario} = useSelector(state=>state.usuario)
  const {idAsesor} = useParams()

  const [datosComercial,setDatosComercial]=useState(null)
  const [loading,setLoading] = useState(false)
  const fetchData = async () =>{
    setLoading(true)
    const datos_comercial = await(dispatch(cargarDatosComercial(idAsesor)))

    if(datos_comercial)
    {
      setDatosComercial(datos_comercial)
      setLoading(false)
    }
  }


  useEffect(() => {
    fetchData()
  }, []);

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title={`Asesor: ${datosComercial?.mi_nombre.nombre_usuario}`}icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "/equipo-ventas", nombre: "Equipo de Ventas" },
                { url: "", nombre: "Detalles del Asesor" },
              ]}
            />
          
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
            <SpinnerLoading loading={loading}>
            <Row className="w-100 mt-2">
            <Col md={6} >
              <Row>
              <Col md={6}>
                <Card className="p-1 mb-3">
                    <Card.Body>
                    <Row>
                      <Col md={3} className='d-flex justify-items-center align-items-center'>
                          <i className='fa fa-3x fa-file text-primary'></i>
                      </Col>
                      <Col md={9}>
                      <h1>{datosComercial?datosComercial.tratos_x_cerrar:0}</h1>
                          <p>Tratos por Cerrar (Mes)</p>
                      </Col>
                    </Row>
                    </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="p-1 mb-3">
                    <Card.Body>
                    <Row>
                      <Col md={3} className='d-flex justify-items-center align-items-center'>
                          <i className='fa fa-3x fa-phone text-danger'></i>
                      </Col>
                      <Col md={9}>
                      <h1>{datosComercial?datosComercial.llamadas_programadas:0}</h1>
                          <p>Llamadas programadas (Hoy)</p>
                      </Col>
                    </Row>
                    
                    </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="p-1 mb-3">
                    <Card.Body>
                      <Row>
                      <Col md={3} className='d-flex justify-items-center align-items-center'>
                          <i className='fa fa-3x fa-users text-warning'></i>
                      </Col>
                      <Col md={9}>
                      <h1>{datosComercial?datosComercial.leads:0}</h1>
                          <p>Leads Nuevos (Hoy)</p>
                      </Col>
                    </Row>
                    
                    </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="p-1 mb-3">
                    <Card.Body>
                    <Row>
                      <Col md={3} className='d-flex justify-items-center align-items-center'>
                          <i className='fa fa-3x fa-edit text-success'></i>
                      </Col>
                      <Col md={9}>
                      <h1>{datosComercial?datosComercial.tratos_cerrados:0}</h1>
                          <p>Ventas Cerradas (Mes)</p>
                      </Col>
                    </Row>
                    </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="p-1 mb-3">
                    <Card.Body>
                    <Row>
                      <Col md={3} className='d-flex justify-items-center align-items-center'>
                          <i className='fa fa-3x fa-line-chart text-success'></i>
                      </Col>
                      <Col md={9}>
                      <h1>{datosComercial?datosComercial.mi_ranking.map((rank,i)=>{
                        if (idAsesor===rank.id_usuario) return rank.puesto 
                      }):'-'}</h1>
                          <p>Mi posición en el Ranking</p>
                      </Col>
                    </Row>
                    </Card.Body>
                </Card>
              </Col>
              </Row>

            </Col>
            <Col md={6}>
                <Card>
                  <Card.Body>
                  { datosComercial ? <LineHistorialVentas datos={datosComercial.grafico} /> : <p>Cargando gráfico....</p>}
                  </Card.Body>
                </Card>
            </Col>
            </Row>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DetalleAsesor;
