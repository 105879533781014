import { faFilePdf, faListOl, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MaterialTableDemo from '../../../plugins/MaterialTableDemo';

const TablaIngresos = ({ tablas }) => {

  const botones = (record) => {
    return (
      <div className='d-flex justify-content-between'>
        <Link to={`/pagos/examinar-pension?q=dni&value=${record.dni_discente}&matricula=${record.id_matricula_proyecto}&pension=${record.id_dt_matricula_proyecto}`} className='mr-1'>
          <Button variant='success' size='xs'>
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </Link>
        <Link to={`/reporte-ingresos/${record.id_registro_pago}`} className='mr-1'>
          <Button variant='info' size='xs'>
            <FontAwesomeIcon icon={faListOl} />
          </Button>
        </Link>
        <Button
          href={`${process.env.REACT_APP_API_URL}/admin/pdf/recibo_ingreso/${record.id_registro_pago}`} 
          variant='danger'
          size='xs'
        >
          <FontAwesomeIcon icon={faFilePdf} />
        </Button>
      </div>
    )
  }

  return ( 
    <div className="box box-primary mt-3">
      <div className="box-body">
        <MaterialTableDemo
          colums={columns(botones)}
          tabla={tablas}
          addConfig={{key_column: 'nro_documento'}}
        />
      </div>
    </div>
  );
}
 
export default TablaIngresos;

const columns = (botones) => [
  {
    key: 'index',
    text: '#',
    className: 'text-center',
    sortable: true,
    cell: (_, index) => index+1
  },
  {
    key: 'nro_documento',
    text: 'N° comprobante',
    className: 'text-center',
    sortable: true,
  },
  {
    key: 'nombre_discente',
    text: 'Nombres y apellidos',
    sortable: true,
  },
  {
    key: 'dni_discente',
    text: 'DNI',
    className: 'text-center',
    sortable: true,
  },
  {
    key: 'nombre_proyecto',
    text: 'Evento',
    width: 500,
    cell: (record) => <p title={`${record.nombre_proyecto}`}>
      {`${record.nombre_proyecto.substring(0, 100)} ${record.nombre_proyecto.length > 100 ? '...' : ''}`}
    </p>
  },
  {
    key: 'concepto',
    text: 'Concepto'
  },
  {
    key: 'medio_pago',
    text: 'Medio de pago'
  },
  {
    key: 'tp_area',
    text: 'Área'
  },
  {
    key: 'monto',
    text: 'Total ingreso (S/)',
    className: 'text-right',
    sortable: true,
  },
  {
    key: 'tp_estado_registro',
    text: 'Estado',
    className: 'text-center',
    cell: (record) => (
      <span className={`badge ${record.tp_estado_registro === 'Valido' ? 'badge-success' : 'badge-danger'}`}>
        {record.tp_estado_registro}
      </span>
    )
  },
  {
    key: 'observaciones',
    text: 'Observaciones'
  },
  {
    key: 'acciones',
    text: 'Acciones',
    className: 'text-center',
    cell: record => botones(record)
  }
]