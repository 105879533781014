import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { actualizarAsesorStatus } from '../../../actions/trabajadoresActions';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const EditarAsesor = ({registro}) => {


  const [estado, setEstado]=useState(registro.id_tp_estado==="1"? true:false)
  const [asignacion, setAsignacion]=useState(registro.asignacion_automatica==="1"?true:false)
  const [descarga, setDescarga]=useState(registro.descarga_leads==="1"?true:false)
  const {asesores} = useSelector(state=>state.trabajadores)
  const dispatch = useDispatch();

  const editarCampaign = async (e) =>{
    e.preventDefault();
    
    const datosCamp={
      id_usuario:registro.id_usuario,
      estado: estado?"1":"0",
      asignacion: asignacion?"1":"0",
      descarga: descarga?"1":"0",
    }

    await dispatch(actualizarAsesorStatus(datosCamp,asesores))
    //console.log(datosCamp)
  }
 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={(e) => editarCampaign(e)}>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Nombre Asesor:
                      </label>
                    </div>
                    <div className="col-md-7">
                      {registro.nombre_usuario} {registro.apellido_usuario}
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Estado:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input type="checkbox" name="estado" defaultChecked={estado} value={estado} onChange={(e)=>setEstado(!estado)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Asignación automática:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input type="checkbox" name="asignacion_automatica" defaultChecked={asignacion} value={asignacion} onChange={(e)=>setAsignacion(!asignacion)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Descarga de leads:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input type="checkbox" name="descarga" defaultChecked={descarga} value={descarga} onChange={(e)=>setDescarga(!descarga)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default EditarAsesor;
