import React, { useEffect, useState } from "react";
import Select from "react-select";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { listarAsesores, listarFuentes } from "../actions/leadsActions";
import authAxios from "../config/authAxios";

const useSelectMedioContacto = (initialSelect = null) => {
	
	const dispatch = useDispatch();
	const [mediosContacto, setMediosContacto] = useState([]);
	
	const [medioSeleccionado, setMedioSeleccionado] = useState(null);
	
	const handleInitialAction = () => {
		if (initialSelect) {
			const medioSelect = mediosContacto.find(
				(medio) => parseInt(medio.id) === parseInt(initialSelect)
			);
			if (medioSelect) {
				setMedioSeleccionado(medioSelect);
			}
		}
	};
	
	const getMediosContacto = async() => {
		try{
			const response = await authAxios.get('/admin/traerMedioContacto');
			if(response.data.status){
				setMediosContacto(response.data.data);
			}
		}catch(error){
			console.log(error);
		}
	}
	
	useEffect(() => {
		handleInitialAction();
	}, [mediosContacto]);
	
	useEffect(() => {
		getMediosContacto();
		return () => {
			setMediosContacto([]);
		}
	}, []);
	
	const SimpleSelect = (props) => {
		return (
			<Select
				isDisabled={false}
				isClearable={true}
				isRtl={false}
				isLoading={mediosContacto.length < 0}
				loadingMessage={() => "Cargando..."}
				isSearchable={true}
				onChange={(medio) => setMedioSeleccionado(medio)}
				value={medioSeleccionado}
				getOptionValue={(medio) => medio.id}
				getOptionLabel={(medio) => medio.nombre}
				options={mediosContacto}
				placeholder="--Seleccione--"
				noOptionsMessage={() => "No hay resultados"}
				{...props}
			/>
		);
	};
	
	return [medioSeleccionado, SimpleSelect, setMedioSeleccionado];
};

export default useSelectMedioContacto;
