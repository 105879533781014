import React from 'react';
import { Badge, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { getColorEstadoPago } from '../../../../helpers/functions';
import RegistroFacturaOrdianria from '../../../../containers/FinanzasContainers/RegistroFacturaOrdinaria';
import * as moment from 'moment';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalBasic, updateStateModalCustom } from '../../../../actions/modalActions';
import { eliminarCuotaFraccion, obtenerIDBoletaSiguiente, registrarDetallesPagos } from '../../../../actions/pagosActions';
import Swal from 'sweetalert2';
import FormularioAnularPago from '../FormularioAnularPago';
import RevisarPago from '../RegistroFactura/RevisarPago';

const FilaCuota = props => {

  const {
    id_fraccion,
    item,
    fecha_limite, 
    monto_establecido,
    nombre_tp_estado_pago
  } = props 
  
  const dispatch = useDispatch();

  const handlePagar = () => {
    dispatch(registrarDetallesPagos('fraccion', id_fraccion))
    //No se le manda parámetro para que reestablesca a null
    dispatch(obtenerIDBoletaSiguiente());
    dispatch(updateStateModalCustom(<RegistroFacturaOrdianria /> , 'Registro de pago', 'modal-wl'));
  }


  const habdleRevisarPago = () => {
    dispatch(
      updateStateModalCustom(
        <RevisarPago idPensionFraccion={id_fraccion} /> ,
        "Revisión de pago reportado",
        "modal-wl"
      )
    );
  }


  const handleEliminarCuota = () => {
    if(nombre_tp_estado_pago === 'PAGADO') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No se puede eliminar esta cuota porque ha sido pagada.',
      })
      return;
    }
    dispatch(eliminarCuotaFraccion(id_fraccion));
  }

  const handleAnularPago = () => {
    dispatch(updateStateModalBasic(<FormularioAnularPago id={id_fraccion} tipo='fraccion'/>, 'Anular pago'));
  }

  return ( 
      <tr>
        <td align='center'>{item + 1}</td>
        <td align='center'>{monto_establecido}</td>
        <td align='center'>{fecha_limite && moment(fecha_limite).format('DD MMM YYYY')}</td>
        <td align='center'>
          <Badge variant={`${getColorEstadoPago(nombre_tp_estado_pago)} py-1`} >
            {nombre_tp_estado_pago}
          </Badge>
        </td>
        <td className='text-center'>
        <DropdownButton
          as={ButtonGroup}
          title="Opciones"
          variant='warning'
          size='xs'
        >
       {   nombre_tp_estado_pago === 'REPORTADO' ? ( <Dropdown.Item
            eventKey="1"
            onClick={habdleRevisarPago}
            disabled={nombre_tp_estado_pago === 'PAGADO' ? true : false}
            className='btn-icon'
          >
            Revisar pago
          </Dropdown.Item> ) : ( <Dropdown.Item
            eventKey="1"
            onClick={handlePagar}
            disabled={nombre_tp_estado_pago === 'PAGADO' ? true : false}
            className='btn-icon'
          >
            Pagar
          </Dropdown.Item> )}
          {
            nombre_tp_estado_pago === 'PAGADO' && (
              <Dropdown.Item
                eventKey="4"
                onClick={handleAnularPago}
                className='btn-icon'
              >
                Anular pago
              </Dropdown.Item>
            )
          }
          <Dropdown.Item 
            eventKey="2"
            className='btn-icon'
            disabled={nombre_tp_estado_pago === 'PAGADO' ? true : false}
            onClick={handleEliminarCuota}
          >
            Eliminar
          </Dropdown.Item>

        </DropdownButton>
        </td>
      </tr>
  );
}
 
export default FilaCuota;