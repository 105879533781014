import { basesCertTypes } from "./types";

import authAxios from "../config/authAxios";
import response from "../config/response";
import {
	avisoCargando,
	avisoCorrecto,
	avisoError,
} from "../view/components/AvisosMensajes/MensajesSwalf";
import Swal from "sweetalert2/src/sweetalert2";
import { updateStateModalLarge } from "./modalActions";

export const listarBases = () => {
	return async (dispatch) => {
		dispatch({
			type: basesCertTypes.OBTENER_BASES,
		});
		try {
			const bases = await authAxios.get(`/admin/listarBasesCertificados`);
			//  console.log(popup.data.data);

			dispatch({
				payload: bases.data.bases,
				type: basesCertTypes.OBTENER_BASES_EXITO,
			});
		} catch (error) {
			console.log(error);
			avisoError("Hubo un error");
		}
	};
};

export const eliminarCertificado = (id, usuario, producto) => {
	return async (dispatch) => {
		const datos = {
			id_certificado: id,
			usuario: usuario,
			producto: producto,
		};
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-success",
				cancelButton: "btn btn-danger",
			},
			buttonsStyling: false,
		});
		swalWithBootstrapButtons
			.fire({
				title: "¿Estas seguro?",
				text: "Se eliminará todo registro",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Si, continuar!",
				cancelButtonText: "No, cancelar!",
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: async function() {
					try {
						const bases = await authAxios.post(
							`/admin/eliminarCertificado/`,
							datos
						);
						//console.log(bases.data.bases);
						dispatch({
							payload: bases.data,
							type: basesCertTypes.OBTENER_EMITIDOS_EXITO,
						});
						return true;
					} catch (error) {
						avisoError("Hubo un error al eliminar el comunicado");
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				//mensaje que se cambio la contraseña satisfactoriamente
				// console.log(result)
				if (result.value === true) {
					swalWithBootstrapButtons.fire(
						"Eliminado!",
						"Curso se elimino correctamente",
						"success"
					);
				}
			});
	};
};
export const listarCertificadosEmitidos = (datos) => {
	return async (dispatch) => {
		dispatch({
			type: basesCertTypes.OBTENER_EMITIDOS,
		});
		try {
			const bases = await authAxios.get(
				`/admin/listarCertificadosEmitidos/${datos.proyecto}/${datos.nivel}`
			);
			//  console.log(popup.data.data);

			dispatch({
				payload: bases.data,
				type: basesCertTypes.OBTENER_EMITIDOS_EXITO,
			});
		} catch (error) {
			console.log(error);
			avisoError("Hubo un error");
		}
	};
};

export const listarCertificadosPorAprobar = (datos) => {
	return async (dispatch) => {
		dispatch({
			type: basesCertTypes.OBTENER_EMITIDOS,
		});
		try {
			const bases = await authAxios.get(
				`/admin/listarCertificadosPorAprobar/${datos.proyecto}`
			);
			//   console.log(bases);

			dispatch({
				payload: bases.data,
				type: basesCertTypes.OBTENER_EMITIDOS_EXITO,
			});
		} catch (error) {
			console.log(error);
			avisoError("Hubo un error");
		}
	};
};

export const aprobarTodos = (producto) => {
	return async (dispatch) => {
		const swalWithBootstrapButtons = Swal.mixin({});
		swalWithBootstrapButtons
			.fire({
				title: "¿Aprobar todos los certificados para su firma?",
				text: "Revise bien los datos antes de continuar....",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Si, continuar!",
				cancelButtonText: "No, cancelar!",
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: async function() {
					try {
						const bases = await authAxios.get(
							`/admin/aprobarTodosFirma/${producto}`
						);
						console.log(bases);

						dispatch({
							payload: bases.data,
							type: basesCertTypes.OBTENER_EMITIDOS_EXITO,
						});
					} catch (error) {
						swalWithBootstrapButtons.fire(
							"Error!",
							"Ocurrió un error inesperado, vuelva a intentar...",
							"error"
						);
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				//mensaje que se cambio la contraseña satisfactoriamente
				// console.log(result)
				if (result.value === true) {
					swalWithBootstrapButtons.fire(
						"Certificado Aprobado",
						"Todos los certificados aprobados podrán ser firmados",
						"success"
					);
				} else {
					swalWithBootstrapButtons.fire(
						"Proceso cancelado",
						"No, revisar datos para su aprobación",
						"warning"
					);
				}
			});
	};
};

export const aprobarFirma = (id_cert, producto) => {
	return async (dispatch) => {
		const swalWithBootstrapButtons = Swal.mixin({});
		swalWithBootstrapButtons
			.fire({
				title: "¿Aprobar certificado para su firma?",
				text: "Revise bien los datos antes de continuar....",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Si, continuar!",
				cancelButtonText: "No, cancelar!",
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: async function() {
					try {
						const bases = await authAxios.get(
							`/admin/aprobarFirma/${id_cert}/${producto}`
						);
						console.log(bases);

						dispatch({
							payload: bases.data,
							type: basesCertTypes.OBTENER_EMITIDOS_EXITO,
						});
					} catch (error) {
						swalWithBootstrapButtons.fire(
							"Error!",
							"Ocurrió un error inesperado, vuelva a intentar...",
							"error"
						);
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				//mensaje que se cambio la contraseña satisfactoriamente
				// console.log(result)
				if (result.value === true) {
					swalWithBootstrapButtons.fire(
						"Certificado Aprobado",
						"Todos los certificados aprobados podrán ser firmados",
						"success"
					);
				} else {
					swalWithBootstrapButtons.fire(
						"Proceso cancelado",
						"No, revisar datos para su aprobación",
						"warning"
					);
				}
			});
	};
};

export const eliminarTodosCertificado = (producto, usuario) => {
	return async (dispatch) => {
		const swalWithBootstrapButtons = Swal.mixin({});
		swalWithBootstrapButtons
			.fire({
				title: "¿Eliminar todos los certificados?",
				text: "Esta acción no se podrá revertir...",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Si, continuar!",
				cancelButtonText: "No, cancelar!",
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: async function() {
					try {
						const bases = await authAxios.delete(
							`/admin/eliminarCertificadoAll/${producto}/${usuario}`
						);
						//console.log(bases);

						dispatch({
							payload: bases.data,
							type: basesCertTypes.OBTENER_EMITIDOS_EXITO,
						});
					} catch (error) {
						swalWithBootstrapButtons.fire(
							"Error!",
							"Ocurrió un error inesperado, vuelva a intentar...",
							"error"
						);
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				//mensaje que se cambio la contraseña satisfactoriamente
				// console.log(result)
				if (result.value === true) {
					swalWithBootstrapButtons.fire(
						"Certificados eliminados",
						"Todos los certificados han sido eliminados.",
						"success"
					);
				} else {
					swalWithBootstrapButtons.fire(
						"Proceso cancelado",
						"No, revisar datos para su aprobación",
						"warning"
					);
				}
			});
	};
};

export const verificarAptos = (datos) => {
	return async (dispatch) => {
		dispatch({
			type: basesCertTypes.OBTENER_APTOS,
		});
		try {
			const bases = await authAxios.get(
				`/admin/verificarAptos/${datos.proyecto}`
			);
			//  console.log(popup.data.data);

			dispatch({
				payload: bases.data.aptos,
				type: basesCertTypes.OBTENER_APTOS_EXITO,
			});
		} catch (error) {
			console.log(error);
			avisoError("Hubo un error");
		}
	};
};

export const filtrarBases = (datosFiltro) => {
	return async (dispatch) => {
		dispatch({
			type: basesCertTypes.OBTENER_BASES,
		});
		try {
			const bases = await authAxios.post(
				`/admin/filtrarBasesCertificados`,
				datosFiltro
			);

			dispatch({
				payload: bases.data.bases,
				type: basesCertTypes.OBTENER_BASES_EXITO,
			});
		} catch (error) {
			console.log(error);
			avisoError("Hubo un error");
		}
	};
};

export const traerUltimoEvento = () => {
	return async (dispatch) => {
		try {
			const bases = await authAxios.get(`/admin/traerUltimoEvento`);
			//  console.log(popup.data.data);

			if (!bases.error) {
				return bases.data.datos;
			} else {
				return null;
			}
		} catch (error) {
			console.log(error);
			avisoError("Hubo un error");
		}
	};
};

export const nuevaPlantilla = (datos) => async (dispatch) => {
	const file = new FormData();

	file.append("archivo", datos.tabla_contenido);
	file.append("titulo", datos.titulo);
	file.append("n_evento", datos.n_evento);
	file.append("periodo", datos.periodo);
	file.append("n_libro", datos.n_libro);
	file.append("responsable", datos.responsable);
	file.append("producto", datos.producto.id_proyecto);

	try {
		avisoCargando("Subiendo plantilla, espere por favor...");
		const datos = await authAxios.post(`/admin/nuevaPlantilla`, file, {
			headers: { 
				"Content-Type": "multipart/form-data",
			},
		});
		
		// console.log(subida)
		dispatch(updateStateModalLarge());
		dispatch({
			type: basesCertTypes.OBTENER_BASES_EXITO,
			payload: datos.data.respuesta,
		});
		// dispatch(listar(usuario))
	} catch (error) {
		console.log(error);
		avisoError("Hubo un error al subir los archivos");
	}
};

export const emitirPDFs = (aptos, usuario) => {
	return async (dispatch) => {
		const swalWithBootstrapButtons = Swal.mixin({});
		swalWithBootstrapButtons
			.fire({
				title: "¿Estás seguro?",
				text:
					"Este proceso generará los certificados PDF con los datos de la lista, si ya existen certificados es posible sean sustituidos...",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Si, continuar!",
				cancelButtonText: "No, cancelar!",
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: async function() {
					try {
						const docsPDF = await authAxios.post(
							`/admin/emitirDocumentosPDF/${usuario.id_usuario}`,
							aptos
						);
						
						if (docsPDF.error) {
							return false;
						}
						return true;
					} catch (error) {
						swalWithBootstrapButtons.fire(
							"Error!",
							"Ocurrió un error inesperado, vuelva a intentar...",
							"error"
						);
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				//mensaje que se cambio la contraseña satisfactoriamente
				// console.log(result)
				if (result.value === true) {
					swalWithBootstrapButtons.fire(
						"Documentos",
						"Los documentos se generaron correctamente!!",
						"success"
					);
				} else {
					swalWithBootstrapButtons.fire(
						"Proceso cancelado",
						"No se emitieron los documentos PDF",
						"warning"
					);
				}
			});
	};
};

export const emitirPDFTerceros = (aptos, usuario) => {
	return async (dispatch) => {
		const swalWithBootstrapButtons = Swal.mixin({});
		swalWithBootstrapButtons
			.fire({
				title: "¿Estás seguro?",
				text:
					"Este proceso generará los certificados PDF con los datos de la lista, si ya existen certificados es posible sean sustituidos...",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Si, continuar!",
				cancelButtonText: "No, cancelar!",
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: async function() {
					try {
						const docsPDF = await authAxios.post(
							`/admin/emitirDocumentosByEmpresasPDF/${usuario.id_usuario}`,
							aptos
						);
						return docsPDF.data;
						
					} catch (error) {
						console.log('err catch',error);
						swalWithBootstrapButtons.fire(
							"Error!",
							"Ocurrió un error inesperado, vuelva a intentar...",
							"error"
						);
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				// console.log(result)
				if (result.value.status === true) {
					
					swalWithBootstrapButtons.fire(
						"Documentos",
						"Los documentos se generaron correctamente!!",
						"success"
					);
					
				} else {
					swalWithBootstrapButtons.fire(
						"Proceso cancelado",
						"No se emitieron los documentos PDF",
						"warning"
					);
				}
			});
	};
};

export const eliminarBoleta = (id) => {
	return async (dispatch) => {
		const datos = {
			id_registro: id,
		};
		const swalWithBootstrapButtons = Swal.mixin({});
		swalWithBootstrapButtons
			.fire({
				title: "¿Estas seguro?",
				text: "Se eliminara todo registro",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Si, continuar!",
				cancelButtonText: "No, cancelar!",
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: async function() {
					try {
						const boletas = await authAxios.delete(
							`/admin/eliminarBoleta/${datos.id_registro}`
						);

						dispatch({
							payload: boletas.data.boletas,
							type: basesCertTypes.OBTENER_BOLETAS_EXITO,
						});
						return true;
					} catch (error) {
						avisoError("Hubo un error al eliminar el documento");
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				//mensaje que se cambio la contraseña satisfactoriamente
				// console.log(result)
				if (result.value === true) {
					swalWithBootstrapButtons.fire(
						"Eliminado!",
						"Documento eliminado correctamente",
						"success"
					);
				}
			});
	};
};

export const eliminarBaseCert = (id) => {
	return async (dispatch) => {
		const datos = {
			id_registro: id,
		};
		const swalWithBootstrapButtons = Swal.mixin({});
		swalWithBootstrapButtons
			.fire({
				title: "¿Estas seguro?",
				text: "Se eliminara todo registro",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Si, continuar!",
				cancelButtonText: "No, cancelar!",
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: async function() {
					try {
						const boletas = await authAxios.delete(
							`/admin/eliminarBaseCert/${datos.id_registro}`
						);

						dispatch({
							payload: boletas.data.bases,
							type: basesCertTypes.OBTENER_BASES_EXITO,
						});
						return true;
					} catch (error) {
						avisoError("Hubo un error al eliminar el documento");
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				//mensaje que se cambio la contraseña satisfactoriamente
				// console.log(result)
				if (result.value === true) {
					swalWithBootstrapButtons.fire(
						"Eliminado!",
						"Documento eliminado correctamente",
						"success"
					);
				}
			});
	};
};
