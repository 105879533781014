import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarAsesores, listarFuentes } from '../actions/leadsActions';

const useSelectFuenteMulti = (initialSelect = null) => {

  const dispatch = useDispatch(); 
  const {fuentes} = useSelector(state => state.leads)

  const [fuenteSeleccionado, setFuenteSelccionado] = useState(null); 

  const handleInitialAction = () => {
    if(initialSelect) {
      const fuenteSelect = fuentes.find(fuente => fuente.id_usuario === parseInt(initialSelect)); 
      if(fuenteSelect) {
        setFuenteSelccionado(fuenteSelect);
      }
    }
  }

  useEffect(() => {
    if(!fuentes.length) {
      dispatch(listarFuentes())
    }
  }, [])

  useEffect(() => {
    handleInitialAction();
  }, [fuentes])

  const SimpleSelect = props => {
    return (
      <Select
        isMulti
        isDisabled={false}
        isClearable={true}
        isRtl={false}
        isLoading={!fuentes.length}
        loadingMessage={() => 'Cargando...'}
        isSearchable={true}
        onChange={fuente => setFuenteSelccionado(fuente)}
        value={fuenteSeleccionado}
        getOptionValue={fuente => fuente.id_fuente}
        getOptionLabel={fuente => fuente.nombre_fuente}
        options={fuentes}
        placeholder='--Seleccione--'
        noOptionsMessage={() => 'No hay resultados'}
        {...props}
      />
    )
  }

  return [fuenteSeleccionado, SimpleSelect, setFuenteSelccionado]

}
 
export default useSelectFuenteMulti;