import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';

const LineMontoVentasMultiAxis = ({ datos }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current && chartRef.current.chartInstance) {
            chartRef.current.chartInstance.update();
        }
    }, [datos]);

    const data = {
        labels: datos.labels,
        datasets: datos.dataSets.map((set, index) => ({
            label: set.label,
            data: set.data,
            borderColor: set.borderColor || `rgba(${index * 50}, 99, 132, 1)`,
            backgroundColor: set.backgroundColor || `rgba(${index * 50}, 99, 132, 0.2)`,
            fill: false,
        })),
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            text: datos.title,
            fontSize: 20
        },
        legend: {
            display: true,
            position: 'top'
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    return (
        <div style={{ height: '400px' }}>
            <Line ref={chartRef} data={data} options={options} />
        </div>
    );
};

export default LineMontoVentasMultiAxis;
