import React, { useEffect, useState } from "react";
import { updateStateModalCustom } from "../../../../actions/modalActions";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import "./RegistroFactura.scss";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { traerDatosPago, validarPagoPension } from "../../../../actions/pagosActions";
import { Spinner } from "react-bootstrap";
import { avisoError } from "../../AvisosMensajes/MensajesSwalf";

const RevisarPago = ({idPensionFraccion}) => {
  const[loading,setLoading] = useState(true)
  const[dataPension,setDataPension] = useState(null)
  const[codeValidation,setCodeValidation] = useState("")
  const dispatch = useDispatch();



  const handleRevisarPago =()=>{
    if(codeValidation!=="")
    dispatch(validarPagoPension(dataPension.id_cuota_pago,dataPension.detalle.id_dt_matricula_proyecto,dataPension.detalle.id_matricula_proyecto,codeValidation))
    else
    avisoError("Debes ingresar el codigo de 'Usuario' que aparece en el reporte de Telecrédito o el código de Autorización de Culqi.")
  }
  const handleCloseModal = () => {
    dispatch(updateStateModalCustom());
  };

  useEffect(()=>{
      const fetchPago = async ()=>{
        const rpta = await dispatch(traerDatosPago(idPensionFraccion));
        if(rpta)
        {
          //console.log(rpta)
          setDataPension(rpta.data)
        }
        setLoading(false)
      }

      fetchPago()
  },[])

  return (
    <div className="card">
    <div className="card-body p-4">
       { !loading ? <div className="row">
            <div className="col-md-6">
              <h5>Comprobante:</h5>
              <div className="my-3">
                  <img src={`${process.env.REACT_APP_API_URL}/${dataPension.voucher}`} alt="..." className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6">
                <h5>Detalles:</h5>
                <div className="my-3" style={{fontSize:'15px'}}>
                <div className="d-flex justify-content-between my-2">
                  <span><b>Discente:{" "}</b></span>
                  <span>{dataPension.detalle.nombre_usuario} {dataPension.detalle.apellido_usuario}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                  <span><b>N° Documento:{" "}</b></span>
                  <span>{dataPension.id_tp_serie}-{dataPension.nro_documento}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                  <span><b>Fecha registro:{" "}</b></span>
                  <span>{dataPension.fecha}</span>
                </div>
               { dataPension.ticket ? <>
                <div className="d-flex justify-content-between my-2">
                  <span><b>N° Operación:{" "}</b></span>
                  <span>{dataPension.ticket}</span>
                </div>
               </>: <>
               <div className="d-flex justify-content-between my-2">
                  <span><b>Banco:{" "}</b></span>
                  <span>{dataPension.nombre_banco}</span>
                </div>
                <div className="d-flex justify-content-between my-2">
                  <span><b>N° Operación / Pedido:{" "}</b></span>
                  <span>{dataPension.vaucher}</span>
                </div>
               </>}
                </div>
                <div className="d-flex justify-content-between my-2">
                  <div className="form-group">
                    <label>Código de Verificación:</label>
                    <input type="text" className="form-control" name="codeValidation" value={codeValidation} onChange={(e)=> setCodeValidation(e.target.value)} autoComplete="off" />
                  </div>
                </div>
                <div className="my-3">
                  <button className="btn btn-primary" onClick={handleRevisarPago}>
                    Confirmar
                  </button>
                  <button className="btn btn-danger" onClick={handleCloseModal}>
                    Cerrar
                  </button>
                </div>
            </div>
            
        </div> : <div className="text-center"> <Spinner animation="border" variant="dark" /> </div>}
    </div>
    </div>
  );
};

export default RevisarPago;
