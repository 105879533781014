import React, { useEffect } from 'react';
import Layout from '../../../app/Layout';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'



//Redux
import {useDispatch, useSelector } from 'react-redux';
import useSelectProyecto from '../../../hooks/useMultiSelectProyectos';
import BarChart from './BarChart';
import {obtenerIndicadores} from '../../../actions/reportesActions'
import PieChart from './PieChart';
import PolarChart from './PolarChart'
import RadarChart from './RadarChart'
import DoughnutChart from './DoughnutChart';
import HorizontalBarChart from './HorizontalBarChart';
const ReporteIndicadores = () => {

  const { loading, error, indicadores } = useSelector(state => state.reporteIndicadoresPosVenta);
  const [seleccionProyecto, SelectProyecto] = useSelectProyecto(null);
  const dispatch = useDispatch();
  const consultarIndicadores = () =>{
    // if (proyecto && Object.keys(proyecto).length > 0) {
      //console.log(proyecto);
      dispatch(obtenerIndicadores(seleccionProyecto));
   //  }
 }
 

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='Reporte de Indicadores' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Reportes" },
              { url: "", nombre: "Indicadores" },
            ]}
          />
        </div>
        <div className="ControlReportes__body">
          <div className="box box-primary p-3">
            <div className="box-body ">
              <div className="row">
                <div className="p-2">
                  <label>Seleccione un Producto:</label>
                </div>
                <div className="col-md-6">
                <SelectProyecto />
                </div>
                <div className="col-md-4 pt-1">
                <button className="btn btn-primary" onClick={consultarIndicadores}> <i className="fa fa-search" style={{marginRight:'5px'}}></i>Consultar</button>
                </div>
                <div className="col-md-2">
                  <p style={{padding:'10px', fontSize:'20px', fontWeight:'500'}}>{indicadores.cantidad_total ? ' Universo: ' + indicadores.cantidad_total :' Universo: 0'  }</p>
                </div>
              </div>
            </div>
          </div>    
       { indicadores.edades ?  <SpinnerLoading loading={loading} error={error}>
            
          <div className="row">
            <div className="col-md-6">
            <BarChart info={indicadores.edades} color="3" />
            </div>
            <div className="col-md-6">
            <BarChart info={indicadores.generos} color="1" />
            </div>
            <div className="col-md-6">
            <PieChart info={indicadores.grados} />
            </div>
            <div className="col-md-6">
            <BarChart info={indicadores.area_labor} color="1" />
            </div>
            <div className="col-md-6">
            <PolarChart info={indicadores.sectores} />
            </div>
            <div className="col-md-6">
            <BarChart info={indicadores.factor} color="4" />
            </div>
           
            <div className="col-md-12">
            <BarChart info={indicadores.departamentos} color="1"/>
            </div>
            <div className="col-md-6">
            <BarChart info={indicadores.niveles} color="2" />
            </div>
            <div className="col-md-6">
            <BarChart info={indicadores.modalidad} color="0"/>
            </div>
            <div className="col-md-6">
            <DoughnutChart info={indicadores.metodo_pago}/>
            </div>
            <div className="col-md-6">
            <BarChart info={indicadores.medio_conocimiento} color="1"/>
            </div>
            <div className="col-md-6">
            <BarChart info={indicadores.momento_decision} color="3"/>
            </div>
            <div className="col-md-6">
            <BarChart info={indicadores.tiempo} color="5" />
            </div>
            <div className="col-md-12">
            <HorizontalBarChart  info={indicadores.carreras} />
            </div>
          </div>
          </SpinnerLoading> : ''}
        </div>
      </div>
    </Layout>
  );
}
 
export default ReporteIndicadores;