import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import useSelectAsesores from '../../../hooks/useSelectAsesores';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import html2pdf from 'html2pdf.js';
import logo from './image.png';

import './Proforma.scss';

// Importar las acciones de Redux
import { connect } from 'react-redux';
import { guardarProforma } from '../../../actions/leadsActions';

const CrearProforma = ({ guardarProforma }) => {
    const [proforma, setProforma] = useState({
        nombreComercial: '',
        razonSocial: '',
        ruc: '',
        cuentaCorrienteBCP: '',
        codigoInterbancario: '',
        cuentaDetracciones: '',
        rnp: '',
        direccion: '',
        correoElectronico: '',
        constoriaCapacitacion: [
            { asesor: null }
        ],
        clienteNombre: '',
        clienteRazonSocial: '',
        clienteRUC: '',
        clienteDireccion: '',
        fecha: '',
        productos: [
            { producto: null, duracion: '', numeroBeneficiarios: '', inversionRegular: '', inversionInstitucional: '' }
        ],
        diasVencimiento: '',
        terminos: '',
    });

    const [proyecto, SelectProyecto] = useSelectProyecto(null);
    const [asesor, SelectAsesor] = useSelectAsesores(null);

    const handleProductChange = (index, key, value) => {
        const updatedProducts = [...proforma.productos];
        updatedProducts[index][key] = value;
        setProforma({ ...proforma, productos: updatedProducts });
    };

    const addProducto = () => {
        setProforma({
            ...proforma,
            productos: [
                ...proforma.productos,
                { producto: null, duracion: '', numeroBeneficiarios: '', inversionRegular: '', inversionInstitucional: '' }
            ]
        });
    };

    const removeProducto = (index) => {
        const updatedProducts = [...proforma.productos];
        updatedProducts.splice(index, 1);
        setProforma({ ...proforma, productos: updatedProducts });
    };

    const handleAsesorChange = (index, value) => {
        const updatedAsesores = [...proforma.constoriaCapacitacion];
        updatedAsesores[index].asesor = value;
        setProforma({ ...proforma, constoriaCapacitacion: updatedAsesores });
    };

    const addAsesor = () => {
        setProforma({
            ...proforma,
            constoriaCapacitacion: [
                ...proforma.constoriaCapacitacion,
                { asesor: null }
            ]
        });
    };

    const removeAsesor = (index) => {
        const updatedAsesores = [...proforma.constoriaCapacitacion];
        updatedAsesores.splice(index, 1);
        setProforma({ ...proforma, constoriaCapacitacion: updatedAsesores });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Validaciones específicas por campo
        switch (name) {
            case 'ruc':
            case 'clienteRUC':  // Aplicar las mismas restricciones al RUC del cliente
                if (/^\d{0,11}$/.test(value)) {
                    setProforma({ ...proforma, [name]: value });
                }
                break;
            case 'cuentaCorrienteBCP':
            case 'codigoInterbancario':
            case 'cuentaDetracciones':
                if (/^\d{0,20}$/.test(value)) {
                    setProforma({ ...proforma, [name]: value });
                }
                break;
            case 'correoElectronico':
                if (value.length <= 100) {  // Permitir cualquier entrada y validar en el momento de guardar
                    setProforma({ ...proforma, [name]: value });
                }
                break;
            case 'nombreComercial':
            case 'razonSocial':
                if (value.length <= 150 && /^[\w\s\.,-/]*$/.test(value)) {
                    setProforma({ ...proforma, [name]: value });
                }
                break;
            case 'rnp':
                if (/^[\d-]{0,12}$/.test(value)) {
                    setProforma({ ...proforma, [name]: value });
                }
                break;
            case 'direccion':
                if (value.length <= 200 && /^[\w\s\.,-/]*$/.test(value)) {
                    setProforma({ ...proforma, [name]: value });
                }
                break;
            case 'inversionRegular':
            case 'inversionInstitucional':
                // Permitir solo números enteros o decimales
                if (/^\d*\.?\d{0,2}$/.test(value)) {
                    setProforma({ ...proforma, [name]: value });
                }
                break;
            default:
                setProforma({ ...proforma, [name]: value });
                break;
        }
    };

    const handleSave = () => {
        let errores = [];

        // Validar RUC
        if (!/^\d{11}$/.test(proforma.ruc)) {
            errores.push("El RUC de la empresa debe tener 11 dígitos.");
        }
        if (!/^\d{11}$/.test(proforma.clienteRUC)) {
            errores.push("El RUC del cliente debe tener 11 dígitos.");
        }

        // Validar Correo Electrónico
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(proforma.correoElectronico)) {
            errores.push("El correo electrónico no tiene un formato válido.");
        }

        // Validar campos obligatorios de Datos de la Empresa
        if (!proforma.nombreComercial.trim()) {
            errores.push("El Nombre Comercial es obligatorio.");
        }
        if (!proforma.razonSocial.trim()) {
            errores.push("La Razón Social es obligatoria.");
        }
        if (!proforma.direccion.trim()) {
            errores.push("La Dirección es obligatoria.");
        }

        // Validar campos obligatorios de Datos del Cliente
        if (!proforma.clienteNombre.trim()) {
            errores.push("El Nombre del Cliente es obligatorio.");
        }
        if (!proforma.clienteRazonSocial.trim()) {
            errores.push("La Razón Social del Cliente es obligatoria.");
        }
        if (!proforma.clienteDireccion.trim()) {
            errores.push("La Dirección del Cliente es obligatoria.");
        }

        // Validar campos de Detalles del Producto
        proforma.productos.forEach((producto, index) => {
            if (!producto.producto) {
                errores.push(`El Producto en la fila ${index + 1} es obligatorio.`);
            }
            if (!producto.duracion.trim()) {
                errores.push(`La Duración en la fila ${index + 1} es obligatoria.`);
            }
            if (!/^\d+(\.\d{1,2})?$/.test(producto.inversionRegular)) {
                errores.push(`La Inversión Regular en la fila ${index + 1} debe ser un número válido.`);
            }
            if (!/^\d+(\.\d{1,2})?$/.test(producto.inversionInstitucional)) {
                errores.push(`La Inversión Institucional en la fila ${index + 1} debe ser un número válido.`);
            }
        });

        // Mostrar errores o guardar
        if (errores.length > 0) {
            Swal.fire({
                title: 'Errores en el formulario',
                html: errores.join('<br>'),
                icon: 'error',
            });
        } else {
            // Despachar la acción para guardar la proforma
            guardarProforma(proforma);
        }
    };

    useEffect(() => {
        const generarCodigo = () => {
            // Genera un código de proforma en el formato deseado "N°0068-OP-PUBLICA.DOC"
            const numero = String(Math.floor(Math.random() * 1000)).padStart(4, '0'); // Asegura que el número tenga 4 dígitos
            const codigo = `N°${numero}-OP-PUBLICA.DOC`;
            setProforma(prevProforma => ({
                ...prevProforma,
                codigoProforma: codigo
            }));
        };
        generarCodigo();
    }, []);

    const handleExportar = () => {
        const element = document.getElementById('proformaPdf');
        element.style.display = 'block';

        const options = {
            margin: 0.5, // Reducir margen para que todo quepa en una página
            filename: 'proforma.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf().from(element).set(options).save().then(() => {
            element.style.display = 'none';
        });
    };

    return (
        <Layout>
            <div className="Proforma">
                <div className="Proforma__container">
                    <div className="Proforma__head">
                        <HeaderContent title="Crear Proforma" icon="file-alt" />
                        <Breadcrumb
                            link={[
                                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                                { url: "", nombre: "Proformas" },
                            ]}
                        />
                    </div>
                    <div className="Proforma__body">
                        {/* Sección Empresa */}
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan="4">Datos de la Empresa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Nombre Comercial:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="nombreComercial"
                                                value={proforma.nombreComercial}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>Razón Social:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="razonSocial"
                                                value={proforma.razonSocial}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>RUC:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="ruc"
                                                value={proforma.ruc}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>Cuenta Corriente BCP:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="cuentaCorrienteBCP"
                                                value={proforma.cuentaCorrienteBCP}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Código de Cuenta Interbancaria:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="codigoInterbancario"
                                                value={proforma.codigoInterbancario}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>Cuenta de Detracciones:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="cuentaDetracciones"
                                                value={proforma.cuentaDetracciones}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R.N.P N°:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="rnp"
                                                value={proforma.rnp}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>Dirección:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="direccion"
                                                value={proforma.direccion}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Correo Electrónico:</td>
                                        <td>
                                            <input
                                                type="email"
                                                name="correoElectronico"
                                                value={proforma.correoElectronico}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>Consultoría y Capacitación:</td>
                                        <td>
                                            {proforma.constoriaCapacitacion.map((item, index) => (
                                                <div key={index} className="mb-2">
                                                    <SelectAsesor
                                                        value={item.asesor}
                                                        onChange={(value) => handleAsesorChange(index, value)}
                                                        style={{
                                                            width: '100%',
                                                            maxHeight: '38px',
                                                            overflowY: 'auto',
                                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                            border: '1px solid #ced4da',
                                                        }}
                                                    />
                                                    {index === proforma.constoriaCapacitacion.length - 1 && (
                                                        <div className="d-flex mt-2">
                                                            <Button variant="success" onClick={addAsesor} className="mr-2">
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </Button>
                                                            <Button variant="danger" onClick={() => removeAsesor(index)}>
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* Sección Cliente */}
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan="4">Datos del Cliente</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Nombre:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="clienteNombre"
                                                value={proforma.clienteNombre}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>Razón Social:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="clienteRazonSocial"
                                                value={proforma.clienteRazonSocial}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>R.U.C. N°:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="clienteRUC"
                                                value={proforma.clienteRUC}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                        <td>Dirección:</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="clienteDireccion"
                                                value={proforma.clienteDireccion}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Fecha:</td>
                                        <td>
                                            <input
                                                type="date"
                                                name="fecha"
                                                value={proforma.fecha}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </td>
                                        <td colSpan="2"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* Sección Producto */}
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan="6">Detalles del Producto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {proforma.productos.map((prod, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>Producto:</td>
                                                <td>
                                                    <SelectProyecto
                                                        value={prod.producto}
                                                        onChange={(value) => handleProductChange(index, 'producto', value)}
                                                    />
                                                </td>
                                                <td>Duración:</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`duracion-${index}`}
                                                        value={prod.duracion}
                                                        onChange={(e) => handleProductChange(index, 'duracion', e.target.value)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td colSpan="2" className="text-center">
                                                    <div className="d-flex justify-content-center">
                                                        <Button variant="success" onClick={addProducto} className="mr-2">
                                                            <FontAwesomeIcon icon={faPlus} />
                                                        </Button>
                                                        <Button variant="danger" onClick={() => removeProducto(index)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>N° Beneficiarios:</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`numeroBeneficiarios-${index}`}
                                                        value={prod.numeroBeneficiarios}
                                                        onChange={(e) => handleProductChange(index, 'numeroBeneficiarios', e.target.value)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td>Inversión Regular:</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`inversionRegular-${index}`}
                                                        value={prod.inversionRegular}
                                                        onChange={(e) => handleProductChange(index, 'inversionRegular', e.target.value)}
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td>Inversión Institucional:</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`inversionInstitucional-${index}`}
                                                        value={prod.inversionInstitucional}
                                                        onChange={(e) => handleProductChange(index, 'inversionInstitucional', e.target.value)}
                                                        className="form-control"
                                                    />
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Sección Días de Vencimiento */}
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Días de Vencimiento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input
                                                type="number"
                                                name="diasVencimiento"
                                                value={proforma.diasVencimiento}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder="Ingrese los días de vencimiento"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* Sección Términos */}
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Términos</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <textarea
                                                name="terminos"
                                                value={proforma.terminos}
                                                onChange={handleChange}
                                                className="form-control"
                                                rows="4"
                                            ></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <h3>Términos y Condiciones</h3>
                            <p>{proforma.terminos}</p>
                            {proforma.diasVencimiento && (
                                <p>
                                    Quedan <strong>{proforma.diasVencimiento} días hábiles</strong> para que venza.
                                </p>
                            )}
                        </div>

                        <div id="proformaPdf" style={{ display: 'none', fontFamily: 'Roboto, sans-serif', color: '#333' }}>
                            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                <img src={logo} alt="Logo de la empresa" style={{ maxWidth: '120px', height: 'auto', marginBottom: '10px' }} />
                                <h1 style={{ fontSize: '28px', color: '#2C3E50', marginBottom: '5px' }}>
                                    PROFORMA N° {proforma.codigoProforma}
                                </h1>
                                <p style={{ fontSize: '14px', color: '#7F8C8D' }}>Fecha: {proforma.fecha}</p>
                            </div>

                            <div style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '8px', marginBottom: '20px' }}>
                                <h2 style={{ fontSize: '20px', color: '#34495E', marginBottom: '10px' }}>Datos de la Empresa</h2>
                                <p><strong>NOMBRE COMERCIAL:</strong> {proforma.nombreComercial}</p>
                                <p><strong>RAZÓN SOCIAL:</strong> {proforma.razonSocial}</p>
                                <p><strong>RUC N°:</strong> {proforma.ruc}</p>
                                <p><strong>CUENTA CORRIENTE BCP:</strong> {proforma.cuentaCorrienteBCP}</p>
                                <p><strong>CÓDIGO DE CUENTA INTERBANCARIA:</strong> {proforma.codigoInterbancario}</p>
                                <p><strong>CUENTA DE DETRACCIONES:</strong> {proforma.cuentaDetracciones}</p>
                                <p><strong>RNP N°:</strong> {proforma.rnp}</p>
                                <p><strong>DIRECCIÓN:</strong> {proforma.direccion}</p>
                                <p><strong>CORREO ELECTRÓNICO:</strong> {proforma.correoElectronico}</p>
                            </div>

                            <div style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '8px', marginBottom: '20px' }}>
                                <h2 style={{ fontSize: '20px', color: '#34495E', marginBottom: '10px' }}>Datos del Cliente</h2>
                                <p><strong>CLIENTE:</strong> {proforma.clienteNombre}</p>
                                <p><strong>RAZÓN SOCIAL:</strong> {proforma.clienteRazonSocial}</p>
                                <p><strong>RUC N°:</strong> {proforma.clienteRUC}</p>
                                <p><strong>DIRECCIÓN:</strong> {proforma.clienteDireccion}</p>
                            </div>

                            <div style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '8px', marginBottom: '20px' }}>
                                <h2 style={{ fontSize: '20px', color: '#34495E', marginBottom: '10px' }}>Detalles del Producto</h2>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid #ccc', padding: '10px', backgroundColor: '#ecf0f1', textAlign: 'left' }}>SERVICIO</th>
                                            <th style={{ border: '1px solid #ccc', padding: '10px', backgroundColor: '#ecf0f1', textAlign: 'left' }}>DURACIÓN</th>
                                            <th style={{ border: '1px solid #ccc', padding: '10px', backgroundColor: '#ecf0f1', textAlign: 'left' }}>N° BENEFICIARIOS</th>
                                            <th style={{ border: '1px solid #ccc', padding: '10px', backgroundColor: '#ecf0f1', textAlign: 'left' }}>INVERSIÓN REGULAR</th>
                                            <th style={{ border: '1px solid #ccc', padding: '10px', backgroundColor: '#ecf0f1', textAlign: 'left' }}>INVERSIÓN INSTITUCIONAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {proforma.productos.map((prod, index) => (
                                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9' }}>
                                                <td style={{ border: '1px solid #ccc', padding: '10px' }}>
                                                    {prod.producto ? prod.producto.nombre_proyecto : 'Producto no seleccionado'}
                                                </td>
                                                <td style={{ border: '1px solid #ccc', padding: '10px' }}>{prod.duracion}</td>
                                                <td style={{ border: '1px solid #ccc', padding: '10px' }}>{prod.numeroBeneficiarios}</td>
                                                <td style={{ border: '1px solid #ccc', padding: '10px' }}>{prod.inversionRegular}</td>
                                                <td style={{ border: '1px solid #ccc', padding: '10px' }}>{prod.inversionInstitucional}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ marginBottom: '20px' }}>
                                <h2 style={{ fontSize: '20px', color: '#34495E', marginBottom: '10px' }}>Términos y Condiciones</h2>
                                <p style={{ textAlign: 'justify' }}>{proforma.terminos}</p>
                                {proforma.diasVencimiento && (
                                    <p style={{ fontStyle: 'italic' }}>Quedan <strong>{proforma.diasVencimiento} días hábiles</strong> para que venza.</p>
                                )}
                            </div>

                            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                <p style={{ marginBottom: '40px', borderBottom: '1px solid #333', width: '200px', margin: '0 auto' }}></p>
                                <p style={{ marginBottom: '0' }}>JOSÉ ALEXCIS DE LA ROCA HUAMÁN</p>
                                <p>DIRECTOR</p>
                                <p style={{ marginTop: '10px', fontStyle: 'italic' }}>{new Date().toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                            </div>

                            <footer style={{ marginTop: '40px', textAlign: 'center', fontSize: '12px', color: '#7F8C8D' }}>
                                <p>Dirección de la empresa | Teléfono: 123-456-7890 | www.empresa.com</p>
                            </footer>
                        </div>


                        {/* Botones Guardar y Exportar */}
                        <div className="text-center">
                            <Button variant="primary" onClick={handleSave}>
                                Guardar
                            </Button>
                            <Button variant="secondary" onClick={handleExportar} className="ml-2">
                                Exportar PDF
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

// Mapeo de dispatch a props
const mapDispatchToProps = {
    guardarProforma,
};

// Conexión del componente con Redux
export default connect(null, mapDispatchToProps)(CrearProforma);
