import React from "react";
import DataTable from 'react-data-table-component';

const DataTableComponent = ({colums, tabla, keyField, setSeleccion, select=false,hideHeader=false}) => {


  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '4px', // override the cell padding for head cells
        paddingRight: '4px',
      },
    },
    cells: {
      style: {
        paddingLeft: '4px', // override the cell padding for data cells
        paddingRight: '4px',
      },
    },
  };

  return(
    <div className="p-2">
      <DataTable
        data={tabla}
        columns={colums}
        keyField={keyField}
        pagination
        paginationRowsPerPageOptions={[5,10,15,20,25,30,35,40,45,50,55,65,75,85,100,200]}
        selectableRows={select}
        selectableRowsVisibleOnly
        onSelectedRowsChange={setSeleccion}
        customStyles={customStyles}
        noHeader={hideHeader}
      />
    </div>
  )
}

export default DataTableComponent