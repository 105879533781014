export const cambiarAside = () => {
    let aside = document.getElementById("m-nav")
    if (aside.classList.contains('d-block')) {
        aside.classList.remove('d-block')
        aside.classList.add('d-none')
        return
    }

    if (aside.classList.contains('d-none')) {

      aside.classList.remove('d-none')
      aside.classList.add('d-block')
      return
  }
 
}