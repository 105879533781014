import React, { useEffect } from 'react';
import HeaderContent from '../../General/HeaderContent';

import './EncuestaDocente.scss';
import Layout from '../../../../app/Layout';
import Breadcrumb from '../../General/Breadcrumb';
import { useParams } from 'react-router-dom';
import Rating from '../../../components/plugins/Rating';

//Redux
import { connect } from 'react-redux'
import { obtenerEncuestaDocente } from '../../../../actions/reportesActions';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';

const EncuestaDocente = props => {

  const params = useParams()
  const { idTutor, idDocenteFormulario } = params

  const { encuestaDocente, obtenerEncuestaDocente } = props

  useEffect(() => {
    obtenerEncuestaDocente(idDocenteFormulario)
  }, [])
  
  return (
    <Layout>
      <HeaderContent title='Resultado encuesta docente' />
      <Breadcrumb
          link={[
            {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
            {url:`/tutores/${idTutor}/detalles`, nombre: 'Eventos'},
            {url:'', nombre: 'Resultados de encuesta'}
          ]}
        />
        <div className="encuesta-docente">
          <SpinnerLoading loading={Object.keys(encuestaDocente).length === 0}>
          <div className="content-resultado-encuesta  h-100">
            <h2 className='content-resultado-encuesta__titulo'>
              { encuestaDocente.nombre_formulario }
            </h2>
            <p style={{marginBottom:'10px'}}><strong>Docente:</strong> {encuestaDocente.nombre_usuario} {encuestaDocente.apellido_usuario}</p>
            <p style={{marginBottom:'10px'}}><strong>Sesion:</strong> {encuestaDocente.nombre_sesion}</p>
            <p style={{marginBottom:'10px'}}><strong>Fecha:</strong> {encuestaDocente.fecha_sesion}</p>
            <div className="content-resultado-encuesta__respuestas">
              { encuestaDocente.preguntas !== undefined &&
                encuestaDocente.preguntas.map((pregunta, index) => (
                <Respuestas
                  key={index}
                  index={index}
                  pregunta={pregunta}
                />
              ))}
            </div>
          </div>
          </SpinnerLoading>
          
        </div>
    </Layout>
   );
}

const mapStateToProps = state => ({
  encuestaDocente: state.encuestas.encuestaDocente
})

const mapDispatchToProps = {
  obtenerEncuestaDocente
}

export default connect(mapStateToProps, mapDispatchToProps)(EncuestaDocente);

const Respuestas = props => {

  const { index, pregunta } = props
  if(!pregunta) return null
  return (
    <div className="repuestas__item">
      <p className="label">
        {index + 1}.- {pregunta.pregunta}
      </p>
      <p className='my-2 mx-3'>Respuesta</p>
      {pregunta.id_tp_pregunta === '1' ? (
        <div className='mx-3'>
          <Rating
            defaultStars={parseInt(pregunta.contenido)}
            disabled={true}
          />
          {pregunta.propuestas && pregunta.propuestas.map(propuesta => (
            <SubRespuestas
              subpregunta={propuesta}
            />
          ))}
        </div>
      ) : (
        <div className='mx-3'>
         <p className='respuesta'>" {pregunta.contenido} "</p>
        </div>
      )}
    </div>
  )
} 

const SubRespuestas = props => {
  
  const { subpregunta = '' } = props

  return (
    <div className='subpregunta'>
      <div className='subpregunta__label'>
        <p>{subpregunta.nombre_tp_tema_encuesta}</p>
      </div>
      <p className='subpregunta__respuesta'>
        {subpregunta.respuesta}
      </p>
    </div>
  )
} 