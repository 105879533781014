import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
//Redux
import { useDispatch } from 'react-redux';
import { actualizarLlamadaAhora, crearLlamadaAhora } from '../../../actions/leadsActions';
import Timer from '../../components/TimerCall/Timer';

const FormularioLlamarAhora = ({registro}) => {

  const dispatch = useDispatch();
  const initialState = {
    id_llamada: registro.id_llamada?registro.id_llamada:null,
    id_lead:registro.id_lead?registro.id_lead:null,
    destino:registro.destino?registro.destino:null,
    tipo_llamada:1,
    asunto:registro.asunto?registro.asunto:'',
    proposito_llamada:registro.proposito_llamada?registro.proposito_llamada	: '',
    id_usuario: registro.asesor ? registro.asesor: null,
    tiempo_llamada:0,
    tiempo_conversado:0,
    anotaciones: registro.anotaciones ? registro.anotaciones : '',
  }
  const [datosLead,setdatosLead]=useState(initialState)
  const [seconds,setSeconds] = useState(0)
  const [inicioConversacion,setInicioConversacion]=useState(0)
  const {asunto,proposito_llamada, anotaciones} = datosLead
  const [disabled,setDisabled] = useState(false)
  const handleChange = (evt) => {
    const value = evt.target.value;
    setdatosLead({
      ...datosLead,
      [evt.target.name]: value
    });
  }

  const creaLlamadaAhora = async (e) =>{
    e.persist();
    e.preventDefault();

    // console.log(datosLead)

    if(registro.id_lead)
    {
      setDisabled(true)
      await dispatch(crearLlamadaAhora(datosLead,registro))
      setDisabled(false)
      setSeconds(0)
      setInicioConversacion(0)
      setdatosLead({
        ...datosLead,
        anotaciones:''
      })
      e.target.reset()
    }
    else
    {
      await dispatch(actualizarLlamadaAhora(datosLead))
      // console.log(datosLead)
    }
  }

  const setTiempoLLamada = (tiempo)=>{
    setdatosLead({
      ...datosLead,
      tiempo_llamada:tiempo,
      tiempo_conversado: inicioConversacion > 0 ? tiempo-inicioConversacion : 0
    })
  }

  const setTiempoConversado = (t) =>{
    setdatosLead({
      ...datosLead,
      tiempo_conversado:seconds-t
    })
  }

  useEffect(()=>{
    setTiempoLLamada(seconds)
  },[seconds])


  return (
    <div style={{padding:'15px'}}>
      <form onSubmit={(e) => creaLlamadaAhora(e)}>
        <div className="row">
        {/*  registro.nombres ? <div className="col-6">
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Lead:</label>
            <p>{registro.nombres} {registro.apellidos}</p>
          </Box>
          </div>:null*/}
          <div className='col-12' >
          <Timer seconds={seconds} setSeconds={setSeconds} setRespondido={setInicioConversacion} inicioConversacion={inicioConversacion} />
          </div>
          <div className="col-12">
          <h4>Datos de llamada</h4>
          <br/>
         {/* <Box component="fieldset" mb={3} borderColor="transparent">
          <label htmlFor="">Asunto:</label>
            <input type="text" name="asunto" value={asunto} className='form-control' onChange={handleChange} required />
          </Box>*/}
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Propósito:</label>
            <select name="proposito_llamada" className='form-control' value={proposito_llamada} onChange={handleChange} required >
              <option value="">Escoge una opción...</option>
              <option value="2"> Venta </option>
              <option value="3"> Seguimiento de Venta</option>
              <option value="1"> Posventa</option>
            </select>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Anotaciones:</label>
            <textarea name="anotaciones" className='form-control' value={anotaciones} onChange={handleChange} required >
      
            </textarea>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent" >
          <button type="submit" className="btn btn-primary btn-submit mr-2" disabled={disabled}>
           Guardar llamada
          </button>
          </Box>
          </div>
        </div>
      </form>
  </div>
   );
}
 
export default FormularioLlamarAhora;