import { Box } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

import EmailEditor from 'react-email-editor';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listarProyectos } from '../../../actions/cursosActions';
import { actualizarPlantillaCorreo, guardarPlantillaCorreo, traerPlantilla } from '../../../actions/leadsActions';
import Layout from '../../../app/Layout';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';


const MailEditorSN = () => {
    const emailEditorRef = useRef(null);
    const proyectos = useSelector(state => state.cursos.proyectos)
    const {id_plantilla} = useParams()
    const dispatch=useDispatch();

    const [plantilla,setPlantilla]=useState({
        nombre_plantilla:'',
        producto:'',
        asunto:'',
        formato_json:null,
        formato_html:null,
    })
    
    const{nombre_plantilla,producto,asunto}=plantilla

    const traerDatosPlantilla = async () =>{
 
          const datos_plantilla =  await dispatch(traerPlantilla(id_plantilla))
          const templateJson = JSON.parse(datos_plantilla.formato_json)
          emailEditorRef.current.editor.loadDesign(templateJson);
          setPlantilla(datos_plantilla)
        
    }

    const handleChange = (evt) => {
        const value = evt.target.value;
        setPlantilla({
          ...plantilla,
          [evt.target.name]: value
        });
      }
    
    const exportHtml = () => {

      if(nombre_plantilla!=='' && producto!=='')
      {  
        emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
     
        const datosPlantilla = {
            nombre_plantilla:plantilla.nombre_plantilla,
            producto:plantilla.producto,
            asunto:plantilla.asunto,
            formato_html:html,
            formato_json:JSON.stringify(design),
            id_plantilla:id_plantilla?id_plantilla:null
        }
     
        if(design && html)
        { 
            if(!id_plantilla)
            {
                dispatch(guardarPlantillaCorreo(datosPlantilla))
            }
            else
            {
                dispatch(actualizarPlantillaCorreo(datosPlantilla))
            }
        }
        else
        {
            avisoError('La plantilla esta vacía...')
        }
      });
      }
      else
      {
          avisoError('Debe completar los campos...')
      }
    };
  
    const onLoad = () => {
      // editor instance is created
      // you can load your template here;
      // const templateJson = {};
      // emailEditorRef.current.editor.loadDesign(templateJson);

    }
  
    const onReady = () => {
      // editor is ready
      console.log('onReady');
    };
  
    useEffect(()=>{
        if(!proyectos.length) {
            dispatch(listarProyectos())
          }
          if(id_plantilla)
          {
             traerDatosPlantilla()
          }
    },[])

    return (
      <Layout>

<div className="Trabajadores mb-3">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            
              <HeaderContent title="Editor de Plantillas" icon="group" />
              <Breadcrumb
                      link={[
                              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                              { url: "/listar-plantillas", nombre: "Plantillas" },
                              { url: "", nombre: "Editor" },
                            ]}
                    />

              <div className="row">
                      <div className="col-md-6">
                      <div className='card'>
                        <div className='card-body' >
                            <div className="row">
                            <div className="col-12">
                            <h4>Datos de la Plantilla</h4>
                            <br/>
                            <Box component="fieldset" mb={3} borderColor="transparent">
                                <label htmlFor="">Nombre de plantilla:</label>
                                <input type="text" name="nombre_plantilla" value={nombre_plantilla} className='form-control' onChange={handleChange}  required />
                            </Box>
                            <Box component="fieldset" mb={3} borderColor="transparent">
                                <label htmlFor="">Asunto:</label>
                                <input type="text" name="asunto" value={asunto} className='form-control' onChange={handleChange}  required />
                            </Box>
                            <Box component="fieldset" mb={3} borderColor="transparent">
                                <label>Producto:</label>
                                <select name="producto" value={producto} className='form-control' onChange={handleChange}  required >
                                <option value="">Seleccione un producto...</option>
                                {
                                    proyectos?.map((proyecto,i)=>(
                                        <option key={i} value={proyecto.id_proyecto}>{proyecto.nombre_proyecto}</option>
                                    ))
                                }
                                </select>
                            </Box>
                        
                      
                            <Box component="fieldset" mb={3} borderColor="transparent" >
                                <button className="btn btn-primary btn-submit mr-2" onClick={exportHtml}>
                                    Guardar Plantilla
                                </button>
                            </Box>
                            </div>
                            </div>
                        </div>
                    </div> 
                      </div>
              </div>
          </div>
        </div>
      </div>
  
        <EmailEditor
          ref={emailEditorRef}
          onLoad={onLoad}
          onReady={onReady}
        />
      </Layout>
    );
};

export default MailEditorSN;