import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actualizarEstadoLeads } from "../../../../actions/leadsActions";
import { updateStateModalBasic } from "../../../../actions/modalActions";
import { Button, Form, Modal, Spinner, Alert } from "react-bootstrap";

const useEstados = () => {
  const { estados } = useSelector((state) => state.leads);
  return estados;
};

const useSubmitForm = (leads, resetSeleccion) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e, nuevoEstado) => {
    e.preventDefault();
    if (nuevoEstado !== "") {
      setLoading(true);
      setSuccessMessage("");
      setErrorMessage("");
      try {
        await dispatch(actualizarEstadoLeads(leads, nuevoEstado));
        resetSeleccion(null);
        dispatch(updateStateModalBasic());
        setSuccessMessage("Estado de leads actualizado correctamente.");
      } catch (error) {
        setErrorMessage("Hubo un error al actualizar el estado de los leads.");
      } finally {
        setLoading(false);
      }
    }
  };

  return { handleSubmit, loading, successMessage, errorMessage };
};

const FormularioCambioEstado = ({ leads, resetSeleccion }) => {
  const estados = useEstados();
  const [nuevoEstado, setNuevoEstado] = useState("");
  const { handleSubmit, loading, successMessage, errorMessage } = useSubmitForm(leads, resetSeleccion);

  return (
    <Modal.Body>

      <Form onSubmit={(e) => handleSubmit(e, nuevoEstado)}>
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form.Group controlId="nuevoEstado">
          <Form.Label>Nuevo Estado</Form.Label>
          <Form.Control
            as="select"
            value={nuevoEstado}
            onChange={(e) => setNuevoEstado(e.target.value)}
            disabled={loading}
          >
            <option value="">Seleccionar estado...</option>
            {estados.map((estado) => (
              <option key={estado.id_estado} value={estado.id_estado}>
                {estado.nombre_estado}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner as="span" animation="border" size="sm" /> : "Cambiar Estado"}
        </Button>
      </Form>
    </Modal.Body>

  );
};

export default FormularioCambioEstado;
