import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listarProformas } from "../../../actions/leadsActions";
import DataTable from 'react-data-table-component';
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
const ListaProformas = () => {
    const dispatch = useDispatch();
    const { proformas, loading } = useSelector(state => state.leads);

    useEffect(() => {
        dispatch(listarProformas());
    }, [dispatch]);

    console.log('Proformas para mostrar:', proformas); // Agrega esta línea para verificar qué datos tienes en el estado

    return (
        <Layout>
            <div className="Trabajadores">
                <div className="Trabajadores__container">
                    <div className="Trabajadores__head">
                        <HeaderContent title="Proformas" icon="file-alt" />
                        <Breadcrumb
                            link={[
                                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                                { url: "", nombre: "Proformas" },
                            ]}
                        />
                    </div>
                    <div className="Trabajadores__body container-fluid px-0 content-actions mt-1">
                        <SpinnerLoading loading={loading}>
                            <DataTable
                                columns={[
                                    { name: "Código", selector: "codigo_proforma", sortable: true },
                                    { name: "Cliente", selector: "cliente_nombre", sortable: true },
                                    { name: "Fecha", selector: "fecha", sortable: true },
                                    { name: "Estado", selector: "estado", sortable: true },
                                ]}
                                data={proformas}
                                pagination
                                paginationPerPage={10}
                            />
                        </SpinnerLoading>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ListaProformas;
