import { faArrowDown, faArrowUp, faChartLine, faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {Table,Card, Popover, OverlayTrigger} from 'react-bootstrap';
const resultadoRecaudacion = ({recaudacion}) => {

    if(recaudacion===undefined || !recaudacion || recaudacion.lenght===0 ) return (
        <>
        <Card className="p-3 mb-3">
         <Card.Title>Reporte de Recaudación</Card.Title>
         <Card.Body>
            No hay datos para mostrar
         </Card.Body>
        </Card>
        </>
    );
    return (
        <>
        <Card className="p-3 mb-3">
         <Card.Title>Reporte de Recaudación</Card.Title>
         <Card.Body>
       <Table striped bordered hover>
        <thead>
            <tr>
            <th>Rango</th>
            <th>Pagos Programados</th>
            <th>Pagos Hechos</th>
            <th>Pagos Pendientes</th>
            <th>Detalle</th>
            <th> </th>
            </tr>
        </thead>
        <tbody>
        {
            recaudacion.map((dato,i)=>(
            <tr key={i}>
            <td>{dato.fecha_inicio} hasta {dato.fecha_fin}</td>
            <td className="text-right"> <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Popover id="popover-basic">
                    <Popover.Title as="h3">Desgloce</Popover.Title>
                    <Popover.Content>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Descripción</th>
                                    <th>Monto</th>
                                    <th>(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Activos</td>
                                    <td>S/.{dato.activos.monto}</td>
                                    <td>{dato.activos.porcentaje}</td>
                                </tr>
                                <tr>
                                    <td>Desertados</td>
                                    <td>S/.{dato.perdida.monto}</td>
                                    <td>{dato.perdida.porcentaje}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Popover.Content>
                </Popover>}><span style={{cursor:'pointer'}}>S/. {dato.meta.monto}</span></OverlayTrigger> <strong> ({dato.meta.porcentaje!=='NAN%' ? dato.meta.porcentaje : 0})</strong></td>
            <td className="text-right">S/. {dato.pagado.monto} <strong> ({dato.pagado.porcentaje!=='NAN%' ? dato.pagado.porcentaje : 0})</strong></td>
            <td className="text-right">S/. {dato.pendiente.monto ? dato.pendiente.monto : 0}<strong> ({dato.pendiente.porcentaje!=='NAN%'  ? dato.pendiente.porcentaje : 0 })</strong></td>
            <td className="d-flex text-center"><a href={dato.enlaceMeta} className="btn btn-info" rel="noopener noreferrer"><FontAwesomeIcon icon={faChartLine} /></a></td>
            <td><span className={parseFloat(dato.pagado.porcentaje)>90?'semaforo_rn verde':'semaforo_rn rojo'} style={{margin:'auto'}}></span></td>
            </tr>

            ))
        }
           
        </tbody>
        </Table>
        </Card.Body>
        </Card>
        </>
    );
};

export default resultadoRecaudacion;
