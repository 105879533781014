import React from 'react';

import './ListaEncuestasDocente.scss'
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faFileExcel, faLink } from '@fortawesome/free-solid-svg-icons'
import { Table, Alert, Button, Badge } from 'react-bootstrap';
import * as moment from 'moment'

//Redux
import { connect } from 'react-redux';
import { avisoCorrecto, avisoCargando } from '../AvisosMensajes/MensajesSwalf';

const COLUMS_TABLA = [
  { label: "Sesión" },
  { label: "Nombre" },
  { label: "Docente" },
  { label: "Fecha" },
  { label: "Estado" },
  { label: "Acciones" }
];

const Columna = ({ label, onlyClase }) => {
  return (
    <>
      <th className={onlyClase}>{label}</th>
    </>
  );
};

const FilaTablas = props => {

  const { index, reporte, redirigirRespuesta } = props
  
  const copiarLink = valor => {
    var aux = document.createElement("input");
    aux.setAttribute("value", `https://encuestas.inedi.edu.pe/form/${valor}`);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    avisoCorrecto('Link de la encuesta copiada')
  }

  return (
    <tr>
      <td align='center' className={`fila fila-item"`}>{ reporte.id_sesion }</td>
      <td className={`fila fila-nombre`}>{reporte.nombre_sesion}</td>
      <td className={`fila fila-nombre`}>{reporte.nombre_usuario} {reporte.apellido_usuario}</td>
      <td className={`fila fila-fecha`}>{reporte.fecha_sesion ? moment(reporte.fecha_sesion).format('DD MMM YYYY') : '---'}</td>
      <td align='center'>
        <Badge variant={`${reporte.id_tp_estado_formulario === '2' ? 'success' : 'warning'}`}>
          {reporte.id_tp_estado_formulario === '2' ? 'Completo' : 'Pendiente'}
        </Badge>
      </td>
      <td align='center' className={`fila fila-test`}>
        <Button
          variant='success'
          size='xs'
          onClick={() => redirigirRespuesta(reporte.id_discente_formulario)}
          className='btn-icon'
        >
          <FontAwesomeIcon icon={faArrowAltCircleRight} />
          Resultado
        </Button>
        <Button 
          className='btn-icon'
          variant='info'
          size='xs'
          onClick={() => copiarLink(reporte.url)}
        >
          <FontAwesomeIcon icon={faLink} />
          Link
        </Button>
      </td>
    </tr>
  );
};

const ListaEncuestasDocente = props => {

  const { encuestasDocente,id_proyecto } = props
  const params = useParams()
  const history = useHistory()
  const { idTutor } = params


  const handleUrlExcelDescarga = () => {
    return `${process.env.REACT_APP_API_URL}/admin/excel/respuestas/docentes/${id_proyecto}`;
  }


  const redirigirRespuesta = (idDocenteFormulario) => {
    if(idDocenteFormulario) {
      history.push(`/tutores/${idTutor}/detalles/encuesta-docente/${idDocenteFormulario}`)
      return
    }

    avisoCargando('El docente aun no llena la encuesta')
  } 

  return ( 
    <div className="lista-encuestas-docentes">
      <div className="lista-sesiones__main-tabla">
        <Button href={handleUrlExcelDescarga()} variant='primary' className='btn-icon'>
                                        <FontAwesomeIcon icon={faFileExcel} />
                                        Descargar
        </Button> 
        <Table responsive="md" className="main-tabla__sesiones">
          <thead className="header-tabla">
            <tr className="main__tabla__header-color">
              {COLUMS_TABLA.map((column, index) => (
                <Columna key={index} {...column} />
              ))}
            </tr>
          </thead>
          <tbody className="body-tabla">
            { encuestasDocente.length > 0 ? (
              encuestasDocente.map((reporte, index) => (
                <FilaTablas
                  key={reporte.id_tutor_formulario}
                  reporte={reporte} 
                  index={index}
                  redirigirRespuesta={redirigirRespuesta}
                />
              ))
            ) : (
              <Alert variant='warning'>
                No hay encuestas
              </Alert>
            )}
          </tbody>
        </Table>
      </div>
    </div>
   );
}
 
const mapStateToProps = state => ({
  encuestasDocente: state.encuestas.encuestasDocente
}) 

export default connect(mapStateToProps, {})(ListaEncuestasDocente);