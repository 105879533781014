import React, { useEffect } from 'react';
import Sidemenu from './Sidemenu'
import Navigation from './Navigation'
import Footer from './Footer'
import ReminderManager from '../view/components/ReminderManager/ReminderManager';

const Layout = ({children}) => {
  useEffect(()=>{
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission().then(function(result) {
        //console.log(result);
      });
    }
  },[])

    return (
      <div className="nav-md">
        <div className="container body">
          <div className="main_container">
            <div
            id="m-nav"
              className="col-md-3 left_col d-none d-md-block"
            >
              <Sidemenu />
            </div>
            <div>
              <Navigation />
            </div>
            <div
              className="right_col"
              role="main"
              style={{ minHeight: "610px" }}
            >
                <ReminderManager />
                {children}
            </div>
            <div>
                <Footer/>
            </div>
          </div>
        </div>
      </div>
    );
}
 
export default Layout;