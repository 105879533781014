import React from 'react';
import ModalBasicwithRedux from '../../../../../containers/ModalBasicwithRedux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faAddressCard,
  faUser,
  faChartLine,
  faBook,
  faBookmark,
  faChartPie
} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import './VistaDatosPersonales.scss';
import Temas from '../../../../pages/AreaTrabajo/Academica/Temas/Temas';


const getEdad = (fechaNacimiento) => {
  if(fechaNacimiento === undefined) {
    return 'No hay datos';
  }
  const dateNacimiento = new Date(fechaNacimiento);
  return moment(new Date()).diff(moment(dateNacimiento), "years")
}

const VistaDatosPersonales = ({ datos, handleEditar }) => {

  const {nombre_usuario, apellido_usuario, calificacion, ultima_calificacion, area , temas } = datos;

  return ( 
    <div className="VistaDatosPersonales">
      <section className='VistaDatosPersonales__container'>
          <div className="box-header with-border d-flex align-items-center justify-content-between">
            <h6 className='title-head mb-0'>
              <FontAwesomeIcon icon={faUser} />
              Perfil
            </h6>
            {
              handleEditar && <div className='cursor-pointer'>
                <FontAwesomeIcon icon={faEdit} className='mr-1' />
                <span onClick={handleEditar}>Editar</span>
              </div>
            }
          </div>
        <div className='VistaDatosPersonales__body p-3 py-4'>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faAddressCard}/>
              <span>Usuario</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{nombre_usuario}{' '}{apellido_usuario}</span>
            </div>
          </div>
         
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faChartLine} />
              <span>Calificación Promedio</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{calificacion.calificacion_promedio ? calificacion.calificacion_promedio + ' %': 'No hay datos'}</span>
            </div>
          </div>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faChartPie} />
              <span>última Calificación</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{ultima_calificacion.calificacion_promedio ? ultima_calificacion.calificacion_promedio +' %': 'No hay datos'}</span>
            </div>
          </div>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faBookmark} />
              <span>Área</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{area.nombre_area_dictado ? area.nombre_area_dictado : 'No hay datos'}</span>
            </div>
          </div>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faBook} />
              <span>Tema</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{ temas.length>0 ? temas[temas.length-1].tema_area : 'No hay datos'}</span>
            </div>
          </div>
        </div>
      </section>
      <ModalBasicwithRedux/>
    </div>
   );
}
 
export default VistaDatosPersonales;