import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { datosGeneralIngresos } from '../../../actions/campaignsActions';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import MonthPicker from './MonthPicker';
import * as moment from 'moment';


const FormularioFiltro = ({setTotales,setLoading}) => {
const dispatch = useDispatch();
const [periodo,setPeriodo]=useState({
  year:moment().format('YYYY'),
  month:moment().format('MM')
})
const handleChange = (value)=>{
  setPeriodo({
      ...periodo,
      year:moment(value).format('YYYY'),
      month:moment(value).format('MM')
  })
}

const traerDatos = async () =>{
  if(!periodo)
  {
    avisoError('Debe seleccionar un producto...')
  }
  else
  {
     setLoading(true)
     const datos = await dispatch(datosGeneralIngresos(periodo))
     setTotales(datos)
     setLoading(false)
  }
}

 return (
 
    <div className="box">
      <div className="box-header with-border">
        <h5
            style={{ color: "#434343" }}
          >
          <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
        </h5>
      </div>
      <div className="row p-2">
        <div className="col-7 mb-2">
          <MonthPicker handleChange={handleChange} />
        </div>
        <div className="col-5 pt-4">
        <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
        </div>
      </div>
    </div>
  
  
    );
};

export default FormularioFiltro;
