import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { cargarSesionesEventos, listarProyectosGantt } from '../../../actions/cursosActions';
import GanttDiagram from './GanttDiagram';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import Comunicado from './Comunicado.js';
import { listarUltimosComunicados, ultimoComunicado, updateMostrarComunicado } from '../../../actions/popupsActions';
import Accesos from './Accesos';
import EventsCalendar from './Horario/EventsCalendar.js'
import { cargarCumples, cargarDatosComercial } from '../../../actions/userActions';

import LineHistorialVentas from './ChartComponents/LineHistorialVentas';

const InicioComercial = ({usuario}) => {
  const dispatch = useDispatch();
  const [proyectos,setProyectos] = useState(null)
  const [loading,setLoading] = useState(true)
  const [modo,setModo] = useState('year')
  const [comunicado,setComunicado] = useState(null)
  const {comunicados} = useSelector(state=>state.comunicados)
  const [eventos, setEventos]=useState([])
 
  const [datosComercial,setDatosComercial]=useState(null)
  const handleModo = (modo)=>{
      setModo(modo)
  }
  const {show}=useSelector(state=>state.popUp)

  const cargarEventos = async (events) =>{
     let dataEventos = [];
     let thisYear = new Date();
     events.map(evento => {
       
         dataEventos = [...dataEventos,{
             title: 'Felíz cumpleaños - '+evento.nombre_usuario,
             start: new Date(evento.fecha_naci+'-'+thisYear.getFullYear()),
             end: new Date(evento.fecha_naci+'-'+thisYear.getFullYear())
         }]
     })
     setEventos(dataEventos)
   }


  useEffect(() => {
    if(!proyectos) {
      const fetchData = async () =>{
          const respuesta = await (dispatch(listarProyectosGantt()))
          setProyectos(respuesta)
          setLoading(false)

          const datos_comercial = await(dispatch(cargarDatosComercial(usuario.id_usuario)))

          if(datos_comercial)
          {
            setDatosComercial(datos_comercial)
          }
          
          const dato = await (dispatch(ultimoComunicado()))
          setComunicado(dato.data)

          const hb_events = await (dispatch(cargarCumples()))

          if(hb_events)
          {
           await cargarEventos(hb_events)
          }

       
          if(dato.data)        
          dispatch(updateMostrarComunicado())
          dispatch(listarUltimosComunicados())
        }
        fetchData();
      }
  }, [proyectos])

  return (
    <>
       { comunicado ? <Comunicado comunicado={comunicado} show={show} cerrarComunicado={updateMostrarComunicado} /> : ''}
        <Row className="w-100 mt-2">
            <Col md={12}>
            <div className="box box-primary">
              <div className="box-body">
              <Row>  

              <Col md={6}> <h3>Productos en Ejecución</h3></Col>
              <Col md={6} className="p-2 text-right"> <button className="btn btn-primary" onClick={()=>handleModo('month')}> Mes </button> <button className="btn btn-primary" onClick={()=>handleModo('year')}> Año</button></Col>

              </Row>

                <SpinnerLoading loading={loading} >
                <div style={{height:'400px'}}>
                {  proyectos ? <GanttDiagram proyectos={proyectos} modo={modo}/> : '' }
                </div>
                </SpinnerLoading>   
                         
                </div>
             
            </div>
            </Col>
        </Row>
        <Row className="w-100 mt-2">
        <Col md={6} >
          <Row>
          <Col md={6}>
            <Card className="p-1 mb-3">
                <Card.Body>
                 <Row>
                   <Col md={3} className='d-flex justify-items-center align-items-center'>
                      <i className='fa fa-3x fa-file text-primary'></i>
                   </Col>
                   <Col md={9}>
                   <h1>{datosComercial?datosComercial.tratos_x_cerrar:0}</h1>
                      <p>Tratos por Cerrar (Mes)</p>
                   </Col>
                 </Row>
                </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="p-1 mb-3">
                <Card.Body>
                <Row>
                   <Col md={3} className='d-flex justify-items-center align-items-center'>
                      <i className='fa fa-3x fa-phone text-danger'></i>
                   </Col>
                   <Col md={9}>
                   <h1>{datosComercial?datosComercial.llamadas_programadas:0}</h1>
                      <p>Llamadas programadas (Hoy)</p>
                   </Col>
                </Row>
                
                </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="p-1 mb-3">
                <Card.Body>
                  <Row>
                   <Col md={3} className='d-flex justify-items-center align-items-center'>
                      <i className='fa fa-3x fa-users text-warning'></i>
                   </Col>
                   <Col md={9}>
                   <h1>{datosComercial?datosComercial.leads:0}</h1>
                      <p>Leads Nuevos (Hoy)</p>
                   </Col>
                 </Row>
                
                </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="p-1 mb-3">
                <Card.Body>
                <Row>
                   <Col md={3} className='d-flex justify-items-center align-items-center'>
                      <i className='fa fa-3x fa-edit text-success'></i>
                   </Col>
                   <Col md={9}>
                   <h1>{datosComercial?datosComercial.tratos_cerrados:0}</h1>
                      <p>Ventas Cerradas (Mes)</p>
                   </Col>
                 </Row>
                </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="p-1 mb-3">
                <Card.Body>
                <Row>
                   <Col md={3} className='d-flex justify-items-center align-items-center'>
                      <i className='fa fa-3x fa-line-chart text-success'></i>
                   </Col>
                   <Col md={9}>
                   <h1>{datosComercial?datosComercial.mi_ranking.map((rank,i)=>{
                     if (usuario.id_usuario===rank.id_usuario) return rank.puesto 
                   }):'-'}</h1>
                      <p>Mi posición en el Ranking</p>
                   </Col>
                 </Row>
                </Card.Body>
            </Card>
          </Col>
          </Row>

        </Col>
        <Col md={6}>
            <Card>
              <Card.Body>
              { datosComercial ? <LineHistorialVentas datos={datosComercial.grafico} /> : <p>Cargando gráfico....</p>}
              </Card.Body>
            </Card>
        </Col>
        </Row>
  <Row className="w-100 mt-2">
    <Col md={6}>
    <Card className="p-3 mb-3">
         <Card.Body>
         <h3>Comunicados</h3>
        {
            comunicados.length!=0 ? comunicados.map((comunicado,i)=>
              (  <div key={i} className="row">
                    <div className="col-12 p-2 mb-2" style={{border:'2px dashed #ebebeb', borderRadius:'8px'}}>
                        <span className="pull-right" style={{fontWeight:'600'}}>{comunicado.fecha_registro}</span>
                        <div className="p-2"><h5> {comunicado.titulo} </h5></div>
                        <div className="p-2 mt-2"  dangerouslySetInnerHTML={{__html:comunicado.mensaje}}></div>  
                       { comunicado.enlace!='' ? <div className="p-2 mt-2"> <a className="btn btn-danger mt-3" href={comunicado.enlace} target="_blank">Más información...</a></div> :''}
                        
                    </div>
                </div>)
            ) : 'No hay comunicados recientes.'
        }
         </Card.Body>
    </Card>
    </Col>
    <Col md={6}>
      <Row>
        <Col md={12}>
        <Card className="p-1 mb-3">
            <Card.Body>
                <Accesos/>
            </Card.Body>
        </Card>
        </Col>
        <Col md={12}>
        <Card className="p-1 mb-3">
            <Card.Body>
            <h3>Cumpleaños</h3>
            <EventsCalendar eventos={eventos} />
            </Card.Body>
        </Card>
        </Col>
      </Row>
    </Col>
  </Row>
  </>
     );
}
 
export default InicioComercial;