import React from 'react';

const TablaReporte = ({datos}) => {
    return (
       <table className='table table-responsive' >
           <thead>
               <tr>
                   <th>Asesor</th>
                   {datos.labels.map((label,i)=>(
                       <th key={i}>{label}</th>
                   ))}
                   <th>Totales</th>
                   <th>%</th>
               </tr>
           </thead>
           <tbody>
               {datos.dataSets.map((asesor,i)=>(
                   <tr key={i}>
                        <td>{asesor.label}</td>
                        {asesor.data.map((monto,j)=>(
                            <td style={{textAlign:'right'}} key={j}>{monto}</td>
                        ))}
                        <td style={{textAlign:'right',fontWeight:'bold'}}>{datos.Total_asesor[i]}</td>
                        <td style={{textAlign:'right'}}>{datos.Total_periodo>0 ? parseFloat((datos.Total_asesor[i]/datos.Total_periodo)*100).toFixed(2):0}%</td>
                   </tr>
               ))}
               <tr style={{fontWeight:'bold'}}>
                    <td>Totales:</td>
                    {datos.Totales.map((monto,j)=>(
                            <td style={{textAlign:'right'}} key={j}>{monto}</td>
                        ))}
                    <td style={{textAlign:'right'}}>{datos.Total_periodo}</td>
                    <td style={{textAlign:'right'}}> 100% </td>
               </tr>
           </tbody>
       </table>
    );
};

export default TablaReporte;