import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';

const CircularProgressWithLabel = (props) => {
    return (
        <div className='d-flex flex-column' style={{position:'relative'}} >
          <CircularProgress variant="determinate" {...props} />
          <div style={{position:'absolute', top:12,left:12}}
          >
            
              {`${Math.round(props.value)}%`}
            
          </div>
        </div>
      );
};

export default CircularProgressWithLabel;