import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { datosGeneralCampaign } from '../../../actions/campaignsActions';
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';

const FormularioFiltro = ({ setTotales, setLoading }) => {
  const dispatch = useDispatch();
  const [proyecto, SelectProyecto] = useSelectProyecto(null)

  const traerDatos = async () => {
    if (!proyecto) {
      avisoError('Debe seleccionar un producto...')
    }
    else {

      const datosFiltro =
      {
        producto: proyecto ? proyecto.id_proyecto : null,
      }
      setLoading(true)
      const datos = await dispatch(datosGeneralCampaign(datosFiltro))
      setTotales(datos)
      setLoading(false)
    }
  }

  return (

    <div className="box">
      <div className="box-header with-border">
        <h5
          style={{ color: "#434343" }}
        >
          <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
        </h5>

      </div>
      <div className="row p-2">
        <div className="col-7 mb-2">
          <label htmlFor="">Producto:</label>
          <SelectProyecto />
        </div>
        <div className="col-5 pt-4">
          <button className="btn btn-primary" onClick={traerDatos} >Consultar</button>
        </div>
      </div>
    </div>


  );
};

export default FormularioFiltro;
