import React from 'react';
import {Bar, Line} from 'react-chartjs-2';


const LineHistorialVentas = ({datos}) => {

    const state = {
        labels: datos.labels,
        datasets: [
          {
            label: 'Ventas cerradas',
            backgroundColor: [
                'rgba(30, 77, 119, 0.2)',
              
            ],
            borderColor: [
                'rgba(30, 77, 119, 1)',
            ],
            borderWidth: 1,
            data: datos.data,
            fill: false,
            lineTension:0,
        
          }
        ]
      }
      


    return (
        <Line
         data={state}
          options={{
            title:{
              display:true,
              text:datos.title,
              fontSize:20
            },
            legend:{
              display:true,
              position:'bottom'
            },
            scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
          }}
        >  
        </Line>
    );
};

export default LineHistorialVentas;