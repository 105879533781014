import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { listarProductosAsesores } from '../../../actions/trabajadoresActions';
import Layout from '../../../app/Layout';
import ModalBasicwithRedux from '../../../containers/ModalBasicwithRedux';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import ProductoAsesores from './ProductoAsesores';


const ConfiguracionAsesores = () => {


    const {productoAsesores,loading} = useSelector(state=>state.trabajadores)
    const [filtroP,setFiltroP] = useState('')
    const dispatch=useDispatch()
    const fetchData = async ()=>{
        
     await dispatch(listarProductosAsesores())

    }


    useEffect(()=>{
        fetchData()
    },[])

    const prodBusqueda = productoAsesores ? productoAsesores.filter(
        prod =>
          (  prod.nombre_proyecto &&  
            prod
            .nombre_proyecto
            .toLowerCase()
            .includes(filtroP.toLowerCase()) )
    
      ) : null



    return (
        <Layout>
        <div className="Trabajadores">
          <div className="Trabajadores__container">
            <div className="Trabajadores__head mb-4">
              <HeaderContent title="Asignación de Asesores" icon="group" />
              <Breadcrumb
                link={[
                  { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                  { url: "/equipo-ventas", nombre: "Equipo" },
                  { url: "", nombre: "Asignación" },
                ]}
              />
            <div className="row">
                <div className="col-md-6">
                  <div className='card'>
                        <div className='card-body'>
                            <label>Filtro</label>
                            <input className='form-control' name='producto' placeholder='Nombre de producto' value={filtroP} onChange={(e)=>setFiltroP(e.target.value)} />
                        </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-end align-items-end">
                
                </div>
            </div>
            </div>
            <div className='row'>
            <ModalBasicwithRedux />
                <SpinnerLoading loading={loading}>
                  <div className='card'>
                    <div className='card-body'>
                    <table className='table responsive'>
                      <thead>
                          <tr>
                            <th>
                              Producto
                            </th>
                            <th>
                              Asesores
                            </th>
                          </tr>
                      </thead>
                      <tbody>
                      { prodBusqueda?.length ? prodBusqueda.map((producto,i)=>(
                            <ProductoAsesores key={i} producto={producto} />
                        )): <tr><td colSpan={2}>No hay datos</td></tr>}
                      </tbody>
                    </table>
                    </div>
                  </div>
               </SpinnerLoading>
            </div> 
          </div>
        </div>
      </Layout>
    );
};

export default ConfiguracionAsesores;