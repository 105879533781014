import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { listarFases, registrarTrato } from '../../../actions/leadsActions';
import { listarProyectos } from '../../../actions/cursosActions';

const FormularioNuevoTrato = ({registro}) => {

  const usuario = useSelector(state=> state.usuario.usuario);
  const {tratos,fases} = useSelector(state=> state.leads);
  const {proyectos} = useSelector(state=>state.cursos)
  const dispatch = useDispatch();
  const initialState = {
    lead:registro.id_lead,
    id_usuario: usuario ? usuario.id_usuario : null,
    fase:'',
    importe:0,
    descripcion:'',
    fecha_cierre:'',
    producto:'',  
  }
  const [datosLead,setdatosLead]=useState(initialState)

  const {fase, importe, descripcion, producto,fecha_cierre} = datosLead

  const handleChange = (evt) => {
    const value = evt.target.value;
    setdatosLead({
      ...datosLead,
      [evt.target.name]: value
    });
  }


  const editarLead = async (e) =>{
    e.preventDefault();
 
   await dispatch(registrarTrato(datosLead,tratos,fases))
  //console.log(datosLead)
  }

  useEffect(()=>{
    dispatch(listarProyectos())
    dispatch(listarFases())
  },[])
  return (
    <div style={{padding:'15px'}}>
      <form onSubmit={(e) => editarLead(e)}>
        <div className="row">
          <div className="col-12">
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Lead:</label>
            <p>{registro.nombres} {registro.apellidos}</p>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
              <label>Producto:</label>
              <select name="producto" className="form-control" value={producto} onChange={handleChange} required>
              <option value="">Seleccione un producto...</option>
              { proyectos.length>0 ? proyectos.map((producto,i)=>{
                return <option key={i} value={producto.id_proyecto}>{producto.nombre_proyecto}</option>
                }):null}
              </select>
          </Box>
          </div>
          <div className="col-12">
          <Box component="fieldset" mb={3} borderColor="transparent">
          <label>Fase:</label>
            <select name="fase" className='form-control' value={fase} onChange={handleChange} required >
            <option value="">Escoge una opción...</option>
              { fases.length>0 ? fases.map((fase,i)=>{
               return <option key={i} value={fase.id_fase}>{fase.nombre_mostrar}</option>
              }):null}
            </select>
          </Box>  
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label htmlFor="">Fecha Cierre:</label>
            <input type="date" name="fecha_cierre" value={fecha_cierre} className='form-control' onChange={handleChange} required />
          </Box>      
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Importe:</label>
            <input name="importe" value={importe} className='form-control' placeholder='0.00' pattern='[0-9]+([.])?([0-9]+)?' onChange={handleChange} required />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Descripción:</label>
            <textarea name="descripcion" className="form-control" value={descripcion} onChange={handleChange} />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent" >
          <button type="submit" className="btn btn-primary btn-submit mr-2" >
           Guardar información
          </button>
          </Box>
          </div>
        </div>
      </form>
  </div>
   );
}
 
export default FormularioNuevoTrato;