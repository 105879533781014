import React, {useState, useEffect} from 'react';
//


//Redux
import { useDispatch, useSelector } from 'react-redux';
import { agregarAsesorProducto } from '../../../actions/trabajadoresActions';
import useSelectAsesores from "../../../hooks/useSelectAsesores";
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const FormularioAsignacion = ({producto}) => {

const [asesor, SelectAsesor] = useSelectAsesores(null)
const {productoAsesores,loading} = useSelector(state=>state.trabajadores)
const dispatch = useDispatch();

const initialState = {
  producto:producto,
  asesor:null
}
const[datosAsignacion,setDatosAsignacion] = useState(initialState)

const handleSubmit = (e) =>
{
    e.preventDefault();
    if(asesor)
      {

      dispatch(agregarAsesorProducto(datosAsignacion,productoAsesores))
       console.log(datosAsignacion)
      // resetSeleccion(null)
       
      }
    else
      { 
        avisoError("Debe Seleccionar un asesor")
      }

}

 useEffect(()=>{
  if(asesor)
  {
    setDatosAsignacion({
      ...datosAsignacion,
      asesor:asesor
    })
  }
 },[asesor])

 return (
 
  <form id="formNuevo3" style={{padding:'15px'}} onSubmit={e => handleSubmit(e,this)}>

                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Producto:
                      </label>
                    </div>
                    <div className="col-md-7">
                      {datosAsignacion.producto.nombre_proyecto}
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Asesor:
                      </label>
                    </div>
                    <div className="col-md-7">
                       <SelectAsesor/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
         
                  <div className="form-group">
                   
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-upload"></i> Asignar a producto
                  </button>
                </form>
  
    );
};

export default FormularioAsignacion;
