import React from 'react';
import { useSelector } from 'react-redux';

const DetallesSesionModal = () => {
  const { detallesSesion } = useSelector((state) => state.control_capacitacion);

  if (!detallesSesion) return <p>Cargando...</p>;

  return (
    <div>
      <h4>Detalles de la Sesión</h4>
      <p><strong>Sesión:</strong> {detallesSesion.nombre_sesion}</p>
      <p><strong>Archivo:</strong> {detallesSesion.nombre_archivo}</p>
      <p><strong>Ruta Archivo:</strong> {detallesSesion.ruta_archivo}</p>
      <p><strong>Link Drive:</strong> <a href={detallesSesion.link_drive} target="_blank" rel="noopener noreferrer">{detallesSesion.link_drive}</a></p>
      <p><strong>Docente:</strong> {detallesSesion.Nombre_Docente} {detallesSesion.Apellido_Docente}</p>
      <p><strong>Email:</strong> {detallesSesion.email}</p>
    </div>
  );
};

export default DetallesSesionModal;
