import React, { useState, useEffect } from 'react';

const Timer = ({setSeconds, seconds,setRespondido,inicioConversacion}) => {
  const [isActive, setIsActive] = useState(false);
  const [time,setTime]=useState({
      sec:0,
      min:0,
  })
  function toggle() {
    setIsActive(!isActive);
    //console.log('init')
  }

  function reset() {
    setSeconds(0);
    setIsActive(false);
    setTime({
        ...time,
        sec:0,
        min:0
    })
  }

  const printTime = (t)=>{
    let divisor_for_minutes = t % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let secs = Math.ceil(divisor_for_seconds);

    return `${minutes<10?'0'+minutes:minutes}:${secs<10?'0'+secs:secs}`
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
       
       // console.log(seconds)
        let divisor_for_minutes = seconds % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let secs = Math.ceil(divisor_for_seconds);

        setTime({
            ...time,
            sec:secs+1,
            min:minutes
        })
        setSeconds(seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    else if (!isActive && seconds === 0) {
      setTime({
            ...time,
            sec:0,
            min:0
        })
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(()=>{
    let divisor_for_minutes = seconds % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let secs = Math.ceil(divisor_for_seconds);

    setTime({
        ...time,
        sec:secs,
        min:minutes
    })
  },[])

  return (
    <div className="p-3 text-center">
      <h5>Tiempo de llamada</h5>
      <div className="row d-flex flex-column justify-content-center align-items-center p-3" style={{fontSize:'25px'}}>
        <span>{time.min<10?'0'+time.min:time.min}:{time.sec<10?'0'+time.sec:time.sec}</span>
        { inicioConversacion > 0 ? <span style={{color:'#9A89A4',fontSize:'14px',marginTop:'10px'}}> Respondido: { printTime(inicioConversacion)} </span>: null }
      </div>
      <div className="row d-flex justify-content-center align-items-center">
        <a className={`btn btn-primary`} style={{color:'#fff'}} onClick={toggle}>
          {isActive ? 'Colgar' : 'Timbrar'}
        </a>
        {  inicioConversacion===0 ?  <a className={`btn btn-success`} style={{color:'#fff'}} onClick={()=>setRespondido(seconds)}>
          Respondido
        </a> : null}
        <a className="btn btn-danger" style={{color:'#fff'}} onClick={reset}>
          Reiniciar
        </a>
      </div>
    </div>
  );
};

export default Timer;