import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import * as moment from 'moment'
const YearPicker =({setYear}) => {
    const [startDate, setStartDate] = useState(new Date());
    useEffect(()=>{
        if(startDate)
        {   
            const date_ = moment(startDate).format('YYYY')
            setYear(date_)
        }
    },[startDate])
    return (
      <ReactDatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        showYearPicker
        dateFormat="yyyy"
        yearItemNumber={9}
        className="form-control"
      />
    );
  };

export default YearPicker;