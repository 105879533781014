import React, {useEffect, useState} from 'react';
import Reminder from './Reminder';
import { useDispatch, useSelector } from 'react-redux';
import { traerRecordatoriosLlamadas } from '../../../actions/leadsActions';

const ReminderManager = () => {

    const dispatch = useDispatch();
    const [reminders,setReminders] = useState([])
    const usuario = useSelector(state=>state.usuario.usuario)
    const {n_llamadas} = useSelector(state=>state.leads)
    useEffect(()=>{
        const fetchData = async (usr) =>{
            const datos = await dispatch(traerRecordatoriosLlamadas(usr))
            if(datos)
            {
                setReminders(datos);
            }
        }
        //console.log(n_llamadas)
        fetchData(usuario);
    },[n_llamadas])


    return (
      reminders.map((reminder,i)=>{
         return <Reminder reminder={reminder} key={i} />
      })
    );
};

export default ReminderManager;