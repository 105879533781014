import {
	faCheck,
	faEdit,
	faLessThanEqual,
	faSearch,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
	aprobarTodos,
	eliminarTodosCertificado,
	listarCertificadosEmitidos,
	listarCertificadosPorAprobar,
} from "../../../actions/basesCertActions";
import useSelectProyecto from "../../../hooks/useSelectProyectos";
import { avisoError } from "../../components/AvisosMensajes/MensajesSwalf";

const FormularioEmitidos = ({ aprobar = false }) => {
	const dispatch = useDispatch();
	const [proyecto, SelectProyecto] = useSelectProyecto(null);
	const { csv_firma } = useSelector((state) => state.bases);
	const { emitidos } = useSelector((state) => state.bases);
	const { usuario } = useSelector((state) => state.usuario);
	const traerDatos = () => {
		if (proyecto) {
			const datosFiltro = {
				proyecto: proyecto ? proyecto.id_proyecto : null,
				nivel: usuario.id_tp_nivel_usuario,
			};
			
			// console.log(datosFiltro)
			if (!aprobar) {
				dispatch(listarCertificadosEmitidos(datosFiltro));
			} else {
				dispatch(listarCertificadosPorAprobar(datosFiltro));
			}
		} else {
			avisoError("Debe seleccionar un producto...");
		}
	};
	
	const handleAprobarTodos = (id_proyecto) => {
		dispatch(aprobarTodos(id_proyecto));
	};
	const handleEliminarTodos = (id_proyecto) => {
		dispatch(eliminarTodosCertificado(id_proyecto, usuario.id_usuario));
	};
	return (
		<div className="box">
			<div className="box-header with-border">
				<h5 style={{ color: "#434343" }}>
					<FontAwesomeIcon icon={faSearch} /> Filtrar documentos
					emitidos:
				</h5>
			</div>
			<div className="row p-2">
				<div className="col-9 mb-2">
					<label htmlFor="">Producto:</label>
					<SelectProyecto />
				</div>
				<div className="col-12 pt-4">
					<button className="btn btn-primary" onClick={traerDatos}>
						Consultar
					</button>
					{emitidos.length && aprobar ? (
						<button
							className="btn btn-primary"
							onClick={() =>
								handleAprobarTodos(
									proyecto ? proyecto.id_proyecto : null
								)
							}
						>
							{" "}
							<FontAwesomeIcon icon={faCheck} /> Aprobar todos
						</button>
					) : null}
					{proyecto && emitidos.length && !aprobar ? (
						<a
							className="btn btn-primary"
							href={`${
								process.env.REACT_APP_API_URL
							}/admin/zip/certificados/${
								proyecto ? proyecto.id_proyecto : null
							}`}
						>
							Descargar Todos
						</a>
					) : null}
					{usuario.id_tp_nivel_usuario === "1" &&
					csv_firma &&
					emitidos.length ? (
						<a
							className="btn btn-primary"
							href={`watana:?url_logo=${process.env.REACT_APP_API_URL}/files_inedi/icon_inedi2.png&url_masivo_csv=${process.env.REACT_APP_API_URL}/files_inedi/${csv_firma}`}
							target="_blank"
						>
							Firmar Todos <FontAwesomeIcon icon={faEdit} />
						</a>
					) : null}
					{proyecto && emitidos.length ? (
						<button
							className="btn btn-primary"
							onClick={() =>
								handleEliminarTodos(
									proyecto ? proyecto.id_proyecto : null
								)
							}
						>
							{" "}
							<FontAwesomeIcon icon={faTrash} /> Eliminar todos
						</button>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default FormularioEmitidos;
