import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../app/Layout';
import HeaderContent from '../General/HeaderContent';
import Breadcrumb from '../General/Breadcrumb';
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import { crearConstancia } from '../../../actions/leadsActions';

const Constancia = () => {
  const dispatch = useDispatch();
  const { loading, message } = useSelector((state) => state.leads);
  const [nombreGeneral, setNombreGeneral] = useState('');
  const [proyecto, SelectProyecto] = useSelectProyecto(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!proyecto) {
      avisoError('Debe seleccionar un proyecto.');
      return;
    }

    dispatch(crearConstancia(nombreGeneral, proyecto.id_proyecto));
  };

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Agregar Constancia" icon="file-alt" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Agregar Constancia" },
              ]}
            />
          </div>
          <div className="Trabajadores__body container-fluid px-0 content-actions mt-1">
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border clearfix">
                  <h5 style={{ color: "#434343" }}>
                    Formulario para Agregar Constancia
                  </h5>
                </div>
                <div className="box-primary">
                  <form onSubmit={handleSubmit} className="p-3">
                    <div className="form-group">
                      <label>Nombre General:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={nombreGeneral}
                        onChange={(e) => setNombreGeneral(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Proyecto:</label>
                      <SelectProyecto />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Agregar
                    </button>
                  </form>
                  {message && <p className="alert alert-info mt-3">{message}</p>}
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Constancia;
