import React, { useEffect } from "react";
import Layout from "../../../app/Layout";
import MaterialTableDemo from "../../components/plugins/MaterialTableDemo";
import Breadcrumb from "../General/Breadcrumb";
import HeaderContent from "../General/HeaderContent";
import { faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
	eliminarProyecto,
	listarProyectos,
} from "../../../actions/cursosActions";
import { Button, Badge } from "react-bootstrap";
import { updateStateModalBasic } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
import FormularioProyecto from "../../components/ExaminarProyecto/FormularioProyecto";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading/SpinnerLoading";
import Fatal from "../../components/MessageAlert/Fatal";

const Proyectos = () => {
	const dispatch = useDispatch();
	const { proyectos, loading, error } = useSelector((state) => state.cursos);
	const { usuario } = useSelector((state) => state.usuario);
	const handleEliminar = (idProyecto) => {
		dispatch(eliminarProyecto(idProyecto));
	};

	const botonesAcciones = (proyecto) => (
		<>
			<Button
				className="btn btn-danger eliminar btn-xs"
				onClick={() => handleEliminar(proyecto.id_proyecto)}
			>
				<FontAwesomeIcon icon={faTrash} />
			</Button>
			<Link
				className="btn btn-warning m-0 btn-xs"
				to={`/proyectos/${proyecto.id_proyecto}`}
			>
				<FontAwesomeIcon icon={faEye} />
			</Link>
		</>
	);

	const handleModalNuevo = () => {
		dispatch(
			updateStateModalBasic(<FormularioProyecto />, "Nuevo proyecto")
		);
	};

	const mostraTablaComponente = () => {
		if (loading && proyectos.length === 0)
			return <SpinnerLoading loading={loading} />;
		if (error) return <Fatal message={error} />;
		return (
			<TablaContainer
				botonesAcciones={botonesAcciones}
				proyectos={proyectos}
				handleModalNuevo={handleModalNuevo}
			/>
		);
	};

	useEffect(() => {
		dispatch(listarProyectos());
	}, [dispatch]);

	return (
		<Layout>
			<HeaderContent
				title={"Producto"}
				icon={"menu_book"}
				subItem={
					usuario.id_tp_nivel_usuario === "5"
						? "¡Comprueba si tu producto esta listo!"
						: null
				}
			/>
			<Breadcrumb
				link={[
					{ nombre: "Inicio", url: "/inicio", icon: "fas fa-home" },
					{ nombre: "Productos", url: "/proyectos" },
				]}
			/>
			<div className="proyectos">
				<ModalBasicwithRedux />

				{mostraTablaComponente()}
			</div>
		</Layout>
	);
};

export default Proyectos;

const TablaContainer = ({ botonesAcciones, proyectos, handleModalNuevo }) => {
	const { usuario } = useSelector((state) => state.usuario);
	return (
		<>
			<div className="container-fluid px-0 text-right my-3">
				<Button onClick={handleModalNuevo} className="btn-icon">
					<FontAwesomeIcon icon={faPlus} /> Nuevo Producto
				</Button>
			</div>
			<div className="box my-5">
				<div className="box-header with-border">
					<h5>
						<i className="fas fa-list pr-2"></i>Lista de Producto
					</h5>
				</div>
				<div className="box-body">
					<MaterialTableDemo
						colums={colums(botonesAcciones)}
						tabla={proyectos}
						addConfig={{ key_column: "id_proyecto" }}
					/>
				</div>
			</div>
		</>
	);
};

const colums = (botonesAcciones) => [
	{
		text: "#",
		key: "index",
		align: "center",
		className: "text-center",
		width: 50,
		cell: (_, index) => index + 1,
	},
	{
		text: "ID",
		key: "id_proyecto",
		align: "center",
		sortable: true,
		className: "text-center",
		width: 50,
	},
	{
		text: "Proyecto",
		key: "nombre_proyecto",
		align: "left",
		sortable: true,
		width: 400,
	},
	{
		text: "Fecha inicio",
		key: "fecha_inicio_proyecto",
		align: "center",
		sortable: true,
		className: "text-center",
	},
	{
		text: "Fecha fín",
		key: "fecha_fin_proyecto",
		align: "center",
		sortable: true,
		className: "text-center",
	},
	{
		text: "Fecha Cert.",
		key: "fecha_entrega_cert",
		align: "center",
		sortable: true,
		className: "text-center",
	},
	{
		text: "Días de clase",
		key: "dias_clase",
		align: "center",
		sortable: true,
		className: "text-center",
	},
	{
		text: "Frecuencia",
		key: "frecuencia_clases",
		align: "center",
		sortable: true,
		className: "text-center",
	},
	{
		text: "Estado",
		key: "tp_estado",
		className: "text-center",
		cell: ({ tp_estado_proyecto, id_tp_estado }) => (
			<Badge
				variant={`${
					id_tp_estado === 1
						? "success"
						: id_tp_estado === 2
						? "warning"
						: "danger"
				}`}
			>
				{tp_estado_proyecto}
			</Badge>
		),
	},
	{
		text: "Acciones",
		key: "acciones",
		align: "center",
		width: 90,
		className: "text-center",
		cell: (record) => botonesAcciones(record),
	},
];
