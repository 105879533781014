import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import ModalBasic from "../../Modals/ModalBasic";
import NuevaPregunta from "../FormularioEncuesta/NuevaPregunta";
import NuevoTitulo from "../FormularioEncuesta/NuevoTitulo";
import Rating from "../../../components/plugins/Rating";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useParams } from 'react-router-dom'
import { avisoError } from "../../AvisosMensajes/MensajesSwalf";
import { authAxios } from "../../../../config/authAxios";

//Redux
import { connect } from 'react-redux';
import { 
  agregarEncabezado,
  insertarNuevaPregunta,
  editarPregunta,
  eliminarPreguntaForm 
} from '../../../../actions/encuestasAction';

import "./VistaEncuesta.scss";
import { PreguntaEncuesta } from "../PreguntaEncuesta/PreguntaEncuesta";


const VistaEncuesta = props => {

  //props Redux
  const { encuesta, agregarEncabezado, insertarNuevaPregunta, editarPregunta, eliminarPreguntaForm } = props

  //Estraer los parametros de la url
  const params = useParams()
  const  { idSesion, idTutorSesion,idEventoTutor } = params 
  
  const [titleModal, setTitleModal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formulario, setFormulario] = useState(null);
  const [encabezado, setEncabezado] = useState({
    titulo: "",
    descripcion: "",
  });
  const [preguntas, setPreguntas] = useState([]);

  const { titulo, descripcion } = encabezado;

  const actualizarPregunta = (preguntaActualizada) => {
    
    let copiaPreguntas = [...preguntas];

    copiaPreguntas.map((pregunta) => {
      if (pregunta.id_pregunta_reporte === preguntaActualizada.id_pregunta_reporte) {
        if (preguntaActualizada.id_tp_pregunta === "2") {
          delete pregunta["id_tp_tema_encuesta"];
        } else {
          pregunta.id_tp_tema_encuesta = preguntaActualizada.id_tp_tema_encuesta;
        }
        pregunta.id_obligacion = preguntaActualizada.id_obligacion;
        pregunta.pregunta = preguntaActualizada.pregunta;
        pregunta.id_tp_pregunta = preguntaActualizada.id_tp_pregunta;
        //Consultado a la api
        editarPregunta(pregunta.id_pregunta_reporte, preguntaActualizada)
      }
    });

    setPreguntas(copiaPreguntas);
    
    updateShowModal(false);
  };

  const eliminarPregunta = eliminarPregunta => {
    let copiaPreguntas = [...preguntas]
    copiaPreguntas = copiaPreguntas.filter(pregunta => pregunta.id_pregunta_reporte !== eliminarPregunta.id_pregunta_reporte)
     //Consultado a la api
    eliminarPreguntaForm(eliminarPregunta.id_pregunta_reporte)
    updateShowModal(false)
    setPreguntas(copiaPreguntas)
  }

  const guardarPreguntaEditar = (pregunta) => {
    setTitleModal("Editar pregunta");
    updateShowModal(true);
    guardarFormulario(
      <NuevaPregunta
        preguntaEditar={pregunta}
        actualizarPregunta={actualizarPregunta}
        eliminarPregunta={eliminarPregunta}
      />
    );
  };

  const guardarFormulario = (componente) => {
    updateShowModal(true);
    setFormulario(componente);
  };

  const updateShowModal = (valor) => {
    setShowModal(valor);
  };

  const guardarEncabezado = (infoEncabezado) => {
    
    setEncabezado(infoEncabezado);

    const data = {
      nombre_formulario: infoEncabezado.titulo,
      descripcion: infoEncabezado.descripcion
    }

    //Consultando a la api
    agregarEncabezado(encuesta.id_formulario, data)
    updateShowModal(false);
  };

  const agregarPregunta = (nuevaPreunta) => {
    setPreguntas([...preguntas, nuevaPreunta]);
    //Consultado a la api
    insertarNuevaPregunta(encuesta.id_formulario, nuevaPreunta)
    updateShowModal(false);
  };

  
  const autocompletarEditar = () => {
    
      setEncabezado({
        titulo: encuesta.nombre_formulario,
        descripcion: encuesta.descripcion,
      })

      setPreguntas(encuesta.preguntas)
    
  }

  useEffect(() => {
    if (Object.keys(encuesta).length > 0) {
      autocompletarEditar()

    } 
  }, [encuesta])

  useEffect(() => {
    
    console.log(preguntas)
    
  }, [preguntas]);
  return (
    <div className="vista-encuesta my-3">
      <div className="main-encuesta py-5 px-4">
        <ModalBasic
          show={showModal}
          title={titleModal}
          updateShowModal={updateShowModal}
        >
          {formulario}
        </ModalBasic>
        <div
          className="main-encuesta__header px-3"
          onClick={() => {
                  setTitleModal("Agregar encabezado");
                  guardarFormulario(
                    <NuevoTitulo
                      encabezado={encabezado}
                      guardarEncabezado={guardarEncabezado}
                    />
                  );
                }}
        >
          <div className="main-encuesta__title text-center d-flex justify-content-start">
            <h4 className="mr-3">
              {titulo !== "" ? titulo : "Titulo de encuesta"}
            </h4>
          </div>
          <div className="main-encuesta__descripcion mt-3 mb-5">
            <p>{descripcion !== "" ? descripcion : "Aqui la descripción"}</p>
          </div>
        </div>

        <div className="main-encuesta__body">
          <div className="body-encuesta__preguntas">
            <TransitionGroup>
              {preguntas.map((pregunta, index) => (
                <CSSTransition key={index} timeout={200} classNames="pregunta">  
                  <PreguntaEncuesta
                    pregunta={pregunta}
                    index={index}
                    guardarPreguntaEditar={guardarPreguntaEditar}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
            
            <div className="body-encuestas__preguntas__boton-nuevo px-3">
              <Button
                type="button"
                variant=''
                className='btn-nueva-pregunta mt-4'
                onClick={() => {
                  setTitleModal("Nueva pregunta");
                  guardarFormulario(
                    <NuevaPregunta agregarPregunta={agregarPregunta} />
                  );
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                Nueva pregunta
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  encuesta: state.encuestas.encuesta
})

const mapDispatchToProps = {
  agregarEncabezado,
  insertarNuevaPregunta,
  editarPregunta,
  eliminarPreguntaForm
}

export default connect(mapStateToProps, mapDispatchToProps)(VistaEncuesta);
